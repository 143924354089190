@import 'styles/core';

/* Перебиваем специфичность библиотеки за счёт global */
:global(.data-grid ) {
  & .tableCell:global(.cell) {
    min-width: 234px;
    height: 36px;
    padding: 0 10px;
    background: #FFF;

    &:last-child {
      border-right: none;
    }

    &.smallWidth {
      min-width: 192px;
    }

    &.isError,
    &.isError:global(.selected) {
      border: 1px double var(--colorNegative);

      & .tableCellInner::before {
        background: none;
      }
    }
  }

  & .withValue:global(.cell) {
    /* background-color: #EAF4DD;
    border-top: 1px solid #EAF4DD;
    border-bottom: 1px solid #EAF4DD; */
  }

  & .isFirst:global(.cell) {}

  & .isLast:global(.cell) {}

  & .tableCell.disabledCell:global(.cell) {
    background: var(--colorBackgroundHover);
    border: 1px solid #E7E9EE;
  }

  & .tableCell:global(.cell.read-only) {
    background: #FAFBFC;
    border: none;
  }

  & tr:first-child {
    & .tableCell:global(.cell) {
      border-top: none;
    }
  }

  & tr:last-child {
    & .tableCell:global(.cell) {
      border-bottom: none;
    }
  }
}

.tableCellInner {
  position: relative;
  height: 100%;
  max-height: 36px;

  & :global(.data-editor),
  & :global(.value-viewer) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  & :global(.data-editor){
    background: none;
    border: none;
    padding: 0 16px;
    width: 100%;
  }

  & :global(.value-viewer) {
    line-height: 20px;
    color: var(--colorTextPrimary);
  }
}

.withValue {
  & .tableCellInner::before {
    content: '';
    position: absolute;
    /* top: -1px; */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  /* &:global(.selected) .tableCellInner::before {
    top: 0;
  } */

  &.isFirst .tableCellInner::before {
    top: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &.isFirstForTableColumn .tableCellInner::before {
    top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &.isLast .tableCellInner::before {
    bottom: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.errorHint {
  display: block;
  position: absolute;
  top: 8px;
  left: 6px;
  height: 20px;
  width: 20px;
  z-index: 1;
}

.hintButton {
  width: 20px;
  height: 20px;
  outline: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}

.tomato {
  & .tableCellInner::before {
    background-color: var(--colorTomatoSecondary);
  }
}

.cucumber {
  & .tableCellInner::before {
    background-color: var(--colorCucumberSecondary);
  }
}

.eggplant {
  & .tableCellInner::before {
    background-color: var(--colorEggplantSecondary);
  }
}

.lettuce {
  & .tableCellInner::before {
    background-color: var(--colorLettuceSecondary);
  }
}

.pepper {
  & .tableCellInner::before {
    background-color: var(--colorPepperSecondary);
  }
}
