@import 'styles/core';

.select {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0;

  &.disabled {
    & .button {
      background-color: #F7F8FA;
      border: 1px solid #BDCFE1;
      cursor: not-allowed;
    }

    & .input {
      color: #9EA4A8;
    }

    & .placeholder {
      color: #9EA4A8;
    }

    & .icon {
      fill: #9EA4A8;
    }
  }

  &.errored {
    padding: 0 0 8px 0;

    & .popup {
      top: calc(100% + 4px - 8px);
    }

    & .placeholder {
      color: var(--colorTextNeutral);

      &.top {
        color: var(--colorNegative);
      }
    }
  }

  &.mini {
    & .button {
      height: 32px;
    }

    & .input {
      padding: 5px 12px 5px 12px;
    }

    & .icon {
      top: 5px;
    }

    & .error {
      left: 0;
    }
  }
}

.button {
  position: relative;
  outline: none;
  cursor: pointer;
  align-items: center;
  box-sizing: border-box;
  min-width: 64px;

  width: 100%;
  padding: 0 0 24px 0;
  border: 1px solid #BDCFE1;
  justify-content: space-between;
  background-color: #FFFFFF;
  height: 48px;
  border-radius: 4px;
  font-size: 14px;
  color: var(--colorTextPrimary);
  letter-spacing: -0.15px;
  line-height: 20px;

  &.notSelected {
    color: #79868F;
  }

  &:hover {
    border-color: var(--colorPrimary);
  }

  &.invalid, &.invalid:hover {
    border-color: var(--colorNegative);
    box-shadow: none;
  }

  &.focus {
    border: 1px solid var(--colorPrimary);
    box-shadow: 0 0 0 2px rgba(67, 179, 226, 0.10)
  }
}

.popup {
  width: 100%;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #E1E8F0;
  box-sizing: border-box;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  max-height: 210px;
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  overflow-y: auto;
  z-index: 100;
  -webkit-overflow-scrolling: touch;
  padding: 4px 0;

  &.isSearchable {
    padding-bottom: 0;
    max-height: initial;
  }
}

.option {
  padding: 6px 32px 6px 12px;
  font-size: 14px;
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
  color: var(--colorTextPrimary);
  letter-spacing: -0.15px;
  line-height: 20px;

  &:hover {
    background-color: #F7F9FA;
  }

  &.selected {
    background-color: #E4E9F0;
  }

}

.description {
  font-size: 14px;
  color: var(--colorTextNeutral);
  letter-spacing: -0.15px;
  line-height: 20px;
  margin: 0 0 0 4px;
}

.input {
  padding: 20px 12px 8px 12px;
  width: 100%;
  text-align: left;
  font-size: 14px;
  color: var(--colorTextPrimary);
  letter-spacing: -0.15px;
  line-height: 20px;

  &::placeholder {
    font-size: 14px;
    color: var(--colorTextNeutral);
    line-height: 20px;
  }
}

.placeholder {
  position: absolute;
  border: none;
  top: 14px;
  left: 12px;
  right: 12px;
  font-size: 14px;
  pointer-events: none;
  text-align: left;
  color: var(--colorTextNeutral);
  letter-spacing: -0.15px;
  line-height: 20px;
  transition: top 100ms ease-in, font-size 100ms ease-in, letter-spacing 100ms ease-in, line-height 100ms ease-in;

  &.top {
    top: 4px;
    left: 12px;
    right: 12px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
    color: var(--colorTextNeutral);
    letter-spacing: 0;
    line-height: 16px;
  }
}

.error {
  position: absolute;
  bottom: -12px;
  left: 12px;
  font-size: 12px;
  color: var(--colorNegative);
  line-height: 16px;
}

.icon {
  position: absolute;
  bottom: 14px;
  top: 14px;
  right: 12px;
  width: 20px;
  height: 20px;
  fill: var(--colorTextSecondary);
}

.textEllipsis {
  padding-right: 32px;

  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.searchListControl {
}
