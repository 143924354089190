@import 'styles/core';

.emptyState {
  max-width: 552px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 1px solid var(--colorBorderDefault);
  border-radius: 4px;
}

.text {
  margin-left: 8px;
}
