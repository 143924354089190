@import 'styles/core';

.select {
  font-size: 16px;
  color: var(--colorTextSecondary);
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &.full {
    & > * {
      margin: 0 20px;
      width: 50%;
    }
  }

  @media only screen and (max-width: 640px) {
    & .full {
      flex-wrap: wrap;
    }
  }

  & > * + * {
    margin-left: 50px;
  }

  & .control {
    min-width: 168px;
    flex-grow: 1;
  }

  & :global {
    & .Select-control {
      cursor: pointer;
      border: 1px solid #BDCFE1;
      border-radius: 5px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    & .Select-arrow-compartment {
      padding-right: 16px;
    }

    & .Select-arrow-zone {
      padding-right: 16px;
    }

    & .Select-multi-value-wrapper {
      display: flex;
    }

    & .Select--single.is-focused > .Select-control {
      border: 1px solid var(--colorPrimary);
      box-shadow: 0 0 1px 1px rgba(67, 179, 226, 1);
    }

    & .Select-placeholder {
      color: var(--colorPrimary);
    }

    & .Select-placeholder,
    & .Select--single > .Select-control .Select-value {
      color: var(--colorTextSecondary);
      line-height: 40px;
      padding-left: 16px;
    }
    & .has-value.Select--single > .Select-control .Select-value,
    & .has-value.is-pseudo-focused.Select--single > .Select-control .Select-value {
      & .Select-value-label {
        max-width: calc(100% - 34px);
        position: inherit;
        overflow: hidden;
        text-overflow: ellipsis;

        color: var(--colorTextSecondary);
      }
      & a.Select-value-label {
        &:hover,
        &:focus {
          color: var(--colorTextSecondary);
        }
      }
    }
    & .has-value.Select--single > .Select-control .Select-value .Select-value-label {
      color: var(--colorTextSecondary);
    }

    & .is-open.Select--single {
      & > .Select-control {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }

      & > .Select-menu-outer {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      & > .Select-menu-outer > .Select-menu {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;

        & > .Select-option {
          border-bottom: 1px solid #F1F3F6;
          font-size: 16px;
          color: var(--colorTextSecondary);

          &.is-focused {
            background-color: #FCFBEB;
          }
        }
      }
    }
  }
  &.mini {
    font-size: 14px;
    color: var(--colorTextPrimary);
    line-height: 20px;

    & :global {
      & .Select-control {
        cursor: pointer;
        border: 1px solid #BDCFE1;
        border-radius: 4px;
        height: 32px;
        line-height: 14px;

        &:hover {
          box-shadow: none;
        }
      }

      & .Select-value {
        position: static;
      }

      & .Select-input {
        height: 32px;
        display: none;
        position: absolute;
        width: 0;
        opacity: 0;
        padding: 0;
      }

      & .Select-arrow-compartment {
        padding-right: 8px;
        vertical-align: bottom;
      }

      & .Select-arrow-zone {
        padding-right: 8px;
        height: 20px;
      }

      & .Select--single.is-focused > .Select-control {
        border-radius: 4px;
        border: 1px solid #BDCFE1;
        box-shadow: none;
      }

      & .Select-placeholder {
        color: var(--colorPrimary);
      }

      & .Select-menu-outer {
        border-radius: 4px;
        background-color: #fff;
        border: 1px solid #E1E8F0;
        box-sizing: border-box;
        box-shadow: 0 8px 24px 0 rgba(0,0,0,0.02), 0 2px 4px 0 rgba(0,0,0,0.04);
        margin-top: -1px;
        max-height: 210px;
        position: absolute;
        top: calc(100% + 4px);
        width: 100%;
        height: 210px;
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
      }

      & .Select-menu {
        max-height: 208px;
        padding: 8px 0;
        overflow-x: hidden;
      }

      & .Select-placeholder,
      & .Select--single > .Select-control .Select-value {
        color: var(--colorTextPrimary);
        line-height: 30px;
        padding-left: 12px;
      }
      & .has-value.Select--single > .Select-control .Select-value,
      & .has-value.is-pseudo-focused.Select--single > .Select-control .Select-value {
        & .Select-value-label {
          color: var(--colorTextPrimary);
        }
        & a.Select-value-label {
          &:hover,
          &:focus {
            color: var(--colorTextPrimary);
          }
        }
      }
      & .has-value.Select--single > .Select-control .Select-value .Select-value-label {
        color: var(--colorTextPrimary);
      }

      & .is-open.Select--single {
        & > .Select-control {
        }

        & > .Select-menu-outer {
          border-radius: 4px;
          height: 210px;
        }

        & > .Select-menu-outer > .Select-menu {

          & > .Select-option {
            padding: 8px 12px;
            border: none;
            font-size: 14px;
            color: var(--colorTextPrimary);
            line-height: 16px;

            &.is-focused {
              background-color: #F7F9FA;
            }

            &.is-selected {
              background-color: #E4E9F0;
            }
          }
        }
      }
    }
  }
  &.micro {
    font-size: 14px;
    color: var(--colorTextPrimary);
    line-height: 20px;

    & :global {
      & .Select-control {
        cursor: pointer;
        border: 1px solid #BDCFE1;
        border-radius: 4px;
        height: 24px;
        line-height: 14px;

        &:hover {
          box-shadow: none;
        }
      }

      & .Select-value {
        position: static;
      }

      & .Select-input {
        height: 24px;
        display: none;
        position: absolute;
        width: 0;
        opacity: 0;
        padding: 0;
      }

      & .Select-arrow-compartment {
        padding-right: 8px;
        vertical-align: bottom;
      }

      & .Select-arrow-zone {
        padding-right: 8px;
        height: 20px;
      }

      & .Select--single.is-focused > .Select-control {
        border-radius: 4px;
        border: 1px solid #BDCFE1;
        box-shadow: none;
      }

      & .Select-placeholder {
        color: var(--colorPrimary);
      }

      & .Select-menu-outer {
        border-radius: 4px;
        background-color: #fff;
        border: 1px solid #E1E8F0;
        box-sizing: border-box;
        box-shadow: 0 8px 24px 0 rgba(0,0,0,0.02), 0 2px 4px 0 rgba(0,0,0,0.04);
        margin-top: -1px;
        max-height: 210px;
        position: absolute;
        top: calc(100% + 4px);
        width: 100%;
        height: 210px;
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
      }

      & .Select-menu {
        max-height: 208px;
        padding: 8px 0;
        overflow-x: hidden;
      }

      & .Select-placeholder,
      & .Select--single > .Select-control .Select-value {
        color: var(--colorTextPrimary);
        line-height: 22px;
        padding-left: 8px;
      }
      & .has-value.Select--single > .Select-control .Select-value,
      & .has-value.is-pseudo-focused.Select--single > .Select-control .Select-value {
        & .Select-value-label {
          color: var(--colorTextPrimary);
        }
        & a.Select-value-label {
          &:hover,
          &:focus {
            color: var(--colorTextPrimary);
          }
        }
      }
      & .has-value.Select--single > .Select-control .Select-value .Select-value-label {
        color: var(--colorTextPrimary);
      }

      & .is-open.Select--single {
        & > .Select-control {
        }

        & > .Select-menu-outer {
          border-radius: 4px;
          height: 210px;
        }

        & > .Select-menu-outer > .Select-menu {

          & > .Select-option {
            padding: 8px 12px;
            border: none;
            font-size: 14px;
            color: var(--colorTextPrimary);
            line-height: 16px;

            &.is-focused {
              background-color: #F7F9FA;
            }

            &.is-selected {
              background-color: #E4E9F0;
            }
          }
        }
      }
    }
  }
}

:global {
  html.focus-outline-hidden .Select--single.is-focused > .Select-control {
    border: 1px solid #BDCFE1!important;
    box-shadow: 0 0 1px 1px transparent!important;
  }
}
