@import 'styles/core';

.filterWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.selectWrapper {}

.filterTitle {
  margin-right: 8px;
  font-size: 14px;
  color: var(--colorTextSecondary);
}

.capitalizeTitle {
  text-transform: lowercase;
}

.capitalizeTitle:first-letter {
  text-transform: uppercase;
}
