@import 'styles/core';

.navigation {
  position: relative;
}

.openMenuBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.avatar {
  margin-right: 8px;

  @media (--tablet) {
    min-width: 36px;
    width: 36px;
    height: 36px;
    line-height: 36px;

    margin-right: 0;
  }
}

.userInfo {
  max-width: 152px;
  font-size: 14px;
  line-height: 20px;

  @media (--tabletBig) {
    max-width: 120px;
  }

  @media (--tablet) {
    display: none;
  }
}

.userName,
.userLocation,
.menuUserName,
.menuUserEmail,
.menuUserLocation {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.userName {
  color: var(--colorTextPrimary);
}

.userLocation {
  font-size: 13px;
  color: var(--colorTextSecondary);
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  & svg {
    width: 20px;
    height: 20px;
  }

  @media (--tablet) {
    display: none;
  }
}

.menu {
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  z-index: 1;

  min-width: 216px;
  max-width: 288px;

  border-radius: 4px;
  border: solid 1px #e1e8f0;
  background: #fff;
  overflow: hidden;

  font-size: 14px;
  color: var(--colorTextPrimary);

  transition: opacity 150ms ease-out, max-height 150ms ease-out;
  transform-origin: top;

  box-shadow: 0 8px 24px 0 rgba(0,0,0,0.02), 0 2px 4px 0 rgba(0,0,0,0.04);

  @media (--tablet) {
    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    max-width: 100vw;
    height: 100vh;

    border: none;
    border-radius: 0;
  }
}

.menuZone {
  padding: 8px 0;
  border-bottom: 1px solid #E1E8F0;

  &:last-child {
    border-bottom: none;
  }

  @media (--tablet) {
    padding: 12px 0;
  }
}

.menuUserInfo {
  display: flex;
  padding: 14px 15px;

  line-height: 18px;

  & .avatar {
    display: none;

    margin-right: 16px;

    @media (--tablet) {
      display: block;

      width: 48px;
      height: 48px;
      line-height: 48px;
    }
  }

  @media (--tablet) {
    padding: 16px 0 28px 0;
  }
}

.menuUserData {
  max-width: 100%;

  @media (--tablet) {
    max-width: calc(100% - 64px);
  }
}

.menuUserName {
  margin-bottom: 4px;
  line-height: 20px;
  font-weight: 500;
}

.menuUserEmail,
.menuUserLocation {
  font-size: 13px;
  color: var(--colorTextSecondary);
}

.menuEnter {
  opacity: 0;

  @media (--tablet) {
    opacity: 1;
    max-height: 0;
  }
}

.menuEnter.menuEnterActive {
  opacity: 1;

  @media (--tablet) {
    max-height: 100vh;
  }
}

.menuLeave {
  opacity: 1;

  @media (--tablet) {
    max-height: 100vh;
  }
}

.menuLeave.menuLeaveActive {
  opacity: 0;

  @media (--tablet) {
    opacity: 1;
    max-height: 0;
  }
}

.menuList {
  margin: 0;
  list-style: none;

  & li {
    @media (--tablet) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.menuItem {
  line-height: 32px;
  padding: 0 15px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: #F7F9FA;
  }

  @media (--tablet) {
    margin: 0 -15px;

    line-height: 48px;
  }
}

.menuFlagWrapper {
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  @media (--tablet) {
    justify-content: flex-start;
  }
}

.menuFlag {
  width: 20px;
  height: 20px;

  @media (--tablet) {
    margin-left: 8px;
  }
}

.arrowRight {
  display: none;

  @media (--tablet) {
    display: flex;
    align-items: center;
  }
}


.logOut {
  cursor: pointer;
}

.logoutLink {
  text-decoration: none;
  color: var(--colorTextPrimary);
}

.link {
  display: flex;
  justify-content: space-between;
  margin: 0 -15px;
  padding: 0 0 0 15px;
  text-decoration: none;
  color: var(--colorTextPrimary);
  width: calc(100% + 15px);
}

.firstLevel {
  @media (--tablet) {
    padding: 16px 15px;
  }
}

.firstLevelHeader {
  display: none;

  padding-bottom: 6px;
  font-size: 16px;
  font-weight: 500;

  @media (--tablet) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.closeIcon {
  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

.secondLevel {
  padding: 8px 0;

  background: #fff;

  @media (--tablet) {
    padding: 16px 0;
  }
}

.secondLevelHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding-bottom: 6px;

  font-weight: 500;

  @media (--tablet) {
    padding-bottom: 16px;
    padding-left: 4px;

    font-size: 16px;
  }
}

.arrowBack {
  width: 36px;
  height: 36px;

  margin-left: 7px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  &:focus {
    outline: none;
  }

  & svg {
    width: 20px;
    height: 20px;

    @media (--tablet) {
      width: 24px;
      height: 24px;
    }
  }

  @media (--tablet) {
    margin-right: 24px;
    margin-left: 0;
  }
}

.secondLevelMenu {
  max-height: 280px;
  padding: 0;
  margin: 0;
  overflow-y: auto;

  @media (--tablet) {
    max-height: none;
  }
}

.secondLevelMenuItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding: 6px 15px;
  line-height: 20px;

  cursor: pointer;

  &.active {
    padding-left: 11px;

    border-left: 5px solid var(--colorPrimary);
  }

  &:hover {
    background-color: #F7F9FA;
  }

  @media (--tablet) {
    padding: 14px 15px;
  }
}

.langMenuItem {
  padding: 4px 15px;

  @media (--tablet) {
    padding: 12px 15px;
  }
}

.flag {
  display: flex;
  margin-right: 8px;

  & svg {
    box-shadow: inset 0 0 1px 0 rgba(0,0,0,0.08);
    border-radius: 2px;
  }
}

.menuContent {
  min-height: 218px;

  transition: height 150ms ease-out;
}

.firstLevel,
.secondLevel {
  transition: transform 150ms ease-out , height 150ms ease-out;

}

.secondLevel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  transform: translateX(100%);

  @media (--tablet) {
    max-height: 100%;
    overflow-y: auto;
  }
}

.secondLevelActive {
  & .firstLevel {
    transform: translateX(-100%);
  }

  & .secondLevel {
    transform: translateX(0);
  }
}

.linkWithBage {
  justify-content: flex-start;
  align-items: center;
}

.onlyMobile{
  display: none;

  @media (--tablet) {
    display: block;
  }
}

.bage {
  display: block;
  height: 20px;
  margin-left: 8px;
  padding: 0 4px;

  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  color: var(--colorWhite);

  border-radius: 4px;
  background: var(--colorPrimary);;

  &.green {
    background-color: var(--colorPositive);
  }

  &.yellow {
    background-color: var(--colorWarning);
  }

  &.red {
    background-color: var(--colorNegative);
  }
}

.grayBage {
  background: var(--colorMenuItemHover);
  color: var(--colorTextPrimary);
}
