@import 'styles/core';

.button {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 32px;
  line-height: 28px;

  font-size: 14px;
  color: var(--colorTextPrimary);
  background: #FFFFFF;
  border: 1px solid #BDCFE1;
  padding-left: 12px;
  padding-right: 8px;
  cursor: pointer;

  & svg {
    margin-left: 8px;
  }

  @media (--tablet) {
    padding: 20px 12px 6px 12px;
    width: 100%;
    text-align: left;
    line-height: 20px;
    height: auto;
    border-radius: 4px;
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.icon {
  @media (--tablet) {
    display: none;
  }
}

.mobileIcon {
  display: none;
  position: absolute;
  bottom: 14px;
  top: 14px;
  right: 12px;
  width: 20px;
  height: 20px;

  @media (--tablet) {
    display: block;
  }
}

.placeholder {
  display: none;
  position: absolute;
  top: 4px;
  left: 13px;
  right: 13px;
  font-size: 12px;
  color: var(--colorTextNeutral);
  line-height: 16px;

  @media (--tablet) {
    display: block;
  }
}

