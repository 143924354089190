@import 'styles/core';

.filterInputWrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 14px 32px 0 32px;
}

.filterControl {
  display: flex;
  flex-grow: 1;
  position: relative;
}

.icon {
  width: 20px;
  height: 20px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 8px;

  & svg {
    width: 20px;
    height: 20px;
  }
}

.filterInputButton {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 9px;
  bottom: 9px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filterInputButtonLeft {
  left: 12px;
}

.filterInputButtonRight {
  right: 12px;
}

.filterInput {
  width: 100%;
  flex-shrink: 1;
  flex-grow: 0;
  margin-right: 8px;
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0 35px;
  border-radius: 5px;
  border: 1px solid #BDCFE1;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;

  &::placeholder {
    color: var(--colorTextSecondary);
  }

  &::-ms-clear {
    display: none;
  }
}

.filterOptionsPopup {
  position: absolute;
  padding: 4px 0;
  top: calc(100% + 2px);
  background-color: white;
  width: 470px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 100;
  border-radius: 5px;
  border: 1px solid #E1E8F0;
  box-shadow: 0 8px 24px 0 rgba(0,0,0,0.02), 0 2px 4px 0 rgba(0,0,0,0.04);
}

.filterOptions {
  max-height: 292px;
}

.filterOption {
  padding: 6px 40px;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    background-color: #EBEEF2;
  }
}

.filterLoader {
  height: 56px;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.filterEmpty {
  height: 116px;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 0 44px;
  color: var(--colorTextSecondary);
}

.groupFilter {
  display: flex;
  align-items: center;
  padding: 8px;
  width: 103px;
  height: 36px;
  border: 1px solid var(--colorBorderCasper);
  border-radius: 4px;
}

.filterAppliedCount {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1px 0;

  width: 20px;
  height: 20px;

  background: #E4F7FB;
  border-radius: 99px;

  font-weight: 500;
  font-size: 13px;
  line-height: 18px;

  text-align: center;
  letter-spacing: -0.08px;

  color: #1DBADF;
}

.selectButtonText {
  text-align: left;
}
