@import 'styles/core';

.arrowRightIcon {
  width: 20px;
  height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;

  & svg path {
    fill: #9EA4A8;
  }
}

.arrowIconRotate {
  transform: rotate(90deg);
  padding: 2px;
}

/* td.chartIconWrapper {
  min-width: auto;
  width: 52px;
  padding: 14px 16px !important;
} */

td.chartIconWrapper {
  width: 36px;
  /* padding: 5px 4px 5px 12px; */
  background: var(--colorWhite);
}

.chartIconWrapper {
  cursor: pointer;

  & svg {
    fill: #9EA4A8;
  }
}

.chartIconContainer {
  width: 20px;
  height: 20px;

  height: 100%;
  display: flex;
  align-items: center;
}

.nameWrapper {
  display: flex;
  align-items: center;
  white-space: nowrap;

  overflow: hidden;
  text-overflow: ellipsis;
}

.plantingCycleLink {
  display: block;
  color: var(--colorTextPrimary);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.tdValue {
  white-space: nowrap;
  background: var(--colorWhite);
}

td.left {
  text-align: left;
  background: var(--colorWhite);
}

.boldRow {
  color: var(--colorTextSecondary);
  font-weight: 500;

  & .tdValue,
  & .chartIconWrapper,
  & .left {
    background-color: var(--colorThDefault);
  }
}
