@import 'styles/core';

.rowsWrapper {
  /* min-height: 48px; */
}

.row {
  display: flex;
  align-items: stretch;
  min-height: 24px;
  height: 100%;
}

.rowLabel,
.rowBar {
  display: flex;
  align-items: center;
}

.rowLabel {
  min-width: 80px;
  max-width: 80px;
  margin-right: 8px;
  padding: 4px 0;

  justify-content: flex-end;
  color: var(--colorTextPrimary);
  font-size: 11px;
  line-height: 16px;
}

.rowLabelLink {
  text-decoration: none;
  text-align: right;

  &:hover {
    text-decoration: underline;
  }
}

.rowLabelText {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.rowBar {
  position: relative;
  width: 100%;
  min-height: 100%;
  border-left: 1px solid var(--colorChartBorder);
  border-right: 1px dashed var(--colorChartDashedBorder);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;

    height: 100%;
    width: 1px;

    border-right: 1px dashed var(--colorChartDashedBorder);
  }

  &:hover {
    & .actualBar {
      background: var(--colorPrimaryHovered);
    }
  }
}

.bufferZone {
  min-width: 28px;
}

.scaleX {
  height: 16px;
  padding: 8px 28px 0 85px;
}

.scaleXValues {
  position: relative;
}

.scaleXMin,
.scaleXMiddle,
.scaleXMax {
  position: absolute;

  font-size: 11px;
  color: var(--colorTextPrimary);
}

.scaleXMin {
  left: 0;
}

.scaleXMiddle {
  left: 50%;
  transform: translate(-30%, 0);
}

.scaleXMax {
  right: 0;
  transform: translate(50%, 0);
}

.actualBar {
  background: var(--colorPrimary);
  height: 12px;
  z-index: 1;

  min-width: 4px;
}

.targetBar {
  height: 12px;

  position: absolute;
  left: 0;
  top: auto;
  width: 8px;

  background: var(--colorSecondary);
  border-left: 2px solid #FFF;
  border-right: 2px solid #FFF;
  z-index: 2;
}

.targetBarTwoLine {
  height: 4px;

  position: absolute;
  left: 0;
  top: 6px;

  background: var(--colorSecondary);
  z-index: 2;
}

.actualBarTwoLine {
  height: 4px;

  position: absolute;
  left: 0;
  top: 12px;

  background: var(--colorPrimary);
  z-index: 2;
}

.expectedBar {
  position: absolute;
  top: 2px;
  bottom: 2px;
  width: 1px;

  border-right: 2px dashed var(--colorTextSecondary);

  z-index: 2;
}
