@import 'styles/core';

.tooltip {
  position: absolute;
  transform: translate(-50%, calc(-100% + 4px));
  max-width: 250px;

  background-color: var(--colorTextSecondary);
  border-radius: 2px;
  font-size: 12px;
  line-height: 16px;
  color: rgba(255,255,255,0.90);
  padding: 4px 8px;
  text-align: left;
  z-index: 99;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: calc(50% - 4px);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 3px 4px 0 4px;
    border-color: var(--colorTextSecondary) transparent transparent transparent;
  }
}

.leftPosition {
  transform: translate(calc(-100% - 4px), -50%);

  &:after {
    top: calc(50% - 3px);
    left: 100%;
    border-width: 3px 0 3px 4px;
    border-color: transparent transparent transparent var(--colorTextSecondary);
  }
}

.noWrap {
  white-space: nowrap;
}

.error {
  background-color: #D15E5E;
  color: var(--colorWhite);

  &:after {
    border-color: transparent transparent transparent #D15E5E;
  }
}
