@import 'styles/core';

.lineChartWrapper {
  min-height: 170px;
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 24px;
  padding: 0 40px 0 24px;
}

.title {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--colorTextPrimary);
}

.totals {
  display: flex;
  align-items: flex-end;
}

.total {
  margin-left: 72px;
  text-align: right;
}

.totalValue,
.totalHeader {
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
}

.totalHeader {
  color: var(--colorTextSecondary);
  margin-bottom: 2px;
}

.totalValue {
  color: var(--colorTextPrimary);
}

.chartContainer {
  min-height: 215px;
  padding: 0 20px 0 30px;
}
