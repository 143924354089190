@import 'styles/core';

.emptyState {
  min-height: 284px;
  border: 1px solid var(--colorBorderDefault);
  border-radius: 2px;
  border-top: none;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextSecondary);
}

.resetButton {
  text-decoration: underline;
  cursor: pointer;
}

.tableHeader {
  & th {
    text-align: right;
  }

  & th,
  & th:first-child {
    min-width: 72px;
  }
}

.compartmentRow {
  & td,
  & td:first-child {
    min-width: 72px;
    height: 36px;
    padding: 8px 24px;
    background-color: var(--colorBackgroundHover);

    font-size: 13px;
    line-height: 18px;
    color: var(--colorTextSecondary);

    font-weight: 500;

    /* box-shadow: inset 1px -1px 0 var(--colorBorderDefault); */
  }
}

.compartmentFloor {
  padding-left: 4px;
  font-weight: 400;
}

.compartmentHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.compartmentIcon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.dataRow {
  & td {
    min-width: 72px;
    height: 36px;
  }

  &.oneRow {
    & td {
      height: 54px;
    }
  }
}

.dataRow td.dataCell,
tr:last-child.dataRow .dataCell {
  box-shadow: inset 1px -1px 0 0 var(--colorBorderDefault);
  color: var(--colorWhite);
  opacity: 0.5;
  padding: 8px 16px;
}

.dataReportName,
td.dataReportName,
td:first-child.dataReportName {
  padding: 12px 8px;

  color: var(--colorTextSecondary);
}

.dataReportNameContent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.qualitySquare {
  display: block;
  width: 12px;
  height: 12px;
  margin-right: 8px;
  opacity: 0.5;
  border-radius: 2px;
  background-color: var(--colorGray);
}

.speciesName {
  text-align: left;
}

.period {
  font-size: 13px;
  line-height: 18px;
  color: var(--colorTextSecondary);
  text-align: left;
}

.plantingCycleLink {
  display: block;
  color: var(--colorTextPrimary);
  text-decoration: none;

  & .period {
    color: var(--colorTextSecondary);
  }

  &:hover .speciesName {
    text-decoration: underline;
  }
}

.green,
.dataCell.green,
.qualitySquare.green {
  background-color: var(--colorPositive);
}

.red,
.dataCell.red,
.qualitySquare.red {
  background-color: var(--colorNegative);
}

.dataCell {
  padding: 0;
}

.tdLink {
  display: block;
  color: var(--colorWhite);
  text-decoration: none;
}

.currentWeek,
.compartmentRow .currentWeek {
  background-color: #E4F7FB;
}

/* Fixed table */
.tableWrapper {
  max-height: calc(100vh - 60px - 48px); /* высота хедера и двойной паддинг*/
  overflow-y: auto;
  padding-left: 384px;

  position: relative;
  border: 1px solid var(--colorBorderDefault);
  border-radius: 2px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 384px;
    bottom: 0;
    width: 4px;
    background: rgba(30, 42, 51, 0.05);
  }
}

.tableScroller {
  overflow-x: scroll;
  overflow-y: visible;

  &.withoutOverflow {
    overflow-x: hidden;
  }
}

.table {
  width: 100%;
  border-spacing: 0;

  & th,
  & td {
    text-align: center;
    box-shadow: inset 0 -1px 0 0 var(--colorBorderDefault);
  }
}

.firsColumn {
  position: absolute;
  top: auto;
  left: 0;
}

.cycleRow,
.compartmentNameCol {
  width: 384px;
}

td.varietyNameCol {
  width: 216px;
  height: 36px;
}

.dataReportName {
  width: 168px;
  left: 216px;
}

.cycleRow,
.compartmentNameCol,
.dataReportName {
  border-right: 1px solid var(--colorBorderGray);
}

th.cycleRow,
th.weekNo {
  color: var(--colorTextSecondary);
  background-color: var(--colorBackgroundHover);
  font-weight: 500;
  padding: 0 16px;
  height: 56px;
}

th.weekNo {
  min-width: 72px;
  text-align: right;
  box-shadow: inset -1px -1px 0 0 var(--colorBorderDefault);
}


th.cycleRow {
  padding: 0 24px;
}

th.cycleRow,
td.dataReportName {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

td.varietyNameCol {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 24px;
}
