@import 'styles/core';

.headerText {
  display: flex;
  align-items: center;

  margin: 0 0 16px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;

  color: var(--colorTextPrimary);

  @media (--tablet) {
    padding-left: 16px;
  }
}

.headerIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  margin-left: 8px;
}
