@import 'styles/core';

.reportWrapper {
  min-height: initial;
}

.menu {
  flex: 0 0 192px;
  padding: 32px 0 0 0;
  margin-top: 0;
  min-height: 50vh;

  @media (--tabletLandscape) {
    padding-left: 24px;
    margin-top: 0;
    transform: translate(-256px, 0);
  }

  @media (--tablet) {
    margin-top: 0;
  }

  transform: translate(-216px, 0);

  &.opened {
    transform: translate(0, 0);
  }
}

.content {
  padding: 32px 0 0 24px;

  transform: translate(-216px, 0);
  min-width: calc(100% + 24px);

  @media (--tabletLandscape) {
    padding-left: 0;
    min-width: 100%;
    transform: translate(-240px, 0);
  }

  &.opened {
    transform: translate(0, 0);
    max-width: 100%;
    min-width: initial;

    @media (--tabletLandscape) {
      padding-left: 16px;
    }
  }
}

.overlay {
  display: none;

  &.opened {
    @media (--tabletLandscape) {
      display: block;
    }
  }
}

.header {
  margin: 0 0 24px 0;
  display: flex;
  align-items: center;

  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: var(--colorTextPrimary);

  @media (--phoneLandscape) {
    margin: 0;
  }
}

.datePicker {}

.graphsWrapper {
  margin-bottom: 24px;

  display: grid;
  row-gap: 12px;
  column-gap: 12px;

  @media (--tablet) {
    display: block;
  }
}

.gridItem {
  padding: 24px;
  border: 1px solid var(--colorBorderDefault);
  border-radius: 4px;

  @media (--tablet) {
    margin-bottom: 16px;
  }
}

.title {
  display: block;
  margin-bottom: 4px;
}

.description {
  display: block;
  margin-bottom: 32px;
  max-width: 540px;
  min-height: 60px;
}

.whenMobileMenuOpened {
  transform: unset;
}

.graphContent {

}

.controls {
  margin: 0 0 32px 0;

  @media (--phoneLandscape) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.speedometerWrapper {
  margin: 0 auto;
  margin-top: 48px;
}