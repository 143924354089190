@import 'styles/core';

.body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.window {
  min-width: 600px;
}

.closeCross {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}

.title {
  margin-bottom: 32px;
}

.description {
  margin-bottom: 24px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.dontSaveButton {
  margin-right: 8px;
}

.saveButton {

}

.users {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin: 12px 0 32px;
  max-width: 552px;
  flex-flow: wrap;
  row-gap: 8px;
}

.notify {}

.contentWrapper {
  position: relative;
}
