@import 'styles/core';

.productMenu,
.menuButton,
.dropdownButton,
.dropdownMenu,
.dropdownWrapper,
.addButton {
  width: 100%;
  height: 100%;
}

.multilevelMenu {
  font-weight: normal;
}

.dropdownContent {
  left: 4px;
  right: auto;
  top: calc(100% + 4px);
  min-width: 184px;
}

.addButton,
.dropdownButton {
  display: flex;
  align-items: center;
  justify-content: center;
}