@import 'styles/core';

.headerControlsSave {
  margin-right: 24px;
  color: var(--colorTextSecondary);
  font-size: 14px;
}

.timeText {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.timeTextButton {
  text-decoration: underline;
  font-weight: normal;
  padding: 0;
  margin-left: 4px;

  & svg circle {
    stroke: var(--colorTextSecondary) !important;
  }

  &:hover {
    text-decoration: underline;
  }
}
