@import 'styles/core';

.cropCompareWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 0;

  cursor: pointer;

  @media (--tablet) {
    display: none;
  }
}

.cropCompareContent {
  width: 360px;

  right: 6px;
  top: calc(100% - 4px);
  padding-top: 0;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding: 16px 16px 8px;
}

.submitButton {
  margin-left: 8px;
}

.locationName,
.dates {
  font-size: 13px;
  line-height: 18px;

  color: var(--colorTextSecondary);
}

.dates {
  width: calc(360px - 32px - 32px - 5px);
  text-overflow: ellipsis;

  white-space: nowrap;
  overflow: hidden;
}

.productName {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
}

.compareItems {
  display: flex;
  align-items: center;

  @media (--desktop) {
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
  }
}

.productItem {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  width: 100%;
  padding: 16px;
  border-right: none;
  border-bottom: 1px solid var(--colorBorderDefault);
}

.onMobile {
  position: fixed;
  bottom: 92px;
  right: 20px;
  width: 60px;
  height: 60px;

  display: none;
  align-items: center;
  justify-content: center;

  border: 1px solid var(--colorBorderCasper);
  border-radius: 50%;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.04);
  background-color: var(--colorWhite);

  @media (--tablet) {
    display: flex;
  }
}