@import 'styles/core';

.dropdownButton {
  display: flex;
  align-items: center;
  justify-content:space-between;

  background-color: var(--colorPrimary);
  padding: 0;
  border: none;
  border-radius: 4px;

  &:disabled {
    opacity: 0.54;
    cursor: not-allowed;
  }
}

.dropdownButtonText {
  display: block;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorWhite);
  font-weight: 500;
  cursor: pointer;

  overflow: hidden;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;

  &:hover {
    background-color: var(--colorHover);
  }
}

.dropdownButtonArrow {
  width: 32px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid var(--colorHover);
  overflow: hidden;
  cursor: pointer;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;

  & svg {
    width: 24px;
    height: 24px;
  }

  & svg path {
    fill: var(--colorWhite);
  }

  &:hover {
    background-color: var(--colorHover);
    border-color: var(--colorPrimary);
  }
}

.dropdownContent {
  right: 0;
  top: calc(100% + 4px);
  z-index: 30;
  min-width: 288px;
  text-align: left;
}

.dropdownOption {
  padding: 8px 16px;
  border-bottom: 1px solid #E1E8F0;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: var(--colorBackgroundHover);
  }
}

.dropdownOptionHeader {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--colorTextPrimary);
}

.dropdownOptionText {
  padding-top: 4px;
  font-size: 13px;
  line-height: 18px;
  color: var(--colorTextSecondary);
}
