@import 'styles/core';

.layout {
  min-height: calc(100vh - 60px);
  overflow-x: auto;
  background-color: #FFF;
}

.content {
  margin: 0 auto;
  padding: 16px 24px 25px;
  max-width: 1440px;

  @media (--tabletBig) {
    padding: 16px 16px 25px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.backButton {
  padding-right: 8px;
  margin-bottom: 16px;

  @media (--tabletBig) {
    margin-bottom: 16px;
  }
}

.tabs {
  display: flex;
  margin-top: 20px;
  margin-bottom: 32px;

  border-bottom: 1px solid #E7E9EE;

  @media (--tablet) {
    margin-bottom: 24px;
  }

  overflow-x: auto;
  overflow-y: hidden;
}

.tab {
  cursor: pointer;
  padding: 16px 0;
  margin-right: 32px;
  margin-bottom: -2px;

  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  color: var(--colorTextSecondary);
}

.activeTab {
  box-shadow: inset 0 -5px 0 0 var(--colorPrimary);
  color: var(--colorPrimary);
}

.tabsContent {
  overflow: auto;
  background: #FFF;
}

.emptyButton {
  outline: none;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  color: var(--colorTextSecondary);
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: inline-block;
}

.reportsTabs {
  margin-bottom: 0;
}

.reportsTabsContent {
  overflow: visible;
}
