@import 'styles/core';

.emptyWrapper {
  padding: 32px;
  text-align: center;
}

.subtitle {
  display: block;
  margin-top: 4px;
  margin-bottom: 16px;
}

.openButton {
  display: block;
  width: max-content;
  margin: 0 auto;
}
