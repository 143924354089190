@import 'styles/core';

.chartBlock {

}

.chartBlockTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #4A4A49;
}

.chartWrapper {
  padding-top: 8px;
}
