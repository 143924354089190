@import 'styles/core';

.container {
  display: flex;
  flex-direction: column;

  padding: 24px 24px 24px 24px;
  border: 1px solid var(--colorBorderDefault);
  border-radius: 4px;

  width: 330px;
  height: 320px;

  & .titleWrapper {

  }

  & .speedometerWrapper {
    display: flex;
    justify-content: center;
    margin-top: 55px;
  }

  & .percentBoxClassName {
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 32px;
    color: var(--colorTextPrimary);
    margin-top: 6px;
  }

  & .currentValueBoxClassName {
    margin-top: 4px;
    color: var(--colorTextSecondary);
  }
}
