.dialog {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-flow: column;
  align-items: center;
  z-index: 10000;
  background-color: rgba(49, 52, 59, .9);
  padding: 0;

  &.isCentered {
    justify-content: center;

    & .dialogWrapper {
      top: -20%;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  & .dialogClose {
    position: absolute;
    top: 36px;
    right: 32px;
    cursor: pointer;
  }

  & .dialogWrapper {
    display: flex;
    flex-flow: column;
    position: relative;
    //top: -20%;
    //bottom: 0;
    //left: 0;
    //right: 0;
    border-radius: 5px;
    background-color: #ffffff;
    margin: 20px auto;
    padding: 30px;
    box-sizing: border-box;
  }

  & .dialogHeader {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.38px;
    color: #1E2A33;
  }

  & .dialogBody {
    padding-top: 40px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.15px;
    color: #4A4A49;
  }
}


@media only screen and (max-width: 640px) {
  .dialog {
    padding: 30px 7px 7px;
    justify-content: flex-start;

    &.isCentered {
      justify-content: flex-start;

      & .dialogWrapper {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    & .dialogWrapper {
      width: 100%;
      margin: 0;
      top: 0;
      padding: 20px 14px 30px;
    }

    & .dialogClose {
      position: absolute;
      top: -22px;
      margin-left: -10px;
      left: 50%;
      right: 50%;
      cursor: pointer;
    }
  }
}
