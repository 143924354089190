@import 'styles/core';

.avatar {
  min-width: 40px;
  width: 40px;
  height: 40px;
  line-height: 40px;

  border-radius: 50%;
  background-color: var(--colorSecondaryLight);
  color: var(--colorWhite);

  font-size: 16px;
  text-align: center;
}
