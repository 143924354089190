@import 'styles/core';

.uploadPhotoDialog {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-end;
  z-index: 10000;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: 40px;

  & .uploadPhotoDialogClose {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  & .uploadPhotoDialogShadowTop {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100px;
  }

  & .uploadPhotoDialogShadowBottom {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
    background-blend-mode: multiply;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 400px;
  }

  & .uploadPhotoDialogForm {
    display: flex;
    flex-flow: column;
    padding: 10px;
    width: 100%;
    max-width: 880px;
    z-index: 10;

    & .uploadPhotoDialogSelect {
      font-size: 14px;
      letter-spacing: 0.7px;
      color: #FFFFFF;
      margin-left: 16px;
    }

    & .uploadPhotoDialogLineRight {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    & .uploadPhotoDialogInput {
      margin-top: 8px;
      border-radius: 10px;
      background-color: #ffffff;
      height: 40px;
      width: 100%;
      outline: none;
      border: none;
      padding: 9px 16px;
      font-weight: 500;
      font-size: 18px;
      letter-spacing: 0.75px;
      color: var(--colorTextSecondary);

      &::placeholder {
        color: #BDCFE1;
      }
    }

    & .uploadPhotoDialogSubmit {
      margin-top: 20px;
      background: var(--colorPositive);
      border-top-right-radius: 10px;
      border-bottom-left-radius: 10px;
      width: 188px;
      height: 40px;
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 0.75px;
      color: #FFFFFF;
      padding: 10px 10px;
      box-sizing: border-box;
      text-align: center;
      text-decoration: none;
      cursor: pointer;
    }

    @media only screen and (max-width: 640px) {
      & .uploadPhotoDialogLineRight {
        justify-content: center;
      }
    }
  }

  & .loader {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    & .shadow {
      width: 100%;
      height: 100%;
      background: rgba(49, 51, 59, 0.8);
      z-index: 0;
    }

    & .content {
      position: absolute;
      top: 50%;
      bottom: 50%;
      left: 50%;
      right: 50%;
      width: 100px;
      height: 64px;
      margin-left: -50px;
      display: flex;
      flex-flow: column;
      align-items: center;
    }

    & .spinner {
      display: flex;
      justify-content: space-between;
      width: 80px;

      & .leaf {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        border-top-right-radius: 50%;
        border-bottom-left-radius: 50%;
        background-color: var(--colorPositive);
        box-sizing: border-box;

        &:nth-child(1) {
          animation: leaf-1 1s infinite linear;
        }

        &:nth-child(2) {
          animation: leaf-2 1s infinite linear;
        }

        &:nth-child(3) {
          animation: leaf-3 1s infinite linear;
        }

        & + .leaf {
          margin-left: 10px;
        }
      }
    }

    & .status {
      margin-top: 30px;
      font-size: 12px;
      color: #ffffff;
      letter-spacing: 0.5px;
      white-space: nowrap;
    }
  }
}

@keyframes leaf-1 {
  from {opacity: 1;}
  50% {opacity: .6;}
  to {opacity: .3;}
}
@keyframes leaf-2 {
  from {opacity: .3;}
  50% {opacity: 1;}
  to {opacity: .6;}
}
@keyframes leaf-3 {
  from {opacity: .6;}
  50% {opacity: .3;}
  to {opacity: 1;}
}

.checkboxContainer {
  user-select: none;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  width: auto;

  height: 40px;
  margin-top: 20px;
  margin-right: 16px;
  
  & .checkbox {
    & > span {
      color: var(--colorWhite);
    }
  }
}


