@import 'styles/core';

.main {
  min-height: calc(100vh - 60px);
  padding: 36px 24px 48px;
  overflow-x: auto;
  background-color: #ffffff;

  @media (--tabletBig) {
    padding: 30px 16px 48px 16px;
  }
}

.content {
  padding: 0;
  margin: 0 auto;
  max-width: 1156px;

  @media (--desktop) {
    max-width: 1024px;
  }

  @media (--tabletBig) {
    max-width: 100%;
  }
}

.title {
  font-weight: 500;
  font-size: 24px;
  color: #000000;
  margin: 0;


  @media (--tabletBig) {
    font-size: 20px;
  }
}

.description {
  font-size: 14px;
  color: var(--colorTextSecondary);
  line-height: 20px;
  margin-top: 20px;
}

.link {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 0;
  color: var(--colorTextSecondary);
  text-align: left;
  text-decoration: none;

  &.active {
    color: var(--colorPrimary);
  }

  &:hover {
    color: var(--colorPrimary);
  }

  & + .link {
    margin-top: 18px;
  }
}

.icon {
  margin-right: 8px;
}

.table {
  display: flex;
  margin: 48px 0 0 0;

  @media (--tabletBig) {
    flex-direction: column;
    margin: 36px 0 0 0;
  }
}

.column {
  display: flex;
  flex-direction: column;
}

.columnMini {
  width: 240px;
  flex-shrink: 0;

  @media (--tabletBig) {
    width: 100%;
  }
}

.columnMain {
  padding: 0 0 0 62px;
  width: calc(100% - 240px);

  @media (--tabletBig) {
    padding: 0;
    margin: 30px 0 0 0;
    width: 100%;
  }
}

.article {
  & h1 {
    font-weight: 500;
    font-size: 20px;
    color: var(--colorTextPrimary);
    text-align: left;
    line-height: 20px;
    margin: 0 0 20px 0;

    @media (--tabletBig) {
      font-size: 18px;
    }
  }

  & h2 {
    font-weight: 500;
    font-size: 14px;
    color: var(--colorTextPrimary);
    line-height: 20px;
    margin: 20px 0 0 0;
  }

  & p {
    font-size: 14px;
    color: var(--colorTextPrimary);
    line-height: 20px;
    margin: 0;
  }

  & ol {
    margin: 0;
  }

  & ul {
    list-style: none;
    margin: 0;
  }

  & ul[type='circle'] {
    list-style: circle;
  }

  & li {
    font-size: 14px;
    color: var(--colorTextPrimary);
    line-height: 20px;
    margin: 0;
  }

  & table {
    border-spacing: 0;
  }

  & th {
    font-weight: 500;
    font-size: 14px;
    color: var(--colorTextPrimary);
    line-height: 20px;
    border-top: 1px solid #e7e9ee;
    border-bottom: 1px solid #e7e9ee;
    padding: 8px;

    & + th {
      border-left: 1px solid #e7e9ee
    }

    &:first-of-type {
      border-left: 1px solid #e7e9ee;
    }

    &:last-of-type {
      border-right: 1px solid #e7e9ee;
    }
  }

  & td {
    font-size: 14px;
    color: var(--colorTextPrimary);
    line-height: 20px;
    padding: 8px;
    border-bottom: 1px solid #e7e9ee;

    & + td {
      border-left: 1px solid #e7e9ee
    }

    &:first-of-type {
      border-left: 1px solid #e7e9ee;
    }

    &:last-of-type {
      border-right: 1px solid #e7e9ee;
    }
  }

  & a {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    white-space: nowrap;
    color: var(--colorPrimary);
    text-align: left;
    text-decoration: none;
  }
}
