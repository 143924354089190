@import 'styles/core';

.nav {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;
  height: 53px;
  width: 100%;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--colorWhite);
  border-bottom: 1px var(--colorBorderDefault) solid;
  box-sizing: border-box;

  @media (--tablet) {
    padding: 0 16px;
  }
}

.link {
  margin-right: 32px;

  font-size: 14px;
  font-weight: 500;
  color: var(--colorTextSecondary);

  text-decoration: none;
  cursor: pointer;

  &:hover,
  &.linkActive {
    color: var(--colorPrimary);
  }

  &:focus {
    outline: none;
  }
}
