@import 'styles/core';

.link, .button {
  -webkit-appearance: none !important;
  max-height: 40px;
  display: flex;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  background: none;
  outline: none;
  position: relative;

  padding: 4px 11px;
  color: var(--colorTextSecondary);
  border: 1px solid #BDCFE1;
  border-radius: 5px;

  align-items: center;

  &:hover {
    text-decoration: none;
    background-color: #F7F9FA;
  }

  &:disabled {
    opacity: 0.54;
    cursor: default;
  }

  & svg {
    width: 18px;
    height: 18px;
    fill: var(--colorTextSecondary);
    fill-rule: evenodd;
  }

  &.bigIcon svg {
    width: 20px;
    height: 20px;
  }

  &.highIcon svg {
    width: 24px;
    height: 24px;
  }

  & .title {
    white-space: nowrap;
  }

  &.hasIcon .title {
    margin-left: 8px;
  }

  &.loading {
    & .title {
      visibility: hidden;
    }
  }

  & .loader {
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);

    & .loaderIcon {
      width: 20px;
      height: 20px;

      & svg {
        width: 20px;
        height: 20px;

        & circle {
          stroke: var(--colorWhite);
        }
      }
    }
  }
}

.icon {
  background: none;
  border: none;

  cursor: pointer;
}

.themeDark {
  background-color: var(--colorPrimary);
  border: none;
  font-size: 14px;
  color: var(--colorWhite);
  line-height: 20px;
  padding: 8px 16px;
  border-radius: 4px;

  &:hover {
    background-color: var(--colorHover);
  }

  &:focus {
    background-color: var(--colorFocus);
  }

  &:disabled {
    opacity: 1;
    color: var(--colorTextNeutral);
    background-color: #E7E9EE;
    border-color: #E7E9EE;
  }


  & .loaderIcon svg circle {
    stroke: var(--colorWhite);
  }
}

.themeLight {
  background-color: var(--colorWhite);
  border: 1px solid #B4C5D6;
  font-size: 14px;
  color: var(--colorTextSecondary);
  line-height: 20px;
  padding: 7px 16px;
  border-radius: 4px;

  &:hover {
    background-color: #F7F9FA;
    border-color: #9BACBD;
  }

  &:focus {
    background-color: #EBEDF0;
    border-color: #8695A3;
  }

  &:disabled {
    opacity: 1;
    color: var(--colorTextNeutral);
    background-color: #E7E9EE;
    border-color: #E7E9EE;
  }

  & .loaderIcon svg circle {
    stroke: #536B7F;
  }
}

.themeMenuItem {
  height: 32px;
  padding: 0 16px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  cursor: pointer;

  white-space: nowrap;

  border: none;

  color: var(--colorTextPrimary);
  font-weight: normal;

  &:hover {
    background-color: #F7F9FA;
  }

  & svg {
    fill: var(--colorTextSecondary);
    height: 20px;
    width: 20px;

    margin-left: 2px;
  }

  & .title {
    margin-left: 10px;
  }
}

.themeTransparent,
.themeTransparentRed {
  background-color: transparent;
  border: none;
  padding: 8px 8px;

  &:hover,
  &:focus {
    background-color: transparent;
    border: none;
  }
}

.themeTransparent {
  color: var(--colorTextSecondary);

  &:hover {
    color: var(--colorHover);

    & svg,
    & svg path {
      fill: var(--colorHover);
    }
  }

  /* &:active, &:focus {
    color: var(--colorFocus);

    & svg,
    & svg path {
      fill: var(--colorFocus);
    }
  } */
}

.themeTransparentRed {
  color: var(--colorNegative);

  &:hover,
  &:focus {
    color: #b55050;;
  }

  &:active {
    color: #944040;
  }
}

.themeError {
  background-color: var(--colorNegative);
  border: none;
  font-size: 14px;
  color: var(--colorWhite);
  line-height: 20px;
  padding: 8px 16px;
  border-radius: 4px;
  border-color: var(--colorNegative);

  &:hover,
  &:focus {
    background-color: #b55050;
  }

  &:active {
    background-color: #944040;
  }

  & .loaderIcon svg circle {
    stroke: var(--colorWhite);
  }
}

.large {
  height: 36px;
}

.small {
  height: 32px;
  padding: 6px 12px;
}
