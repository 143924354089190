
 @import 'styles/core';

.dialogHeader {
  padding-left: 24px;
  padding-right: 24px;
}

.layout {
  width: 624px;
  padding: 24px 0 16px;

  @media (--tablet) {
    width: 100%;
    height: 100%;
    margin: 0;
    top: 0;
    transform: none;
    border-radius: 0;
  }
}

.body {
  width: 100%;
  height: 100%;
}

.filtersAndSearch {
  padding: 0 24px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 16px 24px 0;
  border-top: 1px solid var(--colorBorderDefault);

  @media (--tablet) {
    position: fixed;
    bottom: 16px;
  }
}

.dontSaveButton {
  margin-right: 8px;
}

.filterInputWrapper {
  position: relative;
  width: 100%;
}

.filterControl {
  display: flex;
}

.filterInput {
  width: 100%;
  flex-shrink: 1;
  flex-grow: 0;
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0 44px;
  border-radius: 5px;
  border: 1px solid #BDCFE1;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;

  &::placeholder {
    color: var(--colorTextSecondary);
  }

  &::-ms-clear {
    display: none;
  }
}

.filtersAndSearch {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon {
  width: 20px;
  height: 20px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 8px;
  transition: transform 150ms ease-out;

  & svg {
    width: 20px;
    height: 20px;
    fill: var(--colorTextSecondary);
  }
}

.item {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  padding: 8px 32px 8px 24px;
  font-weight: 500;
  color: var(--colorTextSecondary);

  &:not(.touch) {
    & .checkbox {
      display: none;
    }
  }

  &:not(.checked) {
    &:hover {
      & .checkbox {
        display: flex;
      }
    }
  }

  &.checked .checkbox {
    display: flex;
  }
}

.category {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  padding: 8px 32px 4px 0;
  color: var(--colorTextSecondary);
  text-transform: capitalize;
  font-weight: 500;
  margin-left: 28px;
}

.offsetTwo {
  padding-left: 52px;
}

.item.expanded .expander {
  transform: rotate(90deg);
}

.title {
  font-size: 14px;
  line-height: 20px;
}

.expander {
  & svg,
  & svg path {
  width: 20px;
  height: 20px;
  fill: var(--colorTextSecondary);
  }
}

.treeWrapper {
  margin-top: 12px;
  padding-bottom: 24px;

  height: 336px;
  overflow: auto;

  @media (--tablet) {
    height: calc(100vh - 190px);
  }
}

.filterInputButton {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 9px;
  bottom: 9px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filterInputButtonLeft {
  left: 12px;
}

.filterInputButtonRight {
  right: 12px;
}

.filterOptionsPopup {
  width: calc(100% - 12px);
  position: absolute;
  padding: 4px 0;
  top: calc(100% + 2px);
  background-color: var(--colorWhite);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 100;
  border-radius: 5px;
  border: 1px solid var(--colorBorderCatskillWhite);
  box-shadow: 0 8px 24px 0 rgba(0,0,0,0.02), 0 2px 4px 0 rgba(0,0,0,0.04);
}

.filterOptions {
  max-height: 292px;
}

.filterOption {
  padding: 6px 40px;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;

  color: var(--colorTextPrimary);
  font-size: 14px;
  line-height: 20px;

  &.hovered {
    background-color: #EBEEF2;
  }
}

.filterEmpty {
  height: 116px;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 0 44px;
  color: var(--colorTextSecondary);

  font-size: 14px;
  line-height: 20px;
}

.subTitle {
  margin-left: auto;
}

.dialogClose {
  @media (--tablet) {
    top: 24px;
    right: 24px;
  }
}

 .groupFilterButton {
   display: flex;
   align-items: center;
   padding: 8px;
   width: 103px;
   height: 36px;
   border: 1px solid var(--colorBorderCasper);
   border-radius: 4px;
 }

 .filterAppliedCount {
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 1px 0;

   width: 20px;
   height: 20px;

   background: #E4F7FB;
   border-radius: 99px;

   font-weight: 500;
   font-size: 13px;
   line-height: 18px;

   text-align: center;
   letter-spacing: -0.08px;

   color: var(--colorPrimary);
 }
