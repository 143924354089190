@import 'styles/core';

.shadow {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(49, 52, 59, .9);
  z-index: 10000;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0;
}

.photoDialog {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 900px;
  border-radius: 5px;
  background-color: #ffffff;
  margin: 20px auto;
  padding: 10px;
  box-sizing: border-box;

  & .photoDialogClose {
    position: absolute;
    top: 2px;
    right: -42px;
    cursor: pointer;
  }

  & .photoDialogCloseLarge {
    position: absolute;
    top: 46px;
    right: -42px;
    cursor: pointer;
  }

  & .photoDialogOpenLarge {
    position: absolute;
    top: 46px;
    right: -42px;
    cursor: pointer;
  }

  &.largeMode {
    padding: 0;
    width: calc(100% - 112px);
    margin: 20px 56px;

    & .photoDialogClose {
      right: -36px;
    }

    & .photoDialogCloseLarge {
      right: -36px;
    }
  }

  & .photoDialogHeader {
    background-color: #F6F7FA;
    padding: 0 24px 12px 24px;
    border-radius: 5px;
  }

  & .photoDialogBody {
    display: flex;
    flex-flow: column;
  }

  & .photoDialogPhotoWrapper {
    width: 100%;
    height: 580px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  & .photoDialogPhoto {
    cursor: pointer;
    height: 580px;
    object-fit: contain;
  }


  & .photoDialogLarge {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;

    & img {
      width: 100%;
      object-fit: contain;
    }
  }

  & .photoDialogLargePhoto {
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
  }


  & .photoDialogCommandsWrapper {
    margin-top: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .photoDialogTitleColumn {
    display: flex;
    align-items: flex-start;
    flex-flow: column;
  }

  & .photoDialogTitle {
    padding: 18px 0 0;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    color: #000000;
    letter-spacing: 1.85px;
  }

  & .photoDialogCommands {
    display: flex;
    align-items: center;
  }

  & .linkedButton {
    display: flex;
    align-items: center;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    & svg {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    & div {
      margin-left: 4px;
      color: var(--colorPrimary);
      border-bottom: 2px var(--colorPrimary) dotted;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.5px;
    }

    & + .linkedButton {
      margin-left: 24px;
    }
  }

  & .walkButton {
    border-radius: 5px;
    background-color: #F1F3F6;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    height: 50px;

    & + .walkButton {
      margin-left: 10px;
    }

    & svg {
      cursor: pointer;
    }

    & .walkButtonIcon.disabled {
      opacity: .4;
    }
  }

  & .walkButtonFirst {
    width: 150px;

    & .walkButtonTitle {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      display: flex;
      flex-flow: column;
      justify-content: center;
      color: var(--colorTextNeutral);
      line-height: 16px;
    }
  }

  & .walkButtonSecond {
    width: 230px;

    & .walkButtonTitle {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
    }

    & .walkButtonTitle > div {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      display: flex;
      flex-flow: row;
      justify-content: center;
      color: var(--colorTextNeutral);
      line-height: 16px;
    }
  }

  & .comments {
    display: flex;
    flex-flow: column;
    margin-top: 20px;
  }

  & .comment {
    display: flex;
    padding: 10px 26px 20px;
    border-bottom: 1px #F1F3F6 solid;
  }

  & .commentHeader {
    display: flex;
    flex-flow: column;
    width: 120px;
    min-width: 120px;
  }

  & .commentAuthor {
    font-size: 12px;
    letter-spacing: 0.6px;
    color: var(--colorPrimary);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 120px;
  }

  & .commentDate {
    font-size: 10px;
    letter-spacing: 0.416667px;
    color: #8297A6;
    white-space: nowrap;
    margin-top: 8px;
  }

  & .commentBody {
    font-size: 14px;
    color: var(--colorTextSecondary);
    margin-left: 16px;
  }

  & .createComment {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    margin-top: 0;

    & .editComment {
      width: 100%;
      padding: 20px 26px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    & .createCommentUser {
      font-size: 12px;
      color: var(--colorTextNeutral);
      letter-spacing: 0.6px;
      width: 120px;
      min-width: 120px;
      height: 100%;
      margin-top: 10px;
    }

    & .createCommentText {
      border: 1px #BDCFE1 solid;
      padding: 10px 16px;
      width: 100%;
      border-radius: 10px;
      resize: none;
      font-size: 16px;
      color: var(--colorTextSecondary);
      outline: none;

      &::placeholder {
        font-size: 16px;
        color: #BDCFE1;
      }
    }

    & .createCommentButton {
      margin-top: 0;
      margin-right: 20px;
      margin-bottom: 36px;
    }
  }
}

@media only screen and (max-width: 1020px) {
  .photoDialog {
    width: 100%;
    margin: 0;

    & .photoDialogClose {
      position: absolute;
      top: -22px;
      margin-left: -10px;
      left: 50%;
      right: 50%;
      cursor: pointer;
    }

    &.largeMode {
      width: 100%;
      margin: 0;
    }

    & .photoDialogOpenLarge {
      display: none;
    }

    & .photoDialogCloseLarge {
      display: none;
    }

    & .photoDialogPhotoWrapper {
      height: 384px;
    }

    & .photoDialogPhoto {
      height: 384px;
    }

    & .photoDialogHeader {
      padding: 0;
    }

    & .photoDialogTitle {
      padding: 18px 14px 0;
    }

    & .photoDialogCommandsWrapper {
      margin-top: 14px;
      flex-flow: column;
      padding: 0 12px 28px;
      overflow: hidden;
      align-items: flex-start;
    }

    & .photoDialogCommands {
      display: flex;
    }

    & .walkButtons {
      order: 0;
    }

    & .commandButtons {
      order: 1;
      margin-top: 28px;
    }

    & .linkedButton {
      & div {
        font-size: 16px;
        font-weight: 400;
      }

      & + .linkedButton {
        margin-left: 27px;
      }
    }

    & .comment {
      flex-flow: column;
      padding: 10px 0;
    }

    & .commentHeader {
      flex-flow: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    & .commentAuthor {
      font-weight: bold;
      font-size: 12px;
      color: var(--colorPrimary);
      letter-spacing: 0.5px;
    }

    & .commentDate {
      font-size: 10px;
      margin: 0;
    }

    & .commentBody {
      width: 100%;
      font-size: 14px;
      line-height: 20px;
      margin-left: 0;
      margin-top: 10px;
    }

    & .createComment {
      align-items: center;

      & .editComment {
        margin-top: 10px;
        padding: 0;
        flex-flow: column;
      }

      & .createCommentUser {
        margin-top: 0;
        min-width: 0;
        width: auto;
      }

      & .createCommentText {
        margin-top: 10px;
      }

      & .createCommentButton {
        margin-top: 20px;
        margin-bottom: 0;
        margin-right: 0;
      }
    }
  }

  .shadow {
    padding: 30px 7px 7px;
  }
}

@media only screen and (max-width: 640px) {
  .photoDialog {
    & .photoDialogTitle {
      padding-top: 12px;
      font-size: 20px;
    }

    & .commandButtons {
      margin-top: 20px;
    }

    & .walkButtons {
      margin-top: 10px;
    }

    & .walkButton {
      position: relative;
      padding: 0;

      & + .walkButton {
        margin-left: 26px;
      }

      & .walkButtonTitle {
        position: absolute;
        box-sizing: border-box;
        top: 5px;
        width: 100%;
        text-align: center;
        font-size: 12px;
        color: var(--colorTextNeutral);
        line-height: 16px;
        text-transform: capitalize;
      }
      & .walkButtonTitle > div {
        font-size: 12px;
        color: var(--colorTextNeutral);
        line-height: 16px;
      }

      & .walkButtonIcon {
        position: absolute;
        bottom: 3px;

        &:first-of-type {
          left: 10px;
        }

        &:last-of-type {
          right: 10px;
        }
      }
    }

    & .walkButtonFirst {
      width: 100px;
      height: 76px;
    }

    & .walkButtonSecond {
      width: 132px;
      height: 76px;
    }
  }
}

.photoReplaceWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  & > div {
    margin-top: 10px;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: .6px;
    color: #BDCFE1;
    text-shadow: 1px 1px 0 #FFFFFF;
  }
}
