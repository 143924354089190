@import 'styles/core';

.itemContainer {
  position: relative;
  min-height: 395px;
  /* width: 275px; */
  width: 23%;
  padding: 20px 20px 24px;
  margin-bottom: 24px;
  /* margin-right: 24px; */
  margin-right: 23px;

  background: #FFF;
  border: 1px solid #E7E9EE;
  border-radius: 3px;

  @media (--desktop) {
    width: 31%;
  }

  @media (--tablet) {
    width: 100%;
    margin-right: 0;
    border-radius: 0;
    border-left: none;
    border-right: none;
  }
}

.itemBody {
  display: flex;
  justify-content: center;
  
  padding-top: 48px;
}

.itemHeaderText,
.plantingCyclesHeader {
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
  font-weight: 500;
}

.plantingCycles {
  margin-top: 24px;
}

.itemHeader {
  display: flex;
  align-items: center;
}

.itemHeaderIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  width: 36px;
  height: 36px;

  background-color: var(--colorSecondaryLight);
  border-radius: 50%;
}

.speciesIcon {
  fill: var(--colorWhite);
  width: 20px;
  height: 20px;
}

.horizontalBarChartWrapper{
  padding-top: 16px;
}

.tooltipHeader {
  margin-bottom: 12px;
}

.tooltipDate {
  font-size: 14px;
  color: var(--colorTextPrimary);
  line-height: 20px;
  font-weight: 500;
}

.tooltipSubHeader {
  display: flex;
  align-items: center;

  font-size: 13px;
  line-height: 18px;
  color: var(--colorTextSecondary);
}

.dot {
  width: 2px;
  height: 2px;
  margin: 0 5px;

  background-color: var(--colorTextSecondary);
  border-radius: 50%;
}

.line {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  font-size: 13px;
  color: var(--colorTextPrimary);
  line-height: 18px;

  &:last-child {
    margin-bottom: 0;
  }
}


.lineHeader {
  display: flex;
  align-items: center;
}

.lineColor {
  position: relative;

  display: block;
  width: 12px;
  height: 4px;
  margin-right: 8px;
  background: #869BAD;
}

.dashedLine {
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(50% - 1px);
    height: 100%;
    width: 2px;
    background: #FFF;
  }
}

.lineValue {
  font-weight: 500;
}
