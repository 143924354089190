@import 'styles/core';

.button {
  width: 20px;
  height: 20px;
  margin-left: 32px;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0;

  @media (--tabletBig) {
    margin-left: 16px;
  }

  & svg {
    fill-opacity: .8;
    width: 20px;
    height: 20px;
  }

  &:hover svg {
    fill-opacity: 1;
  }
}

.notification {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 10000;
  min-width: 72px;
  margin-top: 12px;
  padding: 16px 24px;

  white-space: pre-wrap;
  box-sizing: border-box;

  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;
  background-color: var(--colorTextPrimary);
  color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04), 0 8px 24px 0 rgba(0, 0, 0, 0.02);

  @media (--tabletBig) {
    padding: 16px;
  }
}

.text {
  display: flex;
  align-items: flex-start;
}
.icon {
  width: 20px;
  height: 20px;
  margin-right: 14px;

  & svg {
    width: 20px;
    height: 20px;
  }
}

.leftDown {}

.rightDown {}

.withAction {
  min-width: 128px;
}

.withActionWide {
  min-width: 480px;
}

.sidePanel {
  margin-right: 52px;
}

.withFixedWidth {
  width: 552px;

  & .text {
    align-items: flex-start;
    padding-right: 40px;
  }
}

.withSmallWidth {
  width: 240px;

  & .text {
    align-items: flex-start;
    padding-right: 40px;
  }
}

.loaderWrapper {
  position: relative;
  height: 20px;
  width: 20px;
}

.loaderIconClassName {
  background-color:none;
}

.loaderIconClassName {
  width: 20px;
  height: 20px;

  & > svg > circle {
    stroke: var(--colorWhite);
  }
}
