@import 'styles/core';

.activeTh {
  cursor: pointer;

  &:hover {
    background-color: #e8ebed;
  }

  & label {
    display: inline-block;
    padding-right: 14px;
  }
  min-height: 44px;
}

.activeSort {
  & .arrow {
    display: block;
  }
}

.sticky {
  left: 1px;
  position: absolute;
  top: auto;
  width: 320px;
  background-color: #f7f9fa;
  z-index: 10;
  align-items: center;
  display: flex;
  border-right: 1px solid #E7E9ED;

  &:after {
    content: '';
    position: absolute;
    right: -6px;
    height: 100%;
    width: 6px;
    background-color: #4a4a49;
    opacity: 0.05;
    z-index: 1;
  }
}

.tdPad {
  width: 100%;
}

.thContent {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 24px;
  white-space: nowrap;
}

.thLeft {
  text-align: left;

  & .thContent {
    justify-content: flex-start;
  }
}

.arrow {
  width: 24px;
  height: 24px;
  display: none;
  transform: rotate(180deg);

  &.sortDirectionDown {
    transform: rotate(0deg);
  }
}

.checkBox  {
  & input:checked ~ div {
    border: 1px solid #BDCFE1!important;
  }
}
