@import 'styles/core';

.compareButtonWrapper {
  padding: 14px 12px;

  border-top: 1px solid var(--colorBorderCatskillWhite);
}

.compareButton {
  padding: 0;
  width: 100%;

  &:hover .compareButtonIcon,
  &:focus .compareButtonIcon {
    fill: var(--colorPrimary);
  }

  &:hover,
  &:focus,
  &:active {
    color: var(--colorPrimary);
  }
}

.compareButtonIcon {
  margin-right: 4px;

  fill: var(--colorPrimary);
}

.compareButtonWrapper .compareButton .title {
  margin-left: 4px;
}
