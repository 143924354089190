@import 'styles/core';

:global {
  @media (--tabletBig) {
    & #intercom-container {
      z-index: 10000!important;
    }
  }
}

.notificationCenter {
  position: fixed;
  bottom: 0;
  padding: 0 24px 24px;

  z-index: 10000;

  &.empty {
    transition: padding 0 ease-out;
    transition-delay: 150ms;
    padding: 0;
  }
}

.notificationCenterFixed {
  left: calc(50% - 412px);
  right: 0;
  max-width: 776px;
  box-sizing: content-box;
  z-index: 10010;


  @media (--tabletBig) {
    max-width: 100%;
    left: 8px;
    right: 8px;
    bottom: 8px;
    padding: 0;
  }
}

.notificationCenterLeft {
  left: 0;
}

.notificationCenterRight {
  right: 0;
}

.fadeEnter {
  opacity: 0.01;
}

.fadeEnterActive {
  opacity: 1;
  transition: opacity 150ms ease-out;
}

.fadeExit {
  opacity: 1;
}

.fadeExitActive {
  opacity: 0.01;
  transition: opacity 150ms ease-out;
}
