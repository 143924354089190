@import 'styles/core';

.block {
  margin-bottom: 48px;

  & .blockTitle {
    margin-bottom: 24px;
  }

  .metricsContainer {
    margin-bottom: 48px;
    padding: 24px;
    border: 1px solid var(--colorBorderDefault);
    border-radius: 4px;
  }
}
