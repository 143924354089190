@import 'styles/core';

.dropdownContent {
  width: 228px;

  padding: 4px 12px;
}

.yearsRow {
  display: flex;
  align-items: center;
  margin-top: 22px;

  &:first-child {
    margin-top: 0;
  }
}

.year {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 51px;
  height: 32px;

  font-size: 13px;
  line-height: 18px;

  color: var(--colorTextPrimary);
  cursor: pointer;

  &.disabled {
    color: var(--colorTextNeutral);
    cursor: default;
  }

  &.selected,
  &.inProgressSelected,
  &.hovered.inProgressSelected {
    background-color: var(--colorPrimary);
    color: var(--colorWhite);
  }

  &.selectedFirst,
  &.hoveredFirst {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &.selectedLast,
  &.hoveredLast {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &.hovered {
    background-color:var(--colorBorderDefault);
  }
}
