@import 'styles/core';

.dialogText {
  margin: 0;

  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding-top: 32px;
}

.confitm {
  margin-left: 8px;
}

.popupText {
  margin: 0;

  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
}

.dialogWrapper {
  min-width: 480px;
}

.versionName {
  margin-bottom: 16px;
}
