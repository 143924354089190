@import 'styles/core';

.categoryGroup {
  color: var(--colorTextSecondary);
  font-size: 14px;
  font-weight: 500;
}

.tableWrapper {
  position: relative;
  padding-bottom: 58px;
}

.tableScroller {
  overflow-x: scroll;
  overflow-y: visible;
  background: #fff;
  border: 1px solid #E7E9ED;
  border-radius: 4px;
}

.table {
  border: none;
  margin-left: 319px;
  width: calc(100% - 320px);
}

.sticky {
  left: 1px;
  position: absolute;
  top: auto;
  width: 320px;
  display: flex;
  z-index: 10;
  background-color: #FFFFFF;
  align-items: center;
  border-right: 1px solid #E7E9ED;

  &:after {
    content: '';
    position: absolute;
    right: -6px;
    height: 100%;
    width: 6px;
    background-color: #4a4a49;
    opacity: 0.05;
    z-index: 1;
  }
}


.tooltipIndicator {
  z-index: 10000;
  padding: 16px 10px!important;
  border-radius: 10px!important;
  background: #FCFAEB!important;
  color: var(--colorTextSecondary)!important;
  font-size: 12px!important;
  border: 1px solid rgba(203, 142, 40, .1)!important;
  white-space: pre-wrap!important;
  opacity: 1!important;
  width: 200px;


  & :global(.multi-line) {
    text-align: left!important;
  }

  &:global(.place-top) {

    &:after {
      border-top-color: #FCFAEB!important;
    }
    &:before {
      border-top: 8px solid rgba(203, 142, 40, .1)!important;
      bottom: -8px;
    }
  }

  &:global(.place-bottom) {
    &:after {
      border-bottom-color: #FCFAEB!important;
    }
    &:before {
      border-bottom: 8px solid rgba(203, 142, 40, .1)!important;
      top: -8px;
    }
  }

  &:global(.place-left) {
    &:after {
      border-left-color: #FCFAEB!important;
    }
    &:before {
      border-left: 8px solid rgba(203, 142, 40, .1)!important;
      right: -8px;
    }
  }

  &:global(.place-right) {
    &::after {
      border-right-color: #FCFAEB!important;
    }
    &::before {
      border-right: 8px solid rgba(203, 142, 40, .1)!important;
      left: -8px;
    }
  }

  @media only screen and (max-width: 640px) {
    left: 4%!important;
    width: 92%!important;
  }
}
