@import 'styles/core';

.timeline {}

.compareTip {
  position: absolute;
  background: #E4F7FB;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.compareTipIcon {
  fill: var(--colorPrimary);
}

.emptyState {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 24px;

  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.154px;

  color: var(--colorTextSecondary);
}
