@import 'styles/core';

.panel {
  position: fixed;
  right: 0;
  top: 60px;
  bottom: 0;
  width: 720px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid var(--colorBorderDefault);
  background-color: white;
  transition: right 150ms ease-out;
  z-index: 1;
  visibility: visible;

  &.dialogOpened {
    z-index: 1000;
  }

  @media (--phoneLandscape) {
    width: 100%;
  }

  &.collapsed {
    right: -720px;
    visibility: hidden;
  }

  & > .header {
    padding: 16px 32px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: var(--colorTextPrimary);
    border-bottom: 1px solid var(--colorBorderDefault);
  }

  & .iconButton {
    width: 28px;
    height: 28px;
    outline: none;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-shrink: 0;

    & .icon {
      height: 20px;
      width: 20px;
      flex-shrink: 0;
    }

    &:hover {
      & svg path {
        fill: var(--colorTextSecondary);
      }
    }
  }

  & .deleteIcon {
    width: 30px;
    height: 30px;
  }

  & > .body {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 32px 32px 96px 32px;
    overflow-y: auto;
  }

  & .section {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    & + .section {
      margin-top: 32px
    }

    & .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: var(--colorTextPrimary);
    }

    & .body {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: var(--colorTextPrimary);
    }
  }
}

.buttonsWrapper {
  display: flex;
  align-items: center;
}

.actionButtons {
  position: absolute;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 16px 24px;
  border-top: 1px solid var(--colorBorderDefault);
  background: var(--colorWhite);
  z-index: 1;
}

.cancel {
  margin-right: 8px;
}

.sectionSubHeader {
  margin-top: 4px;
}

.sectionContent {
  margin-top: 16px;
}

.ruleNameInput {
  max-width: 360px;
}

.usersDropdown {

}

.usersWrapper {
  margin: 12px 0 32px;
}

.users {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  max-width: 552px;
  flex-flow: wrap;
  row-gap: 8px;
}

.error {
  padding: 4px 16px;
  width: 320px;
  border-radius: 4px;
  background: #F7E2E7;
  color: var(--colorNegative);
  margin-top: 8px;
  font-size: 12px;
  line-height: 16px;
}
