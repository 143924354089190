@import 'styles/core';

.dialogText {
  margin: 0;

  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);

  max-width: 444px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding-top: 24px;
}

.cancel {
  margin-right: 8px;
}

.popupText {
  margin: 0;

  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
}

.dialogWrapper {
  min-width: 504px;
}

.checkbox {
  margin-top: 16px;

  & > span {
    color: var(--colorTextPrimary);
  }
}
