@import 'styles/core';


.input {
  background: #FFFFFF;
  outline: none;
  width: 100%;
  text-align: left;
  font-size: 14px;
  color: var(--colorTextPrimary);
  letter-spacing: -0.15px;
  margin: 0;
  box-sizing: border-box;

  height: 48px;
  line-height: 20px;
  padding: 20px 12px 8px 12px;
  border-radius: 4px;
  border: 1px solid #BDCFE1;

  &::placeholder {
    font-size: 14px;
    color: var(--colorTextNeutral);
    letter-spacing: -0.15px;
    line-height: 20px;
  }

  &:hover {
    border-color: var(--colorPrimary);
  }

  &.invalid, &.invalid:hover, &.invalid:focus {
    border-color: var(--colorNegative);
    box-shadow: none;
  }

  &.danger, &.danger:hover, &.danger:focus {
    border-color: var(--colorWarning);
    box-shadow: none;
  }

  &:focus {
    border: 1px solid var(--colorPrimary);
    box-shadow: 0 0 0 2px rgba(67, 179, 226, 0.10)
  }

  &.mini {
    height: 32px;
    padding: 5px 12px 5px 12px;
  }

  &::-webkit-clear-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
}
