@import 'styles/core';


.layout {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;

  fill: var(--colorPlantPrimary);
  background-color: var(--colorPlantSecondary);
}
