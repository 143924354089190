@import 'styles/core';

.delete {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdownMenu {
  padding: 16px 16px;
  white-space: normal;
}

.deleteTooltipTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  color: var(--colorTextPrimary);
}

.deleteTooltipActions {
  display: flex;
  justify-content: flex-end;

  padding: 0;
  margin: 12px 0 0 0;
}

.deleteTooltipActionCancel {
  padding: 5px 8px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  display: flex;
  align-items: center;
  text-align: center;

  color: var(--colorTextSecondary);

  cursor: pointer;
}

.deleteTooltipActionOk {
  padding: 5px 8px;
  margin: 0 0 0 8px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  display: flex;
  align-items: center;
  text-align: center;

  color: var(--colorNegative);

  cursor: pointer;
}