@import 'styles/core';

.layout {
  height: 216px;
  min-height: 216px;
  width: 100%;
}

.tooltip {
  width: 336px;
}

.tooltipHeaderContent {
  & .locationName,
  & .dates {
    min-width: 171px;
    max-width: 215px;
    text-overflow: ellipsis;

    white-space: nowrap;
    overflow: hidden;
  }
}

.legend {
  max-width: 100%;
  padding-top: 24px;
}
