.overlay {
  position: fixed;
  height: 100%;
  width: 0;
  z-index: 1002; /* перебиваем хедер и тултип */
  right: 0;
  top: 0;
  background-color: rgba(30, 42, 52, .6);
  overflow-x: hidden;
}

.overlayOpened {
  width: 100%;

  & .content {
    right: 0;
  }
}

.content {
  position: fixed;
  height: 100%;
  max-width: 80%;
  width: 560px;
  right: -600px;
  top: 0;
  bottom: 0;
  background-color: rgba(49, 52, 59, .9);
  overflow-x: hidden;
  transition: 0.3s;
  background-color: #F6F9FD;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .5);
}
