
 @import 'styles/core';

.tableWrapper {
  margin-top: 16px;

  & .td:first-child {
    white-space: normal;
  }
}

.checkboxTd {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
