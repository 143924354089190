@import 'styles/core';

.settingsHarvestForecast {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
}

.header {
  margin-bottom: 4px;
}

.infoPlate {
  margin: 16px 0 32px;
}

.supportButton {
  display: inline-block;
  padding: 0;

  color: var(--colorPrimary);
  font-weight: 500;
}

.contentGroup {
  margin-bottom: 48px;
  max-width: 552px;
}

.permissionsHeader {
  margin-bottom: 16px;
}

.publishHeader {
  margin-bottom: 4px;
}

.users {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-top: 16px;
  flex-wrap: wrap;
}

.userBage {
  margin-bottom: 8px;
}

.href {
  text-decoration: none;
  color: var(--colorPrimary);
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
}

.categoriesList {
  margin-top: 16px;
  border: 1px solid var(--colorBorderDefault);
  border-radius: 4px;
}

.group {
  border-bottom: 1px solid var(--colorBorderDefault);

  &:last-child {
    border-bottom: none;
  }
}

.groupContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 16px;

  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: var(--colorBackgroundHover);
  }
}

.arrowRightIconWrapper {
  width: 20px;
  height: 20px;
  margin-left: 16px;
}

.arrowRightIcon {
  & path {
    fill: var(--colorTextSecondary);
  }
}

.firstColumn,
.count {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.count {
  color: var(--colorTextSecondary);
}

/* .metricsCountLength {
  @media (--phoneLandscape) {
    display: none;
  }
} */
