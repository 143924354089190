@import 'styles/core';


.plan {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  cursor: pointer;

  &:hover {
    background-color: var(--colorBackgroundHover);
    
    /* & .dropdownWrapper {
      display: block;
    } */
  }
}

.dropdownButton {
  width: 24px;
  height: 24px;
}

/* .dropdownWrapper {
  display: none;
} */

.planLeftContainer {
  display: flex;
  max-width: calc(100% - 24px);
}

.planActive,
.planActive:hover {
  background-color: #EDFAFD;
}

.planInfo {
  display: block;
  max-width: calc(100% - 44px);
}

.avatar {
  margin-right: 8px;
}

.planLabel {
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
  font-weight: 500;
}

.lastSaved,
.restored,
.planAuthor {
  font-size: 13px;
  line-height: 18px;
  color: var(--colorTextSecondary);
}

.planComment {
  padding-top: 8px;
  font-size: 13px;
  line-height: 18px;
  color: var(--colorTextPrimary);
}

.planPublished {
  display: inline-block;
  padding: 2px 8px;
  margin-top: 4px;
  border-radius: 4px;
  background-color: var(--colorPrimary);
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  color: var(--colorWhite);
}

.dropdownOption {
  padding: 6px 16px;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background: var(--colorBackgroundHover);
  }
}

.dropdownOptionDisabled {
  color: var(--colorTextNeutral);
}

.authorWrapper {
  display: flex;
  align-items: center;
}

.dot {
  display: flex;
  align-items: center;

  &:after {
    content: '';
    width: 4px;
    height: 4px;
    margin: 0 5px;

    background-color: var(--colorTextSecondary);
    border-radius: 50%;
  }
}

.stub {
  min-width: 24px;
}

.authorName {
  max-width: 52%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
