@import 'styles/core';

.input {
  width: 107px;
  height: 32px;
  padding: 6px 12px;
}

.editIcon {
  margin-right: 4px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  visibility: hidden;

  & svg {
    height: 20px;
    width: 20px;
  }
}

.editWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  padding: 4px;
  border-radius: 4px;

  cursor: pointer;

  &:hover {
    background-color: var(--colorBackgroundHover);

    & .editIcon {
      visibility: visible;
    }
  }
}

.popoverValueAmount {
  color: var(--colorTextPrimary);
}

.editControls, .formButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formButtons {
  margin-right: 8px;
}

.closeIcon,
.checkIcon {
  padding: 4px;
  
  &:hover {
    & svg,
    & svg path {
      fill: var(--colorTextPrimary);
    }
  }
}
