@import 'styles/core';

.harvestForecastActionsTable {
  margin-bottom: 48px;
}

.actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  padding-top: 12px;
  padding-bottom: 8px;
}

.fieldsButton {
  padding-right: 8px;
  padding-left: 8px;
}

.exelButton {
  margin-right: 8px;
  padding: 4px 8px;
}

.defaultTableWrapper {
  padding: 0;
  & th:nth-child(2),
  & td:nth-child(2) {
    text-align: left;
  }
}

.scrolledHorizontal {
  overflow-x: auto;
}

.fieldsSelect {

}

th.chartIconWrapper,
td.chartIconWrapper {
  width: 36px;
  /* padding: 5px 4px 5px 12px; */
}

.chartIconWrapper {
  cursor: pointer;

  & svg {
    fill: #9EA4A8;
  }
}


.activeTh {
  cursor: pointer;

  &:hover {
    background-color: #e8ebed;
  }
}

.thContent {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 18px;
  white-space: nowrap;
}

.thLeft {
  text-align: left;

  & .thContent {
    justify-content: flex-start;
  }
}

.point {
  width: 12px;
  height: 12px;
}


.tableScroller {
  overflow-x: scroll;
  overflow-y: visible;
  background: #fff;
  border: 1px solid #E7E9ED;
  border-radius: 4px;
}

.fixedColumns {
  border: none;
  margin-left: 269px;
  width: calc(100% - 270px);

  & tr th:first-child,
  & tr td:first-child,
  & tr th:nth-child(2),
  & tr td:nth-child(2) {
    display: flex;
    align-items: center;
  }

  & tr th:first-child,
  & tr td:first-child {
    left: 1px;
    position: absolute;
    top: auto;
    width: 32px;
    z-index: 10;
  }

  & tr th:nth-child(2),
  & tr td:nth-child(2) {
    left: 33px;
    position: absolute;
    top: auto;
    width: 237px;
    z-index: 10;
    border-right: 1px solid #E7E9ED;

    &:after {
      content: '';
      position: absolute;
      right: -6px;
      top: 0;
      height: 100%;
      width: 6px;
      background-color: #4a4a49;
      opacity: 0.05;
      z-index: 1;
    }
  }


  & tr th:first-child,
  & tr th:nth-child(2) {
    height: 32px;
  }

  & tr td:first-child,
  & tr td:nth-child(2) {
    height: 26px;
    background: var(--colorWhite);
  }

  & tr:first-child th,
  & tr:last-child td {
    background-color: var(--colorThDefault);
  }

  &.threeColumns {
    margin-left: 499px;
    width: calc(100% - 500px);

    & tr th:nth-child(2),
    & tr td:nth-child(2) {
      border-right: none;

      &:after {
        display: none;
      }
    }

    & tr th:nth-child(3),
    & tr td:nth-child(3) {
      left: 270px;
      position: absolute;
      top: auto;
      width: 230px;
      z-index: 10;
      border-right: 1px solid #E7E9ED;

      &:after {
        content: '';
        position: absolute;
        right: -6px;
        top: 0;
        height: 100%;
        width: 6px;
        background-color: #4a4a49;
        opacity: 0.05;
        z-index: 1;
      }
    }

    & tr td:nth-child(3) {
      background: var(--colorWhite);
    }
  }
}

.fixedColumns.wideFixedColumn {
  margin-left: 459px;
  width: calc(100% - 460px);

  & tr th:nth-child(2),
  & tr td:nth-child(2) {
    left: 33px;
    width: 427px;
  }

  &.threeColumns {
    margin-left: 689px;
    width: calc(100% - 690px);

    & tr th:nth-child(3),
    & tr td:nth-child(3) {
      left: 460px;
      width: 230px;
    }
  }
}
