@import 'styles/core';

.button {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  border: 1px solid var(--colorBorderCasper);
  border-radius: 4px;
  background-color: var(--colorWhite);
  color: var(--colorTextSecondary);

  font-size: 14px;
  font-weight: 500;

  cursor: pointer;
}

.plusIcon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
