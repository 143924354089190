@import 'styles/core';

.layout__viewport {
  padding: 0;
  min-height: calc(100vh - 60px);

  @media only screen and (max-width: 640px) {
    min-height: calc(100vh - 56px);
  }
}

.layout__main {
  overflow: hidden;
  padding: 60px 0 0 0;
  margin: 0;
  min-height: 100vh;
  box-sizing: border-box;
}

.no_scroll {
  height: 100vh;
}

.disableScroll {
  overflow: hidden;
}

.noScrollOnlyForMobile {
  @media (--tablet) {
    height: 100vh;
  }
}

@media (--tablet) {
  .layout__main {
    padding-top: 56px;
  }
}
