@import 'styles/core';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 22px 24px;
  border-bottom: 1px solid #E7E9EE;
}

.title {
  font-size: 24px;
  line-height: 24px;
  color: var(--colorTextPrimary);
  letter-spacing: 0.3px;
  text-align: left;
  font-weight: 500;
}

.selectButton {
  justify-content: flex-end;
}

.controls {
  display: flex;
}

.button {
  height: 32px;
  margin-left: 24px;
  padding: 6px 16px 6px 16px;
}

.selectButtonText {
  text-align: left;
}
