@import 'styles/core';

.stateControls {
  display: flex;

  &.withPhoneVersion {
    @media (--tablet) {
      display: none;
    }
  }
}

.select {
  margin-right: 8px;
}

.selectButton {
  height: 32px;
  min-width: 144px;
}

.selectPopup {
  min-width: 144px;
}

.leftButton,
.rightButton {
  position: relative;
  display: flex;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: none;
  background: none;
  background: #FFFFFF;
  border: 1px solid #BDCFE1;
  border-radius: 4px;
  cursor: pointer;

  & svg {
    /* fix iOS safari svg bug https://stackoverflow.com/questions/4504942/mobile-safari-svg-problem */
    position: absolute;
    top: 9px;
  }

  &:hover svg path {
    fill: var(--colorTextSecondary);
  }
}

.leftButton {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}

.rightButton {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
}

.disabled {
  opacity: 0.5;
}
