@import 'styles/core';

.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  z-index: 1;

  &.fixedLoader{
    justify-content: flex-start;
    padding-top: 220px;
  }
}

.icon {
  animation: rotation 1.4s infinite linear;

  @media all and (-ms-high-contrast:none) {
    animation: rotation-ie 1.4s infinite linear;
  }
}

@keyframes rotation {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
}

@keyframes rotation-ie {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(359deg); }
}

.icon > svg > circle {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite;
  stroke: var(--colorPrimary);

  @media all and (-ms-high-contrast:none) {
    stroke-dashoffset: 46.5;
  }
}

/* TODO: сделать circleLoader и circleLoaderIcon дефолтными классами, чтобы не задавать в каждом новом лоадере */
.circleLoader {
  background-color: rgba(256, 256, 256, .8);
}

.withoutOpacity {
  background-color: #FAFBFC;
}

.newBackground {
  background-color: #FAFBFC;
  opacity: .8;
}

.circleLoaderIcon {
  width: 48px;
  height: 48px;
}

@keyframes dash {
  0% { stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.5;
    transform:rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform:rotate(450deg);
  }
}
