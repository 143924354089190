@import 'styles/core';

.varietySelect {
  background: none !important;
  border: none !important;
  font-weight: 500 !important;
}

.selectboxFilterWrapper {

}
