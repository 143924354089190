@import 'styles/core';

.container {
  & .row {
    margin-bottom: 24px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    & .title {

    }
    & .menuButton {
      height: 28px;
      border-radius: 4px;
      display: flex;
      padding: 6px 8px 8px 6px;
      cursor: pointer;
      user-select: none;
      & .settingsIcon {
        margin-right: 10px;
        @media (--phoneLandscape) {
          margin-right: 0;
        }
      }
      & .settingsLabel {
        font-size: 14px;
        font-weight: 500;
        color: var(--colorTextSecondary);
        @media (--phoneLandscape) {
          display: none;
        }
      }
      &:hover {
        background-color: var(--colorMenuItemHover);
      }
      &.active {
        background-color: var(--colorBorderDefault);
      }
      &:checked {
        background-color: var(--colorBorderCatskillWhite);
      }
    }
  }
}
