@import 'styles/core';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 25px 32px;
}

.text {
  font-size: 20px;
  color: var(--colorTextPrimary);
  font-weight: 500;
}

.close {
  width: 24px;
  height: 24px;
}

.closeIcon {
  width: 24px;
  height: 24px;
  fill: #777776;
  fill-rule: evenodd;
  cursor: pointer;
}
