@import 'styles/core';

.settingsMeasurementsList {
  border: 1px solid var(--colorBorderDefault);
  border-radius: 4px;

  max-width: 552px;
}

.secondLevel {
  border-top: 1px solid var(--colorBorderDefault);
}

.groupContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 7px 16px;

  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: var(--colorBackgroundHover);
  }
}

.arrowRightIconWrapper {
  width: 20px;
  height: 20px;
  margin-left: 16px;
}

.arrowRightIcon {
  & path {
    fill: var(--colorTextSecondary);
  }
}

.firstColumn,
.metricsCount {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.metricsCount {
  color: var(--colorTextSecondary);
}

.metricsCountLength {
  @media (--phoneLandscape) {
    display: none;
  }
}

.turnArrowIcon {
  margin-right: 12px;
}
