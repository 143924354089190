@import 'styles/core';

.layout {
  min-height: calc(100vh - 60px);
  background-color: #FAFBFC;
}

.contentWrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 24px 196px 24px;

  @media (--tablet) {
    padding: 0 0 196px 0;
  }
}

.loader {
  position: relative;
  height: 320px;
}

.dashboardHeader {
  padding-bottom: 24px;

  @media (--tablet) {
    padding-bottom: 0;
  }
}

.mobileContainer {
  border: none;
}
