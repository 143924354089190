@import 'styles/core';

.tooltip {
  position: absolute;

  background: var(--colorWhite);
  border: 1px solid var(--colorBorderDefault);
  border-radius: 4px;
  box-shadow: var(--defaultBoxShadow);
}
