@import 'styles/core';

.layout {
  min-height: calc(100vh - 60px);
  background-color: var(--colorBackgroundPrimary);
  padding: 32px 24px 48px;
}

.content {
  position: relative;
  min-height: 100vh;
  margin-top: 32px;
  display: flex;
  overflow: hidden;

  background: var(--colorWhite);
  border: 1px solid var(--colorBorderDefault);
  border-radius: 3px;
}

.basePanel {
  height: 100%;
  width: 100%;
  padding: 24px 0;

  &.sidePanelOpened {
    width: calc(100% - 410px);
  }
}

.panelHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 46px;
  padding: 0 36px 0 24px;
}

.panelHeaderText {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;


  color: var(--colorTextPrimary);
}

.settingsIcon {
  height: 28px;
  width: 28px;
  padding: 4px;

  &:hover,
  &.settingsIconOn {
    background-color: var(--colorBorderDefault);
  }

  &:hover,
  &:focus {
    & svg,
    & svg path {
      fill: var(--colorTextSecondary);
    }
  }
}

.editorTooltipValue {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-align: right;
  letter-spacing: -0.08px;
  margin: 4px 0;
}

.editorTooltipDate {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.08px;
  color: var(--colorTextPrimary);
  margin: 8px 8px;
}

.sidePanelContent {
  padding: 32px 24px;
}

.buttonTooltip {
  padding: 5px 5px !important;
  max-width: 140px;
}
