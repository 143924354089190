.split {
  width: 100%;
  display: flex;
  padding: 0 16px 20px;
}

.splitLeft {
  display: flex;
  width: 50%;
  padding-right: 20px;
  box-sizing: border-box;
}

.splitLeftFull {
  width: 100%;
  padding-right: 0;
}

.splitRight {
  display: flex;
  width: 50%;
  padding-left: 20px;
  box-sizing: border-box;
}

@media only screen and (max-width: 640px) {
  .split {
    padding: 0;
  }

  .splitLeft {
    width: 100%;
  }

  .splitRight {
    display: none;
  }
}

