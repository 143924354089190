@import 'styles/core';

.mobileControls {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-top: 48px;
}

.mobileCancelButton {
  margin-right: 8px;
}
