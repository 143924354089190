@import 'styles/core';

.speciesName {
  font-size: 16px;
  line-height: 20px;

  color: var(--colorTextPrimary);
  font-weight: 500;

  @media (--tablet) {
    padding-left: 16px;
  }
}

.breakdownContainer{
  display: flex;
  flex-wrap: wrap;
  padding: 12px 0;
  margin-right: -24px; /* для компенсации отступа флексбокса */

  @media (--tablet) {
    margin-right: 0;
  }
}
