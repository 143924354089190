@import 'styles/core';

.textWrapper {
  display: flex;
  align-items: center;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  margin-left: 8px;
}

.tooltip {
  text-align: left;
}
