@import 'styles/core';

.tableActionButtons {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
}

.exelButton {
  padding: 4px 8px;
  height: 28px;
}

.tableWrapper {
  padding-top: 28px;
}
