@import 'styles/core';

.settingsDataQuality {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
}

.header2,
.header3 {
  margin: 0;
  font-size: 20px;
  line-height: 28px;
  color: var(--colorTextPrimary);
  font-weight: 500;
}

.header3 {
  font-size: 16px;
  line-height: 20px;
}

.reportHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.green {
  background-color: var(--colorPositive);
}

.red {
  background-color: var(--colorNegative);
}

.overallQuality {
  padding: 16px 0 12px;
}

.subHeader {
  margin: 0 0 4px;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
  font-weight: 500;
}

.qualityItem {
  padding-top: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.qualityItemText {
  min-width: 70px;
}

.dataFilter {
  margin-left: 12px;
}

.filtersContainer {
  padding: 24px 0 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filters,
.legend,
.legendItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.legendItem {
  margin-left: 16px;
}

.legendItemLine {
  margin-right: 8px;
  height: 4px;
  width: 12px;
  opacity: 0.5;
  border-radius: 1px;
}
