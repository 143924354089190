@import 'styles/core';

.settingsPermission {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
}

.header2,
.header3 {
  margin: 0;
  font-size: 20px;
  line-height: 28px;
  color: var(--colorTextPrimary);
  font-weight: 500;
}

.header3 {
  font-size: 16px;
  line-height: 20px;
}

.userInfoHeader,
.userPasswordHeader {
  margin-bottom: 16px;  
}

.section {
  margin-top: 32px;
}

.infoPlate {
  margin: 12px 0 0;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
}

.supportButton {
  display: inline-block;
  padding: 0;

  color: var(--colorPrimary);
  font-weight: 500;
}
