@import 'styles/core';

.email {
  background-color: #FFF;
  height: 40px;
  width: 100%;
  outline: none;
  border: 1px solid #BDCFE1;
  border-radius: 4px;
  padding: 10px 10px;
  z-index: 1;
  box-sizing: border-box;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.15px;

  color: var(--colorTextPrimary);

  &::placeholder {
    color: var(--colorTextSecondary);
  }

  &[value=''] {
    background-color: rgba(256, 256, 256, .6);
  }

  &:focus {
    background-color: #FFF;
  }
}

.withError {
  border: 1px solid var(--colorNegative);
}

.emailError {
  padding-top: 4px;
  font-size: 12px;
  line-height: 16px;
  color: var(--colorNegative);
}
