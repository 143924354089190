@import 'styles/core';

.axisX {
  & :global(.domain) {
    display: none;
  }

  & :global(.tick line) {
    stroke: var(--colorChartDashedBorder);
    stroke-dasharray: 3 2;
  }

  & :global(text) {
    font-size: 11px;
    line-height: 16px;
    fill: var(--colorTextPrimary);
  }
}

.firstTickHidden {
  & :global(.tick:first-of-type line),
  & :global(.tick:first-of-type text){
    display: none;
  }
}
