@import 'styles/core';

.wrapper {
  position: relative;
  /*padding-right: 36px;*/
}

.walkButton {
  width: 28px;
  height: 36px;
  background: var(--colorWhite);
  border: 1px solid var(--colorBorderDefault);

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  z-index: 1;

  position: absolute;
  top: 262px;

  display: none;

  &.arrowsAvailable {
    display: block;
  }

  & .icon path {
    stroke: var(--colorTextSecondary);
  }

  &:hover .icon path {
    stroke: var(--colorTextSecondary);
  }

  &.disabled {
    visibility: hidden;
  }

  &.leftWalkButton {
    left: -25px;
  }

  &.rightWalkButton {
    right: -49px;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 20px 0;
}

.walkButtonMobile {
  background: #FFFFFF;
  border: none;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;

  & .icon {
    stroke: var(--colorTextSecondary);
  }

  &:hover .icon {
    stroke: var(--colorTextSecondary);
  }

  &.disabled {
    opacity: 0.5;
  }

  & + .walkButtonMobile {
    margin-left: 8px;
  }
}
