.weeksPhotos {
  display: flex;
  position: relative;
  flex-grow: 10;
  overflow: auto;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: #ffffff;
}

@media only screen and (max-width: 640px) {
  .weeksPhotos {
    border: none;
  }
}

