@import 'styles/core';

.overallQuality {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.overallQualityText {
  max-width: 456px;
  padding-left: 12px;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
}

.overallQualityInfoIcon {
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  padding-left: 4px;
}

.tooltipRed {
  background: var(--colorNegative);
}

.tooltipOrange {
  background: var(--colorWarning);
}

.tooltipGreen {
  background: var(--colorPositive);
}
