@import 'styles/core';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerText {
  display: flex;
  align-items: center;
  margin: 0;

  font-size: 24px;
  line-height: 36px;
  font-weight: 500;
  color: var(--colorTextSecondary);

  cursor: pointer;

  &:hover {
    background-color: var(--colorBorderDefault);
  }
}

.buttons {
  display: flex;
  align-items: center;
}

.separator {
  margin: 0 16px;
  width: 1px;
  height: 32px;
  background: var(--colorBorderCatskillWhite);
}

.saveAsButton,
.revertButton,
.openButton {
  margin-left: 8px;
}

.headerDesc {
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextSecondary);
}

.edited {
  margin-left: 12px;
  padding: 2px 4px;

  background: var(--colorTextSecondary);
  border-radius: 4px;
  font-size: 12px;
  color: var(--colorWhite);
  line-height: 16px;
}

.lineChartWrapper {
  margin-bottom: 13px;

  &:last-child {
    margin-bottom: 0;
  }
}
