@import 'styles/core';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 30px 0 34px;

  @media (--tablet) {
    padding: 24px 0 24px 16px;
  }

  & .left {
    display: flex;
    flex-direction: column;
    justify-content: center;


    & .header {
      font-size: 24px;
      font-weight: 500;
      color: var(--colorTextPrimary);
      text-align: left;
      line-height: 32px;

      @media (--tablet) {
        font-size: 20px;
        color: var(--colorTextPrimary);
        text-align: left;
        font-size: 24px;
      }
    }

  }
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin: 0;

  &.marginBottom {
    @media (--tablet) {
      margin: 0 0 8px 0;
    }
  }
}

.right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  font-size: 13px;
  color: var(--colorTextSecondary);
  line-height: 18px;
  display: none;

  @media (--tablet) {
    display: block;
  }
}
