@import 'styles/core';

.layout {
  min-height: calc(100vh - 60px);
  padding: 20px 24px 128px;
  overflow-x: auto;
  background-color: #FAFBFC;

  @media (--tabletBig) {
    padding: 0 0 96px 0;
  }
}

.content {
  margin: 0 auto;
  max-width: 1156px;

  @media (--desktop) {
    max-width: 1024px;
  }

  @media (--tabletBig) {
    max-width: 100%;
  }
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0 32px 0;

  @media (--tabletBig) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 16px;
  }
}

.control {
  @media (--tabletBig) {
    margin: 8px 0;
  }
}

.selectButton {
  padding-left: 0;

  border: none;
  background: none;
  font-size: 24px;
  font-weight: 500;

  @media (--tabletBig) {
    font-size: 20px;
  }
}

.selectPopup {
  min-width: 192px;
  margin-top: 4px;
}

.selectOption {
  padding: 6px 12px;
}

.selectLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selectLabelValue {
  padding-left: 30px;
}

.chatLink {
  text-decoration: underline;
  cursor: pointer;
  color: var(--colorTextSecondary);
}

.createPlantingCycleButton {
  height: 36px;

  @media (--tabletBig) {
    height: 32px;
  }
}
