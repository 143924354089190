@import 'styles/core';

.form {
  width: 100%;
  position: relative;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 48px 0 0 0;
}

.button {
  height: 40px;
  box-sizing: border-box;

  & + .button {
    margin-left: 8px;
  }
}
