.tableLoader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, .6);
  z-index: 2;
}

.circleLoader {
  height: calc(100vh - 180px);
}

.circleLoaderIcon {
  width: 48px;
  height: 48px;
}

.split {
  width: 100%;
  display: flex;
  position: relative;
  margin: 16px 0 0 0;
  flex-direction: column;
}

.splitLeft {
  width: 50%;
  padding-right: 20px;
  box-sizing: border-box;
  position: relative;
  display: flex;
}

.splitLeftFull {
  width: 100%;
  padding-right: 0;
}

.splitRight {
  width: 50%;
  padding-left: 20px;
  box-sizing: border-box;
  position: relative;
  display: flex;
}

@media only screen and (max-width: 640px) {
  .indicatorsTable {
    display: none;
  }

  .split {
    padding: 0;
  }

  .splitLeft {
    width: 100%;
    padding-right: 0;
  }

  .splitRight {
    display: none;
  }

  .tableLeft {
    //padding: 16px;
  }
}

