@import 'styles/core';

.plantingAreaWrapper {
  position: absolute;
  top: -20px;
  left: -6px;

  cursor: pointer;

  &:hover .editIcon {
    visibility: visible;
  }
}

.plantingArea,
.plantingAreaPopover {
  background: #FFFFFF;
  border: 1px solid #E1E8F0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 8px 24px rgba(0, 0, 0, 0.02);
  border-radius: 4px;

  font-size: 13px;
  color: var(--colorTextSecondary);
  text-align: left;
}

.plantingArea {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  max-width: 176px;
  padding: 3px 4px 3px 6px;

  color: var(--colorTextSecondary);

  cursor: pointer;
}

.plantingAreaPopover {
  display: none;

  position: absolute;
  top: 30px;
  left: 0;
  width: 331px;
  padding: 16px;

  z-index: 21; /* Сделать 2 после фикса открытие вверх */

  &.popoverOpened {
    display: block;
  }
}

.popoverHeaderCloseIcon,
.plantingAreaIcon,
.editIcon {
  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;

  & svg {
    height: 20px;
    width: 20px;
  }
}

.popoverHeaderCloseIcon,
.plantingAreaIcon {
  margin-left: 4px;
}

.closeIcon {
  & path {
    fill: var(--colorTextNeutral);
  }
}

.editIcon {
  margin-right: 4px;
}

.popoverHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 4px;
}

.popoverHeaderText {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;

  color: var(--colorTextPrimary);
}

.popoverHeaderWeeks {
  margin-bottom: 20px;
  line-height: 18px;
}

.dot {
  display: block;
  width: 2px;
  height: 2px;
  margin: 0 6px;

  background-color: var(--colorTextSecondary);
  border-radius: 50%;
}

.harvestValue,
.areaValue {
  white-space: nowrap;
}


.areaValue {
  overflow: hidden;
  text-overflow: ellipsis;
}

.popoverValue {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 14px;
  line-height: 24px;
}

.popoverValueAmount {
  color: var(--colorTextPrimary);
}

.popoverValuePlantingArea,
.popoverValuePlantingDensity {
  margin-top: 12px;
}

.editWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.plantingAreaInput {
  width: 150px;
  height: 32px;
  padding: 6px 12px;
}
