@import 'styles/core.css';

.dataSheetContainer {
  overflow: scroll;
  height: calc(100vh - 254px); /* 254px на header и фильтры */
  /* height: calc(100vh - 84px); */
  border: 1px solid #E7E9EE;

  background-color: #FBFDFE;
  
  &.notPublished {
    height: calc(100vh - 254px - 70px); /* 254px на header и фильтры и 70 на плашку */
  }

  /* &.bottomBosition {
      height: calc(100vh - 84px);
  } */
}

.dataSheet {
  font-size: 14px;
}

.cellHeader {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 0;
  z-index: 20;
}

.cellHeaderSecondLevel {
  top: 36px;
}

.cellHeaderWithDropdown {
  max-width: 234px;
}

.mainCellHeader {
  left: 0;
  z-index: 30;
  text-transform: capitalize;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: -1px;
    right: -1px;
    width: 1px;
    background: #CBCDD1;
  }
}

/* Перебиваем специфичность библиотеки за счёт global */
:global(.data-grid ) {
  /* background: #fff;

  tbody tr:first-child {
    border-top: 1px solid #E7E9EE;
  }

  & tbody:before {
    content: '';
    display: block;
    height: 20px;
  } */

  & .cellHeader:global(.cell),
  & .cellHeader:global(.cell.read-only) {
    height: 36px;
    color: var(--colorTextSecondary);
    background: #FAFBFC;
    font-weight: 500;

    box-shadow: inset 1px -1px 0px 0px #E7E9EE;
    border: none;

    &.cellHeaderSecondLevel {
      cursor: pointer;

      &:hover {
        background: #E8EBED;
      }
    }

    &.addComartmentButton {
      min-width: 234px;
      width: 100%;
    }

    &.cellHeaderSecondLevel:hover {
      z-index: 30;
    }
  }

  & .mainCellHeader:global(.cell),
  & .mainCellHeader:global(.cell.read-only) {
    padding-left: 25px;
    text-align: left;

    box-shadow: inset 0px -1px 0px 0px #E7E9EE;
  }
}

.selectButton {
  background: none !important;
  border: none !important;
  display: flex;
  justify-content: center;
  padding: 0 !important;
  color: var(--colorTextSecondary) !important;
}

/* Перебиваем специфичность библиотеки за счёт global */
:global(.data-grid ) {
  & .fakeCell:global(.cell.read-only),
  & .totalCell:global(.cell.read-only) {
    min-width: 168px;
    height: 36px;
    padding: 0 10px;
    border-top: none;
  }

  & .fakeCell.disabledCell:global(.cell),
  & .fakeCell.disabledCell:global(.cell.read-only) {
    background: var(--colorBackgroundHover);
  }

  & .fakeCellFirstColumn:global(.cell.read-only),
  & .totalCellFirstColumn:global(.cell.read-only) {
    border-left: none;
  }

  & .fakeCellFirstColumn:global(.cell.read-only) {
    background: var(--colorWhite);
  }

  & .totalCell:global(.cell.read-only) {
    height: 48px;
    background: #FAFBFC;
    border: none;
    text-align: right;
    padding-right: 26px;

    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: var(--colorTextPrimary);
  }

  & .totalCellFirstColumn:global(.cell.read-only) {
    background: #FFF;
    padding: 0 0 0 12px;
  }

  & .fakeCellWithoutVariety:global(.cell.read-only) {
    background: var(--colorBackgroundPrimary);
    border: none;
  }

  /* & .totalCellLastElement:global(.cell.read-only) {
    background: #FFF;
  } */
}

.fakeCellFirstColumn {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  z-index: 20;
  outline: 1px solid var(--colorWhite);

  &:before {
    content: '';
    position: absolute;
    bottom: -2px;
    right: 0;
    left: 0;
    height: 2px;
    background: var(--colorWhite);
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: -1px;
    right: -1px;
    width: 1px;
    background: #CBCDD1;
  }
}

.totalCell {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  bottom: 0;
  z-index: 20;

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    right: 0;
    left: 0;
    height: 1px;
    background: #E7E9EE;
  }
}

.totalCellFirstColumn {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  z-index: 30;

  &:before {
    content: '';
    position: absolute;
    top: -2px;
    right: 0;
    left: 0;
    height: 2px;
    background: #FFF;
  }
}

.totalCellWithoutValue {
  &:before {
    top: 0;
  }
}

.totalCellFirstColumn,
.totalCellLastElement {
  &:before {
    display: none;
  }
}

.totalBarText {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 12px;

  background: #FAFBFC;
  border: 1px solid #E7E9EE;
  border-radius: 4px 0 0 0;
  border-right: none;
  border-bottom: none;

  font-weight: 500;
}

.totalCellLastElement {
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 12px;
    height: 48px;

    background: #FAFBFC;
    border: 1px solid #E7E9EE;
    border-radius: 0 4px 0 0;
    border-left: none;
    border-bottom: none;
  }
}

.emptyBlockPageEnd {
  float: left;
  clear: both;
}

.tableCellInner {
  position: relative;
  height: 100%;
  max-height: 36px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.tomato {
  & .tableCellInner::before {
    background-color: var(--colorTomatoSecondary);
  }
}

.cucumber {
  & .tableCellInner::before {
    background-color: var(--colorCucumberSecondary);
  }
}

.eggplant {
  & .tableCellInner::before {
    background-color: var(--colorEggplantSecondary);
  }
}

.lettuce {
  & .tableCellInner::before {
    background-color: var(--colorLettuceSecondary);
  }
}

.pepper {
  & .tableCellInner::before {
    background-color: var(--colorPepperSecondary);
  }
}

.addCompartmentButtonContent {
  max-width: 234px;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;

  cursor: pointer;
  transition: background-color 500ms ease;

  &:hover {
    background: #E8EBED;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 234px;
    height: 100%;
    width: 1px;
    background-color: var(--colorBorderDefault);
  }
}
