@import 'styles/core';

.uiSearchInput {
  position: relative;
}

.icon {
  width: 20px;
  height: 20px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 8px;
  transition: transform 150ms ease-out;

  & svg {
    width: 20px;
    height: 20px;
    fill: var(--colorTextSecondary);
  }
}

.searchInputButton {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 9px;
  bottom: 9px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchInputButtonLeft {
  left: 12px;
}

.searchInputButtonRight {
  right: 12px;
}

.searchControl {
  display: flex;
  position: relative;
}

.searchInput {
  width: 100%;
  flex-shrink: 1;
  flex-grow: 0;
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0 40px;
  border-radius: 5px;
  border: 1px solid #BDCFE1;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;

  &::placeholder {
    color: var(--colorTextSecondary);
  }

  &::-ms-clear {
    display: none;
  }
}

.item {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  padding: 8px 32px 8px 24px;
  cursor: pointer;

  &:not(.touch) {
    & .checkbox {
      display: none;
    }
  }

  &:not(.checked) {
    &:hover {
      & .checkbox {
        display: flex;
      }
    }
  }

  &.checked .checkbox {
    display: flex;
  }

  &.checked,
  &:hover {
    background-color: #E4E9F0;
  }
}

.category {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  padding: 8px 32px 4px 0;
  color: var(--colorTextSecondary);
  text-transform: capitalize;
  font-weight: 500;
  margin-left: 28px;
}

.offsetTwo {
  padding-left: 52px;
}

.item.expanded .expander {
  transform: rotate(90deg);
}

.title {
  font-size: 14px;
  line-height: 20px;
}

.expander {
  & svg,
  & svg path {
  width: 20px;
  height: 20px;
  fill: var(--colorTextSecondary);
  }
}

.treeWrapper {
  margin-top: 12px;
  padding-bottom: 24px;

  height: 336px;
  overflow: auto;

  @media (--tablet) {
    height: calc(100vh - 190px);
  }
}

.filterOptionsPopup {
  width: 470px;
  position: absolute;
  padding: 4px 0;
  top: calc(100% + 2px);
  left: 0;
  background-color: var(--colorWhite);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 100;
  border-radius: 5px;
  border: 1px solid var(--colorBorderCatskillWhite);
  box-shadow: 0 8px 24px 0 rgba(0,0,0,0.02), 0 2px 4px 0 rgba(0,0,0,0.04);
}

.filterOptions {
  max-height: 292px;
}

.filterOption {
  padding: 6px 40px;
  cursor: pointer;
  text-overflow: ellipsis;

  color: var(--colorTextPrimary);
  font-size: 14px;
  line-height: 20px;

  &.hovered {
    background-color: #EBEEF2;
  }
}

.filterEmpty {
  height: 116px;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 0 44px;
  color: var(--colorTextSecondary);

  font-size: 14px;
  line-height: 20px;
}
