@import 'styles/core';

.button {
  background: var(--colorPositive);
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 40px;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.75px;
  color: #FFFFFF;
  padding: 10px 24px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  outline: none;
  border: none;
  cursor: pointer;

  &:disabled {
    color: #FFFFFF;
    background-color: rgba(150, 200, 61, .4);
  }
}
