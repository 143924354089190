@import 'styles/core';

.week {
  margin-bottom: 24px;
  color: var(--colorTextPrimary);
}

.weekHeader,
.weekDay {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 48px;
  padding: 6px 16px;

  font-size: 14px;
  line-height: 20px;

  @media (--tablet) {
    flex-direction: column;
    align-items: flex-start;

    height: 56px;
    padding: 8px 16px;
  }
}

.weekHeader {
  position: relative;
  background-color: #f0f3f7;
  font-weight: 500;
}

.weekHeaderDate {
  color: var(--colorTextSecondary);
  font-size: 13px;
}

.weekDay {
  border-bottom: 1px solid #e7e9ee;
  position: relative;

  &:last-child {
    border-bottom: none;
  }

  &:hover,
  &.currentDay {
    & .weekDayDate {
      opacity: 0;

      @media (--tablet) {
        opacity: 1;
      }
    }

    & .weekDayControls {
      opacity: 1;
    }
  }
}

.weekDayEmpty {
  color: var(--colorTextSecondary);

  @media (--tablet) {
    padding: 22px 16px;
  }
}

.weekDayDate {
  position: absolute;
  right: 16px;
  top: 14px;

  color: var(--colorTextSecondary);
  font-size: 13px;

  opacity: 1;
  transition: opacity 0.2s ease-in-out;

  @media (--tablet) {
    position: initial;
  }
}

.weekNo {
  position: absolute;
  right: calc(100% + 16px);
  top: 0;

  line-height: 20px;
  color: var(--colorTextSecondary);
  font-weight: normal;
}

.weekNoValue {
  font-size: 18px;
}

.weekDayDropdownWrapper {
  position: absolute;
  right: 12px;
  top: 12px;

  display: none;

  @media (--tablet) {
    display: block;
  }
}

.weekDayControls {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  position: absolute;
  right: 16px;
  top: 6px;

  opacity: 0;

  transition: opacity 0.2s ease-in-out;

  @media (--tablet) {
    display: none;
  }
}

.weekDayEdit,
.weekDayDelete,
.weekDayDeleteWrapper,
.weekDayDeleteExtraWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 36px;

  cursor: pointer;

  & svg {
    fill: #9ea4a8;
  }

  &:hover {
    & svg {
      fill: var(--colorTextSecondary);
    }
  }
}

.deletePopup {
  width: 216px;

  /* important необходим, т.к. иначе не перебить библиотечные стили */
  padding: 16px !important;
}

.deletePopupText {
  margin: 0;

  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
}

.deleteButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding-top: 12px;
}

.deleteConfitm {
  margin-left: 8px;
}

.dropdownItem {
  height: 32px;
  padding: 0 16px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  cursor: pointer;

  white-space: nowrap;

  &:hover {
    background-color: #F7F9FA;
  }
}

.dropdownIcon {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 20px;
  width: 20px;
  margin-right: 8px;

  & svg {
    fill: var(--colorTextSecondary);
  }
}
