@import 'styles/core';

.dropdownMenuWrapper,
.dropdownMenu,
.dropdownMenuButton {
  height: 100%;
}

.dropdownMenuWrapper {
  position: relative;
}

.dropdownWrapper {
  right: 0;
}

.rateIncidentButton {
  &:hover,
  &:focus {
    color: var(--colorTextSecondary);

    & .starIcon path {
      fill: var(--colorTextSecondary);
    }
  }
}

svg.starIcon {
  width: 20px;
  height: 20px;

  & path {
    fill: var(--colorTextSecondary);
  }
}

.confitm {
  margin-left: 8px;
}


.rateIncidentPopup {
  width: 392px;
  padding: 8px 16px;

  font-weight: normal;
}

.rateIncidentPopupHeaderText {
  font-size: 16px;
  line-height: 20px;
  color: var(--colorTextPrimary);
  font-weight: 500;
  margin: 0;
  max-width: 244px;
}

.rateIncidentPopupHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.rateIcon {
  margin-right: 16px;
  width: 48px;
  height: 48px;
}

.rateIncidentPopupScale {
  padding: 8px 0 0 64px;
}

.rateScale {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.rateDescription {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-right: 63px;
  padding-top: 8px;
}

.rateDescriptionText {
  font-size: 12px;
  line-height: 16px;
  color: var(--colorTextNeutral);
}

.rateNumButton {
  padding: 5px 15px;
  margin-right: 8px;

  &.active {
    background-color: var(--colorBorderDefault);
  }
}

.sendingError,
.successfulSending{
  min-width: 392px;
  min-height: 288px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sendingErrorButtons {
  display: flex;
  align-items: center;
  justify-content: center;

  padding-top: 24px;
}
