@import 'styles/core';

.layout {
  max-width: 1072px;
  margin: 0 auto;
  padding: 0 24px 40px 24px;
}

.empty {
  width: 100%;
}

.emptyState {
  padding: 122px 24px;
}

.container {
  padding: 30px 0 34px;
}

.header {
  width: 100%;
  margin: 0;
  padding: 0;
}

.content {
  padding: 0;
  margin: 26px 0 0 0;

  & th,td {
    white-space: nowrap;

    &.bold {
      font-weight: 500;
    }
    &.capitalize {
      text-transform: capitalize;
    }

    &.alignRight {
      text-align: right;
    }

    &.alignLeft {
      text-align: left;
    }
  }
}

.controls {
  padding: 24px 0 0 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
}

.backIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.backText {
  line-height: 18px;
  margin: 0 0 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  color: var(--colorTextSecondary);
  letter-spacing: -0.08px;
}

.title {
  margin: 32px 0 0 0;
}

.supportButton {
  display: inline-block;
  padding: 0;

  color: var(--colorTextSecondary);
  font-weight: normal;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
    color: var(--colorTextSecondary);
  }
}

.table {
  border: none;
  border-radius: 3px;
  overflow: hidden;

  & th {
    height: 44px;
  }

  & td {
    height: 62px;
  }
}