@import 'styles/core';

.layout {
  width: 100%;
  max-width: 560px;

  @media (--tablet) {
    max-width: 100%;
  }
}

.items {
  display: flex;
  flex-wrap: wrap;
  margin: 14px -8px 0 -8px;

  & .item {
    margin: 8px;
    flex-shrink: 0;

    @media (--tablet) {
      width: calc(50% - 16px);
    }

    & .icon {
      width: 64px;
      height: 64px;
    }

    & .itemTitle {
      margin: 12px 0 0 0;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      text-transform: capitalize;
      color: var(--colorTextPrimary);
    }

    & .itemDescription {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      text-align: center;
      color: var(--colorTextSecondary);
    }
  }
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--colorTextPrimary);
}

.description {
  margin: 12px 0 0 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextSecondary);
}

.actions {
  width: 100%;
  margin: 40px 0 0 0;
  display: flex;
  justify-content: flex-end;

  & .action {
    height: 40px;
    box-sizing: border-box;
  }
}

.continue {
  margin: 0 0 0 8px;

  &:disabled {
    opacity: 0.54;
    background-color: var(--colorPrimary);
    color: var(--colorWhite);
  }
}
