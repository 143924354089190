@import 'styles/core';


.issue {
  flex-shrink: 0;
  padding: 12px 16px;
  border: 1px solid #E7E9EE;
  box-sizing: border-box;
  border-radius: 4px;

  & + .issue {
    margin: 8px 0 0 0;
  }
}

.issueTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: var(--colorTextPrimary);
}

.issueIcon {
  margin-right: 8px;
  display: inline;
  vertical-align: top;

  & path {
    fill: var(--colorTextSecondary);
  }
}

.issueDescription {
  margin: 4px 0 0 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: var(--colorTextSecondary);
}

.issueContent {
  margin: 0;
  height: 0;
  overflow: hidden;
  transition: height 200ms ease-out;

  &.open {
    height: auto;

    & + .issueActions {
      margin: 12px 0 0 0;
    }
  }
}

.issueActions {
  margin: 8px 0 0 0;
}

.issueAction {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: var(--colorPrimary);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}


.section {
  margin: 20px 0 0 0;
}

.sectionHeader {
  margin: 0 0 12px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: var(--colorTextSecondary);

}

.sectionContent {

}

.primaryTable {
  width: 100%;
  border-spacing: 0;
  text-align: left;

  & tr:last-of-type {
    & .problemTypeHeaderCellContent {
      padding: 0 12px 4px 0;
    }

    & .problemTypeCellContent {
      padding: 0 0 4px 0;
    }
  }
}

.problemTypeHeaderCell {
  vertical-align: top;
  border-spacing: 0;
  padding: 0;
  margin: 0;
  width: 156px;
}

.problemTypeHeaderCellContent {
  width: 156px;
  padding: 0 12px 12px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: var(--colorTextSecondary);

}

.problemTypeCell {
  vertical-align: top;
  border-spacing: 0;
  padding: 0;
  margin: 0;
}

.problemTypeCellContent {
  padding: 0 0 12px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: var(--colorTextPrimary);

}

.improvements {
}

.improvement {
  margin: 12px 0 0 0;
  padding: 0 16px 0 16px;
  background: var(--colorBackgroundHover);
  border-radius: 4px;
}

.secondaryTable {
  width: 100%;
  padding: 16px 0 8px 0;
  border-spacing: 0;
  text-align: left;

  & + .secondaryTable {
    border-top: 1px solid #E1E8F0;
  }

  &.spaced {
    padding: 16px 0 4px 0;

    & .secondaryCellContent {
      padding-bottom: 12px;
    }

    & .secondaryHeaderCellContent {
      padding-bottom: 12px;
    }
  }
}

.secondaryHeaderCell {
  vertical-align: top;
  border-spacing: 0;
  margin: 0;
  padding: 0;
  width: 92px;
}

.secondaryHeaderCellContent {
  width: 92px;
  padding: 0 12px 8px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.08px;
  color: var(--colorTextSecondary);
}

.secondaryCell {
  vertical-align: top;
  border-spacing: 0;
  margin: 0;
  padding: 0;

}

.secondaryCellContent {
  padding: 0 12px 8px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.08px;
  color: var(--colorTextSecondary);

}

.changes {
  margin: -8px 0 0 0;
}

.improvementsEmptyState {
  margin: 12px 0 0 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--colorBackgroundHover);
  border-radius: 4px;

}

