@import 'styles/core';

.tableWrapper {
  position: relative;
  border: solid 1px var(--colorBorderDefault);
  border-radius: 2px;

  &.small {
    font-size: 13px;
    line-height: 18px;

    & td {
      height: 36px;
      padding: 8px 12px;
    }

    & th {
      height: 40px;
      padding: 6px 12px;
    }
  }
}

.tableScroller {
  overflow-x: auto;
  overflow-y: visible;
}

.table {
  width: 100%;
  background-color: var(--colorWhite);
  border-radius: 2px;
  border-spacing: 0;
  font-size: 14px;

  & thead {
    background-color: var(--colorBackgroundHover);
  }

  & th,
  & td {
    height: 48px;
    min-width: 96px;
    padding: 0 16px;
    text-align: center;
    box-shadow: inset 0 -1px 0 0 var(--colorBorderDefault);

    &:first-child {
      padding-left: 24px;
      text-align: left;

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &:last-child {
      padding-right: 24px;
    }
  }

  & th {
    height: 56px;
    color: var(--colorTextSecondary);
    font-weight: 500;

    &:first-child {
      background-color: var(--colorBackgroundHover);
    }
  }

  & td {
    padding: 14px 16px;
    box-shadow: inset 0 -1px 0 0 var(--colorBorderDefault);
    color: var(--colorTextPrimary);

    min-width: 16%;

    &:first-child {
      background-color: var(--colorWhite);
    }
  }

  & tr {
    &:last-child {
      & td {
        box-shadow: none;
      }
    }
  }
}

.fixedColumnOnMobile {
  &.tableWrapper {
    @media (--tablet) {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 216px;
        bottom: 0;
        width: 4px;
        background: rgba(30, 42, 51, 0.05);
      }
    }

    @media (--phoneLandscape) {
      &:after {
        left: 140px;
      }
    }
  }

  & .tableScroller {
    @media (--tablet) {
      margin-left: 216px;
    }

    @media (--phoneLandscape) {
      margin-left: 140px;
    }
  }

  & .table {
    & th,
    & td {
      &:first-child {
        @media (--tablet) {
          position: absolute;
          top: auto;
          left: 0;
          width: 216px;
          border-right: solid 1px var(--colorBorderDefault);
        }

        @media (--phoneLandscape) {
          width: 140px;
        }
      }
    }

    & th {
      &:first-child {
        @media (--tablet) {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }
  }
}

.fixedColumn {
  &.tableWrapper {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 216px;
      bottom: 0;
      width: 4px;
      background: rgba(30, 42, 51, 0.05);
    }
  }

  & .tableScroller {
    margin-left: 216px;
  }

  & .table {
    & th,
    & td {
      &:first-child {
        position: absolute;
        top: auto;
        left: 0;
        width: 216px;
        min-width: 216px;
        border-right: solid 1px var(--colorBorderDefault);
      }
    }

    & th {
      &:first-child {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
}

