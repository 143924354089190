.button {
  width: 100%;
}

.wrapper {
  width: 100%;
  padding: 0;
  box-sizing: border-box;

  &.errored:not(.errorTextDisabled) {
    padding: 0 0 8px 0;
  }
}

.datePickerWrapper {
  width: 100%;
}