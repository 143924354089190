@import 'styles/core';

.layout {
  min-height: calc(100vh - 60px);
  min-width: 1080px;

  display: flex;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.container {
  height: calc(100vh - 60px);
  overflow: auto;
  padding: 0 24px 0;

  transition: width 150ms ease-out;

  width: 100%;

  &.sidePanelOpened {
    width: calc(100% - 311px);
  }
}

.header {
  padding: 32px 0 24px;
}

.headerRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerRowCentral {
  padding: 16px 0 32px;
}

.headerText {
  margin: 0;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;

  color: var(--colorTextPrimary);
}

.headerControls {
  display: flex;
  align-items: center;
}

.notificationText,
.headerControlsSave {
  margin-right: 24px;
  color: var(--colorTextSecondary);
  font-size: 14px;
}

.headerControlsButtons {
  display: flex;
}

.cancelButton {
  margin-right: 8px;
}

.notificationText,
.timeText {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.notificationTextButton,
.timeTextButton {
  text-decoration: underline;
  font-weight: normal;
  padding: 0;
  margin-left: 4px;

  & svg circle {
    stroke: var(--colorTextSecondary) !important;
  }

  &:hover {
    text-decoration: underline;
  }
}

.notificationText {
  height: 36px;
}
.plansControls {
  display: flex;
  align-items: center;
}

.linksToggle {
  display: flex;
  align-items: center;

  margin-left: 8px;
  border: 1px solid var(--colorBorderSecondary);
  border-radius: 4px;
  overflow: hidden;
}

.plansLink {
  display: block;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
  text-decoration: none;

  &:first-child {
    border-right: 1px solid var(--colorBorderSecondary);
  }

  &.plansLinkActive {
    background-color: var(--colorBorderDefault);
  }
}

.historyButton {
  padding: 0;

  &:hover svg path,
  &:focus svg path {
    fill: var(--colorPrimary);
  }

  &:hover,
  &:focus,
  &:active {
    color: var(--colorPrimary);
  }
}

.infoPlate {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
  max-width: 600px;
  color: var(--colorTextPrimary);
}

.filters {
  display: flex;
  align-items: center;
}

.varietySelect {
  background: none !important;
  border: none !important;
  font-weight: 500 !important;
}

.periodPicker {
  z-index: 100;
}

.rightControls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.buttonSeparator {
  height: 20px;
  width: 1px;
  margin: 0 24px 0 14px;
  background-color: var(--colorBorderCasper);
}

.dateButton {
  width: 130px;
}
