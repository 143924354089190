@import 'styles/core';

.panel {
  position: relative;
  width: 460px;
  box-sizing: border-box;
  padding: 24px;
  height: 100%;
}

.header {
  position: relative;
  width: 100%;
  border-bottom: 1px solid var(--colorBorderDefault);
  padding-bottom: 24px;
}

.body {
  width: 100%;
  padding-bottom: 24px;
}

.bodyEmpty {
  height: calc(100% - 70px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  color: var(--colorTextPrimary);
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 4px;
}

.plantingCycle {
  color: var(--colorTextSecondary);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 349px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.filters {
  margin-top: 24px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chartWrapper {
  padding-top: 8px;
}

.chartBlock {
  margin-bottom: 48px;

  &:last-child {
    margin-bottom: 0;
  }
}

.chartBlockTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #4A4A49;
}

.closeButton {
  position: absolute;
  top: 27px;
  right: 14px;
  z-index: 1;
}


.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.emptyHeader {
  margin-top: 24px;
  margin-bottom: 4px;
  color: var(--colorTextPrimary);
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.emptyDescription {
  color: var(--colorTextSecondary);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.problemPlate {
  margin-top: 32px;
}
