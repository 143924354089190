@import 'styles/core';

.loginWrapper {
  width: 100%;
}

.error {
  margin-top: 8px;
  font-size: 14px;
  color: var(--colorNegative);
  letter-spacing: 0.6px;
  z-index: 1;
  white-space: nowrap;
}

.logo {
  width: 264px;
  height: 90px;
  z-index: 1;
  position: relative;
  background: url('/logo.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-shrink: 0;

  @media (--phoneLandscape) {
    width: 100%;
  }
}

.username {
  background-color: #ffffff;
  height: 40px;
  width: 100%;
  outline: none;
  border: 1px solid #BDCFE1;
  border-radius: 4px;
  padding: 10px 10px;
  margin-top: 32px;
  z-index: 1;
  box-sizing: border-box;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.15px;

  color: #4B4B4A;

  &::placeholder {
    color: var(--colorTextSecondary);
  }

  &[value=''] {
    background-color: rgba(256, 256, 256, .6);
  }

  &:focus {
    background-color: #ffffff;
  }
}

.password {
  margin-top: 16px;
}

.button {
  width: 100%;
  height: 40px;

  background: var(--colorPrimary);

  color: #ffffff;

  outline: none;
  border: none;
  box-shadow: none;
  border-radius: 4px;

  margin-top: 16px;
  z-index: 1;

  padding: 10px 0;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.15px;

  cursor: pointer;

  &:hover {
    background-color: var(--colorHover);
  }
}

.forgot {
  margin-top: 16px;
}

.forgotLink {
  font-size: 14px;
  line-height: 20px;
  color: var(--colorPrimary);
  text-decoration: none;

  &:hover {
    color: var(--colorHover);
    text-decoration: underline;
  }
}
