.dataSheetWrapper {
  position: relative;
}

.circleLoader {
  z-index: 100;
}

.cellInput {
  height: 39px;
  padding: 0;
  background: none;
  border: none;

  &:focus {
    box-shadow: none;
    border: none;
  }
}
