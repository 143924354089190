@import 'styles/core';

.layout {
  min-height: calc(100vh - 60px);
  background-color: var(--colorBackgroundPrimary);
  padding: 52px 24px 48px;
}

.pricePlan {
  margin-top: 134px;
}


