@import 'styles/core';

.selectPopup {
  min-width: 192px;
  width: 100%;
}

.checkboxesContainer {
  padding: 4px 0;
}

.checkboxItem {
  padding: 6px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: var(--colorBackgroundHover);

    & .onlyButton {
      visibility: visible;
    }
  }
}

.onlyButton {
  visibility: hidden;

  padding: 0 0 0 8px;
  font-weight: 400;
  color: var(--colorPrimary);
}

.dropdownRightAlign {
  right: 0;
  left: auto;
}
