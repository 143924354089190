@import 'styles/core';

.menuWrapper {
  min-height: calc(100vh - 60px);
  display: flex;
  width: 100%;
  position: relative;
}

.menu {
  flex: 0 0 224px;
  width: 224px;
  max-width: 224px;
  padding-top: 24px;
  background-color: var(--colorBackgroundPrimary);

  transition: left 150ms ease-out;

  @media (--desktopSmall) {
    flex: none;
    position: fixed;

    height: 100%;
    width: 224px;

    background-color: var(--colorWhite);
  }
}

.content {
  flex: 1;
  width: calc(100% - 224px);
  transition: left 150ms ease-out, width 150ms ease-out;

  @media (--desktopSmall) {
    flex: none;
    position: fixed;
    left: 224px;
    width: 100%;
  }
}

.overlay {
  display: none;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: var(--colorOverlay);
  z-index: 1000;

  @media (--desktopSmall) {
    display: block;
  }
}

.menuClosed {
  & .menu {
    flex: none;
    position: absolute;
    height: 100%;
    left: -224px;
  }

  & .content {
    position: initial;
    flex: none;
    max-width: 100%;
    width: 100%;

    @media (--desktopSmall) {
      left: 0;
    }
  }

  & .overlay {
    @media (--desktopSmall) {
      display: none;
    }
  }
}

.menuEnter, .menuLeave.menuLeaveActive {
  & .menu {
    flex: none;
    position: absolute;
    height: 100%;
    left: -224px;
  }

  & .content {
    position: absolute;
    left: 0;
    width: 100%;
  }
}

.menuEnter.menuEnterActive, .menuLeave {
  & .menu {
    flex: none;
    position: absolute;
    height: 100%;
    left: 0;
  }

  & .content {
    position: absolute;
    left: 224px;
    width: calc(100% - 224px);

    @media (--desktopSmall) {
      width: 100%;
    }
  }
}


.menuLink {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 40px;
  padding: 0 22px 0 24px;

  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--colorTextSecondary);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: var(--colorPrimary);

    & svg {
      fill: var(--colorPrimary);
    }
  }

  &:focus {
    outline: none;
  }

  &.routeActive {
    color: var(--colorPrimary);

    & svg {
      fill: var(--colorPrimary);
    }
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;
  margin-right: 8px;

  & svg {
    width: 24px;
    height: 24px;
    fill: var(--colorTextSecondary);
  }
}