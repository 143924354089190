@import 'styles/core';

.wrapper {
  position: relative;
}

.meter {
  width: 100%;
  height: 100%;
  transform: rotateX(180deg);
}

.circle {
  fill: none;
}

.mask {
  stroke: #E7E9EE;
}

.arrowPoint {
  position: absolute;
  left: calc(50% - 8px);
  top: calc(50% - 8px);

  width: 16px;
  height: 16px;
  background-color: #546B7E;
  border-radius: 50%;
}

.arrow,
.expectedValue {
  position: absolute;
  bottom: 50%;
  left: calc(50% - 2px);

  width: 4px;
  height: calc(50% + 2px);
  background: #546B7E;
  border-radius: 4px;

  transform-origin: bottom center;
  transform: rotate(270deg);
}

.valuesBox {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;

  color: var(--colorTextPrimary);
  text-align: center;
}

.scaleValuesBox {
  position: relative;

  padding-top: 8px;

  font-size: 11px;
  line-height: 16px;
}

.scaleValue {
  white-space: nowrap;

  position: absolute;
  transform: translate(50%, 0);

  &.scaleValueLeft {
    transform: translate(-50%, 0);
  }
}

.currentValueBox {
  padding-top: 4px;

  font-size: 14px;
  line-height: 20px;

  color: var(--colorTextSecondary);
}

.percentBox {
  padding-top: 12px;

  font-size: 32px;
  line-height: 32px;

  font-weight: 300;
}

.small {
  & .percentBox {
    font-size: 20px;
    line-height: 28px;
  }
}

.expectedValue {
  background: none;

  z-index: 1;
  left: calc(50% - 2px);
  height: calc(50% + 4px);
}

.expectedValueDash {
  background: none;
}

.expectedValueDashBorder {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  border-right: 2px dashed var(--colorTextSecondary);
  height: 34px;
  width: 1px;
}

.expectedValueAmount {
  position: absolute;
  top: -24px;
  left: 0;

  font-size: 11px;
  line-height: 16px;
  color: var(--colorTextPrimary);
  white-space: nowrap;
}
