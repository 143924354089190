@import 'styles/core';

.breakdownGraph {
  position: relative;

  text-align: center;
}

.radialLabel {
  font-size: 13px;
  fill: #1E2A33;
}

.radialLabelSubtext {
  fill: var(--colorTextSecondary);
}

.tooltipSubtext {
  color: var(--colorTextSecondary);
}

.tooltip {
  z-index: 1000; /* перебиваем хедер */
  padding: 12px;
  color: var(--colorTextPrimary);
  font-weight: 400;
  font-size: 13px;
  width: 300px;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #E1E8F0;
  box-shadow: 0 8px 24px 0 rgba(0,0,0,0.02), 0 2px 4px 0 rgba(0,0,0,0.04);
  border-radius: 4px;
  pointer-events: none;
  text-align: left;
}

.tooltipHeader {
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;

  margin-bottom: 10px;
}

.tooltipContent {
  display: flex;
}

.legendItem {
  display: flex;
  width: 50%;
}

.legendItemColor {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin: 4px 0;
}

.legendItemColorSquare {
  width: 12px;
  height: 4px;
  border-radius: 1px;
}

.legendItemTitle {
  margin-left: 8px;
}

.tooltipValue {
  width: 50%;
  text-align: right;
}

.breakdownEmptyState {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiBoZWlnaHQ9IjIzMiIgdmlld0JveD0iMCAwIDIzMiAyMzIiIHdpZHRoPSIyMzIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggY2xpcC1ydWxlPSJldmVub2RkIiBkPSJtNTEuNTYzIDExNmMwLTM0LjkwMjMgMjcuNzU2Ni02My4zMzA0IDYyLjM5LTY0LjQwNDh2LTQzLjU5NTJjLTU4LjA4MTggMS44MDE3My0xMDUuOTUzIDQ5LjQ5MDktMTA1Ljk1MyAxMDggMCA0My44OCAyNy40MDMgODIuMDI1IDY0Ljg5MDcgOTguOTI0bDE2LjY2NTYtNDAuMTM5Yy0yMi40NDI2LTEwLjExNy0zNy45OTMzLTMyLjY1OC0zNy45OTMzLTU4Ljc4NXptNjQuNDM3LTY0LjQzN2MzNS41ODggMCA2NC40MzcgMjguODQ5NCA2NC40MzcgNjQuNDM3IDAgMzUuNTg3LTI4Ljg0OSA2NC40MzctNjQuNDM3IDY0LjQzNy04LjgxMiAwLTE3LjE1OTMtMS43NTctMjQuNzUxMy00LjkxOWwtMTYuNzQ5NCA0MC4yMTRjMTIuODA1NiA1LjMzNCAyNi44Mzc3IDguMjY4IDQxLjUwMDcgOC4yNjggNTkuNjQ3IDAgMTA4LTQ4LjM1MyAxMDgtMTA4IDAtNTkuNjQ2OC00OC4zNTMtMTA4LTEwOC0xMDh6IiBmaWxsPSIjZWJlY2VlIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=);
}

.breakdownEmptyStateText {
  max-width: 281px;
}
