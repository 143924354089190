@import 'styles/core';

.energyAddContainer {
  max-width: 816px;
  width: 100%;
  margin: 0 auto;

  padding: 24px 24px 100px;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 24px 0;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding-top: 16px;
}

.saveBtn {
  margin-left: 8px;
}

.rangePicker {
  z-index: 161;
}
