@import 'styles/core';

.group {
  display: flex;
  flex-direction: column;
  margin: 0 0 18px 0;
  padding: 0 32px 0 32px;
  transition: background-color 150ms ease-in;

  &:hover {
    & .groupClose {
      display: flex;
    }
  }

  &.groupHoverable:hover {
    background-color: #F7F9FA;
  }

  &.error .groupSelectButton {
    border: 1px solid var(--colorNegative);
  }
}

.groupDataSeries {
  display: flex;
  flex-wrap: wrap;
  margin: 4px 0 0 48px;
  padding: 2px 0 2px 4px;
  border-left: 1px solid #E7E9EE;
}

.groupLeftMargin {
  margin-left: 48px;
}

.groupControls {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.groupClose {
  padding: 3px 5px;
  margin: 0 0 0 16px;
  width: 28px;
  height: 24px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  fill: #777776;
  background-color: #F0F3F7;
  border-radius: 4px;
  outline: none;
  border: none;

  @media only screen and (min-width: 1025px) {
    display: none;
  }

  &:hover {
    background-color: #E4E9F0;

    & .groupCloseIcon {
      fill-opacity: 1;
    }
  }

  & .groupCloseIcon {
    width: 10px;
    height: 10px;
    fill: var(--colorTextSecondary);
    fill-opacity: 0.5;
    fill-rule: evenodd;
  }
}

.groupIconContainer {
  background: #E4F7FB;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  margin-right: 12px;
}

.groupNameWrapper {
  display: flex;
  height: 28px;
  align-items: center;
}

.groupName {
  text-decoration: none;
  cursor: pointer;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-weight: 500;
  color: var(--colorTextSecondary);

  &:hover {
    text-decoration: underline;
  }
}

.groupIcon {
  width: 20px;
  height: 20px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  margin: 0 4px;
}

.deleteButton {
  display: flex;
  padding: 3px 5px;
  margin: 0 0 0 16px;
  width: 28px;
  height: 24px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  fill: #777776;
  background-color: #E9ECF0;
  border-radius: 4px;
  outline: none;
  border: none;

  &:hover {
    background-color: #DADEE3;

    & .groupCloseIcon {
      fill-opacity: 1;
    }
  }

  & .groupCloseIcon {
    width: 10px;
    height: 10px;
    fill: var(--colorTextSecondary);
    fill-opacity: 0.5;
    fill-rule: evenodd;
  }

  @media only screen and (min-width: 1025px) {
    visibility: hidden;
  }
}
