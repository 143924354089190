@import 'styles/core';

.button {
  user-select: none;
  width: fit-content;
  max-height: 40px;
  display: flex;
  text-decoration: none;
  background-color: var(--colorWhite);
  padding: 5px 9px 5px 12px;
  color: var(--colorTextSecondary);
  border: 1px solid #BDCFE1;
  border-radius: 5px;
  align-items: center;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    background-color: #F7F9FA;
  }

  &:disabled {
    opacity: 0.54;
    cursor: default;
  }

  & svg {
    width: 10px;
    height: 10px;
    fill: var(--colorTextSecondary);
    fill-rule: evenodd;
    background: none;
    border: none;
    cursor: pointer;
  }

  & .title {
    white-space: nowrap;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    margin-right: 8px;
  }
}
