@import 'styles/core';

.wrapper {
  width: 280px;
  margin-top: 50%;
}

.deleteDialog {

}

.deleteDialogText {
  margin: 0;

  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
}

.deleteButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding-top: 32px;
}

.deleteConfitm {
  margin-left: 8px;
}

.deletePopupText {
  margin: 0;

  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
}
