@import 'styles/core';


.link, .button {
  display: flex;
  text-decoration: none;
  color: var(--colorPrimary);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.6px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  padding: 0;

  &:hover {
    text-decoration: none;
    color: #3998C0;

    & svg {
      fill: #3998C0;
    }
  }

  & svg {
    width: 20px;
    height: 20px;
    fill: var(--colorPrimary);
  }

  &:disabled {
    color: rgba(67, 179, 226, .4);

    & svg {
      fill: rgba(67, 179, 226, .4);
    }
  }

  & .title {
    border-bottom: 1px var(--colorPrimary) dotted;
    white-space: nowrap;
  }

  &.hasIcon .title {
    margin-left: 8px;
  }
}

.defaultTheme {
  font-size: 14px;
  color: var(--colorTextSecondary);
  font-weight: normal;

  &:hover {
    color: var(--colorTextSecondary);
  }

  & .title {
    border-bottom: 1px var(--colorTextSecondary) dotted;
  }
}
