@import 'styles/core';

.yearRangePicker {
  display: flex;
  align-items: center;
  width: 200px;
}

.leftButton,
.rightButton {
  position: relative;
  display: flex;
  justify-content: center;

  width: 32px;
  height: 32px;

  background: var(--colorWhite);
  border: 1px solid var(--colorBorderSecondary);
  border-radius: 4px;
  cursor: pointer;

  & svg {
    /* fix iOS safari svg bug https://stackoverflow.com/questions/4504942/mobile-safari-svg-problem */
    position: absolute;
    top: 9px;
  }

  &:hover svg path {
    fill: var(--colorTextSecondary);
  }

  &:disabled {
    background: var(--colorBackgroundHover);
    cursor: default;

    & svg path,
    &:hover svg path {
      fill: var(--colorTextNeutral);
    }
  }
}

.leftButton {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}

.rightButton {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
}

.dropdownContent {
  right: -32px;
  top: calc(100% + 4px);
}
