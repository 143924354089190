@import 'styles/core';

.layout {
  min-height: calc(100vh - 60px);
  background-color: #FAFBFC;
}

.contentWrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 24px 196px 24px;

  @media (--tablet) {
    padding: 0 0 196px 0;
  }
}

.loader {
  position: relative;
  height: 320px;
}

.report {
  margin-top: 16px;

  & + .report {
    margin-top: 40px;
  }
}

.reportTitle {
  margin: 0 0 16px 0;

  @media (--tablet) {
    padding: 0 16px;
  }
}

.charts {
  display: flex;

  & > * {
    margin: 0 0 16px 0;
  }

  & > * + * {
    margin-left: 16px;
  }


  @media (--desktopSmall) {
    flex-wrap: wrap;
    flex-direction: column;

    & > * + * {
      margin-left: 0;
    }
  }
}

.harvest {
  display: flex;
  justify-content: space-between;

  @media (--tablet) {
    flex-direction: column;
  }
}



.firstGraph, .secondGraph {
  background: #FFFFFF;
  border: 1px solid #E7E9EE;

  padding: 20px 25px;

  border-radius: 4px;

  @media (--tablet) {
    padding: 20px 16px;
    border-radius: 0;
    border-left: none;
    border-right: none;
  }
}

.firstGraph {
  width: calc(100% - 470px);
  position: relative;
  flex-shrink: 1;

  @media (--desktopSmall) {
    width: 100%;
  }
}

.secondGraph {
  width: 454px;

  @media (--desktopSmall) {
    width: 100%;
  }
}

.secondGraphHeader {
  margin-bottom: 2px;
}

.breakdownHeader {
  padding: 0 25px;
}

.dashboardHeader {
  padding-bottom: 24px;

  @media (--tablet) {
    padding-bottom: 0;
  }
}

.param {
  @media (--tablet) {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 8px;
  }
}


.defaultBreakdown {
  height: 405px;
  width: 100%;
}


.table {
  background: var(--colorWhite);
  border: 1px solid var(--colorBorderDefault);
  border-radius: 3px;
}

.link {
  text-decoration: none;
  cursor: pointer;
  color: var(--colorTextPrimary);

  &:hover {
    text-decoration: underline;
  }
}

.tooltip {
  max-width: 260px;
}

.chartWrapper {
  padding-right: 20px;
}

.header {
  margin-bottom: 28px;
}
