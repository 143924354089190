@import 'styles/core';

.metricsRow {
 /* width: 100%; */
  height: 222px;
  position: relative;

  @media (--tabletBig) {
    margin-bottom: 56px;
  }

  & .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (--tabletBig) {
      flex-direction: column;
    }

    & .titleContainer {

    }

    & .discrepancy {
      @media (--tabletBig) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 24px;
        margin-bottom: 24px;
      }

      & .discrepancyTitle {
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        color: var(--colorTextSecondary);
        text-align: right;
      }
      & .discrepancyValue {
        font-size: 13px;
        font-weight: 600;
        line-height: 18px;
        color: var(--colorTextPrimary);
        text-align: right;

        @media (--tabletBig) {
          font-size: 20px;
          font-weight: 400;
          line-height: 32px;
          text-align: right;
        }
      }
    }
  }

  & .metricContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & .chartWrapper {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: auto;
      @media (--tabletBig) {
        flex: none;
        width: 100%;
      }
    }

    & .tableWrapper {
      flex: 0 0 auto;
    }

    & table {
      margin-top: 18px;
      @media (--tabletBig) {
        display: none;
      }
    }
  }
}
