@import 'styles/core';

.navigation {
  position: relative;
}

.openMenuBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.menuHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}

.menuContent {
  padding: 32px 0;
}

.menu {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  max-width: 100vw;
  height: 100vh;

  /* Перебиваем интерком */
  z-index: 10001;
  overflow: hidden;

  transition: opacity 150ms ease-out, max-height 150ms ease-out;
  transform-origin: top;

  background: #fff;
  padding: 24px 16px;
}

.menuEnter {
  max-height: 0;
}

.menuEnter.menuEnterActive {
  max-height: 100vh;
}

.menuLeave {
  max-height: 100vh;
}

.menuLeave.menuLeaveActive {
  max-height: 0;
}

.closeIcon {
  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}
