@import 'styles/core';

.compareButton {
  position: relative;
  width: 24px;
  height: 24px;
}

.alertBageWrapper {
  position: absolute;
  bottom: 11px;
  left: 13px;
  background-color: var(--colorWhite);
  border: 1px solid var(--colorWhite);
  border-radius: 4px;
}

.alertBage {
  padding: 2px 4px;
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  background-color: var(--colorPrimary);  
  color: var(--colorWhite);
  border-radius: 4px;

  min-width: 16px;
  text-align: center;
}

.bigSize {
  width: 34px;
  height: 34px;

  & .compareIcon {
    width: 34px;
    height: 34px;
  }

  & .alertBageWrapper {
    bottom: 15px;
    left: 17px;
  }

  & .alertBage {
    line-height: 18px;
    font-size: 13px;
    padding: 1px 4px;
  }
}
