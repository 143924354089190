@import 'styles/core';

.harvestRow {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 13px;
  line-height: 18px;

  color: var(--colorTextSecondary);

  &.secondRow {
    margin-top: 4px;
  }
}

.harvestRowActive {
  color: var(--colorTextPrimary);

  & .harvestColor {
    height: 4px;
  }
}

.harvestRowAmount {
  font-weight: 500;
  text-align: right;

  white-space: nowrap;
}

.harvestTitle {
  display: flex;
  align-items: center;
}

.harvestColor {
  width: 12px;
  min-width: 12px;
  height: 0;
  margin-right: 8px;
}
