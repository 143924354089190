@import 'styles/core';

.additionalInfo {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: var(--colorTextSecondary);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: lowercase;
}

.additionalInfoItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:after {
    content: '';
    display: block;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background-color: var(--colorTextSecondary);
    margin: 0 2px;
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
}
