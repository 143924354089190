@import 'styles/core';

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding-top: 16px;
}

.confitm {
  margin-left: 8px;
}

.rateIncidentPopupText {
  padding-left: 64px;
  margin-top: 16px;
}

.sentButtons {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextSecondary);
}

.checkIcon {
  margin-right: 8px;
}
