.selectWrapper {
  margin: 0;
}

.selectButton {
  height: 32px;
  min-width: 144px;
}

.selectPopup {
  min-width: 144px;
}
