@import 'styles/core';

.controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.button {
  box-sizing: border-box;

  & + .button {
    margin-left: 8px;
  }
}
