@import 'styles/core';

.sidePanel {
  position: absolute;
  right: -410px;
  width: 410px;
  height: 100%;
  overflow: auto;
  border-left: 1px solid var(--colorBorderDefault);
  background-color: var(--colorWhite);
  transition: right 150ms ease-out;

  &.sidePanelOpened {
    right: 0;
  }
}

.header {
 padding: 24px 24px 0;
}

.headerText {
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  color: var(--colorTextPrimary);
  font-weight: 500;
}

.headerRow {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 16px;
  border-bottom: 1px solid var(--colorBorderDefault);
}

.closeIcon {
  margin-top: -8px;
  margin-right: -8px;
}
