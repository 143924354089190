@import "styles/core";

.block {
  padding: 24px;
  border: 1px solid var(--colorBorderDefault);
  border-radius: 4px;

  & .chartContainer {

  }
}
