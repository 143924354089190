@import 'styles/core';

.legend {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  /* Левый padding в нуле, т.к. у item'ов есть левый margin */
  padding: 0 16px 14px 0;

  @media (--tablet) {
    /* Прибавляем к 0 по 16 из-за margin-left */
    padding: 0px 32px 14px 16px;
  }
}

.legendItem {
  display: flex;
  margin-left: 16px;
  max-width: 100%;
}

.legendItemColor {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin: 4px 0;
}

.legendItemColorSquare {
  width: 12px;
  height: 4px;
  border-radius: 1px;
}

.legendItemTitle {
  font-size: 13px;
  color: var(--colorTextPrimary);
  line-height: 20px;
  white-space: nowrap;
  margin-left: 8px;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.legendItemSubTitle {
  color: var(--colorTextSecondary);
}

.columnContainer {
  & .legendItem {
    margin-left: 0;
  }
}
