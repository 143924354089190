.overlay {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, .62); /* TODO: заменить на переменную */
}

.overlayVisible {
  display: block;
}
