@import 'styles/core';

.circleLoader {
  background-color: #ffffff;
  height: 518px;
  border-radius: 5px;
}

.circleLoaderIcon {
  width: 48px;
  height: 48px;
}

.dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(49, 52, 59, .9);
  z-index: 10000;
  overflow-y: auto;
  padding: 0;

  & .dialogClose {
    position: absolute;
    top: 0;
    right: -36px;
    cursor: pointer;
  }

  & .dialogWrapper {
    display: flex;
    flex-flow: column;
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 900px;
    border-radius: 5px;
    background-color: #ffffff;
    margin: 20px auto;
    padding: 30px;
    box-sizing: border-box;
  }

  & .dialogHeader {
    font-weight: bold;
    font-size: 24px;
    color: #000000;
    letter-spacing: 1.85px;
    text-transform: uppercase;
  }

  & .dialogBody {
    padding-top: 20px;
    display: flex;
    flex-flow: column;
  }

  & .dialogFooter {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 30px 0 10px;
    border-top: 1px solid #F1F3F6;
    margin-top: 30px;

    & .descriptions {
      display: flex;
      flex-flow: column;

      & .icon {
        width: 14px;
        height: 14px;
        margin-right: 6px;
      }

      & .descriptionError {
        font-size: 14px;
        color: var(--colorNegative);
        letter-spacing: 0.58px;
        display: flex;
        align-items: center;
      }

      & .descriptionWarning {
        margin-top: 8px;
        font-size: 14px;
        color: #BDCFE1;
        display: flex;
        align-items: center;
      }
    }
  }

  & .dialogItem {
    font-size: 14px;
    color: var(--colorPrimary);
    line-height: 20px;
    cursor: pointer;
    text-decoration: underline;

    & + .dialogItem {
      margin-left: 80px;
    }
  }

  & .dialogItemSelected {
    color: #BDCFE1;
    text-decoration: none;
  }


  & :global {
    & .react-hint__content {
      padding: 16px 10px;
      border-radius: 10px;
      background: #FCFAEB;
      color: var(--colorTextSecondary);
      font-size: 12px;
      min-width: 140px;
      max-width: 320px;
    }

    & .react-hint--bottom:after {
      border-bottom-color: #FCFAEB;
    }
  }


  & .tooltip {
    padding: 16px 10px!important;
    border-radius: 10px!important;
    background: #FCFAEB!important;
    color: var(--colorTextSecondary)!important;
    font-size: 12px!important;
    border: 1px solid rgba(203, 142, 40, .1)!important;
    white-space: pre-wrap!important;
    opacity: 1!important;
    width: 240px!important;


    & :global(.multi-line) {
      text-align: left!important;
    }

    &:global(.place-top) {

      &:after {
        border-top-color: #FCFAEB!important;
      }
      &:before {
        border-top: 8px solid rgba(203, 142, 40, .1)!important;
        bottom: -8px;
        opacity: .1!important;
      }
    }
    &:global(.place-bottom) {
      &:after {
        border-bottom-color: #FCFAEB!important;
      }
      &:before {
        border-bottom: 8px solid rgba(203, 142, 40, .1)!important;
        top: -8px;
        opacity: .1!important;
      }
    }

    &:global(.place-left) {
      &:after {
        border-left-color: #FCFAEB!important;
      }
      &:before {
        border-left: 8px solid rgba(203, 142, 40, .1)!important;
        right: -8px;
        opacity: .1!important;
      }
    }
    &:global(.place-right) {
      &::after {
        border-right-color: #FCFAEB!important;
      }
      &::before {
        border-right: 8px solid rgba(203, 142, 40, .1)!important;
        left: -8px;
        opacity: .1!important;
      }
    }
  }

  @media only screen and (max-width: 640px) {
    & .tooltip {
      left: 4%!important;
      width: 92%!important;

      &:global(.place-top) {

        &:after {
          left: 50px;
        }
        &:before {
          left: 50px;
        }
      }
      &:global(.place-bottom) {
        &:after {
          left: 50px;
        }
        &:before {
          left: 50px;
        }
      }

      &:global(.place-left) {
        &:after {
          left: 50px;
        }
        &:before {
          left: 50px;
        }
      }
      &:global(.place-right) {
        &::after {
          left: 50px;
        }
        &::before {
          left: 50px;
        }
      }
    }
  }
}

.valueTitleFirst {
  text-transform: capitalize;
}

.valueTitleSecond {
  font-weight: 400;
  font-size: 10px;
  color: var(--colorTextNeutral);
}

.compartmentPlantingCycleInfo {
  font-size: 16px;
  color: #BDCFE1;
  margin-bottom: 20px;
}

.dateInfo {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  & .dateInfoTitle {
    margin-top: 20px;
    font-size: 14px;
    color: var(--colorTextSecondary);
    line-height: 20px;
  }

  & .dateInfoPicker {
    margin-top: 20px;
    margin-left: 20px;
    display: flex;

    & .dateInfoPickerButton {
      align-items: center;
      display: flex;
      font-size: 16px;
      color: var(--colorPrimary);
      cursor: pointer;

      & * {
        margin-left: 5px;
      }
    }

    & .dateInfoPickerLabel {
      align-items: center;
      display: flex;
      font-size: 16px;
      color: var(--colorPrimary);

      & * {
        margin-left: 5px;
      }
    }
  }
}

.category {
  width: 100%;

  & .categoryTitle {
    font-size: 18px;
    color: var(--colorTextSecondary);
    letter-spacing: 1.4px;
    width: calc(100% + 40px);
    text-align: center;
    font-weight: bold;
    background-color: #f1f3f6;
    text-transform: uppercase;
    padding: 6px 0;
    margin-left: -20px;
    margin-right: -20px;
  }

  & .categoryBody {
    flex-wrap: wrap;
    display: flex;
    //justify-content: space-between;
    //padding: 0 24px;

    & .categoryColumn {
      display: flex;
      flex-flow: column;
      width: 50%;
      min-width: 280px;
      max-width: 480px;
      padding-right: 60px;
    }

    & .value {
      width: 30%;
      min-width: 280px;
      max-width: 480px;
      padding-right: 60px;
      box-sizing: border-box;
      outline: none;
      box-shadow: none;
    }

    @media only screen and (max-width: 640px) {
      & .value {
        width: 100%;
      }
    }
  }
}

.tooltipHint {
  align-items: center;
  width: calc(100% + 8px);
  margin-left: -4px;
  margin-right: -4px;
  margin-bottom: 10px;
  justify-content: center;
  padding: 8px 10px;
  font-weight: 400;
  font-size: 12px;
  color: var(--colorTextSecondary);
  display: none;
  background-color: #FCFAEB;
}

@media only screen and (min-width: 641px) and (max-width: 1020px) {
  .dialog {
    padding: 30px 7px 7px;
    justify-content: flex-start;
    align-items: center;
    & .dialogWrapper {
      width: 100%;
      margin: 0;
      top: 0;
      padding: 20px 14px 30px;

      & .dialogClose {
        position: absolute;
        top: -22px;
        margin-left: -10px;
        left: 50%;
        right: 50%;
        cursor: pointer;
      }
    }
  }

  .category {
    & .categoryBody {
      padding: 0 14px;
    }

    & .categoryTitle {
      width: calc(100% + 8px);
      margin-left: -4px;
      margin-right: -4px;
    }
  }

  .tooltipHint {
    display: flex;
  }
}

@media only screen and (max-width: 640px) {
  .dialog {
    padding: 30px 7px 7px;
    justify-content: flex-start;
    align-items: center;

    & .dialogWrapper {
      width: 100%;
      margin: 0;
      top: 0;
      padding: 20px 14px 30px;

      & .dialogClose {
        position: absolute;
        top: -22px;
        margin-left: -10px;
        left: 50%;
        right: 50%;
        cursor: pointer;
      }
    }

    & .dialogFooter {
      flex-flow: column;

      & .submit {
        align-self: center;
        margin-top: 30px;
      }
    }
  }

  .category {
    & .categoryBody {
      padding: 0;

      & .categoryColumn {
        display: flex;
        flex-flow: column;
        width: 100%;
        min-width: 0;
        max-width: 320px;
        padding-right: 0;
      }
    }

    & .categoryTitle {
      width: calc(100% + 8px);
      margin-left: -4px;
      margin-right: -4px;
    }
  }

  .tooltipHint {
    display: flex;
  }
}

.value {
  & .valueWrapper {
    display: flex;
    align-items: center;
    padding: 20px 0;
    justify-content: space-between;
    height: 100%;

    &.disabled {
      pointer-events: none;

      & .valueEditorWrapper {
        & .valueEditor {
          color: rgba(84, 107, 126, .4);
          border: 1px solid rgba(84, 107, 126, .4);
        }
      }

      & .valueTitleFirst {
        color: rgba(84, 107, 126, .4);
      }

      & .valueTitleSecond {
        color: rgba(84, 107, 126, .4);
      }

      & .valueDescription {
        color: rgba(84, 107, 126, .4);
      }
    }
  }

  & .valueTitle {
    font-size: 14px;
    color: var(--colorTextSecondary);
    line-height: 20px;
    min-width: 80px;
    cursor: pointer;
  }

  & .valueTitleWrapper {
    min-width: 80px;
    display: flex;
  }

  & .valueEditorCompartment {
    display: flex;
    align-items: center;
    width: 140px;
    margin-left: 20px;
    flex-shrink: 0;
  }

  & .valueEditorWrapper {
    position: relative;

    & .valueEditor {
      height: 40px;
      width: 70px;
      background: #FFFFFF;
      border: 1px solid #BDCFE1;
      border-radius: 10px;
      font-size: 16px;
      color: var(--colorTextSecondary);
      padding: 10px 10px;
      outline: none;
      text-align: center;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }

      &:invalid {
        border-color: var(--colorNegative);
      }
    }

    & .valueErrorIcon {
      display: none;
    }

    & .valueEditor:invalid + .valueErrorIcon {
      display: block;
      width: 14px;
      height: 14px;
      position: absolute;
      top: -7px;
      right: -7px;
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"><g fill="none" fill-rule="evenodd"><rect width="14" height="14" fill="var(--colorNegative)" rx="5"/><path fill="#FFF" d="M9.2572 10.7617l-1.674-1.674c-.3318-.3317-.688-.3364-1.0176-.0066L4.885 10.7618c-.332.332-.8634.331-1.1918.0025l-.3153-.3152c-.3327-.3326-.3274-.862.0024-1.1918L5.061 7.5766c.332-.332.2717-.7394-.058-1.0692L3.3803 4.885c-.3318-.332-.331-.8634-.0025-1.1918l.3152-.3153c.3325-.3327.862-.3274 1.1917.0024L6.5073 5.003c.3318.3317.795.3322 1.1248.0024l1.625-1.625c.332-.3318.8634-.331 1.1918-.0025l.3152.3152c.3326.3325.3273.862-.0025 1.1917L9.1367 6.51c-.3318.3318-.3787.7435-.049 1.0733l1.674 1.674c.332.3318.331.8633.0025 1.1917l-.3152.3152c-.3326.3326-.862.3273-1.1918-.0025z"/></g></svg>') center no-repeat;
    }
  }
  & .valueDescription {
    font-size: 12px;
    color: var(--colorTextNeutral);
    line-height: 16px;
    margin-left: 10px;
  }
}
