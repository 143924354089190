@import 'styles/core';

.progressBarCircleWithValueWrapper {
  position: relative;

  color: var(--colorTextPrimary);
  font-weight: 500;
}

.percentValue {
  /* Для размера medium */
  position: absolute;
  width: 30px;
  top: calc(50% - 8px);
  left: calc(50% - 15px);
  text-align: center;
}

.medium {
  width: 48px;
  height: 48px;

  font-size: 12px;
  line-height: 16px;

  & svg {
    width: 48px;
    height: 48px;
  }
}
