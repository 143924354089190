@import 'styles/core';

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 60px;
  width: 100%;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #FFF;
  border-bottom: 1px #E7E9EE solid;
  box-sizing: border-box;

  @media (--tablet) {
    height: 56px;
    padding: 0 16px;
  }
}

.routes {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-start;

  height: 100%;

  @media (--tablet) {
    justify-content: flex-start;
  }

  @media (--phoneLandscape) {
    justify-content: center;
  }
}

.route {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 8px;
  margin: 0 8px;

  font-size: 14px;
  font-weight: 500;
  color: var(--colorTextSecondary);

  /* Хак для того, чтобы не прыгала ширина шрифта у активного элемента */
  border: 1px solid #fff;

  text-decoration: none;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
    padding-left: 0;
  }

  &:hover {
    color: var(--colorPrimary);

    & svg path {
      fill: var(--colorPrimary);
    }
  }

  &:focus {
    outline: none;
  }

  &.routeActive {
    color: var(--colorPrimary);

    & svg path {
      fill: var(--colorPrimary);
    }
  }

  & svg path {
    fill: var(--colorTextSecondary);
  }

  @media (--tabletBig) {
    margin: 0;
  }

  @media (--tablet) {
    margin: 0;
    padding: 0;
    width: 48px;
    justify-content: center;
  }

  @media (--phoneLandscape) {
    width: 44px;
  }

  @media (--phone) {
    width: 40px;
  }
}

/* For resources icon (specific svg paths) */
.route {
  &.routeActive {
    & .energyIcon {
      & path {
        stroke: var(--colorPrimary);
        fill: none;
      }
    }
  }

  & .energyIcon {
    & path {
      fill: none;
    }
  }
}

.linksWrapper {
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.navigationWrapper {
  display: flex;
  align-items: center;
}

.headerLogo {
  height: 100%;

  display: flex;
  align-items: center;
}

.logo {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-right: 48px;

  @media (--tabletBig) {
    margin-right: 32px;
  }

  @media (--tablet) {
    margin-right: 17px;
  }

  @media (--phoneLandscape) {
    margin-right: 0;
  }
}

.logoDesktop {
  @media (--tablet) {
    display: none;
  }
}

.logoMobile {
  display: none;
  width: 36px;

  @media (--tablet) {
    display: block;
  }
}

.popupFull {
  display: none;
  position: fixed;
  width: 100vw;
  height: calc(100vh - 60px);
  top: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000000;

  & .popupUserName {
    display: none;
  }

  & .popupFullWrapper {
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    padding: 60px 20px 20px 96px;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  & .popupContent {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
  }

  & .route {
    margin-left: 0;
    padding: 5px 12px;

    & + .route {
      margin-top: 10px;
    }
  }
}

:global {
  & html.focus-outline-hidden .header__link {
    &:focus {
      border: 1px solid transparent;
      box-shadow: none;
    }
  }
}

.dropdown {
  height: 100%;
}

.dropdown {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:hover {
    & .dropdownRoute {
      background: var(--colorMenuItemHover);
      color: var(--colorTextSecondary);

      & svg path {
        fill: var(--colorTextSecondary);
      }
    }
  }

  & .dropdownRoute {
    padding-left: 8px;
    margin-left: 8px;
    height: 32px;
    border-radius: 4px;

    @media (--tabletBig) {
      margin: 0;
    }

    @media (--tablet) {
      margin: 0;
      padding: 0;
      height: 40px;
      width: 48px;
    }

    @media (--phoneLandscape) {
      width: 44px;
    }

    @media (--phone) {
      width: 40px;
    }

    &.dropdownOpened {
      background: var(--colorMenuItemHover);
    }

    &.routeActive {
      color: var(--colorPrimary);

      & svg path {
        fill: var(--colorPrimary);
      }
    }
  }
}

.dropdownContent {
  right: initial;
  left: 8px;
  top: calc(100% - 13px);
  width: 168px;

  @media (--tablet) {
    top: calc(100% - 4px);
    min-width: 100%;
    right: 0;
    left: initial;
  }
}

.routeDropdownChild {
  display: flex;
  align-items: center;

  padding: 8px 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--colorTextSecondary);
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;

  /* Хак для того, чтобы не прыгала ширина шрифта у активного элемента */
  border: 1px solid #fff;

  &:hover {
    background: var(--colorMenuItemHover);
    border-color: var(--colorMenuItemHover);
  }

  &.routeActive {
    color: var(--colorPrimary);

    & svg path {
      fill: var(--colorPrimary);
    }
  }
}

.dropdownRoute {
  & .routeText {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  & .arrowDownIcon {
    margin-left: 4px;
  }
}

.route, .dropdownRoute {
  & .routeText {
    @media (--tablet) {
      display: none;
    }
  }

  & .routeIcon {
    display: none;

    @media (--tablet) {
      display: flex;
    }
  }
}
