@import 'styles/core';
.block {
  @media (--tabletBig) {
   width: 100%;
  }
  min-height: 212px;
  & .metrics {
    min-height: 212px;
    padding: 24px;
    border: 1px solid var(--colorBorderDefault);
    border-radius: 4px;
    @media (--tabletBig) {
      margin-bottom: 24px;
    }
    & .title {
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: var(--colorTextPrimary);
    }
  }
}
