@import 'styles/core';

.group {
  border-bottom: 1px solid var(--colorBorderDefault);

  &:last-child {
    border-bottom: none;
  }
}

.groupContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 16px;

  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: var(--colorBackgroundHover);
  }

  &.withAdditionalInfo {
    padding: 8px 16px;
  }
}

.arrowRightIconWrapper {
  width: 20px;
  height: 20px;
  margin-left: 8px;
}

.arrowRightIcon {
  & path {
    fill: var(--colorTextSecondary);
  }
}

.firstColumn {}

.navigationElements {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.firstColumn {
  padding-right: 32px;
}

.navigationElements {
  color: var(--colorTextSecondary);
}

.additionalInfo {
  margin-top: 2px;
}

.name {
  max-width: 600px;
}
