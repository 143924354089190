@import 'styles/core';

.body {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;

  padding: 0 24px 72px 24px;

  @media (--tabletBig) {
    padding: 0 24px 72px 24px;
  }

  @media (--tablet) {
    padding: 0 16px 96px 16px;
  }
}

.controls {
  padding: 25px 0 0 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (--tablet) {
    padding: 28px 0 0 0;
  }
}

.routeControl {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;

  & .routeControlIcon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .routeControlText {
    line-height: 18px;
    margin: 0 0 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 500;
    color: var(--colorTextSecondary);
  }
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 344px;
  width: 100%;

  & .loader {
    position: static;
  }
}
