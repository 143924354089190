@import 'styles/core';

.canvasTimeline {}

.canvas {
  height: calc(100vh - 60px - 83px);
  width: 100%;
  display: block;
  position: relative;
}

.scrollContainer {
  height: 100%;
  width: 18px;
  display: block;
  overflow: scroll;
}

.popupOverlay {
  padding: 16px 24px 32px 24px;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  box-sizing: border-box;
  overflow: hidden;
  animation-duration: 0.3s;
  animation-name: showByOpacity;
}

@keyframes showByOpacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.popup {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #E1E8F0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04), 0 8px 24px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  overflow: auto;
  margin-top: 150px;
  height: calc(100% - 150px);

  & .popupActions {
    position: absolute;
    right: 24px;
    top: 24px;
    z-index: 1;
  }
}

.iconButton {
  width: 24px;
  height: 24px;
  cursor: pointer;

  & path {
    fill: var(--colorTextSecondary);
  }

  &:hover {
    & path {
      fill: var(--colorTextPrimary);
    }

  }
}

.loader {
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, .2);
}
