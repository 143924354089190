@import 'styles/core';

.inputWrapper {
  position: relative;
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  &.disabled {
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }

    & .input {
      background-color: #F7F8FA;
      border: 1px solid #BDCFE1;
      cursor: not-allowed;
    }

    & .placeholder {
      color: #9EA4A8;
    }
  }

  &.warninged {
    padding: 0 0 20px 0;

    & .placeholder {
      color: var(--colorTextNeutral);

      &.top {
        color: var(--colorWarning);
      }
    }
  }

  &.errored {
    padding: 0 0 20px 0;

    & .placeholder {
      color: var(--colorTextNeutral);

      &.top {
        color: var(--colorNegative);
      }
    }
  }


  &.cell {
    &:hover {
      box-shadow: 0 0 0 1px var(--colorPrimary);
    }

    &:focus {
      box-shadow: 0 0 0 1px var(--colorPrimary);
    }

    &.active {
      box-shadow: 0 0 0 1px var(--colorPrimary);
    }

    & .input {
      text-align: right;
      border-color: transparent;

      &:hover {
        border-color: transparent;
      }

      &:focus {
        text-align: left;
        border-color: transparent;
        box-shadow: none;
      }

      &.invalid, &.invalid:hover, &.invalid:focus {
        border-color: transparent;
        box-shadow: none;
      }

      &.danger, &.danger:hover, &.danger:focus {
        border-color: transparent;
        box-shadow: none;
      }
    }

    & .hintButton {
      width: 20px;
      height: 20px;
      outline: none;
      background-color: #ffffff;
      border: none;
      padding: 0;
      margin: 0;
      pointer-events: all;
    }

    & .warningHint {
      display: none;
      width: 0;
      height: 20px;

      & svg {
        margin: 0;
        padding: 0;
      }
    }

    & .warningHintTooltip {
      background-color: var(--colorWarning) !important;
      border-radius: 4px !important;
      font-size: 12px !important;
      line-height: 16px;
      color: #ffffff !important;
      padding: 8px !important;
      text-align: left;
      opacity: 1 !important;
      width: 174px !important;
      z-index: 20 !important;

      &.firstColumn {
        width: 120px !important;

        @media (--tabletBig) {
          width: 90px !important;
        }
      }

      &:global(.place-left) {
        &::after {
          border-left-color: var(--colorWarning) !important;
        }
      }

      &:global(.place-right) {
        &::after {
          border-right-color: var(--colorWarning) !important;
        }
      }
    }

    & .errorHint {
      display: none;
      width: 0;
      height: 20px;

      & svg {
        margin: 0;
        padding: 0;
      }
    }

    & .errorHintTooltip {
      background-color: #D15E5E !important;
      border-radius: 4px !important;
      font-size: 12px !important;
      line-height: 16px;
      color: #ffffff !important;
      padding: 8px !important;
      text-align: left;
      opacity: 1 !important;
      width: 174px !important;
      z-index: 20 !important;

      &.firstColumn {
        width: 120px !important;

        @media (--tabletBig) {
          width: 90px !important;
        }
      }

      &:global(.place-left) {
        &::after {
          border-left-color: #D15E5E !important;
        }
      }

      &:global(.place-right) {
        &::after {
          border-right-color: #D15E5E !important;
        }
      }
    }

    &.warninged {
      padding: 0;
      box-shadow: 0 0 0 1px var(--colorWarning);

      & .warningHint {
        display: block;
        position: absolute;
        top: 18px;
        left: 16px;
      }

      & .warningHint {
        display: block;
        position: absolute;
        top: 18px;
        left: 16px;
      }
    }

    & .warning {
      display: none;
    }

    &.errored {
      padding: 0;
      box-shadow: 0 0 0 1px var(--colorNegative);

      & .errorHint {
        display: block;
        position: absolute;
        top: 18px;
        left: 16px;
      }

      & .warningHint {
        display: block;
        position: absolute;
        top: 18px;
        left: 16px;
      }
    }

    & .error {
      display: none;
    }


    &.disabled {
      box-shadow: none;

      &:hover {
        box-shadow: none;
      }
    }
  }
}

.input {
  background: #FFFFFF;
  outline: none;
  width: 100%;
  text-align: left;
  font-size: 14px;
  color: var(--colorTextPrimary);
  letter-spacing: -0.15px;
  margin: 0;
  box-sizing: border-box;

  height: 48px;
  line-height: 20px;
  padding: 20px 12px 8px 12px;
  border-radius: 4px;
  border: 1px solid #BDCFE1;

  &::placeholder {
    color: rgba(0, 0, 0, 0.54);
    font-size: 14px;
    color: var(--colorTextNeutral);
    letter-spacing: -0.15px;
    line-height: 20px;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  &:hover {
    border-color: var(--colorPrimary);
  }

  &.invalid, &.invalid:hover, &.invalid:focus {
    border-color: var(--colorNegative);
    box-shadow: none;
  }

  &.danger, &.danger:hover, &.danger:focus {
    border-color: var(--colorWarning);
    box-shadow: none;
  }

  &:focus {
    border: 1px solid var(--colorPrimary);
    box-shadow: 0 0 0 2px rgba(67, 179, 226, 0.10)
  }

  &.mini {
    height: 32px;
    padding: 6px 12px;
    line-height: 14px;
    font-size: 14px;
  }
}

.placeholder {
  position: absolute;
  border: none;
  top: 14px;
  left: 12px;
  right: 12px;
  font-size: 14px;
  color: var(--colorTextNeutral);
  line-height: 20px;
  pointer-events: none;
  transition: top 100ms ease-in, font-size 100ms ease-in, letter-spacing 100ms ease-in, line-height 100ms ease-in;

  &.top {
    top: 4px;
    left: 12px;
    right: 12px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
    line-height: 16px;
  }
}

.error {
  position: absolute;
  bottom: 0;
  left: 12px;
  font-size: 12px;
  color: var(--colorNegative);
  line-height: 16px;
  background-color: #ffffff;
}

.warning {
  position: absolute;
  bottom: -12px;
  left: 12px;
  font-size: 12px;
  color: var(--colorWarning);
  line-height: 16px;
  background-color: #ffffff;
}
