@import 'styles/core';

.contextMenu {
  position: absolute;
  padding: 8px 0;
  min-width: 150px;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 8px 24px rgba(0, 0, 0, 0.02);
  border: 1px solid #E1E8F0;
  border-radius: 4px;
  z-index: 20;
  background-color: var(--colorWhite);
  min-width: none;
}

.menuItem {
  padding: 6px 16px;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextSecondary);
  cursor: pointer;

  &:hover {
    background-color: var(--colorBackgroundHover);
  }
}
