@import 'styles/core';

.valueViewer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  line-height: 20px;
  color: var(--colorTextPrimary);
}

.dataEditor {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: none;
  border: none;
  padding: 0 16px;
  width: 100%;
}