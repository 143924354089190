@import 'styles/core';

.plate {
  display: flex;
  align-items: flex-start;
  padding: 14px 48px 14px 14px;
  border: 1px solid var(--colorBorderDefault);
  border-left: 4px solid var(--colorPrimary);
  border-radius: 4px;
  width: fit-content;
}

.tooltip {
  width: 240px!important;
}

.infoIcon {
  width: 20px;
  height: 20px;
  margin-right: 18px;
  flex-shrink: 0;

  & path {
    fill: var(--colorPrimary);
  }
}

.warningIcon {
  width: 20px;
  height: 20px;
  margin-right: 18px;
  flex-shrink: 0;

  & path {
    fill: var(--colorWarning);
  }
}

.error {
  border-left: 4px solid var(--colorNegative);

  & .infoIcon {
    & path {
      fill: var(--colorNegative);
    }
  }
}

.warning {
  border-left: 4px solid var(--colorWarning);

  & .infoIcon {
    & path {
      fill: var(--colorWarning);
    }
  }
}
