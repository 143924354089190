@import 'styles/core';

.typography {
  margin: 0;
  padding: 0;
}

.h1 {
  composes: typography;

  color: var(--colorTextPrimary);

  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

.h2 {
  composes: typography;

  color: var(--colorTextPrimary);

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.h3 {
  composes: typography;

  color: var(--colorTextPrimary);

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.h4 {
  composes: typography;

  color: var(--colorTextPrimary);

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.subtitle1 {
  composes: typography;

  color: var(--colorTextPrimary);

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.subtitle2 {
  composes: typography;

  color: var(--colorTextPrimary);

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.subtitle3 {
  composes: typography;

  color: var(--colorTextSecondary);

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.body1 {
  composes: typography;

  color: var(--colorTextPrimary);

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.body2 {
  composes: typography;

  color: var(--colorTextSecondary);

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
}

.caption1 {
  composes: typography;

  color: var(--colorTextSecondary);

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.caption2 {
  composes: typography;

  color: var(--colorTextPrimary);

  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
}

.bold {
  font-weight: 500;
}

.primary {
  color: var(--colorTextPrimary);
}

.secondary {
  color: var(--colorTextSecondary);
}

.neutral {
  color: var(--colorTextNeutral);
}
