@import 'styles/core.css';

input::-ms-clear {
  display: none;
}

input[type="text"], & textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.react-datepicker {
  font-family: Roboto, Arial, Helvetica Neue, Helvetica, sans-serif;
  border: 1px solid #E1E8F0;
  background: #FFFFFF;
  box-shadow: 0 8px 24px 0 rgba(0,0,0,0.02), 0 2px 4px 0 rgba(0,0,0,0.04);
  border-radius: 4px;
  padding: 16px 0 0 0;
}

.react-datepicker__month {
  margin: 12px;
  font-weight: 500;
}

.react-datepicker__triangle {
  display: none;
  background-color: transparent;
  border-bottom-color: #ffffff;

  &:before {
    border-bottom-color: #E1E8F0;
  }
}

.react-datepicker-popper {
  //left: -20px!important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  border-bottom-color: #ffffff;

  &:before {
    border-bottom-color: #E1E8F0;
  }
}

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 4px;
}

.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 4px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before {
    border-top-color: #E1E8F0;
}

.react-datepicker__year-read-view--down-arrow::before {
    border-top-color: #E1E8F0;
}

.react-datepicker__month-read-view--down-arrow::before {
    border-top-color: #E1E8F0;
}


.react-datepicker__header {
  background-color: #ffffff;
  border: none;
  padding: 0;
}

.react-datepicker__current-month {
  font-size: 14px;
  color: var(--colorTextPrimary);
  text-align: center;
  line-height: 20px;
  font-weight: 500;

  text-transform: capitalize;
}

.react-datepicker__day {
  width: 32px;
  height: 32px;
  font-size: 13px;
  font-weight: 400;
  color: var(--colorTextPrimary);
  text-align: center;
  line-height: 20px;
  padding: 6px 0;
  margin: 0;
  box-sizing: border-box;
  border: none;
  position: relative;
  border-radius: 2px;

  &:hover {
    border-radius: 2px;
    background-color: #E4E9F0;
  }

  & + .react-datepicker__day {
    margin-left: 4px;
  }
}

.react-datepicker__day--today {
  color: var(--colorPrimary);

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--colorPrimary);
    bottom: 0;
    left: 0;
    right: 0;
  }

  &:hover {
    color: var(--colorTextPrimary);
    background-color: #E4E9F0;

    &:before {
      display: none;
    }
  }
}

.react-datepicker__day--disabled {
  color: #C5C8CA;
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
}

.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
  background-color: var(--colorPrimary);
  border: none;
  color: #ffffff;

  &:hover {
    background-color: var(--colorPrimary);

    &.react-datepicker__day--today {
      color: #ffffff;
    }
  }
}

.react-datepicker__day--keyboard-selected:before, .react-datepicker__day--selected:before {
  display: none;
}

.react-datepicker__day-names {
  margin: 16px 0 0 0;
}

.react-datepicker__day-name {
  width: 32px;
  height: 32px;
  font-size: 13px;
  color: #80878C;
  text-align: center;
  line-height: 20px;
  margin: 0;
  padding: 6px 0;

  & + .react-datepicker__day-name {
    margin-left: 4px;
  }
}

.react-datepicker__navigation {
  top: 20px;
  border-width: 6px;

  &:before {
    content: '';
    position: absolute;
    top: -6px;
    bottom: -6px;
    left: -6px;
    right: -6px;
    width: 12px;
    height: 12px;
    background-color: transparent;
    border: 6px solid transparent;
  }
}

.react-datepicker__navigation--previous {
  border-right-color: var(--colorTextSecondary);
  left: 17px;

  &:before {
    margin: 0 0 0 3px;
    border-right-color: #ffffff;
  }

  &:hover {
    border-right-color: var(--colorTextSecondary);
  }
}

.react-datepicker__navigation--next {
  border-left-color: var(--colorTextSecondary);
  right: 17px;

  &:before {
    margin: 0 0 0 -3px;
    border-left-color: #ffffff;
  }

  &:hover {
    border-left-color: var(--colorTextSecondary);
  }
}