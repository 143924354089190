@import 'styles/core';

.option {
  padding: 6px 12px;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.speciesName {
  padding: 10px 12px 6px;
  line-height: 20px;
  font-size: 14px;
  color: var(--colorTextPrimary);
  font-weight: 500;
}
