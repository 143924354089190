@import 'styles/core';

.paper {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #E7E9EE;
  border-radius: 4px;
  position: relative;

  & + .paper {
    margin-top: 24px;
  }

  @media only screen and (--tablet) {
    border: none;
  }
}

.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, .6);
  z-index: 2;
}

.circleLoader {
  height: 100%;
}

.circleLoaderIcon {
  width: 48px;
  height: 48px;
}
