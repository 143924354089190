@import 'styles/core';

.container {
  width: 100%;
  position: relative;
  min-height: 96px;
  margin-top: 48px;
  margin-bottom: 48px;
}

.scroll {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.fixJustify {
  justify-content: space-evenly;
}

.indicator {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 36px;
  height: 96px;
  text-align: center;
  justify-content: space-between;
}

.indicatorMargin {
  margin-left: 24px;
}

.title {
  font-weight: 400;
  font-size: 11px;
  color: var(--colorTextSecondary);
}

.icon {
  align-self: center;
}

.date {
  font-weight: 400;
  font-size: 11px;
  text-align: center;
  color: var(--colorTextPrimary);
}

.navigations {
  position: absolute;
  top: 36px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.button {
  width: 24px;
  height: 24px;
  background: var(--colorWhite);
  border: 1px solid var(--colorBorderCatskillWhite);
  box-sizing: border-box;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.02), 0 2px 4px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  padding-left: 8px;
  padding-top: 1px;
  user-select: none;
}

.disabled {
  background: #EEEEEE;
}

.scrollEmpty {
  justify-content: space-evenly;
}

.emptyTitle {
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: var(--colorTextPrimary);
}

.emptyText {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: var(--colorTextSecondary);
  width: 316px;
}
