@import 'styles/core';

.textWrapper {
  word-break: break-word;
  width: 100%;
}

.ellipsisButton {
  display: inline-block;
  height: 14px;
  width: 20px;
  padding: 0;

  background: #E7E9EE;
  color: #4A4A49;
  border-radius: 2px;
  border: none;
  cursor: pointer;

  &:hover {
    background: #E1E8F0;
  }
}


.ellipsisButtonOpened {
  margin-left: 12px;
}
