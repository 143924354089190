@import 'styles/core';

.settingsAlerting {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);

  @media (--phoneLandscape) {
    padding-bottom: 120px;
  }
}

.header {
  margin-bottom: 4px;
}

.subtitle {
  display: block;
  margin-bottom: 32px;
}

.alertsListContainer {
  margin-top: 16px;
}

.controlsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 552px;

  @media (--phoneLandscape) {
    flex-direction: column-reverse;
  }
}

.titleButton {
  @media (--phoneLandscape) {
    width: 100%;
    margin-bottom: 12px;
    justify-content: center;
  }
}
