@import 'styles/core';

.longSelectButton {
  width: 100%;
}

.longSelectPopup {
  width: 100%;
  text-align: left;
}

.option {
  padding: 6px 12px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
}

.speciesName {
  padding: 10px 12px 6px;
  line-height: 20px;
  font-size: 14px;
  color: var(--colorTextPrimary);
  font-weight: 500;
}

.popup {
  max-height: 210px;
  overflow-y: auto;
  text-align: left;
}

.titleWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.title {
  margin-right: 8px;
  font-size: 14px;
  color: var(--colorTextSecondary);
}
