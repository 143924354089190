@import 'styles/core';


.rangePickerContainer {
  display: flex;
  align-items: center;
  position: relative;

  @media (--tablet) {
    width: 100%;
  }
}

.selectPopup {
  min-width: 166px;
}

.desktop {
  display: flex;

  @media (--tablet) {
    display: none;
  }
}

.mobile {
  width: 100%;
  display: none;

  @media (--tablet) {
    display: block;
  }
}

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background-color: #ffffff;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  font-size: 20px;
  font-weight: 500;
  color: var(--colorTextPrimary);
  text-align: left;
  line-height: 24px;
}

.body {
  padding: 16px 16px 22px;
}

.selects {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;

  & > * + * {
    margin: 24px 0 0 0;
  }
}

.popupDateSelectIcon {
  width: 24px;
  height: 24px;
  top: 12px;
  bottom: 12px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin: 32px 0 0 0;

  & > * + * {
    margin: 0 0 0 10px;
  }
}

.close {
  cursor: pointer;
}


.field {
  display: flex;
  align-items: center;

  & + .field {
    margin: 0 0 0 8px;

    @media only screen and (--tablet) {
      margin: 0;
    }
  }

  &.period {
    @media only screen and (--tablet) {
      display: none;
    }
  }

}



.selectWrapper {
  margin: 0;
}

.selectButton {
  width: 144px;
  height: 32px;
}

.selectPopup {
  min-width: 144px;
}


.datePicker {
  margin: 0;
  display: flex;
  align-items: center;
  box-sizing: border-box;


  @media (--tablet) {
    width: 100%;
  }

  & :global {
    & .react-datepicker-wrapper {
      width: 100%;
    }

    & .react-datepicker__input-container {
      width: 100%;
    }
  }

  & .leftButton,
  & .rightButton {
    position: relative;
    display: flex;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: none;
    background: #FFFFFF;
    border: 1px solid #BDCFE1;
    border-radius: 4px;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
    outline: none;

    & svg {
      /* fix iOS safari svg bug https://stackoverflow.com/questions/4504942/mobile-safari-svg-problem */
      position: absolute;
      top: 9px;
    }

    &:hover svg path {
      fill: var(--colorTextSecondary);
    }
  }


  & .leftButton {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

  & .rightButton {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
  }

  & .disabled {
    opacity: 0.5;
  }
}
