@import 'styles/core';

.settingsMeters {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
}

.header {
  margin: 0;
  color: var(--colorTextPrimary);
}

.infoPlate {
  margin: 12px 0 24px;
}

.metersWrapper {
  margin-top: 16px;
  max-width: 720px;
}

.metersBlock {
  margin-bottom: 56px;
}

.checkboxIcon {
  margin: 0 auto;
  width: 16px;
  height: 16px;
  background: var(--colorWhite);
  border-radius: 2px;
  border: 1px solid var(--colorBorderSecondary);

  display: flex;
  align-items: center;
  justify-content: center;

  &.checkboxIconActive {
    background: var(--colorBorderSecondary);
  }

  & svg {
    fill: var(--colorWhite);
  }
}

.supportButton {
  display: inline-block;
  padding: 0;

  color: var(--colorPrimary);
  font-weight: 500;
}

.infoBlock {
  display: flex;
  justify-content: center;
  align-items: center;
}

.infoIcon {
  width: 16px;
  height: 16px;
  margin-left: 4px;

  & path {
    fill: var(--colorTextSecondary);
  }
}
