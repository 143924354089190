@import 'styles/core';

.layout {
  min-height: calc(100vh - 60px);
  overflow-x: auto;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 359px) {
    align-items: flex-start;
  }
}

.body {
  //display: flex;
  //flex-direction: column;
  //justify-content: flex-start;
  //align-items: center;
  min-width: 328px;
  width: 560px;
  box-sizing: content-box;

  padding: 0 16px;

  @media only screen and (--tablet) {
    width: 100%;
    box-sizing: border-box;
  }
}

.content {
  margin: 0 auto;
  padding: 16px 0 48px 0;

  @media only screen and (--tablet) {
    padding: 16px 0 120px 0;
  }
}

.controls {
  padding: 32px 0 0 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
}

.back {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;

  cursor: pointer;
}

.backIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.backText {
  line-height: 18px;
  margin: 0 0 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  color: var(--colorTextSecondary);
  letter-spacing: -0.08px;
}
