@import 'styles/core';

.cropsSelectBlock {

}

.selectWrapper {

}

.selectButton {
  width: 192px;
  height: 28px;
}

.selectPopup {
  min-width: 192px;
  right: 0;
  left: auto;
}

.selectLine {
  padding-left: 2px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  & .lineTitle {
    font-size: 14px;
    line-height: 28px;
    color: var(--colorTextPrimary);
  }
}

.rangePicker {
  width: 192px;
}

.dateButton {
  width: 192px;
  height: 28px;
  border-radius: 4px;
  font-size: 14px;

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 142px;
  }
}

.headerTitle {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--colorTextPrimary);
}
