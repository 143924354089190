@import 'styles/core';

.layout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 196px;

  @media (max-width: 960px) {
    flex-direction: column;
    margin-top: 0;
    align-items: center;
  }
}

.benefits {
  width: 448px;
  min-width: 448px;
  padding-left: 64px;
  padding-right: 64px;

  @media (max-width: 960px) {
    margin-left: -110px;
  }

  @media (max-width: 480px) {
    margin-left: 0;
  }

  & .label {
    width: 140px;
    height: 22px;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    border-radius: 4px;
    color: var(--colorPrimary);
    background: var(--colorLabel);
    border: 1px solid var(--colorPrimary);
    box-sizing: border-box;
    padding: 2px 8px 2px 8px;
  }

  & .header {
    margin-top: 14px;
    margin-bottom: 14px;
  }

  & .caption {
    font-size: 14px;
    line-height: 18px;
  }

  & .benefitsList {
    padding-top: 8px;
    font-size: 14px;
    line-height: 32px;
    color: #4A4A49;

    padding-left: 18px;
    list-style-image: url('./assets/list-marker.svg');
    margin-bottom: 24px;

    & li {
      padding-left: 6px;
    }
  }

  & .buttons {
    display: flex;

    @media (max-width: 960px) {
      display: none;
    }

    & .button {
      padding: 8px 16px;
      height: 36px;
      box-sizing: border-box;
      border-radius: 4px;
    }

    & .leanMore {
      margin-right: 8px;
    }

    & .contact {
      border: none;
    }
  }
}

.bigButtons {
  display: none;

  @media (max-width: 960px) {
    display: flex;
    flex-direction: column;
    min-width: 328px;
    width: 448px;
    max-width: 448px;

    & .bigButton {
      padding: 8px 16px;
      height: 36px;
      box-sizing: border-box;
      border-radius: 4px;
      display:block;
      text-align: center;
    }

    & .leanMore {
      margin-top: 8px;
    }

    & .contact {
      border: none;
    }
  }

  @media (max-width: 480px) {
    width: 328px;
  }
}

.slider {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 18px;
  padding-bottom: 12px;
  user-select: none;

  @media (max-width: 960px) {
    align-items: center;
  }

  & .image {
    max-width: 528px;
    min-width: 328px;
    width: 100%;
    height: auto;
    box-shadow: 0 0 18px rgba(0.8, 0.8, 0.8, 0.05);
    border-radius: 4px;
    @media (max-width: 960px) {
      width: 448px;
      height: auto;
    }

    @media (max-width: 480px) {
      width: 328px;
      height: auto;
    }
  }

  & .sliderButtons {
    display: flex;
    align-items: center;
    margin-top: 12px;

    @media (max-width: 960px) {
      justify-content: space-between;
      width: 100%;
    }

    & .sliderLabel {
      padding-right: 10px;
      font-size: 13px;
      line-height: 18px;
      color: var(--colorSliderLabel);

      @media (max-width: 960px) {
        display: none;
      }
    }

    & .sliderLabelMobile {
      display: none;
      @media (max-width: 960px) {
        display: block;
      }
    }

    & .sliderButton {
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        background-color: var(--colorBorderCatskillWhite);
      }
    }
  }
}


