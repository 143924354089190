@import 'styles/core';

.horizontalBarRow {
  position: absolute;

  transition: background 150ms ease-out;

  &.isHovered {
    background: rgba(225, 232, 240, 0.75);
  }
}

.horizontalBarWrapper {
  position: absolute;

  & .horizontalBarTooltip {
    display: none;
  }

  &:hover {
    z-index: 1;

    & .horizontalBar {
      opacity: 0.75;
    }

    & .horizontalBarTooltip {
      display: block;
    }
  }
}

.horizontalBar {
  position: absolute;

  border-radius: 2px;

  &.defaultColor {
    background: var(--colorBenchmarkPrimary);

    &:hover {
      background: var(--colorBenchmarkPrimaryHovered);
    }
  }
}
