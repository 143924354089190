@import 'styles/core';

.tableWrapper {
  overflow: auto;
  border: solid 1px var(--colorBorderDefault);
  border-radius: 4px;
}

.tableWrapperEmpty {
  border-radius: 4px 4px 0 0!important;
  border-bottom: none!important;
}


.tableScrollerDisable {
  overflow: hidden!important;
}

.emptyState {
  text-align: center!important;
  padding: 32px!important;
  border: solid 1px var(--colorBorderDefault);
  border-radius: 0 0 4px 4px;
  border-top: none;
  font-size: 13px;
  line-height: 18px;
  background-color: #ffffff;
  color: var(--colorTextPrimary);
}

.table {
  border-radius: 0;
  border: none;
}

th.chartIconWrapper,
td.chartIconWrapper {
  width: 36px;
}

th.chartIconWrapper {
  background-color: var(--colorThDefault);
}

.chartIconWrapper {
  cursor: pointer;

  & svg {
    fill: #9EA4A8;
  }
}

.chartIconContainer {
  width: 20px;
  height: 20px;

  height: 100%;
  display: flex;
  align-items: center;
}

.tdValue {
  white-space: nowrap;
}

td.totalCell {
  padding-left: 40px;
}


.activeTh {
  cursor: pointer;
  background-color: var(--colorThDefault);

  &:hover {
    background-color: #e8ebed;
  }
}

.arrow {
  width: 24px;
  height: 24px;
  display: none;
  transform: rotate(180deg);

  &.sortDirectionDown {
    transform: rotate(0deg);
  }
}

.activeSort {
  & .arrow {
    display: block;
  }
}

.thContent {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 24px;
  white-space: nowrap;
}

.thLeft {
  text-align: left;

  & .thContent {
    justify-content: flex-start;
  }
}

.tdValue {
  white-space: nowrap;
}

.fixedColumn {
  @media (--tabletLandscapeUp) {
    position: relative;
    overflow-y: auto;
    padding-left: 332px;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 332px;
      bottom: 0;
      width: 4px;
      background: rgba(30, 42, 51, 0.05);
    }

    & .tableScroller {
      overflow-x: auto;
      overflow-y: visible;
    }

    & .tableScrollerDisable {
      overflow: hidden!important;
    }

    & .table {
      & td:nth-child(1),
      & th:nth-child(1),
      & td:nth-child(2),
      & th:nth-child(2) {
        position: absolute;
        top: auto;
      }

      & td:nth-child(1),
      & th:nth-child(1) {
        left: 0;
      }

      & th:nth-child(1),
      & td:nth-child(1) {
        width: 36px;
      }

      & td:nth-child(2),
      & th:nth-child(2) {
        left: 36px;

        border-right: 1px solid var(--colorBorderGray);
      }

      & th:nth-child(2),
      & td:nth-child(2) {
        width: 296px;
      }

      & th:nth-child(1),
      & th:nth-child(2) {
        height: 44px;
      }

      & td:nth-child(1),
      & td:nth-child(2) {
        height: 36px;
      }
    }
  }

  &.sizeSmall {
    & .table {
      & th:nth-child(1),
      & th:nth-child(2) {
        height: 38px;
      }

      & td:nth-child(1),
      & td:nth-child(2) {
        height: 28px;
      }
    }
  }
}
