@import 'styles/core';

.settingsPermission {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
}

.header2,
.header3 {
  margin: 0;
  font-size: 20px;
  line-height: 28px;
  color: var(--colorTextPrimary);
  font-weight: 500;
}

.header3 {
  font-size: 16px;
  line-height: 20px;
}

.infoPlate {
  margin: 12px 0 24px;
}

.tableWrapper {
  padding-top: 16px;

  th {
    white-space: nowrap;
  }

  & .nameColumn {
    width: 10%;
    max-width: 384px;
  }

  @media (--tabletBig) {
    & .nameColumn {
      max-width: 264px;
    }
  }

  & .greenhouseName {
    text-align:left;
    max-width: 173px;
    min-width: 173px;
    width: 173px;
    position: relative;
  }
}

.checkboxIcon {
  margin: 0 auto;
  width: 16px;
  height: 16px;
  background: var(--colorWhite);
  border-radius: 2px;
  border: 1px solid var(--colorBorderSecondary);

  display: flex;
  align-items: center;
  justify-content: center;

  &.checkboxIconActive {
    background: var(--colorBorderSecondary);
  }

  & svg path {
    fill: var(--colorWhite);
  }
}

.supportButton {
  display: inline-block;
  padding: 0;

  color: var(--colorPrimary);
  font-weight: 500;
}

.infoBlock {
  display: flex;
  justify-content: center;
  align-items: center;
}

.infoIcon {
  width: 16px;
  height: 16px;
  margin-left: 4px;

  & path {
    fill: var(--colorTextSecondary);
  }
}

.compartmentsLink {
  cursor: pointer;
  padding-top:4px;
  padding-bottom:4px;
  border-bottom: 1px dashed var(--colorTextNeutral);
}

.userName {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.userInfo {
  font-size: 13px;
  line-height: 28px;
  color:var(--colorTextSecondary);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tooltipStyle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  width: 216px;
  height: 202px;
  padding: 12px 2px 12px 12px;
  background: #FFFFFF;
  border: 1px solid #E1E8F0;
  border-radius: 4px;
  pointer-events: all;
  z-index: 100;
  overflow-y: auto;

  & .tooltipTitle {
    font-weight: 600;
    font-size: 13px;
    color: var(--colorTextPrimary);
    padding-bottom: 4px;
  }

  & .greenhouse {
    font-weight: normal;
    font-size: 13px;
    padding-top: 8px;
  }

  margin-bottom: 20px;
}

.userNameText,
.twoFaBage {
  display: inline-block;
}

.twoFaBage {
  margin-left: 8px;
  line-height: 18px;
  font-weight: 500;
  font-size: 13px;
  border-radius: 4px;
  padding: 0px 4px;
  background-color: var(--colorBageBackground);
  color: var(--colorPrimary);
}
