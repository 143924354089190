@import 'styles/core';


.progressBar {
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: #e7e9ee;
  overflow: hidden;
}

.progressBarLine {
  height: 100%;
  background-color: var(--colorPrimary);

  &.green {
    background-color: var(--colorPositive);
  }

  &.yellow {
    background-color: var(--colorWarning);
  }

  &.red {
    background-color: var(--colorNegative);
  }
}
