@import 'styles/core';

.paper {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #E7E7E7;
  border-radius: 4px;

  & + .paper {
    margin-top: 24px;
  }
}

.dialogButton {
  border-radius: 10px;
  width: 49%;
  max-width: 188px;
  height: 40px;
  outline: none;
  border: none;
  font-weight: bold;
  font-size: 18px;
  color: #FFFFFF;
  letter-spacing: 0.75px;
  cursor: pointer;

  & + .dialogButton {
    margin-left: 2%;
  }
}

.inputText {
  border-radius: 10px;
  background-color: #ffffff;
  height: 40px;
  width: 100%;
  outline: none;
  border: 1px solid #BDCFE1;
  padding: 10px 16px;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.75px;
  color: var(--colorTextSecondary);

  &::placeholder {
    color: #BDCFE1;
  }
}

.graphs {
  height: calc(100vh - 60px);
  overflow: hidden;
  position: relative;

  & .graphsContainer {
    overflow: auto;
    height: calc(100vh - 60px);
    width: 100%;
    transition: width 150ms ease-out;
  }

  & .layout {
    min-width: 1256px;
    width: 100%;
    min-height: calc(100vh - 60px);
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 24px 24px 48px 24px;
    background-color: #F7F9FA;
  }

  & .marginRight {
    width: calc(100vw - 480px);

    @media (--phoneLandscape) {
      width: 100%;
    }
  }

  & .dateSelect {
    width: 30%;
  }

  & .graphsPresets {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 32px;
    flex-wrap: wrap;
  }

  & .graphsPresetsWrapper {
    display: flex;
    width: 25%;
    min-width: 256px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    box-sizing: border-box;

    &:first-of-type {
      padding-right: 10px;
    }

    &:last-of-type {
      padding-left: 10px;
    }

    @media only screen and (max-width: 1020px) {
      width: 100%;

      &:first-of-type {
        padding-right: 0;
      }

      &:last-of-type {
        padding-left: 0;
        margin-top: 20px;
      }
    }

    @media only screen and (max-width: 640px) {
      &:first-of-type {
      }

      &:last-of-type {
        flex-wrap: wrap;
        margin-top: 0;
      }
    }
  }

  & .graphsPresetsParametersWrapper {
    display: flex;
    width: 72%;
    min-width: 256px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    box-sizing: border-box;

    &:first-of-type {
      padding-right: 10px;
    }

    &:last-of-type {
      padding-left: 10px;
    }

    @media only screen and (max-width: 1020px) {
      width: 100%;

      &:first-of-type {
        padding-right: 0;
      }

      &:last-of-type {
        padding-left: 0;
        margin-top: 20px;
      }
    }

    @media only screen and (max-width: 640px) {
      &:first-of-type {
      }

      &:last-of-type {
        flex-wrap: wrap;
        margin-top: 0;
      }
    }
  }

  & .graphPresetsButtons {
    display: flex;
    flex-flow: row;
    align-items: center;
    flex-wrap: nowrap;
  }

  @media only screen and (max-width: 640px) {
    & .graphPresetsButtons {
      margin-top: 0;
    }

    & .graphPresetsButtons:first-of-type {
      margin-top: 20px;
    }
  }

  & .graphsPresetsWrapperRight {
    justify-content: flex-end;
    margin-left: 24px;

    & .linkedButton + .linkedButton {
      margin-left: 40px;
    }
  }

  @media only screen and (max-width: 640px) {
    & .graphsPresetsWrapperRight {
      margin-left: 0;
      justify-content: space-between;
      flex-wrap: wrap;

      & .linkedButton {
        margin-top: 20px;
      }

      & .linkedButton + .linkedButton {
        margin-left: 0;
      }

      & .linkedButton:first-of-type {
        margin-right: 10px;
      }
    }
  }

  & .linkedButton {
    & + .linkedButton {
      margin-left: 20px;
    }
  }

  & .presetsSelect {
    min-width: 100%;
  }

  & .compartmentSelect {
    margin-right: 40px;
    min-width: 200px;
  }

  & .wrapperGraphPresetDialog {
    width: 480px;

    @media only screen and (max-width: 640px) {
      width: 100%;
    }
  }

  & .graphPresetDialogContent {
    width: 100%;
  }

  & .graphPresetDialogText {
    color: var(--colorTextSecondary);
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }

  & .graphPresetDialogTextMedium {
    font-weight: 500;
    font-size: 14px;
    color: #000000;
  }

  & .graphPresetDialogRadioButtons {
    margin-top: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .graphPresetDialogButtons {
    margin-top: 24px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .noButton {
    margin-right: 8px;
  }

  .yesButton {
    color: #FFFFFF;
    background: #BE1034;

    &:hover {
      color: #FFFFFF;
      background: #BE1034;
    }
  }


  & .graphPresetDialogCancel {
    background-color: var(--colorTextNeutral);
  }

  & .graphPresetDialogOk {
  }

  & .wrapperGraphPresetDeleteDialog {
    width: 480px;

    @media only screen and (max-width: 640px) {
      width: 100%;
    }

    & .graphPresetDeleteDialogContent {
      width: 100%;
    }

    & .graphPresetDeleteDialogText {
      color: var(--colorTextSecondary);
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
    }

    & .graphPresetDeleteDialogTextMedium {
      font-weight: 500;
      font-size: 14px;
      color: #000000;
    }

    & .graphPresetDeleteDialogRadioButtons {
      margin-top: 60px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    & .graphPresetDeleteDialogButtons {
      margin-top: 60px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    & .graphPresetDeleteDialogCancel {
      background-color: var(--colorTextNeutral);
    }

    & .graphPresetDeleteDialogOk {
      background-color: var(--colorNegative);
    }
  }

  & .wrapperGraphPresetUpdateDialog {
    width: 480px;

    @media only screen and (max-width: 640px) {
      width: 100%;
    }

    & .graphPresetUpdateDialogContent {
      width: 100%;
    }

    & .graphPresetUpdateDialogForm {
      color: var(--colorTextSecondary);
      font-size: 14px;
      line-height: 20px;
    }

    & .graphPresetUpdateDialogButtons {
      margin-top: 40px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    & .graphPresetUpdateDialogDelete {
      font-weight: 900;
      font-size: 16px;
      color: var(--colorNegative);
      border: none;
      outline: none;
      background: none;
      display: flex;
      align-items: center;
    }

    & .graphPresetUpdateDialogDeleteIcon {
      margin-right: 5px;
    }

    & .graphPresetUpdateDialogOk {
    }
  }

  & .wrapperGraphPresetCreateDialog {
    width: 480px;

    @media only screen and (max-width: 640px) {
      width: 100%;
    }

    & .graphPresetCreateDialogContent {
      width: 100%;
    }

    & .graphPresetCreateDialogForm {
      color: var(--colorTextSecondary);
      font-size: 14px;
      line-height: 20px;
    }

    & .graphPresetCreateDialogRadioButtons {
      display: flex;
      margin-top: 30px;

      & > * + * {
        margin-left: 85px;
      }
    }

    & .graphPresetCreateDialogButtons {
      margin-top: 30px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  & .wrapperGraphPresetDatesDialog {
    width: 480px;

    @media only screen and (max-width: 640px) {
      width: 100%;
    }

    & .graphPresetDatesDialogContent {
      width: 100%;
    }

    & .graphPresetDatesDialogForm {
      color: var(--colorTextSecondary);
      font-size: 14px;
      line-height: 20px;
    }

    & .graphPresetDatesDialogForm {
      display: flex;
      flex-flow: column;
    }

    & .graphPresetDatesDialogFormRow {
      display: flex;
      flex-flow: row;

      & + .graphPresetDatesDialogFormRow {
        margin-top: 22px;
      }
    }

    & .graphPresetDatesDialogFormColumn {
      display: flex;
      flex-flow: column;
      width: 50%;
    }

    & .graphPresetDatesDialogFormColumnSingle {
      display: flex;
      flex-flow: row;

    }

    & .radioButton {
      & + .radioButton {
        margin-top: 22px;
      }
    }

    & .graphPresetDatesDialogButtons {
      margin-top: 30px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  & .wrapperGraphPresetParametersDialog {
    width: 900px;
    flex-shrink: 0;

    @media only screen and (max-width: 640px) {
      width: 100%;
    }

    & .graphPresetParametersDialogContent {
      width: 100%;
    }

    & .graphPresetParametersDialogForm {
      color: var(--colorTextSecondary);
      font-size: 14px;
      line-height: 20px;
    }

    & .graphPresetParametersDialogForm {
      display: flex;
      flex-flow: column;
    }

    & .graphPresetParametersDialogFormRow {
      display: flex;
      flex-flow: row;

      @media only screen and (max-width: 640px) {
        flex-flow: column;
      }

      &.graphPresetParametersDialogFormRowMinMargin {
        margin-top: 8px;
      }

      & + .graphPresetParametersDialogFormRow {
        margin-top: 20px;
      }
    }

    & .graphPresetParametersDialogFormColumn {
      display: flex;
      flex-flow: column;
      width: 50%;
    }

    & .graphPresetParametersDialogButtons {
      margin-top: 30px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    & .graphPresetParametersDialogFormFlex {
      display: flex;
      flex-wrap: wrap;
      padding: 0 16px;


      @media only screen and (max-width: 640px) {
        flex-flow: column;
        flex-wrap: nowrap;
      }
    }

    & .graphPresetParametersDialogFormFlexCenter {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      padding: 0 16px;
      align-items: center;
      justify-content: center;
    }
  }
}

.datePicker {
  margin-left: 10px;

  & svg {
    margin-left: 17px;
  }

  &.disabled {
    border: 1px solid rgba(189, 207, 225, .4);
    color: rgba(189, 207, 225, .4);

    & svg {
      opacity: .4;
    }
  }
}

.select {
  min-width: 120px;
  flex-grow: 1;

  & + .select {
    margin-left: 50px;

    @media only screen and (max-width: 640px) {
      margin-left: 0;
      margin-top: 20px;
    }
  }

  & :global {
    & .Select-control {
      cursor: pointer;
      border: 1px solid #BDCFE1;
      border-radius: 10px;
      height: 40px;
    }

    & .Select-arrow-compartment {
      padding-right: 16px;
    }

    & .Select-arrow-zone {
      padding-right: 16px;
    }

    & .Select--single.is-focused > .Select-control {
      border: 1px solid var(--colorPrimary);
      box-shadow: 0 0 1px 1px rgba(67, 179, 226, 1);
    }

    & .Select-placeholder {
      color: var(--colorPrimary);
    }

    & .Select-placeholder,
    & .Select--single > .Select-control .Select-value {
      color: var(--colorTextSecondary);
      line-height: 40px;
      padding-left: 16px;
    }
    & .has-value.Select--single > .Select-control .Select-value,
    & .has-value.is-pseudo-focused.Select--single > .Select-control .Select-value {
      & .Select-value-label {
        color: var(--colorTextSecondary);
      }
      & a.Select-value-label {
        &:hover,
        &:focus {
          color: var(--colorTextSecondary);
        }
      }
    }
    & .has-value.Select--single > .Select-control .Select-value .Select-value-label {
      color: var(--colorTextSecondary);
    }

    & .is-open.Select--single {
      & > .Select-control {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }

      & > .Select-menu-outer {
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      & > .Select-menu-outer > .Select-menu {
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;

        & > .Select-option {
          border-bottom: 1px solid #F1F3F6;
          font-size: 16px;
          color: var(--colorTextSecondary);

          &.is-focused {
            background-color: #FCFBEB;
          }
        }
      }
    }
  }
}

.checkbox {
  box-sizing: border-box;
  width: 33%;
  padding-right: 20px;
  margin-top: 18px;


  @media only screen and (max-width: 640px) {
    width: 100%;
  }
}


.emptyGraphsPresetParametersDialogTableWrapper {
  display: flex;
  justify-content: center;
}

.emptyGraphsPresetParametersDialogTableTitle {
  max-width: 540px;
  height: 100px;
  background-color: #FAFBFC;
  border-radius: 10px;
  margin-top: 20px;
  padding: 0 104px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: var(--colorTextSecondary);
  line-height: 20px;
  white-space: pre-wrap;
  box-sizing: border-box;

}

@media only screen and (max-width: 1020px) {
  .graphs {
    & .graphsPresets {
      flex-flow: column;
      align-items: flex-start;
      padding: 20px 16px;
    }
  }

}

@media only screen and (max-width: 640px) {
  .graphs {
    & .graphsPresets {
      padding: 20px 10px;
    }

    & .dateSelect {
      width: 100%;
    }
  }
  .emptyGraphsPresetParametersDialogTableTitle {
    padding: 0 24px;
  }
}

.fullPaperCircleLoader {
  width: 48px;
  height: 48px;
}
