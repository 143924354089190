@import 'styles/core';

.layout {
  min-height: calc(100vh - 60px);
  background-color: #FAFBFC;

  /* @media (--tablet) {
    min-width: initial;
  } */
}

.contentWrapper {
  max-width: 1232px;
  margin: 0 auto;
  padding: 0 24px;

  @media (--tablet) {
    padding: 0;
  }
}

.desktopContainer {
  display: flex;
  padding: 8px 0 24px;

  @media (--tablet) {
    display: none;
  }
}

.mobileContainer {
  display: none;
  padding: 0 16px 16px;
  flex-wrap: wrap;

  @media (--tablet) {
    display: flex;
  }
}

.filterItem {
  padding: 2px 8px;
  margin-right: 8px;
  background: #E6EBF1;
  color: var(--colorTextSecondary);
  border-radius: 4px;
  line-height: 20px;
  font-size: 14px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.kpiDashboardHeader {
  padding: 30px 0 24px;

  @media (--tablet) {
    flex-wrap: wrap;
    padding: 24px 0 24px 16px;
  }
}

.groupWrapper {
  padding-top: 8px;
}

.groupHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (--phoneLandscape) {
    display: block;
    margin-bottom: 24px;
  }
}

.groupFilter {
  @media (--phoneLandscape) {
    padding-left: 16px;
  }
}

.unitsFilter {
  margin-left: 12px;
}

.unitsFilterButton {
  min-width: 32px;
}

.tooltipRed {
  background: var(--colorNegative);
}

.tooltipOrange {
  background: var(--colorWarning);
}

.tooltipGreen {
  background: var(--colorPositive);
}

.tooltip {
  &:global(.place-top) {
    @media (--tablet) {
      margin-left: 80px !important;
      margin-right: 10px !important;

      &::before,
      &::after {
        margin-left: -90px !important;
      }
    }
  }
}