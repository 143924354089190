@import 'styles/core';


.wrapper {
  width: 100%;
}

.option {
  position: relative;
  padding: 6px 8px 6px 12px;
  color: var(--colorTextPrimary);
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
  outline: none;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: #F7F9FA;
  }

  &.selected {
    background-color: #F7F9FA;
  }
}

.popup {
  position: absolute;
  top: -16px;
  width: 280px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #E1E8F0;
  box-sizing: border-box;
  box-shadow: 0 8px 24px 0 rgba(0,0,0,0.02), 0 2px 4px 0 rgba(0,0,0,0.04);
  z-index: 100;
  padding: 16px 12px;
  -webkit-overflow-scrolling: touch;

  &.left {
    right: calc(100% - 4px);
  }

  &.right {
    left: calc(100% - 4px);
  }
}

.arrowRightIcon {
  width: 20px;
  height: 20px;
}
