@import 'styles/core';

.warningContent {
  position: absolute;
  top: 0;
  left: calc(100% + 8px);
  pointer-events: none;
}

.warningPlate {
  background-color: var(--colorWhite);
  min-width: 216px;
}

.alignLeft {
  & .warningContent {
    left: initial;
    right: calc(100% + 8px);
  }
}

.alignBottom {
  & .warningContent {
    top: initial;
    bottom: -28px;
  }
}

.alignTop {
  & .warningContent {
    top: 0;
  }
}
