@import 'styles/core';

.userInformationForm {
  max-width: 336px;

  @media (--phoneLandscape) {
    max-width: 100%;
  }
}

.userInfoInput {
  margin-bottom: 16px;
}

.buttons {
  display: flex;
  align-items: center;

  padding-top: 4px;
}

.cancelButton {
  margin-left: 8px;
}
