@import 'styles/core';

.layout {
  min-height: calc(100vh - 60px);
  background-color: var(--colorBackgroundPrimary);
}

.row {
  display: flex;
  align-items: center;
}

.header {
  padding: 0 24px;
  margin: 0 0 32px 0;

  @media (--tabletBig) {
    padding: 0 16px;
  }
}

.contentWrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 0 192px;
}

.content {
  position: relative;
  min-height: calc(100vh - 285px);
  background-color: var(--colorWhite);
  border: 1px solid #E7E9EE;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0 24px;

  @media (--tabletBig) {
    margin: 0 16px;
  }

  @media (--tablet) {
    margin: 0;
    border-radius: 0;
    border-left: none;
    border-right: none;
  }
}


.controls {
  padding: 16px 0 0 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
}

.backIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.backText {
  line-height: 18px;
  margin: 0 0 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  color: var(--colorTextSecondary);
  letter-spacing: -0.08px;
}

.titleGroup {
  margin: 16px 0 0 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (--tablet) {
    margin-top: 8px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.titleButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (--tablet) {
    margin-top: 8px;
  }
}

.titleButton {
  margin-left: 16px;

  @media (--tablet) {
    &:first-child {
      margin-left: 0;
      padding-left: 0;
    }
  }
}

.title {
  margin: 0;
}

.headerIcon {
  margin-left: 4px;
  color: var(--colorTextSecondary);
  fill: var(--colorTextSecondary);
  cursor: help;

  &:hover {
    fill: var(--colorTextPrimary);
  }
}

.iconButton {
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
  background: none;
  cursor: pointer;

  & svg path {
    fill: var(--colorTextSecondary);
  }

  &:hover {
    & svg path {
      fill: var(--colorTextPrimary);
    }
  }

  & .dropdownItem {
    width: 100%;

    & > div {
      margin-left: 0;
    }
  }
}


.marginRight {
  margin-right: 16px;
}

.harvestSection {
  margin: 0 0 12px 0;
}

.harvestSectionWrapper {
  padding-bottom: 0;
}

.metricsSectionWrapper {
  padding-top: 18px;
}

.growingDetailsSection {
  padding-bottom: 16px;
  padding-top: 4px;
}

.growingDetailsSectionWrapper {
  padding-bottom: 46px;
}

.analysisSection {
  padding-bottom: 10px;
}

.tooltip {
  text-align: left;
  max-width: 230px;

  @media (--tablet) {
    max-width: 164px;
  }
}

.backButton {
  margin-top: 16px;
}

.emptyState {
  width: 100%;
  min-height: calc(100vh - 285px);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.emptyStateInfo {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.emptyStateTitle {
  font-size: 16px;
  color: var(--colorTextPrimary);
  line-height: 20px;
  margin-top: 20px;
  font-weight: 500;
}

.emptyStateDescription {
  font-size: 14px;
  color: var(--colorTextSecondary);
  line-height: 20px;
  margin-top: 4px;
  text-align: center;
}

.emptyStateButton {
  outline: none;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  color: var(--colorTextSecondary);
  padding: 0;
  margin: 0;
  cursor: pointer;
  text-transform: lowercase;
}

.compareIcon {
  width: 78px;
  height: 78px;

  & path {
    fill: var(--colorPrimary);
  }
}
