@import 'styles/core';

.layout {
 /* min-width: 960px; */
  min-height: calc(100vh - 60px);
  background-color: var(--colorBackgroundPrimary);
}

.contentWrapper {
  margin: 0 auto;
  padding: 0 24px 48px 24px;
}

.pricePlan {
  margin-top: 86px;
  @media (max-width: 960px) {
    margin-top: 0;
  }
}
