@import 'styles/core';

.container {
  padding: 24px;
  position: relative;
}

.paper {
  min-height: calc(100vh - 60px - 48px);
  display: flex;
  flex-direction: column;
  padding: 0;
}

.controlsWrapper {
  display: flex;
  border-bottom: 1px solid #E7E9EE;
  min-height: 64px;
  flex-direction: column;
}

.inputs {
  padding: 16px 24px 0 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (--desktopSmall) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  @media (--tablet) {
    flex-direction: row-reverse;
  }
}

.filter {
  display: flex;
  flex-direction: column;
  @media (--desktopSmall) {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.filterPlaceholders {
  padding: 24px;
}

.filterInfo {
  display: inline-block;
  background-color: #E7E9EE;
  color: #777776;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;
  padding: 2px 8px 2px 8px;
  margin: 4px;
}

.mobileFilter {
  border: none;
}

.timelineWrapper {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  height: auto;
  object-fit: contain;
}


.historyButtons {
  position: absolute;
  top: 170px;
  right: 60px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  width: 28px;
  height: 73px;
  background: rgba(30,42,51,0.85);
  border-radius: 4px;
  overflow: hidden;
  z-index: 997;
}

.historyButton {
  width: 28px;
  height: 36px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 8px 4px;
}

.historyButtonsDelimeter {
  width: 20px;
  height: 1px;
  background: rgba(30,42,51,0.30);
  border-radius: 1px;
}

.compartmentFilterWrapper {
  margin-right: 0;
}

.popupClassName {
  width: auto;
}

.dateButtonClassName {
  white-space: nowrap;
  min-width: 196px;
}

.addIncidentsButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 208px;
  position: absolute;
  bottom: 40px;
  left: 40px;
  background: white;
  user-select: none;
}

.canvasWrapper {
  position: relative;
}

.canvas {
  position: absolute;
}

.dropdownContentClassName {
  @media (--desktopSmall) {
    right: initial;
    left: 0;
  }
}

