@import 'styles/core';

.configuration {
  margin-bottom: 48px;
}

.configurationHeader {
  margin-bottom: 16px;
}

.configurationText {}

.incidentLogic {
  & :global {
    & .rule_table_wrapper {
      display: table;
      border: 1px solid var(--colorBorderCatskillWhite);
      border-radius: 4px;
      overflow: auto;
    }

    & .rule_table,
    & .rule_table table,
    & .rule_table th,
    & .rule_table td {
      border-collapse: collapse;
      border-spacing: 0;
      border: 1px solid var(--colorBorderCatskillWhite);
      text-align: center;
      color: var(--colorTextPrimary);
    }

    & .rule_table {
      /* border: 1px solid var(--colorBorderCatskillWhite); */
      border-style: hidden;
    }

    & .rule_table th,
    & .rule_table td {
      padding: 8px;
    }

    & .rule_table th {
      font-size: 14px;
      font-weight: 500;
      background-color: var(--colorThDefault);
      color: var(--colorTextSecondary);
    }

    & .rule_table tr {
      font-size: 13px;
    }

    & .rule_table .data_table {
      margin-top: 5px;
      margin-left:auto;
      margin-right:auto;
    }

    & var {
      font-style: normal;
    }

    & .rule_data_table {
      margin: 0 auto;
      margin-top: 12px;
    }

    & .top_description {
      color: var(--colorTextPrimary);
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin-top: 32px;
      margin-bottom: 16px;
    }

    & .bottom_description {
      margin-top: 16px;
      color: var(--colorTextPrimary);
      font-size: 14px;
      line-height: 20px;
    }
  }
}
