@import 'styles/core';

.lineY {
  stroke: #A1B1C2;
  stroke-width: 1px;
}

.lineX {
  stroke: #A1B1C2;
  stroke-width: 1px;
}

.labelY {
  fill: #4A4A49;
}

.customDataPoint {
  fill: #A1B1C2;
}

.yAxis {
  & path {
    opacity: 0;
  }
  & g:first-of-type {
    stroke-dasharray: none;
    stroke: #849EB8;
    stroke-width: 1px;
  }
}

.emptyState {
  position: absolute;
  top: -35px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  text-align: center;
  letter-spacing: -0.154px;

  color: var(--colorTextSecondary);
}

.testBlock {
  background-color: red;
  height: 200px;
}
