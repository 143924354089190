@import 'styles/core';

.circleLoader {
  width: 48px;
  height: 48px;
}

.wrapperDeleteDialog {
  width: 480px;
}

.photoDeleteDialogContent {
  width: 100%;
}

.photoDeleteDialogText {
  color: var(--colorTextSecondary);
  font-size: 14px;
  line-height: 20px;
}

.photoDeleteDialogTextMedium {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}

.photoDeleteDialogButtons {
  margin-top: 62px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.photoDeleteDialogButton {
  border-radius: 10px;
  width: 49%;
  max-width: 188px;
  height: 40px;
  outline: none;
  border: none;
  font-weight: bold;
  font-size: 18px;
  color: #FFFFFF;
  letter-spacing: 0.75px;
  cursor: pointer;

  & + .photoDeleteDialogButton {
    margin-left: 2%;
  }
}

.photoDeleteDialogCancel {
  composes: photoDeleteDialogButton;
  background-color: var(--colorTextNeutral);

}

.photoDeleteDialogOk {
  composes: photoDeleteDialogButton;
  background-color: var(--colorNegative);
}
