@import 'styles/core';

.valueEditorWrapper {
  position: relative;

  & .valueEditor {
    height: 40px;
    width: 70px;
    background: #FFFFFF;
    border: 1px solid #BDCFE1;
    border-radius: 10px;
    font-size: 16px;
    color: var(--colorTextSecondary);
    padding: 10px 10px;
    outline: none;
    text-align: center;

    &::placeholder {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.54);
      line-height: 20px;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }

    &.invalid {
      border-color: var(--colorNegative);
    }
  }

  & .valueErrorIcon {
    display: none;
  }

  & .valueEditor.invalid + .valueErrorIcon {
    display: block;
    width: 14px;
    height: 14px;
    position: absolute;
    top: -7px;
    right: -7px;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"><g fill="none" fill-rule="evenodd"><rect width="14" height="14" fill="var(--colorNegative)" rx="5"/><path fill="#FFF" d="M9.2572 10.7617l-1.674-1.674c-.3318-.3317-.688-.3364-1.0176-.0066L4.885 10.7618c-.332.332-.8634.331-1.1918.0025l-.3153-.3152c-.3327-.3326-.3274-.862.0024-1.1918L5.061 7.5766c.332-.332.2717-.7394-.058-1.0692L3.3803 4.885c-.3318-.332-.331-.8634-.0025-1.1918l.3152-.3153c.3325-.3327.862-.3274 1.1917.0024L6.5073 5.003c.3318.3317.795.3322 1.1248.0024l1.625-1.625c.332-.3318.8634-.331 1.1918-.0025l.3152.3152c.3326.3325.3273.862-.0025 1.1917L9.1367 6.51c-.3318.3318-.3787.7435-.049 1.0733l1.674 1.674c.332.3318.331.8633.0025 1.1917l-.3152.3152c-.3326.3326-.862.3273-1.1918-.0025z"/></g></svg>') center no-repeat;
  }
}
