@import 'styles/core';

.link {
  display: block;
  color: var(--colorPrimary);
  text-decoration: none;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  padding: 0;

  &:hover {
    text-decoration: underline;
  }
}
