@import 'styles/core';

.verificationWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
}

.backButton {
  margin-bottom: 8px;
}

.header {
  margin-bottom: 24px;
}

.firstParagraph {
  margin-top: 24px;
}

.paragraph {
  margin-bottom: 8px;
}

.requestNewCodeLink {
  display: block;
  margin-bottom: 8px;
  margin-left: 0;
  margin-top: 0;
}

.loaderWrapper {
  position: relative;
  height: 48px;
  margin-top: 24px;
  margin-bottom: 8px;
}

.circleLoader {
  background: none;
  align-items: flex-start;
}

.iconCircleLoader {
  width: 32px;
  height: 32px
}

.phoneNumberMask {
  white-space: nowrap;
}
