@import 'styles/core';

.backButton {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;

  font-size: 13px;
  font-weight: 500;
  color: var(--colorTextSecondary);
  text-decoration: none;

  & svg {
    width: 18px;
    height: 18px;
    margin-right: 4px;

    & path {
      fill: var(--colorTextSecondary);
    }
  }

  &:hover {
    background: none;
  }
}

.backButtonWithAction {
  padding: 0;
  border: none;
}

.backButtonTitle {
  margin-left: 0 !important;
}
