@import 'styles/core';

.propertyView {
  
  & .item {
      display: flex;
      flex-wrap: nowrap;

      margin-bottom: 8px;
      
      & .name {
        color: var(--colorTextSecondary);
        font-size: 14px;
        line-height: 20px;
        width: 152px;
        min-width: 152px;
        margin-right:16px;
        word-break: break-all;
        
        &:after {
          content:':'
        }
      }

      & .value {
        color: var(--colorTextPrimary);
        font-size: 14px;
        line-height: 20px;
        flex-basis: calc(100% - 152px);
      }

      @media (--tablet) {
        flex-wrap: wrap;
        & .value {
          flex-basis: 100%;
        }
      }
  }
}
