@import 'styles/core';

.layout {
  min-width: 960px;
  min-height: calc(100vh - 60px);
  background-color: var(--colorBackgroundPrimary);
}

.contentWrapper {
  max-width: 1232px;
  margin: 0 auto;
  padding: 0 24px 156px;
}

.scrolledHoryzontal {
  overflow-x: auto;
}

.reportWrapper {
  margin-top: 14px;
}

.unitsFilter {
  min-width: initial;
}

.header {
  margin: 0;
  padding: 32px 0;
  font-size: 24px;
  line-height: 32px;
  color: var(--colorTextPrimary);
  font-weight: 500;
}

.graphWrapper {
  padding: 25px 24px;
  background: var(--colorWhite);
  border: 1px solid var(--colorBorderDefault);
  border-radius: 3px;

  overflow: visible;
}

.table {
  border-radius: 0;
  border: none;
  padding: 0;
}

.actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  padding-top: 12px;
  padding-bottom: 8px;
}

.defaultTableWrapper {
  padding: 0;
  & th:nth-child(2),
  & td:nth-child(2) {
    text-align: left;
  }
}

.totalRow {
  background-color: var(--colorBackgroundHover);
  color: var(--colorTextSecondary);
  font-weight: 500;
}

/*
td.totalCell {
  padding-left: 40px;
}
*/

.filtersWrapper {
  display: flex;

  & > * + * {
    margin-left: 8px;
  }
}

th.chartIconWrapper,
td.chartIconWrapper {
  width: 36px;
  /* padding: 5px 4px 5px 12px; */
}

.chartIconWrapper {
  cursor: pointer;

  & svg {
    fill: #9EA4A8;
  }
}

.chartIconContainer {
  width: 20px;
  height: 20px;
}

.graphHeaderWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.graphName {
  margin-bottom: 24px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  color: var(--colorTextPrimary);
}

.activeTh {
  cursor: pointer;

  &:hover {
    background-color: #e8ebed;
  }
}

.arrow {
  width: 24px;
  height: 24px;
  display: none;
  transform: rotate(180deg);

  &.sortDirectionDown {
    transform: rotate(0deg);
  }
}

.activeSort {
  & .arrow {
    display: block;
  }
}


.thContent {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 24px;
  white-space: nowrap;
}

.thLeft {
  text-align: left;

  & .thContent {
    justify-content: flex-start;
  }
}

.tdValue {
  white-space: nowrap;
}

.planFactDashboardHeader {
  padding-bottom: 24px;

  @media (--tablet) {
    padding: 30px 0 24px;
  }
}

.percentage {
  color: var(--colorTextSecondary);
}

.barchart,
.newtable {
  position: relative;
}

.barchart {
  padding-right: 24px;
}

.newTableWrapper {
  padding: 0;
}

.exelButton {
  margin-right: 8px;
  padding: 4px 8px;
}

.varietySelect {
  background: none !important;
  border: none !important;
  font-weight: 500 !important;
}

.legend {
  padding-top: 20px;
  padding-bottom: 0;
}

.fieldsButton {
  padding-right: 8px;
  padding-left: 8px;
}