@import 'styles/core';

.contextMenu {
  padding: 8px 0;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 8px 24px rgba(0, 0, 0, 0.02);
  border: 1px solid #E1E8F0;
  border-radius: 4px;
  z-index: 20;
}

.menuItem {
  & div {
    padding: 6px 16px;
    font-size: 14px;
    line-height: 20px;
    color: var(--colorTextSecondary);
  }

  /* Перебиваем специфичность react-contexify, т.к. не даёт кастомизировать класс contexify__item__content */
  &:global(.react-contexify__item){
    &:hover div {
      &:global(.react-contexify__item__content) {
        background-color: var(--colorBackgroundHover);
        color: var(--colorTextSecondary);
      }
    }
  }
}
