@import 'styles/core';

.layout {
  width: 480px;
  padding: 24px;
}

.body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.closeCross {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}

.title {
  margin-bottom: 32px;
}

.description {
  margin-bottom: 24px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-top: 16px;
}

.dontSaveButton {
  margin-right: 8px;
}

.saveButton {}

.filters {}

.contentWrapper {
  position: relative;
}

.filterWrapper {
  margin-bottom: 16px;
  width: 100%;
}

.compartmentFilter {
  width: 100%;
}
