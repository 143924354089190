@import 'styles/core';


.layout {
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: transparent;
}

.control {
  position: relative;
  display: flex;
  padding: 11px 11px 0 11px;
}

.button {
  position: absolute;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  top: 17px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border: none;
  outline: none;
  background-color: transparent;
  fill: var(--colorTextSecondary);

  &:hover {
    fill: var(--colorTextPrimary);
  }

  &.left {
    left: 20px;
  }

  &.right {
    right: 20px;
  }

  & svg {
    width: 20px;
    height: 20px;
  }
}

.input {
  position: relative;
  width: 100%;
  flex-shrink: 1;
  flex-grow: 0;
  height: 32px;
  display: flex;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
  align-items: center;
  padding: 0 36px;
  border-radius: 4px;
  border: 1px solid #BDCFE1;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;

  &:focus {
    border: 1px solid var(--colorPrimary);
  }

  &::placeholder {
    color: var(--colorTextSecondary);
  }

  &::-ms-clear {
    display: none;
  }
}

.content {
  overflow-y: auto;
  overflow-x: hidden;
  /*margin: 12px 0 0 0;*/
  /*padding: 0 0 8px 0;*/
  max-height: 183px;
}

.options {
}

.option {
  display: flex;
  padding: 6px 12px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--colorTextPrimary);
  font-size: 14px;
  line-height: 20px;
  outline: none;
  align-items: center;

  &.selected {
    background-color: #E4E9F0;
  }

  &.withCheckBox.selected  {
    background-color: var(--colorWhite);
  }

  &.hovered,
  &.withCheckBox.hovered {
    background-color: var(--colorBackgroundHover);
  }

  &:hover {
    & .onlyButton {
      visibility: visible;
    }
  }

  & svg {
    width: 15px;
    height: 9px;
  }
}

.optionCheckbox {
  margin-right: 8px;
}

.onlyButton {
  visibility: hidden;
  margin-left: auto;
  padding: 0 0 0 8px;
  font-weight: 400;
  color: var(--colorPrimary);
}

.group {
  &.padding {
    padding: 6px 12px;
  }

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  outline: none;
}

.emptyOption {
  color: var(--colorTextSecondary);
  cursor: auto;
  font-size: 14px;

  height: 100%;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  height: 56px;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.empty {
  height: 116px;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 0 44px;
  color: var(--colorTextSecondary);
}


.icon {
  width: 20px;
  height: 20px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;

  & svg {
    width: 20px;
    height: 20px;
  }
}

.loading .loadable.icon {
  animation: rotation 1.4s infinite linear;

  @media all and (-ms-high-contrast: none) {
    animation: rotation-ie 1.4s infinite linear;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

@keyframes rotation-ie {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.loadable.icon > svg > circle {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite;
  stroke: var(--colorPrimary);

  @media all and (-ms-high-contrast: none) {
    stroke-dashoffset: 46.5;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.5;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}

.leadIconWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
