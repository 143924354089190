@import 'styles/core';

.group {
  border-top: 1px solid var(--colorBorderDefault);

  &:first-child {
    border-top: none;
  }
}

.groupContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 16px;

  cursor: pointer;
  text-decoration: none;

  &:hover, &.isActive  {
    background-color: var(--colorBackgroundHover);
  }

  &.withAdditionalInfo {
    padding: 8px 16px;
  }
}

.arrowRightIconWrapper {
  width: 20px;
  height: 20px;
  margin-left: 8px;
}

.arrowRightIcon {
  & path {
    fill: var(--colorTextSecondary);
  }
}

.firstColumn {
  width: 100%;
}

.navigationElements {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.firstColumn {
  padding-right: 32px;
}

.navigationElements {
  color: var(--colorTextSecondary);
}

.additionalInfo {
  margin-top: 2px;
}

.textWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.iconBellWrapper {
  width: 20px;
  height: 20px;
  background: var(--colorBageBackground);;
  border-radius: 4px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    width: 16px;
    height: 16px;
  }
}

.name {
  word-break: break-word;
}
