@import 'styles/core';

.login {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 768px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  background: rgba(49, 51, 59, 0.8) url('/background.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: 61px;
  box-sizing: border-box;
}

.footer {
  display: flex;
  align-items: flex-start;

  & .language {
    padding: 4px 8px;
    position: relative;
    top: 5px;
    left: 16px;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    text-transform: capitalize;

    background: rgba(255, 255, 255, 0.92);

    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.08px;

    color: #777776;


    text-decoration: underline;
  }

  & > * + * {
    margin-left: 110px;
  }
}

.wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  align-items: center;
  box-sizing: border-box;
  padding: 56px 72px 78px 72px;
  z-index: 1;

  width: 408px;
  height: 408px;
  background: rgba(255, 255, 255, 0.92);

  top: 50%;
  transform: translateY(-50%);

  @media (--phoneLandscape) {
    top: initial;
    transform: initial;

    width: 100%;
    padding: 56px 16px 78px 16px;
  }
}

.kubo {
  position: absolute;
  width: 192px;
  height: 24px;
  right: -4px;
  bottom: -1px;
  background: url('/kubo.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

@media only screen and (max-width: 1070px) {
  .login {
    min-height: 568px;

    & .footer {
      > * + * {
        margin-left: 44px;
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .login {
    padding: 20px 10px 16px;

    & .footer {
      & .language {
        top: 0;
        left: 0;
      }
    }
  }
}

@media only screen and (max-width: 1024px) and (min-width: 641px) and (max-height: 764px) {
  .login {
    padding: 20px 10px 16px;
  }
}
