@import 'styles/core';

.progressBarWithValue {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.valueText {
  padding-left: 12px;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
}
