@import 'styles/core';

.reportTabPageContainer {
  width: 1024px;
}

.forecastNotes {
  max-width: 600px;
  color: var(--colorTextPrimary);
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}


.filtersWrapper {
  display: flex;
  align-items: baseline;
  margin-bottom: 16px;
  & > * + * {
    margin-left: 8px;
  }
}

.varietySelect {
  background: none !important;
  border: none !important;
  font-weight: 500 !important;
}

.unitsFilter {
  min-width: initial;
}

.contentBlockHeader {
  color: #4A4A49;
  font-weight: 500;
  font-size: 20px;
}

.contentBlockHeaderHighlights {
  margin-top: 0;
  margin-bottom: 24px;
}


.contentBlockHeaderForecast {
  margin-top: 0;
  margin-bottom: 16px;
}

.emptyState {
  color: #777776;
  font-size: 14px;
  line-height: 20px;
  height: 82px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.mainContentWrapper {
  position: relative;
  margin-top: 52px;
}

.defaultComment {
  color: var(--colorSliderLabel);
}

.userInfoInput {
  max-width: 600px;
}

.inputContainerClassName {
  padding-bottom: 14px;
}

.selectboxFilterWrapper {
  margin-right: 0;
}
