@import 'styles/core';

.user {
  display: flex;
  align-items: center;
  background: var(--colorMenuItemHover);
  border-radius: 36px;
  height: 36px;
  padding: 4px 12px 4px 4px;
  margin-right: 4px;
}

.userNameWrapper {
  display: flex;
  align-items: center;
}

.avatar {
  min-width: 28px;
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.username {
  margin-left: 8px;
  margin-right: 8px;
}

.userCloseWrapper {
  width: 20px;
  height: 20px;
}

.userClose {
  cursor: pointer;

  & path {
    fill: #777776;
  }
}

.disabled {
  & .userClose {
    & path {
      fill: #B3B5B5;
    }
  }
}
