.sliceHoverPath {
  opacity: 0.25;
  transition: opacity 150ms ease-out;
}

.sliceEnter {
  opacity: 0;
}

.sliceEnter.sliceEnterActive {
  opacity: 0.25;
}

.sliceLeave {
  opacity: 0.25;
}

.sliceLeave.sliceLeaveActive { 
  opacity: 0;
}