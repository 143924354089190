@import 'styles/core';

.chartsWrapper {
  min-height: 332px;
}

.graphHeaderWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 32px 0;
}

.graphWrapper {
  overflow: initial;
  margin: 24px 1px;

  min-height: 446px;
}
