@import 'styles/core';

.harvestHeader {
  margin-bottom: 8px;

  font-size: 13px;
  line-height: 18px;
  color: var(--colorTextSecondary);
  font-weight: 500;
}

.harvestParam.withoutRightContent {
  @media (--tablet) {
    align-items: flex-end;
    margin-bottom: 16px;

    & .harvestHeader {
      margin-bottom: 0;
    }
  }
}

.harvestValue {
  color: var(--colorTextPrimary);
  font-size: 14px;

  @media (--tablet) {
    text-align: right;
  }
}

.harvestValueAmount {
  font-size: 32px;
  line-height: 32px;
  font-weight: 300;

  @media (--tablet) {
    font-size: 20px;
  }
}

.harvestValueUnits {
  padding: 0 12px 0 4px;

  @media (--tablet) {
    padding-right: 0;
  }
}

.rightContent {
  @media (--tablet) {
    display: block;
    font-size: 13px;
  }
}
