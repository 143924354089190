@import 'styles/core';

.textLabel {
  display: flex;
  align-items: center;

  height: 18px;
  padding: 0 4px;

  border-radius: 4px;
  background-color: #F0F3F7;
  font-size: 13px;
  font-weight: normal;
  color: var(--colorTextSecondary);
}

.icon {
  margin-right: 4px;
}
