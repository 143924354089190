@import 'styles/core';

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 12px 0 0;
  border-top: 1px solid var(--colorBorderDefault);
}

.cancelButton,
.okButton {
  padding: 6px 16px;
}

.cancelButton {
  margin-right: 8px;
}

.withMobileVersion {
  @media (--phoneLandscape) {
    & .actions {
      position: fixed;
      bottom: 12px;
      left: 12px;
      right: 12px;
      width: calc(100% - 24px);
    }
  }
}
