@import 'styles/core';

.arrowRightIcon {
  width: 20px;
  height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  & svg path {
    fill: #9EA4A8;
  }
}

.arrowIconRotate {
  transform: rotate(90deg);
}

.trSecondLevel {
  user-select: none;
  & .nameWrapper {
    padding-left: 32px;
  }
  & label {
    padding-right: 26px;
  }
}


td.chartIconWrapper {
  width: 32px;
}


.nameWrapperTd {

}

.chartIconWrapper {
  cursor: pointer;

  & svg {
    fill: #9EA4A8;
  }
}

.sticky {
  left: 1px;
  position: absolute;
  top: auto;
  width: 320px;
  display: flex;
  z-index: 10;
  background-color: #FFFFFF;
  align-items: center;
  border-right: 1px solid #E7E9ED;

  &:after {
    content: '';
    position: absolute;
    right: -6px;
    height: 100%;
    width: 6px;
    background-color: #4a4a49;
    opacity: 0.05;
    z-index: 1;
  }

  & label {
    padding-right: 14px;
  }
}

.chartIconContainer {
  width: 20px;
  height: 20px;
}

.nameWrapper {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.nameText {
  overflow: hidden;
  width: 240px;
  text-overflow: ellipsis;
}

.nameExtra {
  color: #777776;
  display: inline;
}

.calculated {
  width: 220px;
}

.plantingCycleLink {
  display: block;
  color: var(--colorTextPrimary);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.tdValue {
  white-space: nowrap;
}

