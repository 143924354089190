@import 'styles/core';

.report {
  margin-top: 16px;
}

.chart {
  margin: 24px 0 24px 0;
  padding: 20px 25px;
  border-radius: 4px;
  width: 100%;
  position: relative;
  flex-shrink: 1;

  @media (--desktopSmall) {
    width: 100%;
  }

  @media (--tablet) {
    padding: 20px 16px;
    border-radius: 0;
    border-left: none;
    border-right: none;
  }
}

.chartLines {
  margin-top:48px;
}
