@import 'styles/core';

.settingsHarvestForecastSpecies {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
  height: 100%;
}

.header {
  margin-top: 10px;
  margin-bottom: 4px;
}

.content {
  margin-top: 16px;
}

.categoryGroup {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (--phoneLandscape) {
    display: block;
    margin-top: 48px;
  }
}

.categoriesList {
  display: table;

  @media (--phoneLandscape) {
    display: block;
  }
}

.categoryGroup {
  display: table-row;

  & .columnHeader {
    display: none;

    @media (--phoneLandscape) {
      display: block;
    }
  }

  &:first-child {
    & .columnHeader {
      display: block;
    }
  }

  @media (--phoneLandscape) {
    display: block;
  }
}

.firstColumn,
.secondColumn,
.thirdColumn {
  display: table-cell;

  @media (--phoneLandscape) {
    display: block;
  }
}

.columnHeader {
  margin-top: 16px;
}

.firstColumn {
  width: 240px;
  padding-right: 8px;

  @media (--phoneLandscape) {
    width: 100%;
    padding-right: 0;
  }
}

.secondColumn {
  min-width: 96px;
  padding-right: 24px;

  @media (--phoneLandscape) {
    padding-right: 0;
  }
}

.thirdColumn {
  min-width: 240px;

  @media (--phoneLandscape) {
    width: 100%;
  }
}

.columnContent {
  margin-top: 16px;
}

.inputContainer {
  padding: 18px 12px 8px 12px;
  width: 100%;

  border: 1px solid var(--colorBorderSecondary);
  border-radius: 4px;

  font-size: 14px;
  color: var(--colorTextPrimary);

  &.inputContainerFocused {
    border-color: var(--colorPrimary);
    box-shadow: 0px 0px 0px 2px rgba(67, 179, 226, 0.1);
  }

  &.widthoutPlaceholder {
    padding: 13px 12px 13px 12px;

    &.mini {
      padding: 5px 12px 5px 12px;
    }
  }

  &.inputWithError {
    border-color: var(--colorNegative);

    & .label.labelActive {
      color: var(--colorNegative);
    }

    &.inputContainerFocused {
      border-color: var(--colorNegative);
    }
  }
}

.inputWithLabel {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}

.label {
  padding-bottom: 8px;
  margin: 0;
  border: 0;

  position: absolute;
  color: var(--colorTextNeutral);
  bottom: 0;

  transition: all 0.2s ease-in-out;
  transform-origin: left top;
  cursor: text;
  pointer-events: none;
  width: 76.6%;
  line-height: 16px;

  &.labelActive {
    /* transform: translate3d(0, -40%, 0) scale(0.85); */
    transform: translate3d(0, -40%, 0) scale(0.8333); /* 8333 чтобы не было мыла на винде */
  }
}

.input {
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  line-height: 20px;
  color: var(--colorTextPrimary);

  &::placeholder {
    color: var(--colorTextSecondary);
    opacity: 0;
    transition: opacity 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }

  &.inputActive {
    &::placeholder {
      opacity: 1;
    }
  }
}

.inputContainerDisabled {
  background: var(--colorBackgroundHover);
  color: var(--colorTextNeutral);

  & .label,
  & .input {
    color: var(--colorTextNeutral);
  }

  & .input {
    background: var(--colorBackgroundHover);
  }
}

.categoryWrapper {
  display: flex;
}

.arrowWrapper {
  position: relative;
  width: 96px;
}

.arrowWrapperWide {
  width: 240px;
}

.arrowDown {
  position: absolute;
  top: calc(50% - 4px);
  right: 10px;
}

.controlPrefix {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  margin-top: 16px;
  color: var(--colorTextSecondary);
}

.categoryName {
  display: inline-block;
  padding: 0 8px;
  margin-right: 4px;
  border-radius: 4px;
  background-color: var(--colorSecondary);
  color: var(--colorWhite);
  opacity: 0.5;
}
