@import 'styles/core';


.layout {
  padding: 24px 24px 12px 24px;
  background: var(--colorWhite);

  @media (--tablet) {
    padding: 24px 16px 12px 16px;
  }
}

.wrapper {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  padding-bottom: 22px;

  @media (--tablet) {
    padding-bottom: 12px;
  }
}

.header {
  display: flex;
  align-items: center;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.content {
  width: 100%;
}
