@import 'styles/core';

.overall {
  padding-bottom: 24px;
}

.legend {
  padding: 20px;
}

.speciesName {
  margin-bottom: 12px;

  font-size: 16px;
  line-height: 20px;

  color: var(--colorTextPrimary);
  font-weight: 500;

  @media (--tablet) {
    padding-left: 16px;
  }
}

.targetsContainer {
  display: flex;

  @media (--tabletBig) {
    flex-wrap: wrap;
  }
}

.targets {
  position: relative;
  height: 366px;
  padding: 20px 24px 32px;

  background: #FFF;
  border: 1px solid #E7E9EE;
  border-radius: 3px;

  @media (--tablet) {
    border-radius: 0;
    border-left: none;
    border-right: none;
  }
}

.targetsMonth {
  min-width: 396px;
  max-width: 396px;
  margin-right: 24px;

  @media (--tabletBig) {
    margin-right: 0;
    margin-bottom: 24px;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}

.targetsMonthToMonth {
  width: 100%;

  overflow: hidden;
  padding-right: 40px;
}

.targetsHeader {
  font-size: 14px;
  line-height: 20px;

  color: var(--colorTextPrimary);
  font-weight: 500;
}

.targetsContent {
  display: flex;
  justify-content: center;

  padding-top: 48px;
}

.targetsContentChart {
  padding-top: 24px;
}
