@import 'styles/core';

.container {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;

  & .title {
    color: var(--colorTextPrimary);
  }

  & .iconWrapper {
    padding-top: 4px;

    & svg {
      margin-left: 4px;
    }

    & .tooltip {
      word-wrap: break-word;
    }
  }
}




