@import 'styles/core';

.extraWrapper {
  width: 100%;

  &.fullWidth {
    width: 100%;
  }

  & .tooltipIndicator {
    padding: 16px 10px!important;
    border-radius: 10px!important;
    background: #FCFAEB!important;
    color: var(--colorTextSecondary)!important;
    font-size: 12px!important;
    border: 1px solid rgba(203, 142, 40, .1)!important;
    white-space: pre-wrap!important;
    opacity: 1!important;
    width: 300px!important;
    z-index: 100;


    & :global(.multi-line) {
      text-align: left !important;
    }

    &:global(.place-top) {

      &:after {
        border-top-color: #FCFAEB !important;
      }
      &:before {
        border-top: 8px solid rgba(203, 142, 40, .1) !important;
        bottom: -8px;
      }
    }
    &:global(.place-bottom) {
      &:after {
        border-bottom-color: #FCFAEB !important;
      }
      &:before {
        border-bottom: 8px solid rgba(203, 142, 40, .1) !important;
        top: -8px;
      }
    }

    &:global(.place-left) {
      &:after {
        border-left-color: #FCFAEB !important;
      }
      &:before {
        border-left: 8px solid rgba(203, 142, 40, .1) !important;
        right: -8px;
      }
    }
    &:global(.place-right) {
      &::after {
        border-right-color: #FCFAEB !important;
      }
      &::before {
        border-right: 8px solid rgba(203, 142, 40, .1) !important;
        left: -8px;
      }
    }
  }

  @media only screen and (max-width: 640px) {
    width: 100%;

    & .tooltipIndicator {
      left: 4%!important;
      width: 92%!important;
    }
  }
}

.tooltipContent {
  display: flex;
  flex-flow: column;
}

.tooltipPeriod {
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 0.5px;
  flex-shrink: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tooltipButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  padding-top: 4px;
  border-top: 1px solid rgba(147, 173, 194, .4);
  flex-shrink: 0;
}

.tooltipButton {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 4px;
  outline: none;
  background: transparent;
  border: none;

  &:first-of-type {
    margin-left: -5px;
  }

  &:last-of-type {
    margin-right: -5px;
  }

  & + .tooltipButton {
    margin-left: 5px;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.disabled .tooltipButtonIcon {
    opacity: .4;
  }

  &:hover:not(.disabled) {
    background-color: rgba(0, 0, 0, .25);
  }
}

.tooltip {
  width: 160px;
  height: 75px;
  position: fixed;
  padding: 10px;
  border-radius: 2px;
  background-color: rgba(84, 107, 126, .95);
  border: none;
  opacity: 1;
  pointer-events: auto;
  z-index: 100;

  & .before {
    width: 0;
    height: 0;
    position: absolute;
    border: 8px solid transparent;
    bottom: -14px;
    left: 50%;
    margin-left: -8px;
    border-top-color: rgba(84, 107, 126, .95);
  }
}
