@import 'styles/core';

.tableWrapper {
  padding-top: 8px;
}

.hotTableWrapper {
  position: relative;
  border-bottom: 1px solid var(--colorBorderDefault);
  border-left: 1px solid var(--colorBorderDefault);

  & :global(.htCore) td.dateCell {
    color: var(--colorTextSecondary);
    white-space: nowrap;
  }

  & :global(.htCore) td.dateCell.dateCellDisabled {
    color: var(--colorTextNeutral);
    white-space: nowrap;
  }

  & :global(span.colHeader) {
    display: block;
  }

  & :global(.htCore thead th:nth-child(1)) {
    text-align: left;
  }
  & :global(.htCore thead th:nth-child(2)) {
    text-align: right;
  }

  & :global(.htCore tbody td:nth-child(2)) {
    text-align: right;
  }

  & :global(.htCore thead th) {
    padding: 8px 10px;
  }

  & :global(.htCore tbody td) {
    padding: 0 16px;
  }

  & :global(.handsontableInput) {
    height: 28px !important;
  }

  & :global(.handsontableInputHolder) {
    z-index: 0 !important;
  }

}

td.invalidCell {
  overflow: initial;
  position: relative;

  &:global(.current) {
    z-index: 1;

    & .errorContent {
      display: block;
    }
  }
}

:global(.valueError) {
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 8px 0;
    border-color: transparent var(--colorNegative) transparent transparent;
  }
}

.errorPlate {
  position: absolute;
  top: -30px;
  left: -200px;
  background: #fff;
  padding: 8px;
  width: 180px;
  font-size: 14px;
}
