@import 'styles/core';

.select {
  & > button.longSelectButton {
    width: 100%;
    padding: 0 30px 0 0;
  }
}

.longSelectPopup {
  max-height: 248px;
  text-align: left;
  overflow: hidden;
  width: 100%;
  min-width: 210px;
}

.option {
  padding: 6px 12px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
}

.speciesName {
  padding: 10px 12px 6px;
  line-height: 20px;
  font-size: 14px;
  color: var(--colorTextPrimary);
  font-weight: 500;
}

.popup {
  max-height: 210px;
  text-align: left;
}

.titleWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.title {
  margin-right: 8px;
  font-size: 14px;
  color: var(--colorTextSecondary);
}

.loading {
  background: #E7E9EE;
  border-radius: 99px;
  width: 60px;
  height: 8px;
}
