@import 'styles/core';

.table {
  position: relative;

  & :global(.htCore th) {
    overflow: initial;
    box-sizing: border-box;

    background: var(--colorThDefault);
    border-color: var(--colorBorderDefault);

    font-size: 13px;
    line-height: 20px;
    color: var(--colorTextSecondary);
  }

  & :global(.htCore tr:first-child th) {
    border-top-color: var(--colorBorderDefault);
  }

  & :global(.htCore thead th) {
    font-weight: 500;
    padding: 10px 16px;
    text-align: left;
  }

  & :global(.htCore tbody th) {

    font-weight: normal;
    /* padding: 4px 4px; */
  }

  & :global(.htCore tbody td) {
    /* padding: 2px 8px; */
    padding: 8px 16px;
    vertical-align: middle;
    border-color: var(--colorBorderDefault);
    color: var(--colorTextPrimary);
    font-size: 14px;
    line-height: 20px;
    /* padding-left: 8px;
    padding-right: 8px; */
  }

  & :global(.htCore tbody td.htInvalid) {
    position: relative;
    background-color: var(--colorWhite) !important;
    border: 1px solid var(--colorNegative);

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 8px 0;
      border-color: transparent var(--colorNegative) transparent transparent;
    }
  }

  & :global(.htCore tbody td.htWarning) {
    position: relative;
    background-color: var(--colorWhite) !important;
    border: 1px solid var(--colorWarning);

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 8px 0;
      border-color: transparent var(--colorWarning) transparent transparent;
    }
  }

  & :global(.handsontableInput) {
    height: 40px !important;
  }

  & :global(.handsontable thead th.ht__highlight) {
    background: var(--colorBorderDefault);
  }

  & :global(.handsontable td.area:before) {
    background: var(--colorPrimary);
  }

  & :global(.handsontable .wtBorder.current),
  & :global(.handsontable .wtBorder.corner),
  & :global(.handsontable .wtBorder.area) {
    background-color: var(--colorPrimary) !important;
  }
}
