@import 'styles/core';

.layout {
  margin: 0 0 40px 0;

  display: flex;
  width: 100%;
  position: relative;

  border: 1px solid #E7E9EE;
  border-radius: 2px;

  &.isDetachedComponent {
    margin: 0;
    border: 1px solid transparent;
  }
}

.container {
  margin: 0 auto;
  padding: 20px 24px;
  width: 100%;


  &.sidePanelOpened {
    width: calc(100% - 311px);
  }
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.38px;
  color: var(--colorTextPrimary);
}

.header {
  padding: 32px 0 24px;
}

.headerRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerRowCentral {
  padding: 16px 0 32px;
}

.headerText {
  margin: 0;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;

  color: var(--colorTextPrimary);
}

.headerControls {
  display: flex;
  align-items: center;
}

.headerControlsSave {
  margin-right: 24px;
  color: var(--colorTextSecondary);
  font-size: 14px;
}

.headerControlsButtons {
  display: flex;
}


.tabs {
  display: flex;
  margin-top: 8px;
  margin-bottom: 32px;

  border-bottom: 1px solid #E7E9EE;

  @media (--tablet) {
    margin-bottom: 24px;
  }
}

.tab {
  cursor: pointer;
  padding: 16px 0;
  margin-right: 32px;
  margin-bottom: -2px;

  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  color: var(--colorTextSecondary);
}

.emptyTabs {
  height: 40px;
}

.activeTab {
  box-shadow: inset 0 -3px 0 0 var(--colorPrimary);
  color: var(--colorPrimary);
}


.cancelButton {
  margin-right: 8px;
}

.timeText {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.timeTextButton {
  text-decoration: underline;
  font-weight: normal;
  padding: 0;
  margin-left: 4px;

  & svg circle {
    stroke: var(--colorTextSecondary) !important;
  }

  &:hover {
    text-decoration: underline;
  }
}

.plansControls {
  display: flex;
  align-items: center;
}

.linksToggle {
  display: flex;
  align-items: center;

  margin-left: 8px;
  border: 1px solid var(--colorBorderSecondary);
  border-radius: 4px;
  overflow: hidden;
}

.plansLink {
  display: block;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
  text-decoration: none;

  &:first-child {
    border-right: 1px solid var(--colorBorderSecondary);
  }

  &.plansLinkActive {
    background-color: var(--colorBorderDefault);
  }
}

.historyButton {
  padding: 0;

  &:hover svg path {
    fill: var(--colorPrimary);
  }
}

.infoPlate {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
  max-width: 600px;
  color: var(--colorTextPrimary);
}


.table {
  position: relative;
  margin: 12px 0 0 0;

  & table {
    font-size: 13px;
  }

  & .tableHeaderRow {
    & .tableHeaderRowCell {
      height: 32px;
      min-width: auto;
      white-space: nowrap;

      &.alignRight {
        text-align: right;
      }

      &:first-child {
        padding-left: 16px;
      }

      &:last-child {
        padding-right: 16px;
      }
    }
  }

  & .tableRow {
    & .tableRowCellEmpty {
      height: 28px;
      padding: 5px 16px;
      white-space: nowrap;
      box-shadow: none;
    }

    & .tableRowCell {
      height: 28px;
      padding: 5px 16px;
      white-space: nowrap;

      &.alignRight {
        text-align: right;
      }

      &:last-child {
        background-color: var(--colorThDefault);
      }
    }

    &:last-child {
      & .tableRowCell {
        height: 28px;
        background-color: var(--colorThDefault);

      }
    }
  }
}

.tableScroller {
  overflow-x: auto;
}

.filters {
  display: flex;
  align-items: center;
  margin: 16px 0 0 0;

  & .filter {
    display: flex;
    align-items: center;

    & + .filter {
      margin: 0 0 0 16px;
    }

    & .filterTitle {
      margin: 0 8px 0 0;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.15px;
      color: var(--colorTextSecondary);
    }

    & .filterSelectButton {
      min-width: auto;
    }
  }
}

.periodLengthFilterWrapper {
  margin: 0 16px;
}

.bar {
  fill: var(--colorHarvest);
}

.emptyState {
  width: calc(100% - 2px);
  height: 82px;
  position: absolute;
  top: 34px;
  left: 1px;
  right: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
