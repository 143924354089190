@import 'styles/core';

.verificationProblemsWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
}

.verificationProblemsContainer {}

.backButton {
  margin-bottom: 8px;
}

.firstParagraph {
  margin-top: 16px;
  margin-bottom: 20px;
  padding-right: 4px;
}

.button,
.buttonTitle {
  width: 100%;
}
