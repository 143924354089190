@import 'styles/core';

.wrapper {
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
}

.periodSelect {

}

.periodSelectButton {
  width: 120px;
  height: 32px;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.button {
  height: 32px;
  margin-left: 12px;
}

.tableLoader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, .6);
  z-index: 2;
}

.circleLoader {
  height: calc(100vh - 180px);
}

.circleLoaderIcon {
  width: 48px;
  height: 48px;
}

.cropCreateWideButton {
  display: flex;
}

.tableWrapper {
  width: 100%;
  position: relative;
  display: flex;
  padding: 0;
  margin: 0;
  overflow-x: auto;
}

.lineChartWrapper {
  min-height: 370px;
}

.graphsWrapper {
  margin-bottom: 16px;
}

.tablesContainer {
  width: 100%;

  box-sizing: border-box;
  position: relative;
  display: flex;
}

@media only screen and (max-width: 640px) {
  .indicatorsTable {
    display: none;
  }
}

.selectedMetricsCount {
  background-color: var(--colorPlantPrimary);
  color: var(--colorWhite);
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: 6px;
  font-weight: 500;
}

.tableActionButtons {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding-bottom: 16px;
  width: 100%;
}

.exelButton {
  padding: 4px 8px;
  height: 28px;
}
