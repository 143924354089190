@import 'styles/core';

.progress {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.progressText {
  margin-left: 8px;
}

.progressBarContainer {
  width: 48px;

  @media (--tabletLandscape) {
    display: none;
  }
}
