@import 'styles/core';

.layout {
  height: calc(100vh - 60px);
  background-color: #FFFFFF;
  padding: 0 0 24px 0;
  overflow: hidden;
}

.empty {
  width: 100%;
}

.emptyState {
  padding: 122px 24px;
}


.emptyButton {

}

.container {
  padding: 30px 0 34px;
}

.header {
  width: 100%;
  margin: 0;
  padding: 0;

  @media (--tablet) {
    display: none;
  }
}

.content {
  padding: 0;
}

.bodyContent {
  margin-top: -69px;
  overflow-y: auto;
}

.bodyWrapper {
  position: relative;
}

.timelines {
  overflow-x: auto;
}

.mobile {
  display: none;

  @media (--tablet) {
    display: block;
  }
}

.desktop {
  min-height: calc(100vh - 60px - 48px);

  @media (--tablet) {
    display: none;
  }
}
