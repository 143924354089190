@import 'styles/core';

.button {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 136px;
  height: 32px;
  padding-left: 12px;
  padding-right: 8px;

  font-size: 14px;
  line-height: 20px;

  color: var(--colorTextPrimary);
  background: var(--colorWhite);
  border: 1px solid var(--colorBorderSecondary);

  cursor: pointer;
}

.disabled {
  opacity: 0.5;
}

.icon {
  fill: var(--colorIcons);
  width: 16px;
  height: 16px;
}
