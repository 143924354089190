@import 'styles/core';

.component {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
}

.title, .titleLeft {
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextSecondary);
}

.title {
  padding-left: 8px;
}
.titleLeft {
  padding-right: 8px;
}

.enabledWrapper {
  & .titleLeft {
    color: var(--colorPrimary);
  }
}

.wrapper {
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  padding: 0;
  outline: none;
  background-color: transparent;
}

.button {
  position: relative;
  width: 20px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--colorTextSecondary);
  padding: 2px;
  box-sizing: border-box;


  &.enabled {
    background-color: var(--colorPrimary);
    flex-direction: row-reverse;

    & .circle {
      left: 12px;
    }
  }
}

.circle {
  position: absolute;
  left: 2px;
  top: 2px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #ffffff;
  transition: 0.2s;
}
