@import 'styles/core';

.inputBlock {
  margin-bottom: 48px;
}

.inputTitle {
  margin-bottom: 12px;
}

.inputSubtitle {
  display: block;
  margin-bottom: 24px;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-bottom: 32px;
}

.submitButton {
  margin-left: 8px;
}

.speciesItems {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px 0 -8px;
}

.item {
  margin: 0 8px 2px 8px;
  flex-shrink: 0;
  @media (--tabletBig) {
    width: 156px;
  }
}

.datesControls {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (--tabletBig) {
    display: block;
  }
}

.inputBlockDates {
  margin-bottom: 46px;
}

.dateControl {
  width: 276px;

  @media (--tabletBig) {
    width: 100%;
    padding-bottom: 20px;
  }
}
