@import 'styles/core';

.arrowWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;

  border: 1px solid var(--colorBorderSecondary);
  border-radius: 4px;

  &.active,
  &:hover {
    background: var(--colorBorderDefault);
  }
}

.switchWrapper {
  margin-left: 8px;
}

.switchContent {
  left: 0;
  top: calc(100% + 4px);
  width: 384px;
  height: 336px;

  font-weight: normal;

  @media (--tablet) {
    max-width: 90vw;
    right: 0;
    left: initial;
  }
}

.mobileSwitchWrapper {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: var(--colorWhite);
}

.headersGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px 13px;
}

.header {
  line-height: 24px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
  border-top: 1px solid var(--colorBorderDefault);
}

.cancel {
  margin-right: 8px;
}

.searchListMobile {
  height: calc(100% - 130px);
}