@import 'styles/core';

.parameterHeader {
  margin-bottom: 8px;

  font-size: 13px;
  line-height: 18px;
  color: var(--colorTextSecondary);
  font-weight: 500;
}

.parameterValueWrapper {
  display: flex;
}

.parameterParam.withoutRightContent {
  @media (--tablet) {
    align-items: flex-end;
    margin-bottom: 16px;

    & .parameterHeader {
      margin-bottom: 0;
    }
  }
}

.parameterValueContent {
  padding: 0 16px 0 0;

  & + .parameterValueContent {
    padding-left: 16px;
    border-left: 1px solid var(--colorBorderSecondary);
  }
}

.parameterValue {
  color: var(--colorTextPrimary);
  font-size: 14px;

  @media (--tablet) {
    text-align: right;
  }
}

.parameterValueAmount {
  font-size: 32px;
  line-height: 32px;
  font-weight: 300;
  white-space: nowrap;

  @media (--tablet) {
    font-size: 20px;
  }
}

.parameterValueUnits {
  padding: 0 12px 0 4px;

  @media (--tablet) {
    padding-right: 0;
  }
}

.rightContent {
  @media (--tablet) {
    display: block;
    font-size: 13px;
  }
}
