@import 'styles/core';


.rangePickerContainer {
  display: flex;
  align-items: center;
  position: relative;
}

.select {
}

.selectButton {
  border-radius: 0;
  height: 32px;
  min-width: 166px;
}

.selectPopup {
  min-width: 166px;
}

.leftButton,
.rightButton {
  position: relative;
  display: flex;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: none;
  background: none;
  background: #FFFFFF;
  border: 1px solid #BDCFE1;
  border-radius: 4px;
  cursor: pointer;

  & svg {
    /* fix iOS safari svg bug https://stackoverflow.com/questions/4504942/mobile-safari-svg-problem */
    position: absolute;
    top: 9px;
  }

  &:hover svg path {
    fill: var(--colorTextSecondary);
  }
}


.leftButton {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}

.rightButton {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
}

.disabled {
  opacity: 0.5;
}

.desktop {
  display: flex;

  @media (--tablet) {
    display: none;
  }
}

.mobile {
  display: none;

  @media (--tablet) {
    display: block;
  }
}

.withoutSelect {
  & .desktop {
    display: flex;
  }
  & .mobile {
    display: none;
  }
}

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background-color: #ffffff;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  font-size: 20px;
  font-weight: 500;
  color: var(--colorTextPrimary);
  text-align: left;
  line-height: 24px;
}

.body {
  padding: 16px 16px 22px;
}

.selects {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;

  & > * + * {
    margin: 24px 0 0 0;
  }
}

.popupDateSelectIcon {
  width: 24px;
  height: 24px;
  top: 12px;
  bottom: 12px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin: 32px 0 0 0;

  & > * + * {
    margin: 0 0 0 10px;
  }
}

.close {
  cursor: pointer;
}

.yearLabel {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;

  min-width: 166px;
  padding: 0px 32px 0px 8px;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
  background-color: #FFF;
  border: 1px solid #bdcfe1;
}
