@import 'styles/core';

.selectPopup {
  min-width: 262px;
}

.selectWrapper {
  & .selectButton {
    padding: 0;
  }
}

.checkboxesContainer {
  padding: 2px 0;
}

.checkboxItem {
  padding: 6px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: var(--colorBackgroundHover);

    & .onlyButton {
      visibility: visible;
    }
  }
}

.dash {
  margin-right: 4px;
  fill: var(--colorTextSecondary);
  cursor: move;
}
