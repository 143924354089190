@import 'styles/core';

.main {
  position: relative;
  min-height: calc(100vh - 56px);

  @media (--tabletLandscape) {
    overflow: hidden;
  }

  &.menuOpened {
    @media (--tabletLandscape) {
      position: fixed;
      left: 0;
      right: 0;
    }

    & .header {
      @media (--tabletLandscape) {
        transform: translate(240px, 0);
      }
    }

    & .menu {
      @media (--tabletLandscape) {
        transform: translate(0, 0);
      }
    }

    & .content {
      @media (--tabletLandscape) {
        transform: translate(0, 0);
      }
    }
  }
}

.header {
  display: flex;
  align-items: center;

  margin: 0;
  padding: 20px 24px;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: var(--colorTextPrimary);
  border-bottom: 1px solid #E7E9EE;

  transition: transform 150ms ease-out;

  @media (--tabletLandscape) {
    padding: 32px 24px 0;
    border-bottom: none;
  }

  @media (--tablet) {
    padding: 24px 16px 0;
  }

  &.hiddenOnDesktop {
    display: none;

    @media (--tabletLandscape) {
      display: flex;
    }
  }
}

.menuIconWrapper {
  display: none;
  width: 24px;
  height: 24px;
  margin-right: 8px;

  @media (--tabletLandscape) {
    display: block;
  }
}

.menuIcon {
  width: 24px;
  height: 24px;
  fill: var(--colorTextSecondary);
}

.pageLayout {
  display: flex;
  overflow: hidden;
}

.menu {
  flex: 0 0 216px;
  padding: 32px 24px;

  font-weight: 500;
  line-height: 20px;

  transition: transform 150ms ease-out;

  @media (--tabletLandscape) {
    height: calc(100vh - 56px); /* высота шапки */
    margin-top: -64px; /* высота страницы настроек */
    min-width: 240px;
    width: 240px;
    transform: translate(-240px, 0);

    z-index: 1001;
    background: var(--colorWhite);
  }

  @media (--tablet) {
    margin-top: -56px; /* высота страницы настроек */
  }
}

.menuItem {
  margin-bottom: 16px;
}

.pointer {
  cursor: pointer;
}

.menuItemHeader {
  font-size: 16px;
  color: var(--colorTextPrimary);
  margin-bottom: 8px;
}

.menuItemLink {
  display: block;
  font-size: 14px;
  color: var(--colorTextSecondary);
  margin-bottom: 8px;
  text-decoration: none;

  &:hover,
  &.menuItemLinkActive {
    color: var(--colorPrimary);
  }
}

.parentMenuItemLink {
  & span {
    display: block;
    font-size: 14px;
    color: var(--colorTextSecondary);
    text-decoration: none;
    &:hover {
      color: var(--colorPrimary);
    }
  }

  & .childs {
    padding-left: 16px;
    padding-top: 8px;
  }
}


.content {
  width: 100%;

  flex: 1;
  padding: 32px 24px;
  transition: transform 150ms ease-out;

  @media (--tabletLandscape) {
    padding: 24px 24px;
    min-width: 100%;
    transform: translate(-240px, 0);
  }

  @media (--tablet) {
    padding: 24px 16px;
  }
}
