@import 'styles/core';

.dialog {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-flow: column;
  align-items: center;
  z-index: 1000;
  background-color: rgba(30, 42, 51, 0.6);
  color: var(--colorTextPrimary);
  padding: 0;
}

.dialogClose {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;

  @media (--tablet) {
    top: 16px;
    right: 16px;
  }
}

.dialogWrapper {
  display: flex;
  flex-flow: column;
  position: relative;

  margin: 20px auto;
  padding: 24px 24px 16px;

  top: 50%;
  transform: translateY(calc(-50% - 20px));

  border-radius: 3px;
  background-color: #ffffff;
  box-sizing: border-box;

  box-shadow: 0 1px 20px 0 rgba(30,42,51,0.20);

  @media (--tablet) {
    padding: 16px;
  }
}

.dialogHeader {
  padding-bottom: 20px;
}

.dialogHeaderTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  margin-bottom: 4px;

  /* отсуп на ширину крестика и паддинг */
  padding-right: 48px;

  @media (--tablet) {
    margin-bottom: 8px;
    padding-right: 40px;
  }
}

.dialogHeaderSubtitle {
  font-size: 14px;
  line-height: 20px;
  color: #536b7f;

  /* отсуп на ширину крестика и паддинг */
  padding-right: 48px;

  @media (--tablet) {
    padding-right: 40px;
  }
}
