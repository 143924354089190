@import 'styles/core';

.weekPhoto {
  display: flex;
  flex-flow: column;
  width: 200px;
  min-width: 200px;
  box-sizing: content-box;

  & .tableHeader {
    height: 60px;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 12px 14px 16px;
    box-sizing: border-box;

    & > .tableHeaderLink {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.67px;
      line-height: 18px;
      text-decoration: none;
      outline: none;
      color: var(--colorTextSecondary);
    }

    & > .tableHeaderData {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.5px;
      line-height: 18px;
      color: var(--colorTextNeutral);
    }
  }

  & .weekPhotoMain {
    padding: 0 10px;
    width: 180px;
  }

  & .weekPhotoExtra {
    margin-top: 5px;
    background-color: #FAFBFC;
    padding: 5px 10px 5px;
    width: 180px;
  }

  &:first-of-type {
    & .tableHeader {
      padding-left: 4px;
    }

    & .weekPhotoMain {
      //padding-left: 0;
    }

    & .weekPhotoExtra {
      //padding-left: 0;
    }
  }
}
