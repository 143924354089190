@import 'styles/core';

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding-top: 16px;
}

.dialog {
  justify-content: center;
}

.layout {
  width: 900px;
  height: 600px;
  margin: 20px;

  @media (--tabletBig) {
    width: 624px;
    height: 600px;
  }

  @media (--tablet) {
    width: 100vw;
    height: 100vh;
    margin: 0;
  }
}

.content {
  height: 458px;
  overflow: hidden;
  border-bottom: 1px solid var(--colorBorderDefault);
}

.parameters {
  height: 100%;
  width: 100%;
  flex-grow: 1;
  padding-right: 16px;
}

.marginLeft {
  margin-left: 8px;
}

.row {
  display: flex;
}

.alignCenter {
  align-items: center;
}

.spaceBetween {
  justify-content: space-between;
}

.selectedMetrics {
  flex-grow: 0;
  flex-shrink: 0;
  width: 350px;
  border-left: 1px solid var(--colorBorderDefault);

  @media (--tabletBig) {
    width: 216px;
  }
}

.selectedMetricsList {
  overflow-y: auto;
  padding: 16px 0 16px 16px;
}

.selectedMetricsHeader {
  padding: 6px 0 6px 16px;
  margin: 0 0 2px 0;
}

.groups {
  overflow-y: auto;
  width: 100%;
  height: calc(100% - 48px);
  padding: 0 16px 48px 0;
}

.group {
  & + .group {
    margin-top: 24px;
  }
}

.groupHeader {
  margin: 0 0 16px 0;
}

.graphMetric {

  & + .graphMetric {
    margin-top: 8px;
  }
}


.control {
  position: relative;
  display: flex;
  padding: 0;
  margin: 0 0 16px 0;
}

.button {
  position: absolute;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  top: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border: none;
  outline: none;
  background-color: transparent;
  fill: var(--colorTextSecondary);

  &:hover {
    fill: var(--colorTextPrimary);
  }

  &.left {
    left: 10px;
  }

  &.right {
    right: 10px;
  }

  & svg {
    width: 20px;
    height: 20px;
  }
}

.input {
  position: relative;
  width: 100%;
  flex-shrink: 1;
  flex-grow: 0;
  height: 32px;
  display: flex;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
  align-items: center;
  padding: 0 36px;
  border-radius: 4px;
  border: 1px solid #BDCFE1;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;

  &:focus {
    border: 1px solid var(--colorPrimary);
  }

  &::placeholder {
    color: var(--colorTextSecondary);
  }

  &::-ms-clear {
    display: none;
  }
}
