@import 'styles/core';

.mobileMenu {
  display: none;
  padding-right: 16px;

  @media (--tablet) {
    display: block;
  }
}

.mobilePeriodSelect {
  margin-bottom: 24px;
}

.mobileControls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 32px;
}

.cancelButton {
  margin-right: 8px;
}

.customDatePickerWrapper {
  @media (--tablet) {
    & :global {
      & .react-datepicker-wrapper,
      & .react-datepicker__input-container {
        width: 100%;
      }
    }
  }
}

.dateButtonFocused {
  border: 1px solid var(--colorPrimary);
}
