@import 'styles/core';

.settingsMeasurements {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
}

.header {
  margin-bottom: 4px;
}

.infoPlate {
  margin: 16px 0 32px;
}

.field {
  max-width: 336px;
}

.supportButton {
  display: inline-block;
  padding: 0;

  color: var(--colorPrimary);
  font-weight: 500;
}

.buttons {
  display: flex;
  align-items: center;

  padding-top: 20px;
}

.cancelButton {
  margin-left: 8px;
}

.settingsMeasurementsList {
  margin-bottom: 24px;
}

.metricName {
  margin-bottom: 16px;
}

.plantsSubtitle,
.metricsSubtitle {
  margin-bottom: 16px;
}

.metricsSubtitle {
  margin-top: 48px;
}
