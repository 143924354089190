@import 'styles/core';

.firstColumn {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  z-index: 20;
}

.week {
  left: 68px;

  & .weekNo {
    display: block;
  }
  & .datesRange {
    display: none;
  }

  &:hover {
    & .weekNo {
      display: none;
    }
    & .datesRange {
      display: block;
    }
  }
}

.isHoliday {
  color: var(--colorTextNeutral);
}

.isToday {
  color: var(--colorPrimary);
}

/* Перебиваем специфичность библиотеки за счёт global */
:global(.data-grid ) {
  & .month,
  & .week {
    &:global(.cell.read-only) {
      background: #FFF;
      color: var(--colorTextPrimary);
      font-weight: normal;

      border: none;
      cursor: default;
    }
  }

  & .month {
    &:global(.cell.read-only) {
      min-width: 68px;
      padding-left: 25px;
      text-align: left;
      text-transform: capitalize;

      outline: 1px solid #FFF;
    }
  }

  & .week {
    &:global(.cell.read-only) {
      min-width: 100px;
      padding-right: 25px;
      text-align: right;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: -1px;
      width: 1px;
      background: #CBCDD1;
    }
  }
}

.dayNo {
  cursor: pointer;

  &:hover {
    & .dayWeekNo {
      display: inline;
    }
  }
}

.dayWeekNo {
  background-color: var(--colorWhite);
  display: none;
  position: absolute;
  left: -43px;
}
