@import 'styles/core';

.summaryTable {
  border-collapse: collapse;
  border-spacing: 0;
  display: block;
  width: 333px;

  & .nameCell {
    width: 85px;
    padding-left: 4px;
  }

  & .centerSeparator {
    min-width: 16px;
    width: 100%;
  }

  & .legendItem {
    display: flex;
  }

  & .legendItemColor {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    margin: 4px 0;
  }

  & .legendItemColorSquare {
    width: 12px;
    height: 4px;
    border-radius: 1px;
    background-color: var(--colorPrimary);
  }

  & .legendItemTitle {
    font-size: 14px;
    color: var(--colorTextPrimary);
    line-height: 20px;
    white-space: nowrap;
    margin-left: 8px;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  & .valueSeparatorCell {
    width: 16px;
    min-width: 16px;
    max-width: 16px;
  }

  & .endCell {
    width: 4px;
  }

  & .titleCell {
    font-size: 13px;
    font-weight: 500;
    text-align: right;
    color: var(--colorTextSecondary);
    padding-top: 4px;
    padding-bottom: 4px;
  }

  & .valueCell {
    font-size: 13px;
    font-weight: 400;
    text-align: right;
    color: var(--colorTextPrimary);
    padding-top: 4px;
    padding-bottom: 4px;
  }

  & .modelRow {
    background-color: var(--colorThDefault);
    height: 26px;
    & .legendItemColorSquare {
      background-color: var(--colorHarvest);
    }
  }

  & .nasaRow {
    background-color: var(--colorThDefault);
    height: 26px;
    & .legendItemColorSquare {
      background-color: var(--colorWarning);
    }
  }

  & .modelRowCycle {
    height: 26px;
  }

  & .headerRow {
    background-color: var(--colorThDefault);
    height: 26px;
  }
}
