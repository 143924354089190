@import 'styles/core';

.layout {
  min-height: 100vh;
  background-color: var(--colorBackgroundPrimary);
  padding: 20px 24px 48px;

  @media (--tabletBig) {
    padding: 32px 24px 48px;
  }
}

.content {
  margin: 0 auto;
  max-width: 1156px;

  @media (--desktop) {
    max-width: 1024px;
  }

  @media (--tabletBig) {
    max-width: 100%;
  }
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0 32px 0;

  @media (--tabletBig) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 0 24px 0;
  }
}

.control {
  @media (--tabletBig) {
    margin-top: 16px;
  }
}

.title {
  @media (--tabletBig) {
    font-size: 20px;
  }
}

.infoBlock {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.infoIcon {
  width: 16px;
  height: 16px;
  margin-left: 4px;

  & path {
    fill: var(--colorTextSecondary);
  }
}

.table {
  & th, & td {
    text-align: left;

    &.firstColumn {
      padding-left: 24px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &.firstHeaderColumn {
      padding-left: 24px;
      padding-top: 14px;
      padding-bottom: 14px;
    }

    &.lastColumn {
      padding-right: 24px;
      width: 1%;
      white-space: nowrap;
    }
  }
}

.name {
  font-weight: 500;
}

.description {
  font-size: 13px;
  color: var(--colorTextSecondary);
}

.edit,
.delete {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editIcon path {
  fill: var(--colorTextSecondary);

  &:hover {
    fill: var(--colorTextPrimary);
  }
}

.edit:hover {
  & .editIcon path {
    fill: var(--colorTextPrimary);
  }
}

.avgAccurancyCellContainer, .commandWrapper {
  display: flex;
  align-items: center;
}

.avgAccurancyCellContainer {
  justify-content: space-between;
}

.commandWrapper {
  visibility: hidden;
  justify-content: flex-start;
}

.tableRow {
  cursor: pointer;

  &:hover {
    & .commandWrapper {
      visibility: visible;
    }
  }
}

.tooltipContent {
  padding: 12px 4px;
  min-width: 120px;
}

.tooltipContentRow {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 4px 0;
  font-size: 13px;
}

.tooltipContentBold {
  font-weight: 500;
}

.tableWrapper {
  max-width: 100%;
  position: relative;

  @media (--tabletBig) {
    overflow: scroll;
  }
}


.forecastNameLink {
  color: var(--colorTextPrimary);
  text-decoration: none;

  &:hover {
    & .name {
      text-decoration: underline;
    }
  }
}

.forecastName {
  min-width: 200px;
}

.tooltipWrapper {
  width: 256px;
  text-align: left;
}
