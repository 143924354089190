@import 'styles/core';

.searchListContent {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: initial;
  height: calc(100% - 53px);
}

.option {
  white-space: normal;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-weight: 400;

  &.onMobile {
    padding: 6px 16px;
  }

  & .doubleCheckIcon {
    width: 16px;
    height: 16px;
  }
}

.control.onMobile {
  padding: 11px 16px 0 16px;
}

.groupName.onMobile {
  padding: 6px 16px;
}

.iconWrapper {
  width: 20px;
  height: 20px;
  min-width: 20px;
  margin-left: 4px;

  display: flex;
  align-items: center;
  justify-content: center;
}
