@import 'styles/core';

.filterWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.selectWrapper {}

.filterTitle {
  margin-right: 8px;
  font-size: 14px;
  color: var(--colorTextSecondary);
}

.capitalizeTitle {
  text-transform: capitalize;
}
