@import 'styles/core';

.form {
  width: 100%;
  position: relative;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 32px 0 0 0;
}

.button {
  height: 40px;
  box-sizing: border-box;

  & + .button {
    margin-left: 8px;
  }
}

.dropdownWrapper {
  display: flex;
  justify-content: flex-end;
}

.dropdownContent {
  right: 0;
  width: 192px;
  padding: 14px 12px;
}
