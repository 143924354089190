@import 'styles/core';

.progressBar {
  transform: rotate(-90deg);
}

.progressBarMeter,
.progressBarValue {
  fill: none;
}

.progressBarMeter {
  stroke: #E7E9EE;
}

.progressBarValue {
  stroke: var(--colorTextSecondary);

  &.strokeLinecap {
    stroke-linecap: round;
  }

  &.green {
    stroke: var(--colorPositive);
  }

  &.yellow {
    stroke: var(--colorWarning);
  }

  &.red {
    stroke: var(--colorNegative);
  }
}

