@import 'styles/core.css';

@import 'react-datepicker/dist/react-datepicker.css';
@import 'styles/lib/datepicker.css';
@import 'styles/lib/datasheet.css';
@import 'react-select/dist/react-select.css';
@import 'date-range-picker/dist/styles.css';
@import 'date-range-picker/dist/theme/default.css';


@global-import 'handsontable/dist/handsontable.full.css';
@global-import 'react-contexify/dist/ReactContexify.min.css';

html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

html,
body,
button,
input,
optgroup,
select,
textarea {
  font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html.focus-outline-hidden *:focus {
  outline: none;
}

/** Global styles for charts */

.tick text {
  fill: var(--colorTextPrimary);
  font-weight: 400;
  stroke: none;
  font-size: 11px;
  color: var(--colorTextPrimary);
  line-height: 16px;
}

.tick line {
  stroke: #DFE6ED;
}

.domain {
  stroke: #DFE6ED;
}

.tick {
  stroke: #DFE6ED;
}

.axisX .tick line {
  stroke: #849EB8;
  stroke-dasharray: none;
}

.axisY .tick:first-of-type line {
  stroke: #849EB8;
  stroke-dasharray: none;
}

.axisY .domain {
  display: none;
}

.axisY .tick {
  stroke-dasharray: 3 2;
}

.axisYLeft.empty .tick text {
  display: none;
}


.Select-control {
  position: static!important;
}
