@import 'styles/core';

.editor {
  margin-top: 48px;

  width: 100%;
  color: var(--colorTextPrimary);
  user-select: none;

  & .title {
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 500;
    padding-left: 2px;
  }

  & .sunLightContainer {
    margin-left: -8px;
  }

  & .toggleButtons {
    padding-left: 2px;
    color: var(--colorTextPrimary);
    font-size: 14px;
    line-height: 20px;

    & .geo,
    & .custom {
      width: 50%;
      height: 28px;
      cursor: pointer;
      color: var(--colorTextPrimary);
      background-color: var(--colorWhite);
      border: 1px solid var(--colorBorderSecondary);
    }

    & .geo {
      border-radius: 4px 0 0 4px;
      border-right: none;
    }

    & .custom {
      border-radius: 0 4px 4px 0;
    }

    & .active {
      background-color: var(--colorBorderDefault);
    }

    button:focus {
      outline:0 !important;
    }
  }

  & .geoModeContainer {
    padding-left: 2px;
    padding-top: 18px;

    & .selectLine {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      & span {
        font-size: 14px;
      }

      & .geolocation {
        margin-left: 10px;
        cursor: pointer;
        display: inline-flex;

        & .locationTitle {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 160px;
          display: inline-block;
        }

        & .locationTitle:hover {
          border-bottom: 1px solid var(--colorTextSecondary);
        }

        & .icon {
          width: 36px;
        }

        &:focus {
          outline:0 !important;
        }
      }

      & .selectWrapper {
        margin-left: 16px;
      }
      & .selectButton {
        width: 186px;
        height: 28px;
      }
      & .selectPopup {
        min-width: 186px;
        right: 0;
        left: auto;
      }
    }
  }
}

.selectLineText {
  padding-right: 8px;
}
