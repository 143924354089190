@import 'styles/core';

.inputContainer {
  display: flex;
  gap: 8px;
  height: initial;
  width: initial;
}

.pinField {
  width: 40px;
  height: 48px;
  line-height: 48px;

  font-size: 16px;
  font-weight: 400;
  color: var(--colorTextPrimary);
  text-align: center;

  background: var(--colorWhite);
  border: 1px solid var(--colorBorderSecondary);
  border-radius: 4px;

  outline: none;

  &:focus {
    border: 1px solid var(--colorPrimary);
    outline: none;
  }
}

.errorText {
  padding-top: 4px;
  font-size: 12px;
  line-height: 16px;
  color: var(--colorNegative);
}

.hasError {
 & .pinField {
  border: 1px solid var(--colorNegative);
 }
}
