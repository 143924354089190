@import 'styles/core';

.selectWrapper {
  margin-bottom: 24px;
}

.mobileControls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 48px;
}

.cancelButton {
  margin-right: 8px;
}

.mobileMenu {
  margin-right: 16px;
  display: none;

  @media (--tablet) {
    display: block;
  }
}

.selectGroup {
  margin-bottom: 24px;
  border-bottom: 1px solid #E7E9EE;

  &:last-child {
    border-bottom: none;
  }
}

.selectGroupHeader {
  margin-bottom: 16px;
  line-height: 20px;
  font-size: 16px;
}
