@import 'styles/core';


.row {
  display: flex;
}

.alignCenter {
  align-items: center;
}

.spaceBetween {
  justify-content: space-between;
}


.layout {
  padding: 4px 8px;
  border-radius: 4px;
  transform: translate(0, 0);
  background-color: var(--colorBorderDefault);
  overflow: hidden;

  & + .layout {
    margin-top: 16px;
  }

  & .dash {
    margin-right: 8px;
    fill: var(--colorTextSecondary);
    cursor: move;
  }

  & .title {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
