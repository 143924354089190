@import 'styles/core';

.body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.window {
  min-width: 600px;
  height: 228px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  background: #FFFFFF;
  box-shadow: 0 1px 20px rgba(30, 42, 51, 0.2);
  border-radius: 4px;
  position: relative;
}

.closeCross {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #4A4A49;
  margin-bottom: 48px;
}

.description {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #4A4A49;
}

.actions {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  right: 30px;
  bottom: 30px;
}

.noButton {
  margin-right: 8px;
}

.yesButton {
  color: #FFFFFF;
  background: #BE1034;

  &:hover {
    color: #FFFFFF;
    background: #BE1034;
  }
}
