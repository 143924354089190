@import 'styles/core';

.layout {
  min-height: calc(100vh - 60px);
  background-color: #FAFBFC;
  padding: 0 0 24px 0;
}

.empty {
  height: 344px;
  width: 100%;
}

.emptyState {
  padding: 122px 24px;
}

.container {
  padding: 30px 0 34px;

  @media (--tabletBig) {
    padding: 32px 0;
  }

  @media (--tablet) {
    padding: 24px 0 24px 16px;
  }
}

.desktopContainer {
  display: flex;
  padding: 8px 0 24px;

  @media (--tablet) {
    display: none;
  }
}

.header {
  margin: 0 auto;
  max-width: 1232px;
  padding: 0 24px;

  @media (--tablet) {
    max-width: 100%;
    padding: 0;
  }
}

.content {
  margin: 0 auto;
  max-width: 1232px;
  padding: 0 24px;

  @media (--tablet) {
    max-width: 100%;
    padding: 0;
  }
}

.cropsPerformance {
  position: relative;
  background: #FFFFFF;
  margin: 0 0 24px 0;
  border: 1px solid #E7E9EE;
  border-radius: 4px;
  overflow: hidden;

  @media (--tablet) {
    border-radius: 0;
    border-left: none;
    border-right: none;
  }
}

.title {
  padding: 20px 25px;
  color: var(--colorTextPrimary);
  text-align: left;
  border-bottom: 1px solid #E7E9EE;
  border-bottom: none;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  @media (--tablet) {
    padding: 20px 16px;
  }
}

.speciesIcon {
  margin: 0 8px 0 0;
  width: 20px;
  height: 20px;
  fill: var(--colorTextSecondary);
}

.tableWrapper {
  position: relative;
}

.cropLink {
  text-decoration: none;
  cursor: pointer;

  &:hover {
    & .titleCellHeader {
      text-decoration: underline;
    }
  }
}

.titleCellHeader {
  font-size: 14px;
  font-weight: 500;
  color: var(--colorTextPrimary);
  line-height: 20px;
}

.titleCellDate {
  margin: 2px 0 0 0;
  font-size: 13px;
  color: var(--colorTextSecondary);
  line-height: 18px;
}

.textCell {
  font-size: 14px;
  color: var(--colorTextPrimary);
  line-height: 20px;
  text-align: left;
  padding: 0 8px;
}

.numberCell {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
  font-size: 14px;
  color: var(--colorTextPrimary);
  line-height: 20px;
  text-align: right;
  padding: 0 24px 0 8px;
}

.contentCell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  font-size: 14px;
  color: var(--colorTextPrimary);
  line-height: 20px;
  text-align: right;
  padding: 0 24px 0 8px;

  &.green {
    color: var(--colorPositive);
  }

  &.red {
    color: var(--colorNegative);
  }
}

.harvestValueMonitor {
  width: 36px;
}

.harvestMonitor {
  height: 12px;
  width: 56px;
  margin: 0 0 0 8px;
}

.harvestMonitorColor {
  background: var(--colorPrimary);
  border-radius: 2px;
  height: 12px;
}

.numberCellIcon {
  margin: 0 2px 0 0;
}

.indicatorHeaderTitle {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  color: var(--colorTextSecondary);
  line-height: 18px;
  padding: 6px 0;
  width: 288px;
  box-sizing: border-box;

  @media (width < 1280px) {
    width: 168px;
  }

  @media (--tablet) {
    width: 100%;
  }
}

.headerTitle {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  color: var(--colorTextSecondary);
  line-height: 18px;
  padding: 2px 0 2px 8px;

  & > span {
    white-space: nowrap;
  }
}

.headerTitleBig {
  min-width: 124px;
}

.noWhiteSpace {
  white-space: nowrap;
}

.arrow {
  width: 24px;
  height: 24px;
  visibility: hidden;
  transform: rotate(0deg);

  &.sortDirectionDown {
    transform: rotate(180deg);
  }
}

.activeSort {
  & .arrow {
    visibility: visible;
  }
}

.indicators {
  display: flex;
  width: 100%;
  padding: 0 0 24px 0;


  @media (--tablet) {
    display: none;
  }
}

.indicatorsContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.indicatorTitle {
  font-size: 13px;
  font-weight: 500;
  color: var(--colorTextSecondary);
  line-height: 18px;
}

.indicatorValue {
  font-size: 24px;
  color: var(--colorTextPrimary);
  letter-spacing: 0.33px;
  text-align: left;
  line-height: 32px;
  margin: 4px 0 0 0;
}

.indicatorUnit {
  font-weight: 400;
  font-size: 16px;
  margin: 0 0 0 4px;
}
