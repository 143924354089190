@import 'styles/core';

.settingsMeasurementsCrop {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
  height: 100%;
}

.header {
  margin-top: 10px;
  margin-bottom: 4px;
}

.table {
  border: none;
  margin-top: 32px;

  & .th,
  & .td {
    text-align: left;
  }

  & .th {
    padding: 10px 16px;
  }

  & .td {
    padding: 8px 51px 8px 16px;

    &:last-child {
      padding-right: 16px;
    }
  }
}

.tdLong {}

.tdMedium {}

.tdEllipsis {
  display: flex;
  min-width: 200px;
}

.tdNoWrap {
  white-space: nowrap;
}

.description {
  width: 50%;
}

.tableScroll {
  overflow-x: auto;
  overflow-y: visible;
}

.tdHeader {
  font-weight: 500;
}
