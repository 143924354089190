@import 'styles/core';

.incidentLoader {
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: var(--colorWhite);
  z-index: 1000;
}


.loader {
  width: 16px;
  height: 16px;

  animation: rotation 1.4s infinite linear;

  @media all and (-ms-high-contrast:none) {
    animation: rotation-ie 1.4s infinite linear;
  }
}

.loader circle {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite;
  stroke: var(--colorTextSecondary);

  @media all and (-ms-high-contrast:none) {
    stroke-dashoffset: 46.5;
  }
}

@keyframes dash {
  0% { stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.5;
    transform:rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform:rotate(450deg);
  }
}

@keyframes rotation {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
}

@keyframes rotation-ie {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(359deg); }
}
