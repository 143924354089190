@import 'styles/core';

.dropdownMenuWrapper,
.dropdownMenu,
.dropdownMenuButton {
  height: 100%;
}

.varietyDropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 12px;
  height: 100%;
}

.varietyText {
  width: 100%;
  padding: 8px 0;
  text-align: left;
  line-height: 20px;
}

.dropdownWrapper {
  left: 4px;
  right: auto;
  top: calc(100% + 4px);
  min-width: 184px; /* Чтобы было выравнивание по ячейке */
}

.dropdownWrapperSmallPaggings {
  padding: 4px 0;
}

.arrowDown {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
}

.dropdownItem {
  width: 100%;
  height: 32px;
  padding: 0 16px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  text-decoration: none;

  color: inherit;

  white-space: nowrap;

  &:hover {
    background-color: #F7F9FA;
  }
}

.dropdownIcon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.line {
  border: none;
  border-top: solid 1px #e1e8f0;
  margin: 8px 0;
}

.deleteButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding-top: 12px;
}

.deleteConfitm {
  margin-left: 8px;

  & svg circle {
    stroke: var(--colorTextSecondary) !important;
  }
}

.deletePopup {
  padding: 8px 16px 0;
  text-align: left;
  font-weight: normal;
  color: var(--colorTextPrimary);
}
