@import 'styles/core';

.layout {
  min-height: calc(100vh - 60px);
  background-color: #FAFBFC;
}

.contentWrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 24px 196px 24px;

  @media (--tablet) {
    padding: 0 0 196px 0;
  }
}

.loader {
  position: relative;
  height: 320px;
}

.report {
  margin-top: 16px;

  & + .report {
    margin-top: 40px;
  }
}

.dashboardHeader {
  padding-bottom: 24px;

  @media (--tablet) {
    padding-bottom: 0;
  }
}

.infoPlate {
  margin: 24px 0 0 0;
  max-width: 576px;
  background-color: var(--colorWhite);
}

.infoPlateClose {
  margin: 0 0 0 24px;
  outline: none;
  padding: 0;
  background-color: transparent;
  width: 16px;
  height: 16px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover svg path {
    fill: var(--colorTextSecondary);
  }
}

.infoPlateContent {
  display: flex;
  justify-content: space-between;
}

.mobileContainer {
  border: none;
}
