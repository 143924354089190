@import 'styles/core';

.groupFilter {
  display: flex;
  align-items: baseline;
  & .selectContainer {
    display: inline-block;
    margin-left: 8px;
  }
  & .icon {
    display: inline-block;
  }
  cursor: pointer;
}

.groupFilterTopLevel {
  &:hover {
    text-decoration: none;
    background-color: var(--colorBackgroundHover);
  }
}

.topLevelPopup {
  display: flex;
  overflow: visible;
  left: -155px;
  flex-direction: column;
  align-items: flex-end;
}

.groupFilter .selectContainer .topLevelButton {
  color: var(--colorTextSecondary);
}

.groupFiltersContainer {
  width: 240px;
  padding: 12px 12px;

  & .selectWrapper {
    margin-bottom: 12px;
    margin-right: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.selectGroup {
  margin-bottom: 16px;

  &:last-child {
    border-bottom: none;
  }
}

.button {
  position: relative;
  outline: none!important;
  width: 216px!important;
  height: 36px!important;
  cursor: pointer;
  border: 1px solid #BDCFE1!important;
  border-radius: 5px!important;
  align-items: center!important;
  justify-content: space-between!important;
  box-sizing: border-box!important;
  font-size: 14px!important;
  color: var(--colorTextPrimary)!important;
  background-color: #fff!important;
  letter-spacing: -0.15px!important;
  line-height: 20px!important;
  padding: 0 32px 0 8px!important;
  min-width: 64px!important;
  font-weight: 400!important;
  &.notSelected {
    color: #79868F!important;
  }

  &:disabled,
  &[disabled]{
    opacity: 0.6!important;
  }

  &.invalid, &.invalid:hover {
    border-color: var(--colorNegative)!important;
    box-shadow: none!important;
  }
}

.noMargin {
  margin: 0;
}

.longPopup {
  width: 100%;
}

.longButton {
  width: 100%;
}

.longWrapper {
  width: 100%;
}

.resetButton {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1DBADF;
  border: 0;
  background: none;
  padding: 8px 12px 12px 0;
  cursor: pointer;
}
