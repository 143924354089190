@import "styles/core";

.settingsBlock {
  display: inline-flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  margin-bottom: 48px;
  border: 1px solid var(--colorBorderDefault);
  border-radius: 4px;

  & .title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: var(--colorTextPrimary);
    align-self: flex-start;
    margin-bottom: 12px;
  }

  & .infoPlate {
    margin-bottom: 24px;
    font-size: 14px;
    color: var(--colorTextPrimary);
  }

  & .supportButton {
    display: inline-block;
    padding: 0;
    color: var(--colorPrimary);
    font-weight: 500;
  }

  & .valuesContainerFullWidth {
    display: none;
    @media (--tabletBigUp) {
      display: block;
    }
  }

  & .tableValues {
    float: left;
  }

  & .valuesContainer {
    display: flex;

    @media (--tabletBigUp) {
      display: none;
    }

    @media (--tabletBig) {
      display: flex;
      flex-direction: column;
    }

    @media (--tablet) {
      display: flex;
      flex-direction: row;
    }

    @media (--phoneLandscape) {
      display: flex;
      flex-direction: column;
    }

    & .values {
      &:first-child {
        margin-right: 100px;
        @media (--tablet) {
          margin-right: 16px;
        }
      }
      white-space: nowrap;
      & .settingLine {
        display: flex;
        flex-direction: row;
        max-width: 500px;
        margin-bottom: 8px;
        justify-content: space-between;
        @media (--tablet) {
          flex-direction: column;
          margin-bottom: 24px;
        }
      }
    }
  }
}

.lineTitle {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: var(--colorTextSecondary);
}

.lineValue {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: var(--colorTextPrimary);
  width: 50%;
}

.trTitle {
  padding-right: 16px;
}
