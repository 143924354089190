@import 'styles/core';


.incidentBtn {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;
  background: none;
  border: none;
  padding: 0;

  cursor: pointer;
}

.incidentControlsBtn {
  width: 28px;
  height: 28px;
}

.incidentControlsBtnExpand {
  border-right: 1px solid #B4C5D6;
}

.incidentPopupHeaderContainer,
.incidentPopupControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.incidentPopupHeader {
  font-size: 16px;
  line-height: 20px;
  color: #1E2A33;
  font-weight: 500;
}

.incidentPopupGroup {
  margin-top: 12px;
}

.incidentPopupGroupHeader,
.incidentPopupGroupText {
  padding-top: 8px;

  font-size: 14px;
  line-height: 20px;
}

.incidentPopupGroupHeader {
  color: #546B7E;
}

.incidentPopupGroupText {
  color: #1E2A33;
}

.incidentPopupGroupError {
  margin: 4px 0 0 0;
  color: var(--colorNegative);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.row {
  display: flex;
  align-items: center;
}

.delimeter {
  margin: 0 8px;
}

.timeInput {
  width: 275px;
  margin: 0 0 0 8px;
}

.controls {
  margin: 16px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & .button {
    & + .button {
      margin: 0 0 0 8px;
    }
  }
}
