@import 'styles/core';

.tableContainer {
  position: relative;

  & :global(.htCore) td.dateCell {
    color: var(--colorTextSecondary);
    white-space: nowrap;
  }

    & :global(.htCore) td.dateCell.dateCellDisabled {
    color: var(--colorTextNeutral);
    white-space: nowrap;
  }

  & :global(span.colHeader) {
    display: block;
  }
}

.tableWrapper {
  & :global(.htCore th .relative),
  & :global(.htCore th .relative .colHeader) {
    height: 100%;
  }
}

.meterHeader {
  text-align: right;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  & .meterHeaderName {
    margin-bottom: 4px;
    white-space: normal;
  }

  & .meterHeaderUnit {
    margin-bottom: 8px;
  }

  & .meterHeaderUsage {
    display: inline-block;
    font-weight: normal;
  }
}

td.disabledCell {
  background-color: var(--colorThDefault);
  font-weight: 500;
}

td.totalFirstCell,
td.totalCell {
  background-color: var(--colorThDefault);
  font-weight: 500;
}

.errorContent {
  display: none;
}

td.invalidCell {
  overflow: initial;
  position: relative;

  &:global(.current) {
    z-index: 1;

    & .errorContent {
      display: block;
    }
  }
}

.meterHeaderUsageIconWrapper {
  display: flex;
  align-items: center;
}

.meterHeaderUsageIcon {
  width: 20px;
  height: 20px;
  margin-right: 4px;

  fill: #9EA4A8;
}

.workClassName {
  font-weight: normal;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: -10px;
    bottom: -10px;
    right: -21px;
    width: 1px;
    background: var(--colorThDefault);
  }

  &.lastWorkClassName:after {
    display: none;
  }
}

.weeksHeader {
  text-align: left;
}

.weeksHeader,
.weeksEmptyHeader {
  height: 100%;
  position: relative;

  /* Хак, т.к. nth-child/first-child не работают как надо из-за виртуализации */
  &:after {
    content: '';
    position: absolute;
    bottom: -11px;
    right: -20px;
    left: -20px;
    height: 1px;
    background: var(--colorThDefault);
  }
}

.tableContainer {
  & :global(.htCore thead th) {
    padding: 8px 16px;
    text-align: center;
    font-size: 14px;
  }

  /* & :global(.htCore thead tr:nth-child(1) th:first-child),
  & :global(.htCore thead tr:nth-child(2) th:first-child),
  & :global(.htCore thead tr:nth-child(3) th:first-child) {
    border-bottom: none;
  } */

  & :global(.htCore thead tr:nth-child(3) th) {
    border-bottom: none;
  }

  /* & :global(.htCore thead tr:nth-child(4) th:nth-child(even)) {
    border-right: none;
  } */
}

.plantingCycleLink {
  display: block;
  color: var(--colorTextSecondary);
  text-decoration: none;

  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    text-decoration: underline;
  }
}

.workTypeNameText {
  overflow: hidden;
  text-overflow: ellipsis;
}
