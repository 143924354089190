@import "styles/core";

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @media (--tabletBig) {
    flex-direction: column;
  }

  & .totals {
    display: flex;
    align-items: flex-end;
    @media (--tabletBig) {
      flex-direction: column;
      width: 100%;
      margin-top: 36px;
    }
    & .total {
      margin-left: 72px;
      text-align: right;
      @media (--tabletBig) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 26px;
      }
      & .totalValue,
      & .totalHeader {
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
       }
      & .totalHeader {
        color: var(--colorTextSecondary);
        margin-bottom: 2px;
      }
      & .totalValue {
        color: var(--colorTextPrimary);
        & .value {
          @media (--tabletBig) {
            font-size: 18px;
            font-weight: 400;
          }
        }
        & .unit {
          font-size: 14px;
        }
      }
    }
  }
}






