@import 'styles/core';

.settingsIncidents {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);

  @media (--phoneLandscape) {
    padding-bottom: 120px;
  }
}

.header {
  margin-bottom: 4px;
}

.infoPlate {
  margin: 16px 0 32px;
}

.field {
  max-width: 336px;
}

.supportButton {
  display: inline-block;
  padding: 0;

  color: var(--colorPrimary);
  font-weight: 500;
}

.incidentsList {
  margin-bottom: 24px;
}

.listHeader {
  margin-bottom: 16px;
}

.incidentsListContainer {
  margin-top: 24px;
}

.searchWrapper {
  width: 288px;

  @media (--phoneLandscape) {
    width: 100%;
  }
}

.searchOptions {
  @media (--phoneLandscape) {
    width: 100%;
  }
}

.controlsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 552px;

  @media (--phoneLandscape) {
    flex-direction: column-reverse;
  }
}

.titleButton {
  @media (--phoneLandscape) {
    width: 100%;
    margin-bottom: 12px;
    justify-content: center;
  }
}
