@import 'styles/core';

.tabs {

}

.tabNavsWrapper {
  padding: 0 32px 0 32px;
}

.tabNavs {
  display: flex;
  margin-top: 8px;
  margin-bottom: 24px;

  border-bottom: 1px solid #E7E9EE;

  justify-content: left;
}

.tab {
  cursor: pointer;
  padding: 16px 0;
  margin-right: 32px;
  margin-bottom: -2px;

  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  color: var(--colorTextSecondary);
  position: relative;
}

.matchCount {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 1px 6px;

  min-width: 21px;
  height: 20px;

  background: #E7E9EE;
  border-radius: 4px;

  color: #777776;
  margin-left: 8px;
}

.active {
  color: var(--colorPrimary);
  /*box-shadow: inset 0 -5px 0 0 var(--colorPrimary);*/

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--colorPrimary);
  }

  & .matchCount {
    background: #E4F7FB;
    color: #1DBADF;
  }
}

.tabContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}


