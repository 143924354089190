@import 'styles/core';

.treeContainer {
  width: 100%;
  display: none;
}

.selected {
  display: block;
}

.rcTree {

}

.leafNode {
  align-items: center;
  & :global(.rc-tree-checkbox) {
    width: 14px!important;
    height: 14px!important;
    background-color: #FFFFFF!important;
    background-image: none!important;
    border: 1px solid #BDCFE1!important;
    border-radius: 2px!important;
    opacity: 0!important;
    &:hover {
      opacity: 1!important;
    }
  }

  & :global(.rc-tree-checkbox-checked) {
    background-color: #1DBADF!important;
    border: none!important;
    background-position: 2px 2px!important;
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.9262 0.312255C10.229 0.547667 10.2836 0.98396 10.0482 1.28674L4.64893 8.23115C4.52868 8.38581 4.34872 8.48254 4.15338 8.4974C3.95804 8.51226 3.76552 8.44393 3.62326 8.30921L0.689183 5.53145C0.410669 5.26777 0.398642 4.82824 0.662319 4.54973C0.925997 4.27122 1.36553 4.25919 1.64404 4.52286L4.02228 6.7744L8.95168 0.434242C9.1871 0.131459 9.62342 0.0768435 9.9262 0.312255Z' fill='white'/%3E%3C/svg%3E")!important;
    opacity: 1!important;
  }

  &:hover {
    & :global(.rc-tree-checkbox) {
      opacity: 1!important;
    }
  }
}

.categoryNode {
  min-height: 32px;
  width: 100%;
  align-items: center;
  &:hover {
    background-color: transparent;
  }
  color: var(--colorTextSecondary);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.treeIcon {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  vertical-align: center;
  fill: var(--colorTextSecondary);
}

:global(.rc-tree-treenode-checkbox-checked) {
  background-color: #E4E9F0;
}

.treeNode {
  min-height: 36px;
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
  /* & :global(.rc-tree-switcher) {
    background-image: none!important;
    margin-right: 8px!important;
  } */
  &:hover {
    background-color: #E4E9F0;
  }
}

.treeTitle {
  margin-left: 4px;
  padding: 2px 0;
  color: var(--colorTextPrimary);
  font-size: 14px;
  line-height: 20px;
  width: 412px;
  display: flex;
  white-space: normal;
  justify-content: space-between;
}

.titleText {
  padding-right: 8px;
  max-width: calc(100% - 16px);
}

.units {
  color: var(--colorTextSecondary);
}

.complete {}

.switcherIcon {

}

.switcherIconExpanded {
  & svg {
    transform: rotate(90deg);
  }
}

.loader {
  width: 20px;
  height: 20px;

  animation: rotation 1.4s infinite linear;

  @media all and (-ms-high-contrast:none) {
    animation: rotation-ie 1.4s infinite linear;
  }
}

.loader circle {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite;
  stroke: var(--colorPrimary);

  @media all and (-ms-high-contrast:none) {
    stroke-dashoffset: 46.5;
  }
}


@keyframes dash {
  0% { stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.5;
    transform:rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform:rotate(450deg);
  }
}

@keyframes rotation {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
}

@keyframes rotation-ie {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(359deg); }
}

:global(.rc-tree) {
  & .treeNode {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    padding-left: 28px;

    &:global(.rc-tree-treenode span.rc-tree-switcher) {
      width: 24px;
      height: 24px;
      background-image: none;

      & span {
        width: 24px;
        height: 24px;
      }
      & svg path {
        fill: var(--colorTextSecondary);
      }
    }

    &:global(.rc-tree-node-content-wrapper) {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  /* иконка */
  & :global(.rc-tree-treenode span.rc-tree-iconEle) {
    width: 20px;
    height: 20px;
  }

  /* контейнер названия и папки */
  & :global(.rc-tree-treenode span.rc-tree-node-content-wrapper) {
    display: flex;
    align-items: flex-start;
  }

  /* отступ для вложенных категорий */
  & :global(.rc-tree-indent-unit) {
    width: 24px;
    margin-left: 2px;
  }

  /* иконка группы метрик (её нужно скрывать) */
  & :global(.rc-tree-treenode span.rc-tree-iconEle.rc-tree-icon__docu) {
    visibility: hidden;
  }

  /* иконка папки */
  & :global(.rc-tree-treenode span.rc-tree-iconEle.rc-tree-icon__customize) {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .leafNode {
    /* отступ перед чекбоксом */
    & :global(.rc-tree-indent-unit-start) {
      width: 0;
    }

    & :global(.rc-tree-indent) {
      width: 24px;
      margin-left: 5px;
    }

    &.secondNestingLevel {
      & :global(.rc-tree-indent) {
        width: 2px;
        margin-left: 0;
      }
    }

    &.thirdNestingLevel {
      & .treeTitle {
        width: 375px;
      }
    }

    /* отступ между чекбоксом и иконкой */
    &:global(.rc-tree-treenode span.rc-tree-checkbox) {
      margin-right: 10px;
      margin-top: 5px;
    }
  }

  & .categoryNode {
    & :global(.rc-tree-iconEle.rc-tree-icon__docu) {
      margin-right: 0;
      width: 14px;
    }
  }
  /*& :global() {}*/
}
