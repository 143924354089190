@import 'styles/core';

.body {
  min-width: 328px;
  width: 560px;
  margin: 0 auto;

  @media (--tabletBig) {
    width: 100%;
    padding: 0 16px;
  }
}

.header {
  margin: 32px 0;
}
