@import 'styles/core';

.table {
  width: 100%;
  background-color: #fff;
  border-radius: 2px;
  border: solid 1px #e7e9ee;
  border-spacing: 0;

  @media (--tabletBig) {
    border-left: none;
    border-right: none;
    border-radius: 0;
  }

  & thead {
    background-color: #fafbfc;
    box-shadow: inset 0 -1px 0 0 #e7e9ee;

    @media (--tabletBig) {
      display: none;
    }
  }

  & th,
  & td {
    padding: 10px 24px 10px 8px;

    &:first-child {
      padding-left: 25px;
    }

    @media (--tabletBig) {
      display: none;
      padding: 16px;

      &:first-child {
        padding-left: 16px;
      }
    }

    &.tdPhoneVisible {
      position: relative;

      @media (--tabletBig) {
        display: block;
      }

      & .more {
        @media (--tabletBig) {
          display: flex;
        }
      }
    }
  }

  & th {
    padding-top: 13px;
    padding-bottom: 13px;
    padding-right: 0;

    text-align: left;
    font-size: 13px;
    color: var(--colorTextSecondary);
    font-weight: 500;

    cursor: pointer;

    &:hover {
      background-color: #e8ebed;
    }
  }

  & td {
    box-shadow: inset 0 -1px 0 0 #e7e9ee;

    font-size: 14px;
    color: var(--colorTextPrimary);

    &:last-child {
      padding-right: 8px;
    }
  }

  & tr {
    &:last-child {
      & td {
        box-shadow: none;
      }
    }
  }
}

.thContent {
  display: flex;
  align-items: center;
}

.thRight {
  text-align: right;

  & .thContent {
    justify-content: flex-end;
  }
}

.tdSpecies {
  text-transform: capitalize;
}

.tdCycleWeek {
  text-align: right;
}

.valuesValue {
  line-height: 20px;
}

.valuesTotalGoal {
  line-height: 18px;
  font-size: 13px;
  color: var(--colorTextSecondary);
}

.arrow {
  width: 24px;
  height: 24px;
  visibility: hidden;
  transform: rotate(180deg);

  &.sortDirectionDown {
    transform: rotate(0deg);
  }
}

.activeSort {
  & .arrow {
    visibility: visible;
  }
}

.edit,
.addHarvest,
.delete {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.otherWrapper {
  position: relative;
  height: 36px;
  display: flex;
  align-items: center;

  & .commandWrapper {
    position: absolute;
    height: 36px;
    display: none;
    top: 0;
    left: -26px;
    align-items: center;
    justify-content: flex-start;

    @media (--tabletLandscape) {
      padding: 0;
    }

    & .delete {
      & .deleteIcon {
        & path {
          fill: var(--colorTextSecondary);
        }
      }
    }
  }

  & .progressWrapper {
    display: block;
  }

  &.focused {
    & .commandWrapper {
      display: flex;
    }

    & .progressWrapper {
      visibility: hidden;
    }
  }
}

.cycleRow {
  &:hover {
    & .otherWrapper {
      & .commandWrapper {
        display: flex;


        & .compareButton:hover {
          & .compareIcon {
            & path {
              fill: var(--colorTextPrimary);
            }
          }
        }

        & .delete:hover {
          & .deleteIcon {
            & path {
              fill: var(--colorTextPrimary);
            }
          }
        }

        & .edit:hover {
          & .editIcon {
            fill: var(--colorTextPrimary);
          }
        }

        & .addHarvest:hover {
          & .harvestIcon {
            fill: var(--colorTextPrimary);
          }
        }

        & .addHarvestDisabled:hover {
          & .harvestIcon {
            fill: #9EA4A8;
          }
        }

      }

      & .progressWrapper {
        visibility: hidden;

        &.withoutButtons {
          visibility: visible;
        }
      }
    }
  }
}

.more {
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 4px;
  top: 1px;
  width: 48px;
  height: 48px;
}

.dropdownContent {
  min-width: 144px;
}

.dropdownItem {
  width: 100%;
  height: 32px;
  padding: 0 16px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  text-decoration: none;

  color: inherit;

  white-space: nowrap;

  &.itemDisabled {
    color: #9EA4A8;

    & svg path {
      fill: #9EA4A8;
    }
  }

  &:hover {
    background-color: #F7F9FA;

    &.itemDisabled {
      background-color: #FFF;
    }
  }
}

.dropdownIcon {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 20px;
  width: 20px;
  margin-right: 8px;

  & .editIcon,
  & .harvestIcon {
    fill: var(--colorTextSecondary);
  }
}

.editIcon,
.harvestIcon {
  fill: var(--colorTextSecondary);
}

.harvestIcon {
  width: 20px;
  height: 20px;
}

.deleteWrapper {
  position: relative;
}

.deleteTooltip {
  padding: 16px;
  overflow: visible;
  white-space: normal;

  position: absolute;
  width: 250px;
  left: -110px;

  background: #FFFFFF;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04), 0px 8px 24px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  border: 1px solid #E1E8F0;
  box-sizing: border-box;
  cursor: default;
  z-index: 100;

  @media (--tabletBig) {
    display: none;
  }

  &.bottom {
    top: calc(100% + 10px);

    & .deleteTooltipTriangle {
      top: -6px;
      border-right-color: transparent;
      border-bottom-color: transparent;
    }
  }

  &.top {
    bottom: calc(100% + 10px);

    & .deleteTooltipTriangle {
      bottom: -6px;
      border-left-color: transparent;
      border-top-color: transparent;
    }
  }

  & .deleteTooltipTriangle {
    position: absolute;
    left: 120px;

    width: 12px;
    height: 12px;
    box-sizing: border-box;

    background: #FFFFFF;
    border: 1px solid #E1E8F0;
    transform: rotate(45deg);
  }


  & .deleteTooltipTitle {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    color: var(--colorTextPrimary);
  }

  & .deleteTooltipActions {
    display: flex;
    justify-content: flex-end;

    padding: 0;
    margin: 12px 0 0 0;
  }

  & .deleteTooltipActionCancel {
    padding: 5px 8px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    display: flex;
    align-items: center;
    text-align: center;

    color: var(--colorTextSecondary);

    cursor: pointer;
  }

  & .deleteTooltipActionOk {
    padding: 5px 8px;
    margin: 0 0 0 8px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    display: flex;
    align-items: center;
    text-align: center;

    color: var(--colorNegative);

    cursor: pointer;
  }
}

.dialog {
  display: none;
  white-space: normal;
  justify-content: center;
  align-items: center;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  @media (--tabletBig) {
    display: flex;
  }

  & .wrapper {
    width: calc(100% - 32px);
    max-width: 328px;
    margin: 0;
  }

  & .dialogText {
    margin: 0;
  }

  & .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 36px;
  }

  & .confirm {
    margin-left: 8px;
  }

  & .cancel {
  }
}
