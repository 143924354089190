@import 'styles/core';

.desktopRangePickerContainer {
  display: flex;
  position: relative;

  @media (--tablet) {
    display: none;
  }
}

.mobileMenu {
  display: none;
  padding-right: 16px;

  @media (--tablet) {
    display: block;
  }
}

.mobilePeriodSelect {
  margin-bottom: 24px;
}

.select {
  margin-right: 8px;
}

.selectButton {
  height: 32px;
  min-width: 144px;
}

.selectPopup {
  min-width: 144px;
}

.customDatePickerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--tablet) {
    display: initial;

    & :global {
      & .react-datepicker-wrapper,
      & .react-datepicker__input-container {
        width: 100%;
      }
    }
  }
}

.invisibleDatepickerButton {
  visibility: hidden;
  height: 32px;
}

.secondDatePicker {
  position: absolute;
  right: 30px;
}

.highlightedDate {
  color: var(--colorPrimary);
}

.leftButton,
.rightButton {
  position: relative;
  display: flex;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: none;
  background: none;
  background: #FFFFFF;
  border: 1px solid #BDCFE1;
  border-radius: 4px;
  cursor: pointer;

  & svg {
    /* fix iOS safari svg bug https://stackoverflow.com/questions/4504942/mobile-safari-svg-problem */
    position: absolute;
    top: 9px;
  }

  &:hover svg path {
    fill: var(--colorTextSecondary);
  }

  &.disabled {
    cursor: not-allowed;

    &:hover svg path {
      fill: #777776;
    }
  }

  @media (--tablet) {
    display: none;
  }
}

.leftButton {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}

.rightButton {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
}

.disabled {
  opacity: 0.5;
}

.mobileControls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 32px;
}

.cancelButton {
  margin-right: 8px;
}

.dateButtonFocused {
  border: 1px solid var(--colorPrimary);
}
