@import 'styles/core';

.option {
  padding: 6px 12px;
}

.cycleCompartment {
  color: var(--colorTextSecondary);
  margin-top: 4px;
}

.cycleVarietyName {
  padding: 10px 12px 6px;
  line-height: 20px;
  font-size: 14px;
  color: var(--colorTextPrimary);
  font-weight: 500;
}

.optionsHeader {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  padding: 8px 12px 10px 12px;

  line-height: 20px;
  font-size: 14px;
  color: var(--colorTextSecondary);
}

.showAllYearsButton {
  padding: 0;
  line-height: 20px;
  font-weight: normal;
}
