@import 'styles/core';

.menuPage {
  background-color: var(--colorBackgroundPrimary);
}

.menuContent {
  min-width: 720px;

  @media (--tabletLandscape) {
    min-width: 100%;
  }

  /* @media (--tablet) {
  } */
}
