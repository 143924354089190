@import 'styles/core';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  margin-right: 8px;
  font-size: 14px;
  color: #777776;
}

.selectButton {
  justify-content: flex-end;
}

.controls {
  display: flex;
}

.button {
  height: 32px;
  margin-left: 24px;
}

.selectboxFilterWrapper {
  margin-right: 16px;
}

.capitalizeTitle {
  text-transform: capitalize;
}
