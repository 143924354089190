@import 'styles/core';


.layout {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;

  fill: var(--colorPlantPrimary);
  background-color: var(--colorPlantSecondary);
}

.tomato {
  fill: var(--colorTomatoPrimary);
  background-color: var(--colorTomatoSecondary);
}

.cucumber {
  fill: var(--colorCucumberPrimary);
  background-color: var(--colorCucumberSecondary);
}

.eggplant {
  fill: var(--colorEggplantPrimary);
  background-color: var(--colorEggplantSecondary);
}

.lettuce {
  fill: var(--colorLettucePrimary);
  background-color: var(--colorLettuceSecondary);
}

.pepper {
  fill: var(--colorPepperPrimary);
  background-color: var(--colorPepperSecondary);
}

