@import 'styles/core';

.tooltipContent {
  padding: 12px;
}

.locationName,
.compartment,
.dates {
  font-size: 13px;
  line-height: 18px;

  color: var(--colorTextSecondary);
}

.productName {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
}

.harvest {
  margin-top: 4px;
}


