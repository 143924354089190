@import 'styles/core';

.photosTypes {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 60px;
  width: 36px;
  min-width: 36px;
  max-width: 36px;

  & .photoType {
    width: 135px;
    height: 135px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: .6px;
    color: var(--colorTextSecondary);
    transform: rotate(-90deg) translate(0px,56px);
    white-space: nowrap;
    text-align: right;
    padding: 8px;

    &.extra {
      background-color: #FAFBFC;
      color: var(--colorTextNeutral);
    }
  }
}

@media only screen and (max-width: 640px) {
  .photosTypes {
    width: 28px;
    padding-left: 7px;

    & .photoType {
      padding: 2px;
    }
  }
}
