@import 'styles/core';

.resetPageWrapper {
  width: 100%;
  padding-top: 31px;
}

.resetPageHeader {
  font-size: 24px;
  line-height: 32px;
  color: var(--colorTextPrimary);
  margin: 0;
  margin-bottom: 16px;
}

.resetPageSubHeader {
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
  margin-bottom: 20px;
}

.emailField {
  margin-bottom: 16px;
}

.sendButton,
.sendButtonTitle {
  width: 100%;
}

.sendButton {
  height: 40px;
  text-align: center;
}

.boldEmail {
  font-weight: 500;
}

.backButton {
  margin-bottom: 8px;
}
