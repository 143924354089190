@import 'styles/core';

.layout {
  & .input {
    width: 100%;
    height: 48px;
    margin: 24px 0 0 0;
    text-align: left;
    border-radius: 4px;
    box-sizing: border-box;
  }
}

.form {
  width: 100%;
}

.row {
  & + .row {
    margin: 24px 0 0 0;
  }
}

.title {
  margin: 0 0 32px 0;
  font-size: 24px;
  line-height: 32px;
  color: var(--colorTextPrimary);
  letter-spacing: 0.33px;
  text-align: left;
  font-weight: 500;
}

.part {
  & + .part {
    margin: 48px 0 0 0;
  }
}

.subtitle {
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  color: var(--colorTextPrimary);
  letter-spacing: -0.32px;
  font-weight: 500;
}

.description {
  margin: 12px 0 24px 0;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextSecondary);
  letter-spacing: -0.15px;
  font-weight: 400;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0 0 0;
}

.back {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.backIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.backText {
  line-height: 18px;
  margin: 0 0 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  color: var(--colorTextSecondary);
  letter-spacing: -0.08px;
}


.button {
  height: 40px;
  box-sizing: border-box;

  & + .button {
    margin-left: 8px;
  }
}

.selectButton {
  width: 480px;
  height: 48px;
}

.customDatePickerInput {

}

.customDatePickerInputWrapper {
  margin: 24px 0 0 0;
  width: 480px;
  height: 48px;
}

.flexRow {
  display: flex;
  align-items: center;
}

.plantingDensityAdd {
  user-select: none;
  cursor: pointer;
  margin-top: 18px;
  font-weight: 500;
  font-size: 14px;
  fill: var(--colorTextSecondary);
  color: var(--colorTextSecondary);
  vertical-align: middle;
  display: inline-block;
  & .entity {
    vertical-align: middle;
    display: inline-block;
  }
}

.fields {

}

.plantingDensityRow {
  position: relative;
  display: flex;
  margin-bottom: 16px;

  & .rowTitle {
    position: absolute;
    display: none;
    @media (--tablet) {
      display: block;
    }
    left: 0;
    top: 16px;
  }

  @media (--tablet) {
    flex-direction: column;
    align-items: flex-end;
  }

  &:last-child {
    margin-bottom: 0!important;
  }

  & .dateSelector {
    width: 212px;
    @media (--tablet) {
      width: 100%;
    }
  }

  & .dateSelectorWrapper {
    width: 212px;
    @media (--tablet) {
      width: 100%;
    }
  }

  & .plantingValue {
    width: 100%;
    margin-right: 12px;
    @media (--tablet) {
      margin-bottom: 12px;
      margin-right: 0;
    }
  }

  & .removeIconContainer {
    cursor: pointer;
    width: 36px;
    min-width: 36px;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 8px;
    align-items: center;
    padding-top: 4px;

    @media (--tablet) {
      margin-right: 5px;
      order: -1;
    }
  }

  & .noHover {
    cursor: default!important;
  }


  & .deleteIconWrapper {
    width: 20px;
  }
}
