@import 'styles/core';

.cellPhoto {
  width: 180px;
  height: 130px;
  box-sizing: border-box;
  position: relative;
  padding: 7px 22px 7px 22px;
  font-size: 14px;
  color: #BDCFE1;
  letter-spacing: 0.58px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 14px;
  text-align: right;
  background-color: rgba(241, 243, 246, .5);
  cursor: pointer;
  background-size: cover;
  background-position: center;
  border-radius: 4px;

  &.addPhotoDisabled {
    cursor: default;
  }

  &.loading {
    background-color: #D5E1EE;
    cursor: pointer;
  }

  & .cellPhotoHover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    pointer-events: none;
    border: 4px var(--colorPrimary) solid;
    border-radius: 4px;
  }

  &:hover .cellPhotoHover {
    display: block;
  }

  &:hover .cellPhotoHeader {
    display: flex;
  }

  &:hover .cellPhotoComment {
    height: 70px;
    word-wrap: break-word;
    white-space: pre-wrap;
    text-align: left;
  }

  &.selected {
    background: rgba(74, 144, 226, .2);
  }

  & .cellPhotoHeader {
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: none;
    background-color: rgba(0, 0, 0, .4);
    padding: 9px 10px 3px;

    @media only screen and (max-width: 640px) {
      display: none!important;
    }
  }

  & .cellPhotoIcons {
    display: flex;
    justify-content: space-between;
    //width: 72px;
  }

  & .cellPhotoIconWrapper {
    & + .cellPhotoIconWrapper {
      margin-left: 8px;
    }
  }

  & .cellPhotoIcon {
  }

  & .cellPhotoTime {
    color: #ffffff;
    font-weight: 500;
    font-size: 12px;
    position: relative;
    top: -2px;
    left: -4px;
  }

  & + .cellPhoto {
    margin-top: 5px;
  }

  & .cellPhotoComments {
    display: flex;
    position: absolute;
    bottom: 8px;
    left: 10px;
    right: 10px;
    padding-left: 4px;

    & .cellPhotoComment {
      font-weight: 300;
      font-size: 12px;
      letter-spacing: 0.5px;
      color: #FFFFFF;
      background-color: rgba(49, 52, 59, .7);
      border-radius: 5px;
      padding: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & .cellPhotoCommentCount {
      position: absolute;
      top: -10px;
      left: 0;
      padding: 4px;
      background: var(--colorNegative);
      border-radius: 5px;
      font-style: normal;
      font-weight: bold;
      font-size: 9px;
      letter-spacing: 0.375px;
      line-height: 100%;
      color: #FFFFFF;
    }
  }

  & .cellPhotoReplaceWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    & > div {
      margin-top: 10px;
      font-weight: bold;
      font-size: 14px;
      letter-spacing: .6px;
      color: #BDCFE1;
      text-shadow: 1px 1px 0 #FFFFFF;
    }
  }
}
