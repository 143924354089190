@import 'styles/core';

.errorContent {
  position: absolute;
  top: 0;
  left: calc(100% + 8px);
}

.errorPlate {
  background-color: var(--colorWhite);
  min-width: 216px;
  min-height: 76px;
}

.alignLeft {
  & .errorContent {
    left: initial;
    right: calc(100% + 8px);
  }
}

.alignBottom {
  & .errorContent {
      top: initial;
      bottom: 0;
  }
}
