@import 'styles/core';


.layout {
}

.form {

}

.field {
  width: 100%;

  &::placeholder {
    color: #79868F;
  }

  & .fieldButton {
    width: 100%;
    height: 32px;
    padding: 0 32px 0 12px;
  }
}

.row {
  width: 100%;

  & + .row {
    margin: 12px 0 0 0;
  }
}

.controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 16px 0 0 0;

  & > * + * {
    margin-left: 8px;
  }
}

.button {
  height: 32px;

  padding: 0 16px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  cursor: pointer;

  white-space: nowrap;

  font-size: 14px;
  line-height: 14px;
}
