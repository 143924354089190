@import 'styles/core';

.layout {
  background: red;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color:rgba(255, 255, 255, 0.5);;
}

.content {
  background:var(--colorWhite);
  box-shadow: 0px 1px 20px rgba(30, 42, 51, 0.2);
  border-radius: 4px;
  margin: 144px auto 0;
  width: 464px;
  padding: 24px;
  text-align: center;
}

.text {
  margin-top: 16px;
}


