@import 'styles/core';

.panel {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 480px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid var(--colorBorderDefault);
  background-color: white;
  transition: right 150ms ease-out;
  z-index: 999;
  visibility: visible;

  @media (--phoneLandscape) {
    width: 100%;
  }

  &.collapsed {
    right: -500px;
    visibility: hidden;
    display: none;
  }

  & > .header {
    padding: 0 20px 0 32px;
    height: 68px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.38px;
    color: var(--colorTextPrimary);
    border-bottom: 1px solid var(--colorBorderDefault);
  }

  & .iconButton {
    width: 36px;
    height: 36px;
    outline: none;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-shrink: 0;

    & .icon {
      height: 20px;
      width: 20px;
      flex-shrink: 0;
    }

    &:hover {
      & svg path {
        fill: var(--colorTextSecondary);
      }
    }
  }

  & > .body {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 32px 32px 96px 32px;
    overflow: auto;
  }

  & .section {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    & + .section {
      margin-top: 32px;
    }

    & .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.32px;
      color: var(--colorTextPrimary);
    }

    & .body {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.15px;
      color: var(--colorTextPrimary);
    }

    & .simpleText {
      margin-bottom: 16px;
    }

    & .table {
      border-spacing: 0;

      & .row {}

      & .headerCell {
        text-align: left;
        vertical-align: top;
        padding: 0 0 16px 0;
      }

      & .headerCellContent {
        padding: 0 48px 0 0;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.15px;
        color: var(--colorTextSecondary);
      }

      & .cell {
        vertical-align: top;
        padding: 0 0 16px 0;
      }

      & .cellContent {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.15px;
        color: var(--colorTextPrimary);
      }
    }

    & .tooltip {
      width: 240px;
    }

    & .label {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.15px;
      color: var(--colorTextPrimary);
      align-items: center;

      & svg {
        display: inline;
        vertical-align: middle;
        margin-left: 8px;
      }

      &:hover {
        & svg {
          fill: var(--colorTextSecondary);
        }
      }
    }

    & .link {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.15px;
      color: var(--colorTextSecondary);
      text-decoration: none;
      display: flex;
      align-items: center;
      cursor: pointer;

      & svg {
        margin-left: 8px;

        & path {
          fill: var(--colorTextSecondary);
        }
      }

      &:hover {
        text-decoration: underline;
      }
    }

    & .button {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.15px;
      color: var(--colorTextPrimary);
      text-decoration: none;
      background: transparent;
      outline: none;
      border: none;
      cursor: pointer;
      padding: 0;
      margin: 0;
      text-align: left;

      &:hover {
        text-decoration: underline;
      }
    }

    & .card {

      & + .card {
        margin: 24px 0 0 0;
      }

      & .title {
        margin: 0 0 8px 0;
        color: var(--colorTextSecondary);
      }

      & .description {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        margin: 0 0 8px 0;
      }

      & .data {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.severityTooltip {
  text-align: left;
  z-index: 1001;
}

.label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.severityColor {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 4px;
}

.settingsLink {
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerText {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 8px;
  width: 320px;
}

.chartWrapper {}

.incidentDetails {
  line-height: 20px;
  display: block;
}
