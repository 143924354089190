@import 'styles/core';

.warningPlate {
  margin-bottom: 24px;
  font-size: 14px;
  color:#4a4a49;
}

.page {
  & .container {
    & .potentialHarvestRow {
      display: flex;
      margin-bottom: 48px;
      flex-direction: row;
      @media (--tabletBig) {
        flex-direction: column;
      }

      & .indicator {
        width: 330px;
        min-width: 330px;
        @media (--tabletBig) {
          width: 100%;
          margin-bottom: 24px;
        }
        @media (--phoneLandscape) {
          min-width: auto;
        }
      }

      & .harvestChart {
        width: calc(100% - 330px - 24px);
        margin-left: 24px;
        @media (--tabletBig) {
          width: 100%;
          margin-left: 0;
        }
      }
    }
    & .calculationOverviewTitle {
      margin-bottom: 24px;
    }
    & .overviewRow {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin-bottom: 48px;
      flex-direction: row;
      @media (--tabletBig) {
        flex-direction: column;
      }
      & .overviewChart {
        max-width: 802px;
        min-width: 640px;
        margin-right: 24px;
        @media (--desktopSmall) {
          min-width: 386px;
        }
        @media (--tabletBig) {
          max-width: 100%;
        }
      }
      & .lightingProductivity {
        width: 100%;
        @media (--tabletBig) {
          margin-left: 0;
          height: 242px;
        }
      }
    }
  }
}
