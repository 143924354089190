.main {
  display: flex;
  flex-flow: column;
}

.scrollable {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: auto;
}
