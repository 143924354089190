@import 'styles/core';

.link {
  text-decoration: none;
  cursor: pointer;
  color: var(--colorTextPrimary);
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    text-decoration: underline;
  }
}
