@import 'styles/core';

.graphHeaderWrapper {
  margin-bottom: 28px;
}

.graphHeaderContent {
  padding-top: 25px;
}

.graphHeader {
  display: flex;
  justify-content: space-between;
}

.graphHeaderText {
  margin: 0;

  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  color: var(--colorTextPrimary);
}

.select {
  & .selectButton {
    color: var(--colorTextSecondary);
  }
}

.selectPopup {
  left: initial;
  right: 0;
}

.toggles {
  display: flex;
}

.percentageToggle {
  margin-right: 16px;
}
