@import 'styles/core';

.abnormalityIncidentOverlay {
  position: absolute;

  background-color: transparent;

  z-index: 1020;

  &.red {
    background: rgba(210, 94, 94, 0.16);
    border-left: 1px dashed #F94440;
    border-right: 1px dashed #F94440;
  }
}

.incidentOverlayStartOutOfRange {
  border-left: none;
}

.incidentOverlayEndOutOfRange {
  border-right: none;
}

.abnormalityIncidentControls,
.abnormalityIncidentPopup {
  position: absolute;
  top: 5px;
  left: calc(100% + 8px);
}

.abnormalityIncidentControls {
  width: 58px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: #FFF;
  border: 1px solid #B4C5D6;
  border-radius: 4px;

  &.leftPosition {
    left: -66px;
  }
}

.abnormalityIncidentPopup {
  width: 510px;
  left: calc(100% + 17px);
  padding: 16px;

  background: #FFF;
  border: 1px solid #E1E8F0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 8px 24px rgba(0, 0, 0, 0.02);
  border-radius: 4px;

  position: relative;

  &:after, &:before {
    right: 100%;
    top: 25px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #FFF;
    border-width: 9px;
    margin-top: -9px;
  }
  &:before {
    border-color: rgba(225, 232, 240, 0);
    border-right-color: #E1E8F0;
    border-width: 10px;
    margin-top: -10px;
  }

  &.leftPosition {
    left: -513px;

    &:after, &:before {
      left: 100%;
    }

    &:after {
      border-right-color: transparent;
      border-left-color: #FFF;
    }
    &:before {
      border-right-color: transparent;
      border-left-color: #E1E8F0;
    }
  }

  &.topPosition {
    top: 0;
    left: 7px;
  }
}

.incidentBtn {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;
  background: none;
  border: none;
  padding: 0;

  cursor: pointer;
}

.incidentControlsBtn {
  width: 28px;
  height: 28px;
}

.incidentControlsBtnExpand {
  border-right: 1px solid #B4C5D6;
}

.incidentPopupHeaderContainer,
.incidentPopupControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.incidentBtnCollapse {
  margin-right: 16px;
}

.incidentPopupHeader {
  font-size: 16px;
  line-height: 20px;
  color: #1E2A33;
  font-weight: 500;
}



.incidentPopupGroup {
  margin-top: 12px;
}

.incidentPopupGroupHeader,
.incidentPopupGroupText {
  padding-top: 8px;

  font-size: 14px;
  line-height: 20px;
}

.incidentPopupGroupHeader {
  color: #546B7E;
}

.incidentPopupGroupText {
  color: var(--colorTextPrimary);
}

.incidentPopupGroupRow {
  display: flex;
  align-items: center;
}

.incidentPopupGroupIcon {
  margin: 0 12px 0 0;
}

.incidentPopupGroupList {
  margin: 16px 0;
  padding: 0 0 0 16px;
}

.controls {
  margin: 16px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & .button {
    & + .button {
      margin: 0 0 0 8px;
    }
  }
}
