@import 'styles/core';

.chart {
  width: 100%;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  display: flex;

  & :global(.tick text) {
    fill: var(--colorTextPrimary);
    font-weight: 400;
    stroke: none;
    font-size: 11px;
    color: var(--colorTextPrimary);
    line-height: 16px;
  }

  & :global(.tick line) {
    stroke: #DFE6ED;
  }

  & :global(.domain) {
    stroke: #DFE6ED;
  }

  & :global(.tick) {
    stroke: #DFE6ED;
  }

  & .lineX {
    stroke: #DFE6ED;
    stroke-width: 1px;
  }

  & .lineY {
    stroke: #DFE6ED;
    stroke-width: 1px;
  }

  & .axisX {
    & :global(.tick line) {
      stroke: #849EB8;
      stroke-dasharray: none;
    }
  }

  & .axisY {
    & :global(.tick:first-of-type line) {
      stroke: #849EB8;
      stroke-dasharray: none;
    }

    & :global(.domain) {
      display: none;
    }

    & :global(.tick) {
      stroke-dasharray: 3 2;
    }
  }

  & .axisYLeft.empty {
    & :global(.tick text) {
      display: none;
    }
  }

  & .emptyState {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    text-align: center;
    letter-spacing: -0.154px;

    color: var(--colorTextSecondary);
  }
}

.chartOverlay {
  fill: none;
  pointer-events: all;
}

.bar {
  fill: var(--colorPrimary);

  &.hover {
    fill: var(--colorPrimaryHovered);

    &.noData {
      fill: #A4B2BD;
    }
  }

  &.noData {
    fill: #8293A1;
  }

  &.transparent {
    fill: none;
    pointer-events: all;
  }
}

.barDash {
  fill: var(--colorSecondary);

  stroke: #FFF;
  stroke-width: 2px;
}

.tooltipDate {
  text-transform: capitalize;
}


.barItem {

  &.hover {
    &.noData {

    }
  }

  &.noData {

  }

  &.transparent {
    fill: none;
    pointer-events: all;
  }
}
