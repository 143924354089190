@import 'styles/core';

.content {
  font-size: 14px;
  line-height: 20px;
}

.presetsHeader {
  padding: 20px 30px;
  padding: 25px 32px 12px 32px;
  color: var(--colorTextSecondary);
}

.preset {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 14px 30px;
  background: #FFFFFF;
  border-bottom: 1px solid #E1E8F0;
  color: var(--colorTextPrimary);
  cursor: pointer;
}

.preset:nth-of-type(1) {
  border-top: 1px solid #E1E8F0;
}

.preset:hover {
  background: #F2F3F5;
}

.presetHeader {
  display: flex;
  flex: 1 1 100%;
}

.graphIcon {
  margin-right: 8px;
  width: 20px;
  height: 20px;
  fill: var(--colorTextSecondary);
}

.moreIcon {
  width: 20px;
  height: 20px;
  fill: var(--colorTextSecondary);
}

.delete {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deleteIcon {
  @media only screen and (min-width: 1024px) {
    display: none;
  }

  fill: #777776;
  fill-opacity: 0.5;

  &:hover {
    fill-opacity: 1;
  }
}

/*1024*/
.preset:hover .deleteIcon {
  display: block;
}
