@import 'styles/core';

.point {
  position: relative;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid #BDCFE1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  align-self: flex-start;
  flex-shrink: 0;
  flex-grow: 0;
  background-color: #FFFFFF;

  & .arrow {
    display: none;
  }
}

.disabled {
  background-color: #EFF3F4;
}

.checkbox {
  position: relative;
  display: flex;
  align-items: center;

  & > input {
    display: none;

    &:checked + .point {
      background-color: var(--colorPrimary);
      border: 1px solid var(--colorPrimary);
      & .arrow {
        display: flex;
      }
    }

    &:checked + .point.noBackgroundColor {
      background-color: #FFFFFF!important;
    }
  }

  & > span {
    margin-left: 10px;
    font-size: 14px;
    color: var(--colorTextSecondary);
    line-height: 20px;
    font-weight: 400;
  }
}


.alignCenter {
  & .point {
    align-self: center;
  }
}

.noWrap {
  white-space: nowrap;
}
