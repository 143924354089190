@import 'styles/core';

.header {
  margin: 0 0 24px 0;
}

.header2 {
  margin: 0;
}

.filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button {
  height: 32px;
}

.chartsWrapper {
  min-height: 332px;
}


.graphHeaderWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 32px 0;
}

.graphWrapper {
  margin: 24px 1px;
  overflow: visible;
}
