@import 'styles/core';

.body {
  width: 100%;
  padding: 0 60px 0 0;
}

.controlsContainer {
  margin-top: -4px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.filtersContainer {
  display: flex;

  & > * + * {
    margin-left: 8px;
  }
}

.varietySelect {
  background: none !important;
  border: none !important;
  font-weight: 500 !important;
  margin-left: 8px;
}

.mainContent {
  display: flex;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.container {
  transition: width 150ms ease-out;
  width: 100%;

  &.sidePanelOpened {
    width: calc(100% - 472px);
  }
}

.sidePanel {
  position: absolute;
  right: -472px;
  width: 460px;
  height: 100%;
  overflow: auto;
  border: 1px solid var(--colorBorderDefault);
  border-radius: 4px;
  margin-left: 12px;
  transition: right 150ms ease-out;

  &.sidePanelOpened {
    right: 0;
  }
  overflow-x: hidden;
}

.showDataAssistantButton {
  padding: 4px 8px;
  height: 28px;
  /* color: var(--colorPrimary); */

  &.sidePanelOpened {
    background: #E7E9EE;
    right: 0;
  }
}
