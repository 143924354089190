@import 'styles/core';

.select {
  display: flex;
  align-items: center;
  position: relative;

  &.errored {
    padding: 0 0 20px 0;

    & .popup {
      top: calc(100% + 4px - 20px);
    }
  }

  &.withoutArrow {
    & > button.button {
      padding-right: 0;
    }
  }
}

.button {
  position: relative;
  display: flex;
  outline: none;
  cursor: pointer;
  border: 1px solid #BDCFE1;
  border-radius: 5px;
  height: 24px;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  font-size: 14px;
  color: var(--colorTextPrimary);
  background-color: #fff;
  letter-spacing: -0.15px;
  line-height: 20px;
  padding: 0 32px 0 8px;
  min-width: 64px;
  overflow: hidden;

  &.notSelected {
    color: #79868F;
  }

  &:disabled,
  &[disabled]{
    opacity: 0.6;
  }

  &.invalid, &.invalid:hover {
    border-color: var(--colorNegative);
    box-shadow: none;
  }
}

.arrow {
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
  width: 20px;
  height: 20px;
}

.popup {
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #E1E8F0;
  box-sizing: border-box;
  box-shadow: 0 8px 24px 0 rgba(0,0,0,0.02), 0 2px 4px 0 rgba(0,0,0,0.04);
  max-height: 210px;
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  overflow-y: auto;
  z-index: 100;
  -webkit-overflow-scrolling: touch;
  padding: 4px 0;

  &.isSearchable {
    padding-bottom: 0;
    max-height: initial;
    width: 100%;
  }
}

.right {
  & .popup {
    left:  auto;
    right: 0;
  }
}

.options {

}

.option {
  padding: 6px 32px 6px 12px;
  font-size: 14px;
  color: var(--colorTextPrimary);
  text-align: left;
  line-height: 20px;
  cursor: pointer;

  &:hover {
    background-color: #F7F9FA;
  }

  &.selected {
    background-color: #E4E9F0;
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.bigTransparent {
  & .button {
    padding-left: 0;

    border: none;
    background: none;
    font-size: 24px;
    font-weight: 500;

    @media (--tabletBig) {
      font-size: 20px;
    }
  }

  & .popup {
    min-width: 192px;
    margin-top: 4px;
  }

  & .option {
    padding: 0;
  }
}

.dark {
  & > .button {
    background: #EAEBF0;
    border: none;
    color: var(--colorTextPrimary);
  }
}

.white {
  & > .button {
    background: #FFF;
    border: 1px solid #BDCFE1;
    color: var(--colorTextPrimary);
  }
}

.transparent {
  & > .button {
    background: none;
    border: none;
    color: var(--colorTextPrimary);
    font-weight: 500;
  }
}

.medium {
  & > .button {
    height: 36px;
    padding: 0 40px 0 12px;
  }

  &.transparent {
    & > .button {
      height: 36px;
      padding: 0 30px 0 0;
    }
  }
}

.withBigArrow {
  & .arrow {
    width: 32px;
    height: 32px;
    right: 4px;
    top: calc(50% - 16px);
  }
}

.error {
  position: absolute;
  bottom: 0;
  left: 12px;
  font-size: 12px;
  color: var(--colorNegative);
  line-height: 16px;
}

.textEllipsis {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.withoutButton {
  display: none;
}

.rightPopupAlign {
  left: initial;
  right: 0;
}

.icon {
  padding-right: 24px;
  width: 20px;
  height: 20px;
}
