@import 'styles/core';

.tooltipWrapper {
  position: relative;

  &:hover .tooltip {
    display: block;
  }
}

.tooltip {
  z-index: 10000;
  display: none;

  position: absolute;
  bottom: calc(100% + 7px);
  left: 50%;
  transform: translate(-50%, 0);

  background-color: var(--colorTextSecondary);
  border-radius: 2px;
  font-size: 12px;
  line-height: 16px;
  min-height: 24px;
  color: rgba(255,255,255,0.90);
  padding: 4px 8px;
  text-align: center;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: calc(50% - 4px);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 3px 4px 0 4px;
    border-color: var(--colorTextSecondary) transparent transparent transparent;
  }
}

.topLeft {
  & .tooltip {
    left: 100%;
    transform: translate(-100%, 0);

    &:after {
      left: calc(100% - 18px);
    }
  }
}

.bottom {
  & .tooltip {
    top: calc(100% + 7px);

    &:after {
      top: initial;
      bottom: 100%;
      border-color: transparent transparent var(--colorTextSecondary) transparent;
      border-width: 0 4px 3px 4px
    }
  }
}

.noWrap {
  white-space: nowrap;
}

.themeLight {
  & .tooltip {
    background-color: var(--colorWhite);
    color: var(--colorTextPrimary);
    border: 1px solid #E1E8F0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04), 0px 8px 24px rgba(0, 0, 0, 0.02);

    &:after {
      background: var(--colorWhite);
      left: 50%;
      width: 7px;
      height: 7px;
      transform: rotate(45deg) translate(-70%);
      border: 1px solid #E1E8F0;
      border-color: transparent #E1E8F0 #E1E8F0 transparent;
    }
  }
}

.ellipsis {
  text-overflow: ellipsis;
}
