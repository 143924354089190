@import 'styles/core';

.energyReportContainer {
  padding-bottom: 60px;
}

.header {
  margin: 0 0 24px 0;

  @media (--tabletLandscape) {
    display: none;
  }
}

.header2 {
  margin: 0;
}

.filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button {
  height: 32px;
}

.plantingCycleLink {
  display: block;
  color: var(--colorTextPrimary);
  text-decoration: none;

  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    text-decoration: underline;
  }
}
