@import 'styles/core';

.tableContainer {
  position: relative;

  & :global(.htCore) td.dateCell {
    color: var(--colorTextSecondary);
    white-space: nowrap;
  }

    & :global(.htCore) td.dateCell.dateCellDisabled {
    color: var(--colorTextNeutral);
    white-space: nowrap;
  }

  & :global(span.colHeader) {
    display: block;
  }
}

.tableWrapper {
  & :global(.htCore th .relative),
  & :global(.htCore th .relative .colHeader) {
    height: 100%;
  }
}

.meterHeader {
  text-align: right;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  & .meterHeaderName {
    margin-bottom: 4px;
    white-space: normal;
  }

  & .meterHeaderUnit {
    margin-bottom: 8px;
  }

  & .meterHeaderUsage {
    display: inline-block;
    font-weight: normal;
  }
}

td.totalFirstCell,
td.totalCell {
  background-color: var(--colorThDefault);
  font-weight: 500;
}

.errorContent {
  display: none;
}

td.invalidCell {
  overflow: initial;
  position: relative;

  &:global(.current) {
    z-index: 1;

    & .errorContent {
      display: block;
    }
  }
}

.meterHeaderUsageIconWrapper {
  display: flex;
  align-items: center;
}

.meterHeaderUsageIcon {
  width: 20px;
  height: 20px;
  margin-right: 4px;

  fill: #9EA4A8;
}
