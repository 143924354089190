/* .dataSheetWrapper {
  position: relative;

  height: calc(100vh - 324px);
} */

.dataSheetWrapper {
  position: relative;
  height: calc(100vh - 254px - 92px);
  border: 1px solid #E7E9EE;
  background-color: #FBFDFE;
  border-radius: 4px;
}
