@import 'styles/core';

.emptyWrapper {
  padding: 32px;
  text-align: center;
  min-height: 529px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.subtitle {
  display: block;
  margin-top: 4px;
  margin-bottom: 32px;
}

.openButton {
  margin: 0 auto;

  & svg.icon path {
    fill: var(--colorWhite);
  }
}

.unionIcon {
  margin-bottom: 39px;
}
