@import 'styles/core';

.dataQualityNotification {
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  width: 40px;
  height: 40px;
  margin-right: 16px;
  cursor: pointer;

  @media (--tablet) {
    display: none;
  }
}

.alertBageWrapper {
  position: absolute;
  bottom: 19px;
  left: 21px;
  background-color: var(--colorWhite);
  border: 1px solid var(--colorWhite);
  border-radius: 4px;
}

.alertBage {
  padding: 2px 3px;
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  background-color: var(--colorNegative);  
  color: var(--colorWhite);
  border-radius: 4px;

  &.green {
    background-color: var(--colorPositive);
  }

  &.yellow {
    background-color: var(--colorWarning);
  }

  &.red {
    background-color: var(--colorNegative);
  }
}

.dropdownContent {
  width: 312px;
  padding: 16px 24px 20px 16px;
}

.overallQuality {
  align-items: flex-start;
}

.overallQualityText {
  display: flex;
  align-items: center;
  min-height: 48px;
}

.linkWrapper {
  margin-top: 8px;
  text-align: right;
}

.link {
  font-size: 14px;
  line-height: 20px;
  font-weight:500;
  color: var(--colorPrimary);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}