.icon {
  & path {
    fill: #777776;
  }
  &:hover {
    & path {
      fill: #000;
    }
  }
}
