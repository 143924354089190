@import 'styles/core';

.table {
  border: none;

  & td.chartIconWrapper {
    width: 36px;
    cursor: pointer;
    vertical-align: top;

    & svg {
      fill: #9EA4A8;
    }
  }

  & th.chartIconWrapper {
    width: 44px;
  }

  & td.firstRowInGroup {
    text-align: left;
    min-width: 180px;
    vertical-align: top;
    padding: 8px 16px 5px 0;
  }

  & td.categoryName {
    min-width: 148px;
    text-align: left;
  }

  & td.dataCell {
    min-width: 148px;
  }

  & tr.totalRow {
    background-color: var(--colorMenuItemHover);
    font-weight: 500;
  }

  & th.activeTh {
    padding: 5px 12px;
  }
}

.activeTh {
  cursor: pointer;

  &:hover {
    background-color: #e8ebed;
  }
}

.arrow {
  width: 24px;
  height: 24px;
  display: none;
  transform: rotate(180deg);

  &.sortDirectionDown {
    transform: rotate(0deg);
  }
}

.activeSort {
  & .arrow {
    display: block;
  }
}


.thContent {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 24px;
  white-space: nowrap;
}

.thLeft {
  text-align: left;

  & .thContent {
    justify-content: flex-start;
  }
}

.tdValue {
  white-space: nowrap;
}

.percentage {
  color: var(--colorTextSecondary);
}
