@import 'styles/core';

.graphHeaderWrapper {
  margin-bottom: 18px;
}

.graphHeaderContent {
  padding-top: 25px;
}

.graphHeader {
  display: flex;
  justify-content: space-between;
}

.graphHeaderText {
  margin: 0;

  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  color: var(--colorTextPrimary);
}
