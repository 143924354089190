@import 'styles/core';

.card {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;

  text-decoration: none;
  color: var(--colorTextPrimary);

  @media (--tabletBig) {
    align-items: flex-start;
    width: 100%;
  }

  &:hover {
    & .cardNameText {
      text-decoration: underline;
    }
  }
}

.icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;
  margin-right: 8px;
  border-radius: 50%;

  @media (--tabletBig) {
    margin-right: 16px;
  }

  & .cropIcon {
    width: 100%;
    height: 100%;
  }
}

.cardName {
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  line-height: 20px;
  font-weight: 500;
}

.cardNameText {
  display: flex;
  align-items: center;
}

.cardPeriod {
  font-size: 13px;
  line-height: 18px;
  color: var(--colorTextSecondary);
}

.dotWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.dot {
  width: 2px;
  height: 2px;
  margin: 0 5px;

  background-color: var(--colorTextSecondary);
  border-radius: 50%;
}

.completedIcon {
  display: none;

  position: absolute;
  bottom: 1px;
  right: -6px;
  width: 18px;
  height: 18px;
  background-color: var(--colorSecondaryLight);
  border-radius: 50%;
  border: 1px solid #FFF;

  & svg {
    width: 16px;
    height: 16px;

    & path {
      fill: var(--colorWhite);
    }
  }

  @media (--tabletBig) {
    display: block;
  }
}

.completedLabel {
  margin-left: 4px;

  @media (--tabletBig) {
    display: none;
  }
}

.compartmentName,
.harvest {
  font-size: 13px;
  line-height: 18px;
  color: var(--colorTextSecondary);

  display: none;
}

.compartmentName {
  margin-bottom: 2px;
}

.harvest {
  padding-top: 12px;
  justify-content: flex-start;
  align-items: center;
}

.harvestWeek,
.harvestValue,
.harvestPercent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 8px;

  & .harvestIcon {
    height: 18px;
    width: 18px;
    margin-right: 4px;

    & svg {
      width: 18px;
      height: 18px;

      & path {
        fill: var(--colorTextSecondary);
      }
    }
  }

  & .harvestText {
    line-height: 1;
  }
}

.harvestWeek .harvestText {
  min-width: 48px;
}

.harvestValue .harvestText {
  min-width: 80px;
}

.harvestPercent .harvestText {
  min-width: 60px;
}

.harvestPercent {
  & .harvestIcon,
  & .harvestIcon svg {
    height: 16px;
    width: 16px;
  }
}

.big {
  @media (--tabletBig) {
    align-items: center;
  }

  &:hover {
    & .cardNameText {
      text-decoration: none;
    }
  }

  & .icon {
    min-width: 56px;
    width: 56px;
    height: 56px;
    margin-right: 16px;

    @media (--tabletBig) {
      min-width: 40px;
      width: 40px;
      height: 40px;
    }
  }

  & .cardName {
    font-size: 24px;
    line-height: 32px;

    @media (--tabletBig) {
      font-size: 20px;
    }
  }

  & .completedLabel {
    margin-left: 8px;
    margin-top: 2px;
  }
}

.normal {
  & .compartmentName {
    @media (--tabletBig) {
      display: block;
    }
  }
  & .harvest {
    @media (--tabletBig) {
      display: flex;
    }
  }

  & .more {
    @media (--tabletBig) {
      display: flex;
    }
  }
}

.editButton {
  margin-left: 24px;

  text-transform: none;

  @media (--tablet) {
    display: none;
  }

  & svg {
    fill: #777776;
    width: 20px;
    height: 20px;
  }

  &:hover {
    & svg {
      fill: var(--colorTextSecondary);
    }
  }
}

.dropdownMenu {
  & .dropdownMenuDefaultButton {
    width: 36px;
    height: 36px;
  }

  &.desktop {
    display: inline-block;

    @media (--tablet) {
      display: none;
    }
  }

  &.mobile {
    display: none;

    position: absolute;
    top: -48px;
    right: 0;

    @media (--tablet) {
      display: block;
    }
  }

  & .dropdownItem {
    width: 100%;
  }
}

.dialog {
  white-space: normal;
  justify-content: center;
  align-items: center;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  @media (--tablet) {
    display: flex;
  }

  & .wrapper {
    width: calc(100% - 32px);
    max-width: 464px;
    margin: 0;

    @media (--tablet) {
      max-width: 328px;
    }

  }

  & .dialogText {
    margin: 0;
  }

  & .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 36px;
  }

  & .confirm {
    margin-left: 8px;
  }

  & .cancel {
  }
}

.dropdownMenuButton {
  width: 48px;
  height: 48px;
}

.arrowsClockwiseIcon.dropdownItem svg {
  fill: none;
}

.cropSwitchDesktop {
  @media (--tablet) {
    display: none;
  }
}

.cropSwitchMobile {
  display: none;

  @media (--tablet) {
    display: initial;
  }
}
