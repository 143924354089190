@import 'styles/core';

.graphWrapper {
  padding: 25px 24px;
  background: var(--colorWhite);
  border: 1px solid var(--colorBorderDefault);
  border-radius: 3px;
  overflow: visible;
}

.graphHeaderWrapper {
  margin-bottom: 16px;
}

.graphName {
  margin-bottom: 24px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  color: var(--colorTextPrimary);
}

.chartContainer {
  padding-right: 24px;
  position: relative;
}

.graphIndicators {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* max-width: 50%; */
}

.graphIndicator {
  margin-right: 32px;
}

.graphIndicatorTitle {
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #777776;
  margin-bottom: 8px;
}

.graphIndicatorValue {
  font-weight: 300;
  font-size: 32px;
  line-height: 32px;
  color: #4A4A49;
  margin-right: 4px;
}

.graphIndicatorUnits {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #4A4A49;
  margin-right: 8px;
}

.graphIndicatorAccuracy {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #777776;
}

.legend {
  padding-top: 10px;
  padding-bottom: 0;
}

.tooltipWrapper {
  width: 256px;
}
