@import 'styles/core';

.select {
  @media (--tablet) {
    width: 100%;
  }
  @media (--tabletUp) {
    & > button.longSelectButton {
      width: 100%;
      padding: 0 30px 0 0;
      background: none !important;
      border: none !important;
      font-weight: 500 !important;
    }
  }
}

.longSelectPopup {
  max-height: 248px;
  text-align: left;
  overflow: hidden;
  width: 100%;
  min-width: 312px;
}

.option {
  padding: 6px 12px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
}

.popup {
  max-height: 210px;
  text-align: left;
}

.titleWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.title {
  display: none;
  margin-right: 8px;
  font-size: 14px;
  color: var(--colorTextSecondary);
  @media (--tabletUp) {
    display: block;
  }
}

.loading {
  background: #E7E9EE;
  border-radius: 99px;
  width: 60px;
  height: 8px;
}

.content {
  max-height: 283px;
}
