@import 'styles/core';

.table {
  width: 100%;
  background-color: var(--colorWhite);
  border: solid 1px var(--colorBorderDefault);
  border-radius: 2px;
  border-spacing: 0;

  font-size: 14px;
  line-height: 20px;

  & thead {
    background-color: var(--colorBackgroundHover);
  }

  & tr {
    &:last-child {
      & td {
        box-shadow: none;
      }
    }
  }

  & th,
  & td {
    box-shadow: inset 0 -1px 0 0 var(--colorBorderDefault);
    color: var(--colorTextPrimary);
    text-align: right;

    &:first-child {
      text-align: left;
    }
  }

  & td {
    padding: 8px 12px;
  }

  & th {
    padding: 10px 12px;
    color: var(--colorTextSecondary);
    font-weight: 500;
  }

  &.sizeSmall {
    font-size: 13px;
    line-height: 18px;

    & td {
      padding: 4px 12px;
    }

    & th {
      padding: 7px 12px;
    }
  }
}

.emptyState {
  text-align: center;
  padding: 26px;
  border-radius: 0 0 4px 4px;
  border-top: none;
  font-size: 14px;
  line-height: 20px;
  background-color: var(--colorWhite);
  color: var(--colorTextSecondary);
}
