@import 'styles/core';

.cumulativeToggle {
  display: flex;
  height: 28px;

  border: 1px solid #BDCFE1;
  border-radius: 4px;
  overflow: hidden;
}

.cumulativeToggleIcon {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 34px;

  cursor: pointer;

  & svg {
    width: 20px;
    height: 20px;
  }

  & svg path {
    fill: var(--colorTextSecondary);
  }

  &:first-child {
    border-right: 1px solid #BDCFE1;
  }

  &:hover {
    background-color: #F7F9FA;

    &.selected {
      background-color: #E4E9F0;
    }
  }
}
.selected {
  background-color: #E4E9F0;
}

.disabled {
  opacity: 0.6;

  & .cumulativeToggleIcon {
    cursor: not-allowed;

    &:hover {
      background-color: #FFF;

      &.selected {
        background-color: #E4E9F0;
      }
    }
  }
}
