@import 'styles/core';

.dialog {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  background-color: rgba(49, 52, 59, .9);
  padding: 0;

  & .dialogClose {
    position: absolute;
    top: 0;
    right: -36px;
    cursor: pointer;
  }

  & .dialogWrapper {
    display: flex;
    flex-flow: column;
    max-width: 400px;
    position: relative;
    top: -20%;
    bottom: 0;
    left: 0;
    right: 0;
    width: 400px;
    border-radius: 5px;
    background-color: #ffffff;
    margin: 20px auto;
    padding: 30px 30px 50px;
    box-sizing: border-box;
  }

  & .dialogHeader {
    font-weight: bold;
    font-size: 24px;
    color: #000000;
    letter-spacing: 1.85px;
    text-transform: uppercase;
  }

  & .dialogBody {
    padding-top: 40px;
    display: flex;
    flex-wrap: wrap;
  }

  & .dialogItem {
    font-size: 14px;
    color: var(--colorPrimary);
    line-height: 20px;
    cursor: pointer;
    text-decoration: underline;

    & + .dialogItem {
      margin-left: 80px;
    }
  }

  & .dialogItemSelected {
    color: #BDCFE1;
    text-decoration: none;
  }
}


@media only screen and (max-width: 640px) {
  .dialog {
    padding: 30px 7px 7px;
    justify-content: flex-start;

    & .dialogWrapper {
      width: 100%;
      margin: 0;
      top: 0;

      & .dialogClose {
        position: absolute;
        top: -22px;
        margin-left: -10px;
        left: 50%;
        right: 50%;
        cursor: pointer;
      }
    }
  }
}
