@import 'styles/core';

.inputContainer {
  padding: 20px 12px 16px 12px;
  width: 100%;

  border: 1px solid var(--colorBorderSecondary);
  border-radius: 4px;

  font-size: 14px;
  color: var(--colorTextPrimary);

  /* &.inputContainerLabelActive{
    padding-top: 20px;
  } */

  &.inputContainerFocused {
    border-color: var(--colorPrimary);
  }

  &.widthoutPlaceholder {
    padding: 13px 12px 13px 12px;
  }

  &.inputWithError {
    border-color: var(--colorNegative);

    & .label.labelActive {
      color: var(--colorNegative);
    }

    &.inputContainerFocused {
      border-color: var(--colorNegative);
    }
  }
}

.inputWithLabel {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}

.label {
  margin: 0;
  border: 0;

  position: absolute;
  color: var(--colorTextNeutral);
  top: -8px;

  transition: all 0.2s ease-in-out;
  transform-origin: left top;
  cursor: text;
  pointer-events: none;
  width: 66.6%;
  line-height: 16px;

  &.labelActive {
    /* transform: translate3d(0, -40%, 0) scale(0.85); */
    transform: translate3d(0, -40%, 0) scale(0.8333); /* 8333 чтобы не было мыла на винде */
    width: 120%;
  }
}

.input {
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  line-height: 20px;
  color: var(--colorTextPrimary);

  min-height: 48px;
  box-sizing: border-box;
  resize: none;

  &::placeholder {
    color: var(--colorTextSecondary);
    opacity: 0;
    transition: opacity 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }

  &.inputActive {
    &::placeholder {
      opacity: 1;
    }
  }
}

.inputContainerDisabled {
  background: var(--colorBackgroundHover);
  color: var(--colorTextNeutral);

  & .label,
  & .input {
    color: var(--colorTextNeutral);
  }

  & .input {
    background: var(--colorBackgroundHover);
  }
}

.error {
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
  margin-left: 12px;
  color: var(--colorNegative);
}
