@import 'styles/core';

.desktopContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (--tablet) {
    display: none;
  }
}

.mobileContainer {
  display: none;
  padding: 16px 16px 0;
  flex-wrap: wrap;

  @media (--tablet) {
    display: flex;
  }
}

.filterItem {
  padding: 2px 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  background: #E6EBF1;
  color: var(--colorTextSecondary);
  border-radius: 4px;
  line-height: 20px;
  font-size: 14px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selectWrapper {
  margin-right: 8px;
}

.topLevelPopup {
  overflow-y: visible;
}

.topLevelButton {
  max-width: 264px;
}

.longSelectButton,
.longSelectPopup {
  width: 100%;
}

.growingFiltersContainer {
  width: 240px;
  padding: 12px 12px;

  & .selectWrapper {
    margin-bottom: 12px;
    margin-right: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.filterTitle {
  margin-right: 20px;
  font-size: 14px;
  color: var(--colorTextSecondary);
}

.boldLabel {
  font-weight: 500;
}

.unitsFilter {
  min-width: initial;
}

.unitsFilterPopup {
  min-width: 96px;
}
