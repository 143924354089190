@import 'styles/core';

.harvest {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (--tablet) {
    display: none;
  }
}

.table {
  width: 100%;
  border: none;
  border-spacing: 0;

  & td, & th {
    text-align: right;
  }

  & th {
    padding-bottom: 8px;

    font-size: 13px;
    font-weight: 500;
    color: var(--colorTextSecondary);
  }

  & td {
    min-width: 120px;
    font-size: 18px;
    color: var(--colorTextPrimary);
  }
}

.units {
  margin-left: 4px;
  font-size: 14px;
}

.progressContainer {
  width: 328px;
  padding-top: 10px;
}

