@import 'styles/core';

.fieldWrapper,
.inputWrapper {
  position: relative;
}

.password {
  background-color: #FFF;
  height: 40px;
  width: 100%;
  outline: none;
  border: 1px solid #BDCFE1;
  border-radius: 4px;
  padding: 10px 35px 10px 10px;
  z-index: 1;
  box-sizing: border-box;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.15px;

  color: var(--colorTextPrimary);

  &::placeholder {
    color: var(--colorTextSecondary);
  }

  &[value=''] {
    background-color: rgba(256, 256, 256, .6);
  }

  &:focus {
    background-color: #FFF;
  }
}

.withError {
  border: 1px solid var(--colorNegative);
}

.passwordError {
  padding-top: 4px;
  font-size: 12px;
  line-height: 16px;
  color: var(--colorNegative);
}

.eyeIconWrapper {
  position: absolute;
  top: calc(50% - 10px);
  right: 12px;

  width: 20px;
  height: 20px;

  cursor: pointer;

  &:hover {
    & .eyeIcon {
      fill: var(--colorTextSecondary);
    }
  }
}

.eyeIcon {
  width: 20px;
  height: 20px;
  fill: var(--colorTextNeutral);
}
