@import 'styles/core';

.container {
  color: var(--colorTextPrimary);
  font-size: 14px;
  line-height: 20px;
  padding-top: 32px;
  padding-bottom: 32px;
}

.metrics {

}
