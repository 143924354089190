@import 'styles/core';

.chartContainer {
  position: relative;

  user-select: none;
  outline: none;

  margin-top: -8px;
}
