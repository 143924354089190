@import 'styles/core';


.dropdown {
  position: relative;
}

.dropdownButton {
  cursor: pointer;
}

.dropdownContent {
  position: absolute;
  top: 100%;
  right: 12px;

  min-width: 120px;
  padding: 8px 0;

  border-radius: 4px;
  border: solid 1px #e1e8f0;
  background: #fff;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, .02), 0 2px 4px 0 rgba(0, 0, 0, .04);

  transition: opacity 150ms ease-out, max-height 150ms ease-out;
  z-index: 1;

  &.withArrow {
    width: 240px;
    right: calc(-100% - 66px);
  }
}

.dropdownEnter {
  opacity: 0;
}

.dropdownEnter.dropdownEnterActive {
  opacity: 1;
}

.dropdownLeave {
  opacity: 1;
}

.dropdownLeave.dropdownLeaveActive {
  opacity: 0;
}

.defaultButton {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  &:hover {
    & .moreIcon {
      fill: var(--colorTextSecondary);
    }
  }
}

.dropdownOpened {
  & .moreIcon {
    fill: var(--colorTextSecondary);
  }
}

.big {
  & .defaultButton {
    width: 48px;
    height: 48px;
  }
  & .dropdownContent {
    top: calc(100% - 8px);
  }
}

.small {
  & .defaultButton {
    width: 20px;
    height: 20px;
  }
  & .dropdownContent {
    top: 100%;
    right: 0;
  }
}

.deleteTooltipTriangle {
  position: absolute;
  left: calc(50% - 6px);
  top: -6px;

  width: 12px;
  height: 12px;
  box-sizing: border-box;

  background: #FFFFFF;
  border: 1px solid #E1E8F0;
  transform: rotate(45deg);
  border-right-color: transparent;
  border-bottom-color: transparent;
}