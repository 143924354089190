@import 'styles/core';

.settingsIncidents {
  display: table;
  max-width: 960px;

  @media (--phoneLandscape) {
    padding-bottom: 120px;
  }
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 24px;
  margin-bottom: 32px;

  @media (--phoneLandscape) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.header {
  @media (--phoneLandscape) {
    margin-bottom: 12px;
  }
}

.switch {
  @media (--phoneLandscape) {
    flex-direction: row-reverse;
    & div {
      padding-right: 0;
    }

    & button {
      padding-right: 8px;
    }
  }
}

.subHeader {
  margin-bottom: 16px;
}

.infoText {
  p {
    margin: 0 0 24px 0;
    padding: 0;
    color: var(--colorTextPrimary);

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.info {
  max-width: 588px;
}

.relatedRules {
  margin-bottom: 48px;
}

.relatedRulesEmptyPlate {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 19px 32px 19px;
  border: 1px solid var(--colorBorderDefault);
  border-radius: 4px;
}

.plateButton {
  display: inline-block;
  padding: 0;

  color: var(--colorPrimary);
  font-weight: 500;
}

.relatedRulesEmptyPlateText {
  margin-left: 16px;
}

.alertsHrefsWrapper {
  border: 1px solid var(--colorBorderDefault);
  border-radius: 4px;
}
