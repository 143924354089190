@import 'styles/core';


.layout {
  position: relative;
  width: 176px;
  height: 160px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0 0 0;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #E1E8F0;
  outline: none;
  cursor: pointer;

  &:hover {
    border: 1px solid #1DBADF;
  }

  &.selected {
    border: 1px solid #1DBADF;

    & .check {
      display: block;
    }
  }

  &:disabled {
    opacity: 0.54;
    cursor: default;

    &:hover {
      border: 1px solid #E1E8F0;

        &.selected {
          border: 1px solid #1DBADF;
        }
    }
  }
}

.icon {
  width: 64px;
  height: 64px;
}

.title {
  margin: 12px 0 0 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: capitalize;
  color: var(--colorTextPrimary);
}

.check {
  display: none;
  position: absolute;
  top: 12px;
  right: 12px;
  fill: #1DBADF;
}
