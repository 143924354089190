@import 'styles/core';

.lightProductivity {
  white-space: nowrap;
  border: 1px solid var(--colorBorderDefault);
  border-radius: 4px;
  padding: 24px;
  display: flex;
  flex-direction: column;

  & .title {
    align-self: flex-start;
  }

  @media (--tabletLandscape) {
    min-width: auto;
  }

  & .content {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    @media (--tablet) {
      flex-direction: column;
      width: 100%;
      min-height: auto;
    }
    & .divIconWrapper {
      width: 24px;
      height: 32px;
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 8px;
      @media (--tablet) {
        display: none;
      }
    }
    & .valueContainer {
      @media (--tablet) {
        width: 100%;
        display: inline-flex;
        justify-content: space-between;
        padding-top: 13px;
      }
      & .valueTitle {
        color: var(--colorTextSecondary);
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        margin-bottom: 4px;
        @media (--tablet) {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      & .valueLine {
        & .value {
          font-size: 32px;
          font-weight: 300;
          line-height: 32px;
          color: var(--colorTextPrimary);
          @media (--tablet) {
            font-size: 20px;
            font-weight: 400;
          }
        }
        & .units {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: var(--colorTextPrimary);
          margin-left: 4px;
        }
      }
    }
  }
}
