@import 'styles/core';


.main {
  width: 100vw;
  height: calc(100vh - 64px);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.content {
  height: 256px;
  max-width: 432px;
  padding: 16px;
}

.title {
  font-weight: bold;
  font-size: 24px;
  color: #000000;
  letter-spacing: 1.85px;
  text-transform: uppercase;
}

.description {
  font-size: 14px;
  color: var(--colorTextSecondary);
  line-height: 20px;
  margin-top: 20px;
}

.link {
  font-size: 14px;
  color: var(--colorPrimary);
  line-height: 20px;
  margin-top: 20px;
}

.icon {
  margin-right: 8px;
}
