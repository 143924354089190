@import 'styles/core';

.background {
  background: rgba(30, 42, 51, 0.6);
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  overflow: hidden;
  overscroll-behavior: contain;
}
