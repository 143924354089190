@import 'styles/core';

.nameEditorComponent {
  position: absolute;
  top: 0;

  min-width: 312px;
  padding: 16px 12px 8px;

  border-radius: 4px;
  border: solid 1px var(--colorBorderCatskillWhite);
  background: var(--colorWhite);
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, .02), 0 2px 4px 0 rgba(0, 0, 0, .04);

  transition: opacity 150ms ease-out, max-height 150ms ease-out;
  z-index: 1;

  color: var(--colorTextPrimary);
}

.deleteButtonWrapper {
  margin-top: 12px;
  padding-top: 8px;
  border-top: 1px solid var(--colorBorderDefault);
}

.deleteButton {
  padding: 0;
  width: 100%;
  color: var(--colorTextSecondary);
}


.deleteButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding-top: 12px;
}

.deleteConfitm {
  margin-left: 8px;

  & svg circle {
    stroke: var(--colorTextSecondary) !important;
  }
}

.deletePopup {
  max-width: 286px;
  font-size: 14px;
  padding: 0 4px 8px;
  text-align: left;
  font-weight: normal;
  color: var(--colorTextPrimary);
}

.checkIcon svg {
  width: 14px;
  height: 14px;
}

.closeIcon svg path {
  fill: var(--colorTextSecondary);
}

.form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 12px;
  padding-top: 3px;
}

.checkIcon,
.closeIcon {
  padding: 4px;
}

.textInput {
  width: 223px;
}