@import 'styles/core';

.content {
  color: var(--colorTextPrimary);
  font-size: 14px;
  line-height: 20px;
  padding: 24px 0 0 0;
}

.tree {
  padding: 16px 0 32px 0;
}

.checkboxControl {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 8px;
  width: 20px;
  height: 20px;
}

.icon {
  width: 20px;
  height: 20px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 8px;

  & svg,
  & svg path {
    width: 20px;
    height: 20px;
    fill: var(--colorTextSecondary);
  }
}

.checkIcon {
  align-self: flex-start;
  color: var(--colorTextSecondary);
  margin-left: auto;
}

.loading .loadable.icon {
  animation: rotation 1.4s infinite linear;

  @media all and (-ms-high-contrast:none) {
    animation: rotation-ie 1.4s infinite linear;
  }
}

@keyframes rotation {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
}

@keyframes rotation-ie {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(359deg); }
}

.loadable.icon > svg > circle {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite;
  stroke: var(--colorPrimary);

  @media all and (-ms-high-contrast:none) {
    stroke-dashoffset: 46.5;
  }
}

@keyframes dash {
  0% { stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.5;
    transform:rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform:rotate(450deg);
  }
}

.item.expanded .expander {
  transform: rotate(90deg);
}

.title {
  font-size: 14px;
  line-height: 20px;
}

.item {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  padding: 8px 32px;
  cursor: pointer;

  &:not(.touch) {
    & .checkbox {
      display: none;
    }
  }

  &:not(.checked) {
    &:hover {
      & .checkbox {
        display: flex;
      }
    }
  }


  &.checkboxVisible .checkbox {
    display: flex;
  }

  &.checked .checkbox {
    display: flex;
  }

  /* &.checked, */
  &:hover {
    background-color: #E4E9F0;
  }
}

.category {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  padding: 8px 32px 4px 0;
  color: var(--colorTextSecondary);
  text-transform: capitalize;
  font-weight: 500;
  margin-left: 28px;
}

.offsetNone {
  padding-left: 24px;
}

.offsetOne {
  padding-left: 52px;
}

.offsetTwo {
  padding-left: 80px;
}


.dataSeriesWrapper {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 34px);
  margin: 2px 30px 0 34px;
  padding: 2px 0 2px 4px;
  border-left: 1px solid #E1E3E8;

  & .dataSeriesItem {
    background-color: #E4E9F0;

    & .dataSeriesItemClose {
      background-color: #E4E9F0;
    }
  }
}

.groups {
  padding-bottom: 14px;
}

.groupsHovered:hover {
  background-color: #EDF1F5;
  transition: background-color 150ms ease-in;

  & .dataSeries {
    /* Вместо border-radius, чтобы не было скачка при ховере */
    box-shadow: 0 0 0 1px #C5CAD1 inset;
    box-sizing: border-box;
  }
}

.groupWrapper {
  padding: 6px 0 4px 0;
  margin-bottom: 10px;

  &:hover .deleteButton {
    visibility: visible;
  }
}

.group {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 30px 0 32px;
  font-weight: 500;
  font-size: 13px;
  color: var(--colorTextSecondary);
  justify-content: space-between;
}

.groupNameWrapper {
  display: flex;
}

.groupName {
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.groupIcon {
  width: 20px;
  height: 20px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  margin: 0 4px;
}

.deleteButton {
  display: flex;
  padding: 3px 5px;
  margin: 0 0 0 16px;
  width: 28px;
  height: 24px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  fill: #777776;
  background-color: #E9ECF0;
  border-radius: 4px;
  outline: none;
  border: none;

  &:hover {
    background-color: #DADEE3;

    & .groupCloseIcon {
      fill-opacity: 1;
    }
  }

  & .groupCloseIcon {
    width: 10px;
    height: 10px;
    fill: var(--colorTextSecondary);
    fill-opacity: 0.5;
    fill-rule: evenodd;
  }

  @media only screen and (min-width: 1025px) {
    visibility: hidden;
  }
}

.filter {
  padding: 0 32px 0;
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;
}

.filterIcon {
  width: 24px;
  height: 24px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 0 0 16px;
  cursor: pointer;
  position: relative;

  & .filterIconSvg {
    width: 24px;
    height: 24px;
  }

  &:hover .filterIconSvg {
    fill: var(--colorTextSecondary);
  }

  &.active .filterIconSvg {
    fill: var(--colorTextSecondary);
  }

  &.filtered:after {
    content: '';
    background-color: var(--colorPrimary);
    width: 8px;
    height: 8px;
    box-sizing: content-box;
    border: 2px solid #F6F9FD;
    border-radius: 50%;
    position: absolute;
    top: -2px;
    right: -2px;
  }
}

.locationFilter {

}

.metricCategoriesFilter {

}

.filterPopup {
  display: none;
  flex-flow: column;
  position: absolute;
  bottom: -294px;
  right: 0;
  width: 248px;
  height: 292px;
  z-index: 100;
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid #E1E8F0;
  box-shadow: 0 8px 24px 0 rgba(0,0,0,0.02), 0 2px 4px 0 rgba(0,0,0,0.04);

  &.open {
    display: flex;
  }

  @media only screen and (--phoneLandscape) {
    width: 160px;
  }
}

.filterPopupContent {
  width: 248px;
  height: 292px;
  padding: 4px 0;
  overflow-y: auto;
  overflow-x: hidden;

  @media only screen and (--phoneLandscape) {
    width: 160px;
  }
}

.filterItem {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px 16px;
  flex-shrink: 0;

  &:hover {
    background-color: #EBEEF2;
  }
}

.filterItemName {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.filterCheckbox {
  margin-right: 8px;
}

.filterOnly {
  display: none;
  margin-left: auto;
  cursor: pointer;
  color: var(--colorTextSecondary);
  //font-weight: 500;

  &:hover {
    color: var(--colorPrimary);
  }
}

.filterItem:hover .filterOnly {
  display: flex;
}


.filterInputWrapper {
  position: relative;
  width: 100%;
}

.filterControl {
  display: flex;
}

.filterInput {
  width: 100%;
  flex-shrink: 1;
  flex-grow: 0;
  margin-right: 8px;
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0 44px;
  border-radius: 5px;
  border: 1px solid #BDCFE1;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;

  &::placeholder {
    color: var(--colorTextSecondary);
  }

  &::-ms-clear {
    display: none;
  }
}

.filterInputButton {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 9px;
  bottom: 9px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filterInputButtonLeft {
  left: 12px;
}

.filterInputButtonRight {
  right: 12px;
}

.filterOptionsPopup {
  position: absolute;
  padding: 4px 0;
  top: calc(100% + 2px);
  background-color: white;
  width: 470px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 100;
  border-radius: 5px;
  border: 1px solid #E1E8F0;
  box-shadow: 0 8px 24px 0 rgba(0,0,0,0.02), 0 2px 4px 0 rgba(0,0,0,0.04);
}

.filterOptions {
  max-height: 292px;
}

.filterOption {
  padding: 6px 40px;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.hovered {
    background-color: #EBEEF2;
  }
}

.filterLoader {
  height: 56px;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.filterEmpty {
  height: 116px;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 0 44px;
  color: var(--colorTextSecondary);
}

.emptyTree {
  padding: 60px 76px;
  text-align: center;
  color: var(--colorTextSecondary);
}

.emptyTreeButton {
  outline: none;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  color: var(--colorTextSecondary);
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.message {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 16px 12px 20px;
  margin: 0 32px 24px;
  border: 1px solid #E1E8F0;
  border-radius: 4px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background: var(--colorPrimary);
    border-radius: 4px 0 0 4px;
  }
}

.messageIcon {
  margin: 0 12px 0 0;
}

.messageText {
  font-size: 14px;
  color: var(--colorTextPrimary);
  text-align: left;
  line-height: 20px;
}
