@import 'styles/core';

.layout {
  min-height: calc(100vh - 60px);
  background-color: #FAFBFC;
}

.contentWrapper {
  max-width: 1232px;
  margin: 0 auto;
  padding: 0 24px;

  @media (--tablet) {
    padding: 0;
  }
}

.firstGraph, .secondGraph, .breakdown {
  background: #FFFFFF;
  border: 1px solid #E7E9EE;

  padding: 20px 25px;

  border-radius: 3px;

  @media (--tablet) {
    padding: 20px 16px;
    border-radius: 0;
    border-left: none;
    border-right: none;
  }
}

.firstGraph {
  position: relative;
  margin-bottom: 32px;
}

.secondGraph {
  margin-right: 24px;

  @media (--tablet) {
    margin-right: 0;
    margin-bottom: 32px;
  }
}

.breakdown {
  width: 500px;
  padding: 20px 0;

  @media (--tabletBig) {
    width: 300px;
  }

  @media (--tablet) {
    width: auto;
  }
}

.breakdownHeader {
  padding: 0 25px;
}

/* Flexbox Fallback */
.content {
  padding: 16px 0 44px;

  display: flex;
  flex-wrap: wrap;

  @media (--tablet) {
    display: block;
  }
}

.firstGraph {
  flex-basis: 100%;
}

.secondGraph {
  flex: 2;
}

.breakdown {
  flex: 1;
}
/* End of Flexbox Fallback */

/* Grid Implementation */
@supports (display: grid) {
  .content {
    display: grid;
    grid-template-columns: minmax(1px, 1fr) 500px;

    grid-template-areas:
      "firstGraph firstGraph"
      "secondGraph breakdown";

    @media (--tabletBig) {
      grid-template-columns: minmax(1px, 1fr) 300px;
    }

    @media (--tablet) {
      display: block;
    }
  }

  .firstGraph {
    grid-area: firstGraph;
  }

  .secondGraph {
    grid-area: secondGraph;
  }

  .breakdown {
    grid-area: breakdown;
  }
}
/* End of Grid Implementation */

.harvestDashboardHeader {
  padding-bottom: 24px;

  @media (--tablet) {
    padding-bottom: 0;
  }
}

.harvestParam {
  width: 33%;

  @media (--tablet) {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 8px;
  }
}

.harvest {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  @media (--tablet) {
    display: block;
  }
}

.dynamicValue {
  margin-right: 4px;
}

.arrowIcon {
  margin-right: 6px;
}

.green {
  color: var(--colorPositive);

  & svg path {
    fill: var(--colorPositive);
  }
}

.red {
  color: var(--colorNegative);

  & svg path {
    fill: var(--colorNegative);
  }
}

.qualityPercent {
  margin-top: 4px;
  line-height: 20px;
  font-size: 14px;
  color: var(--colorTextNeutral);
}
