@import 'styles/core';

.sidePanel {
  position: absolute;
  right: -311px;
  width: 311px;
  height: 100%;
  overflow: auto;
  border-left: 1px solid var(--colorBorderDefault);
  background-color: var(--colorWhite);
  transition: right 150ms ease-out;

  &.sidePanelOpened {
    right: 0;
  }
}

.headerText {
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  color: var(--colorTextPrimary);
  font-weight: 500;
}

.headerRow {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 8px 8px 0 16px;
}

.yearOfPlan {
  padding: 0 16px 8px;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextSecondary);
}

.emptyState {
  height: 100%;
  padding: 50% 42px 0;
}

.emptyStateText {
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
  text-align: center;
}

.filterWrapper {
  padding: 8px 16px;
}

.filterButton {
  min-width: 44px;
}

.filterTitle {
  margin-right: 8px;
}
