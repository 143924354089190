@import 'styles/core';

.menuIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  margin-right: 12px; /* TODO: вынести в пропсы */
  padding: 0 1px;
  cursor: pointer;
}

.menuIcon {
  fill: var(--colorTextSecondary);
  margin-right: 2px;
  transition: transform 150ms ease-out;
  transform: scale(1);
}

.linesWrapper {
  width: 14px;
  height: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: width 150ms ease-out;

  @media (--desktopSmall) {
    width: 18px;
  }
}

.line {
  display: block;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background-color: var(--colorTextSecondary);
}

.isMenuClosed {
  & .menuIcon {
    transform: scale(0);
  }

  & .linesWrapper {
    width: 18px;
  }
}

.tooltip {
  z-index: 1000;
}
