@import 'styles/core';

.desktopContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (--tablet) {
    display: none;
  }
}

.mobileContainer {
  display: none;
  border: 1px solid var(--colorBorderCasper);
  border-radius: 4px;
  height: 36px;
  width: 36px;
  align-items: center;
  justify-content: center;

  @media (--tablet) {
    display: flex;
  }
}

.filterItem {
  padding: 2px 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  background: #E6EBF1;
  color: var(--colorTextSecondary);
  border-radius: 4px;
  line-height: 20px;
  font-size: 14px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.headerMobile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.backIcon {
  margin-right: 8px;
}

.mobileControls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 32px;
}

.cancelButton {
  margin-right: 8px;
}

.selectGroup {
  margin-bottom: 16px;

  &:last-child {
    border-bottom: none;
  }
}

.selectGroupHeader {
  margin-bottom: 16px;
  line-height: 20px;
  font-size: 16px;
}

.longPopup {
  width: 100%;
}

.longButton {
  width: 100%;
}

.incidentPopup {
  max-height: 348px;
}
