@import 'styles/core';

.tableContainer {
  position: relative;
  margin: 30px 0 0 0;

  & :global(.handsontableInput) {
    height: 35px !important;
  }

  & :global(.handsontable.ht_clone_top) {
    z-index: 0;
  }

  & :global(.htCore tbody td) {
    /* padding: 2px 8px; */
    padding: 0 16px;
    vertical-align: middle;
    border-color: var(--colorBorderDefault);
    color: var(--colorTextPrimary);
    font-size: 14px;
    line-height: 20px;
    /* padding-left: 8px;
    padding-right: 8px; */
  }

  & :global(.htCore) td.dateCell {
    color: var(--colorTextSecondary);
    white-space: nowrap;
  }

  & :global(.htCore) td.dateCell.dateCellDisabled {
    color: var(--colorTextNeutral);
    background-color: var(--colorThDefault);
    white-space: nowrap;
  }

  & :global(span.colHeader) {
    display: block;
    font-size: 14px;
    line-height: 20px;
  }
}

.tableWrapper {
  width: 100%;
  border: 1px solid transparent;

  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;

  & th {
    vertical-align: bottom;
  }

  & .headerCellContainer {
    text-align: right;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }

  & .headerCellTitle {
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    font-size: 14px;
    text-align: right;
    color: var(--colorTextSecondary);
  }

  & .headerCellDescription {
    margin: 4px 0 0 0;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    font-size: 14px;
    text-align: right;
    color: var(--colorTextSecondary);
    width: 100%;

    box-sizing: content-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }


  & .cellContainer {
    padding: 0 16px;

    &.date {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      box-sizing: border-box;
      padding: 0 16px 0 0;
    }

    &.main {
      padding: 18px 17px 14px 15px;
      height: 54px;
      box-sizing: border-box;
      font-style: normal;
      font-weight: normal;
      line-height: 20px;
      font-size: 14px;
      text-align: right;
      color: var(--colorTextPrimary);

    }

    &.total {
      height: 54px;
      font-style: normal;
      font-weight: normal;
      line-height: 20px;
      font-size: 14px;
      color: var(--colorTextSecondary);
      text-align: right;
      padding: 16px 0 16px 16px;

      box-sizing: border-box;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & .cellTitle {
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      font-size: 14px;
      color: var(--colorTextSecondary);
    }

    & .cellDescription {
      margin: 0 0 0 0;
      font-style: normal;
      font-weight: normal;
      line-height: 20px;
      font-size: 14px;
      color: var(--colorTextSecondary);
      white-space: nowrap;
    }
  }


  & :global(.htCore th .relative),
  & :global(.htCore th .relative .colHeader) {
    height: 100%;
  }
}

td.cell {
  text-align: right;
}

td.totalFirstCell,
td.totalCell {
  background-color: var(--colorThDefault);
  font-weight: 500;
}

td.cellDisabled {
  background-color: var(--colorThDefault);
}

.errorContent {
  display: none;
}

.warningContent {
  display: none;
}

td.invalidCell,
td.warningCell {
  overflow: initial;
  position: relative;

  &:global(.current) {
    z-index: 1;

    & .errorContent {
      display: block;
    }
    & .warningContent {
      display: block;
    }
  }
}
