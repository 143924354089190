@import 'styles/core';

.header {
  margin-bottom: 8px;

  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  color: var(--colorTextSecondary);
}

.value {
  font-size: 32px;
  line-height: 32px;
  font-weight: 300;
  color: var(--colorTextPrimary);
}

.units {
  margin-right: 12px;

  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
}

.groupValue,
.changeValue {
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextSecondary);
}
