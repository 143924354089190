@import 'styles/core';

.layout {
  padding: 6px 24px 58px 24px;
  margin: 0;


  @media (--tablet) {
    padding: 8px 16px 16px 16px;
  }
}

.table {
  text-align: left;
  border-collapse: collapse;
  border-spacing: 0;
  display: block;
  overflow-x: auto;
  table-layout: fixed;

  & tr:not(:first-child) {
    & .expandCard {
      padding-top: 20px;
    }
  }
}

.row {
  display: flex;
  align-items: center;
}

.alignBaseline {
  align-items: baseline;
}

.bodyRow {
  border-top: 1px solid var(--colorBorderDefault);
}

.mainPadding {
  padding-left: 0;
  padding-right: 16px;
}

.contentPadding {
  padding-left: 16px;
  padding-right: 16px;

  @media (--tablet) {
    padding-left: 0;
  }
}


.spaceBetween {
  justify-content: space-between;
}

.mobileCell {
  display: none;

  @media (--tabletBig) {
    display: table-cell;
  }
}

.desktopCell {
  display: table-cell;

  @media (--tabletBig) {
    display: none;
  }
}

.mobileRow {
  display: none;

  @media (--tabletBig) {
    display: table-row;
  }
}

.desktopRow {
  display: table-row;

  @media (--tabletBig) {
    display: none;
  }
}

.mobileBorderTopNone {
  @media (--tablet) {
    border-top: none;
  }
}

.square {
  width: 12px;
  height: 12px;
  border-radius: 3px;
  margin: 0 8px 0 0;
  flex-shrink: 0;
}

.iconButton {
  position: absolute;
  right: 0;
  top: 14px;
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
  background: none;
  cursor: pointer;
}

.headerCellMain {
  width: 320px;
  min-width: 320px;
  max-width: 320px;
}

.headerCell {
  width: 300px;
}

.bodyCell {
  padding-top: 12px;
  padding-bottom: 12px;

  @media (--tabletBig) {
    padding-bottom: 16px;
  }
}

.mobileHeaderBodyCell {
  padding-bottom: 2px;
}

.headerCard {
  position: relative;
  width: 320px;
  display: block;
  text-align: left;
  padding: 16px 0 0 0;

  & .headerCardTitle {
  }

  & .headerCardName {
  }

  & .headerCardDates {
    width: 262px;
    text-overflow: ellipsis;

    white-space: nowrap;
    overflow: hidden;

    margin: 0;
    padding: 0;

    color: var(--colorTextSecondary);

    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
  }
}

.expandCard {
  padding: 15px 0 22px 0;


  @media (--tabletBig) {
    padding-top: 30px;
  }
}

.expandButton {
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
  background: none;
  cursor: pointer;
  fill: var(--colorTextSecondary);
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &.hidden {
    & > svg {
      transform: rotate(180deg);
    }
  }

  & > svg {
    margin: 0 0 0 4px;
  }

  &:hover {
    fill: var(--colorTextPrimary);
  }
}

.dropdownItem {
  width: 100%;
}
