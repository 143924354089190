@import 'styles/core';

.layout {
  min-width: 960px;
  min-height: calc(100vh - 60px);
  background-color: var(--colorBackgroundPrimary);
}

.contentWrapper {
  margin: 0 auto;
  padding: 0 24px 196px 24px;
}

.dashboardGroup {
  margin-top: 24px;
  padding: 16px 24px;
  background: var(--colorWhite);
  border: 1px solid var(--colorBorderDefault);
  border-radius: 3px;
  position: relative;
}

.dashboardGroupCyclesHarvest {
  margin-top: 12px;
}

.dashboardGroupHeaderWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 24px;
}

.dashboardGroupHeader {
  color: #4A4A49;
  font-weight: 500;
  font-size: 20px;
}

.header {
  margin: 0;
  padding: 32px 0;
  font-size: 24px;
  line-height: 32px;
  color: var(--colorTextPrimary);
  font-weight: 500;
}

.benchmarkingDashboardHeader {
  padding-bottom: 24px;

  @media (--tablet) {
    padding: 30px 0 24px;
  }
}

.filtersWrapper {
  display: flex;
}

.avgHarvestGroup {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-bottom: 32px;
}

.avgHarvest {
  width: 50%;
}

.varietySelect {
  background: none !important;
  border: none !important;
  font-weight: 500 !important;
}

.barChartContainer {
  width: 100%;
  padding-top: 8px;
}

.selectboxFilterWrapper {
  margin-right: 8px;
}

.sortingOptionsFilterWrapper {
  margin-right: 16px;
}

.benchmarkGroupFilterWrapper {
  height: 36px;
  position: relative;
  padding-left: 24px;

  &:before {
    content: '';
    position: absolute;
    top: 9px;
    left: 0;
    bottom: 9px;
    background-color: var(--colorBorderCasper);
    width: 1px;
  }
}

.optionsFooterWrapper {
  margin-top: 4px;
  padding-top: 2px;
  border-top: 1px solid var(--colorBorderCatskillWhite);
}

.dashboardGroupFilters {
  display: flex;
  align-items: center;
}

.additionalMetricsFilterWrapper {
  margin-left: 16px;
}

.joinBtn {
  padding: 6px 32px 6px 12px;
}
