@import 'styles/core';

.totalRow {
  background-color: var(--colorBackgroundHover);
  color: var(--colorTextSecondary);
  font-weight: 500;
}

.chartIconContainer {
  width: 12px;
  height: 12px;
}

.point {
  width: 12px;
  height: 12px;
}

td.totalCell {

}

.tdValue {
  white-space: nowrap;
}

th.chartIconWrapper,
td.chartIconWrapper {
  width: 36px;
}

.chartIconWrapper {
  cursor: pointer;

  & svg {
    fill: #9EA4A8;
  }
}

.activeTh {
  cursor: pointer;

  &:hover {
    background-color: #e8ebed;
  }
}


.tr {
  height: 18px;
}

.trSecondLevel td:nth-child(2) {
  padding-left: 56px;
}

.nameWrapper {
  display: flex;
  align-items: center;
  white-space: nowrap;

  max-width: 100%;

  & div, & a {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & .tooltip {
    overflow: inherit;
  }
}

.arrowRightIcon {
  width: 20px;
  height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  & svg path {
    fill: #9EA4A8;
  }
}

.arrowIconRotate {
  transform: rotate(90deg);
}

.trSecondLevel td:nth-child(2) {
  padding-left: 56px;
}

.percentValue {
  color: var(--colorTextSecondary);
}

.plantingCycleLink {
  display: block;
  color: var(--colorTextPrimary);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.rowText {
  overflow: hidden;
  text-overflow: ellipsis;
}
