@import 'styles/core';

.plateWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;
}

.icon {
  margin-bottom: 28px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  margin-bottom: 8px;

  font-size: 16px;
  line-height: 20px;
  font-weight: 600;

  color: var(--colorTextPrimary);
}

.subHeader {
  padding: 0 70px;

  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  color: var(--colorTextPrimary);
}
