@import 'styles/core';

.rangePickerDropdown {
  width: fit-content;
}

.dropdownContent {
  left: 0;
  top: calc(100% + 4px);
  width: fit-content;
  padding: 16px 12px 12px;
  /* @media (--tablet) {
    max-width: 90vw;
    right: 0;
    left: initial;
  } */
}
.alignRight {
  right: 0;
  left: initial;
}

.mobileMenuInner {}

.withMobileVersion {
  & .mobileRangePickerContainer {
    display: none;
  }

  @media (--phoneLandscape) {
    & .mobileRangePickerContainer {
      display: block;
    }
    & .rangePickerDropdown {
      display: none;
    }
  }
}
