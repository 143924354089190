@import 'styles/core';


.dropdown {
  position: relative;
}

.dropdownButton {
  cursor: pointer;
}

.dropdownContent {
  position: absolute;
  top: 100%;
  right: 12px;

  min-width: 120px;
  padding: 8px 0;

  border-radius: 4px;
  border: solid 1px #e1e8f0;
  background: #fff;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, .02), 0 2px 4px 0 rgba(0, 0, 0, .04);

  transition: opacity 150ms ease-out, max-height 150ms ease-out;
  z-index: 1;
}

.dropdownContent {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.dropdown:hover .dropdownContent {
  opacity: 1;
  transform: translateY(0);
}
