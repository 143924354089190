@import 'styles/core';

.layout {
  & .input {
    width: 100%;
    height: 48px;
    margin: 24px 0 0 0;
    text-align: left;
    border-radius: 4px;
    box-sizing: border-box;
  }
}

.headerRangePicker {
  z-index: 161;
}

.form {
  width: 100%;
}

.row {
  & + .row {
    margin: 24px 0 0 0;
  }
}

.title {
  margin: 0 0 32px 0;
  font-size: 24px;
  line-height: 32px;
  color: var(--colorTextPrimary);
  letter-spacing: 0.33px;
  text-align: left;
  font-weight: 500;
}

.part {
  & + .part {
    margin: 48px 0 0 0;
  }
}

.subtitle {
  margin: 0;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.86);
  line-height: 20px;
  color: var(--colorTextPrimary);
  letter-spacing: -0.32px;
  font-weight: 500;
}

.description {
  margin: 12px 0 24px 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
  line-height: 20px;
  color: var(--colorTextSecondary);
  letter-spacing: -0.15px;
  font-weight: 400;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 48px 0 0 0;
}

.button {
  height: 40px;
  box-sizing: border-box;

  & + .button {
    margin-left: 8px;
  }
}

.customDatePickerInput {
}

.customDatePickerInputWrapper {
  margin: 24px 0 0 0;
  width: 480px;
  height: 48px;
}

.optionSupport {
  display: flex;
  align-items: center;

  & .optionIcon {
    margin: 0 4px 0 0;
    fill: var(--colorTextSecondary);
  }

  & .optionTitle {
    font-size: 14px;
    color: var(--colorTextPrimary);
    letter-spacing: -0.15px;
    line-height: 20px;
  }

  & .optionDescription {
    margin: 0 0 0 4px;
    font-size: 14px;
    color: var(--colorTextNeutral);
    letter-spacing: -0.15px;
    line-height: 20px;
  }
}

.header {
  margin: 24px 0 7px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (--tablet) {
    flex-direction: column;
    align-items: flex-start;
  }


  & .headerPartColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  & .headerPartRow {
    display: flex;
    align-items: center;
    margin: 0 0 0 8px;

    @media only screen and (--tablet) {
      margin: 21px 0 0 0;
      width: 100%;
    }
  }

  & .headerPartField {
    display: flex;
    align-items: center;

    & + .headerPartField {
      margin: 0 0 0 8px;

      @media only screen and (--tablet) {
        margin: 0;
      }
    }

    &.period {
      @media only screen and (--tablet) {
        display: none;
      }
    }

  }

  & .headerTitle {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: normal;
    line-height: 32px;
    font-size: 24px;
    color: var(--colorTextPrimary);
  }

  & .headerDescription {
    margin: 4px 0 0 0;
    padding: 0;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    font-size: 14px;
    color: var(--colorTextSecondary);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @media only screen and (--tablet) {
      margin: 0 0 0 0;
      white-space: normal;
    }
  }

  & .headerTooltip {
    max-width: 320px !important;
    text-align: left !important;
  }
}

.loader {
  position: static;
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 344px;
  width: 100%;
}

.dateSwitchingMode {
  padding: 6px 6px;
  margin-right: 8px;

  &:focus {
    background-color: var(--colorWhite);
  }

  &.active {
    background-color: var(--colorBorderDefault);
  }

  @media (--tablet) {
    margin-right: 32px;
  }
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.arrowsClockwiseIcon.dropdownItem svg {
  fill: none;
}

.dropdownMenuButton {
  display: none;
  margin-left: 8px;

  @media (--tablet) {
    display: block;
  }
}

.cropSwitch {
  @media (--tablet) {
    display: none;
  }
}
