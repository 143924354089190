@import 'styles/core';


.layout {
  min-height: calc(100vh - 60px);
  overflow-x: auto;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 359px) {
    align-items: flex-start;
  }
}

.body {
  min-width: 328px;
  width: 560px;
  box-sizing: content-box;

  padding: 0 16px;

  @media only screen and (--tablet) {
    width: 100%;
    box-sizing: border-box;
  }
}

.controls {
  padding: 32px 0 0 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
}

.back {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
}

.backIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.backText {
  line-height: 18px;
  margin: 0 0 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  color: var(--colorTextSecondary);
  letter-spacing: -0.08px;
}

.content {
  margin: 32px auto 0 auto;
  padding: 0 0 48px 0;

  @media only screen and (--tablet) {
    padding: 0 0 120px 0;
  }

  @media only screen and (--phoneLandscape) {
    margin: 22px auto 0 auto;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0 0 0;

  @media only screen and (--phoneLandscape) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.headerTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: var(--colorTextPrimary);

  @media only screen and (--tablet) {
    font-size: 20px;
  }
}

.headerStep {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: var(--colorTextSecondary);
}
