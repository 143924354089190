@import 'styles/core';

.link {
  text-decoration: none;
  color: var(--colorTextPrimary);
}

.link:hover {
  text-decoration: underline;
  color: var(--colorTextPrimary);
}
