@import 'styles/core';

.settingsReports {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorTextPrimary);
}

.header {
  margin-bottom: 4px;
}

.infoPlate {
  margin: 24px 0;
}

.supportButton {
  display: inline-block;
  padding: 0;

  color: var(--colorPrimary);
  font-weight: 500;
}

.tableWrapper {
  max-width: 563px;

  @media (--tablet) {
    max-width: 100%;
  }

  & .th, & .td {
    text-align: left;
    white-space: nowrap;
  }
}
