@import 'styles/core';

.axisY {
    & :global(.domain) {
      stroke: var(--colorChartBorder);
    }

    & :global(text) {
      font-size: 11px;
      line-height: 16px;
      fill: var(--colorTextPrimary);
    }
}
