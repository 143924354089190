@import 'styles/core';

.layout {
  height: 216px;
  min-height: 216px;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;

  @media (--tablet) {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.dates {
  max-width: 65%;
}

.tooltip {
  width: 336px;
}

.tooltipHeaderContent {
  & .locationName,
  & .dates {
    min-width: 171px;
    max-width: 215px;
    text-overflow: ellipsis;

    white-space: nowrap;
    overflow: hidden;
  }
}

.legend {
  max-width: 100%;
  padding-top: 24px;
}
