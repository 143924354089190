@import 'styles/core';

.card {

  & .item {
    overflow: hidden;
    border-color: var(--colorBorderDefault);
    border-style: solid;
    border-width: 1px 1px 0 1px;

    & label {
      display: flex;
      cursor: pointer;
      transition: all 0.25s ease-out;
      width: 100%;
      min-height: 40px;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 10px 16px;

      &:hover {
        background-color: var(--colorBackgroundHover);
      }
    }

    & > input {
      display: none;
    }

    & .lables {
      display: flex;
      vertical-align: middle;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      flex: 2;
      margin-right: 50px;

      & .title {
        display: flex;
        vertical-align: middle;
        flex: 1;
        flex-direction: column;
        font-size: 14px;
        line-height: 20px;
        color: var(--colorTextPrimary);
      }

      & .subTitle {
        display: flex;
        vertical-align: middle;
        flex-direction: column;
        font-size: 14px;
        line-height: 20px;
        color: var(--colorTextSecondary);
      }

      @media (--tablet) {
        & .title {
          flex-basis: 100%;
        }
      }
    }

    & .arrow {
      display: flex;
      width: 9px;
      height: 9px;
      border-top: 2px solid var(--colorTextSecondary);
      border-right: 2px solid var(--colorTextSecondary);
      transform: rotate(135deg);
      vertical-align: middle;
      transition: transform 0.25s;
    }

    & .content {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.25s ease-out, padding 0.25s ease-out;
      & .contentWrap {
         padding: 10px;
      }
    }

    & > input:checked ~ .content {
      max-height: 500px;
      transition: max-height 0.25s ease-in, padding 0.25s ease-in;
    }

    & > input:checked ~ label > .arrow {
      transform: rotate(-45deg) translateY(3px) translateX(-3px);
      transition: transform 0.25s;
    }

    & > input:checked ~ label {
      background-color: var(--colorThDefault);
    }
  }

  & .item:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  & .item:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: 1px solid var(--colorBorderDefault);
  }

}
