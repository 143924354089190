@import 'styles/core';


.plusWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;

  border: 1px solid var(--colorBorderCasper);
  background-color: var(--colorWhite);

  /* &:hover {
    background-color: var(--colorBorderDefault);
  } */
}

.switchContent {
  right: 0;
  top: calc(100% + 8px);
  width: 288px;
  height: 196px;

  &.positionTop {
    top: initial;
    bottom: calc(100% + 8px);
  }
}

.alignLeft {
  right: initial;
  left: 0;
}

.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.title {
  margin-left: 8px;
  color: var(--colorTextSecondary);
  font-weight: 500;
}
