@import 'styles/core';

.selectPopup {
  min-width: 262px;
}

.checkboxesContainer {
  padding: 2px 0;
}

.checkboxItem {
  padding: 6px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: translate(0, 0);

  &:hover {
    background-color: var(--colorBackgroundHover);
  }
}

.dash {
  margin-right: 4px;
  fill: var(--colorTextSecondary);
  cursor: move;
}
