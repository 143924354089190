@import 'styles/core';

.container {
  margin-top: 48px;

  & .title {
     font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: var(--colorTextPrimary);
    padding-left: 2px;
  }

  & .valueInputContainer {
    padding-left: 2px;
    margin-top: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .inputWithLabel {
      display: flex;
      align-items: center;

      & .label {
        padding-right: 20px;
        min-width: 150px;
      }
      & .valueInput {
        width: 72px;
        height: 28px;
        border: 1px solid var(--colorBorderSecondary);
        padding: 4px 8px;
        display: block;
        font-family: inherit;
        border-radius: 4px;
        -webkit-appearance: none;
        color: var(--colorTextPrimary);
      }
    }
  }
}

.errorLabel {
  color: var(--colorNegative);
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
  margin-top: 4px;
}

.error {
  border: 1px solid var(--colorNegative)!important;
}

.bulkEditIconWrapper {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover,
  &.active {
    background: var(--colorBorderDefault);
  }
}

.chartWrapper {
  margin: 0 -8px;
}

.iconTooltip {
  white-space: nowrap;
}
