@import 'styles/core';

.layout {
  min-height: calc(100vh - 60px);
  background-color: #FFF;
  min-width: 1080px;

  display: flex;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.container {
  padding: 32px 24px 25px;

  transition: width 150ms ease-out;
  width: 100%;

  @media (--tabletBig) {
    padding: 16px 16px 25px;
  }

  &.sidePanelOpened {
    width: calc(100% - 311px);
  }
}

.content {
  /*max-width: 1440px;*/
  margin: 0 auto;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.backButton {
  margin-top: 6px;
  padding-right: 8px;
}

.rightControls {
  display: flex;
  flex-direction: row;
}

.restoreBanner {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #777776;
}

.restoreButton {
  text-decoration: underline;
  cursor: pointer;
  margin-left: 5px;
}

.addDataButton {
  margin-right: 8px;
}

.publishButton {

}

.publishButtonTitle {

}

.addButtonTitle {

}

.titlesContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.titleText {
  font-size: 24px;
  color: #4A4A49;
  font-weight: 500;
  line-height: 32px;
  padding-right: 12px;
}

.iconsContainer {
  display: inline-flex;
  align-items: flex-end;
}

.titleStatusIcon {
  background: #EFF2F4;
  border-radius: 4px;
  padding: 4px 8px 4px 8px;
  color: #777776;
  font-weight: 500;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.historyButton {
  padding: 0;

  &:hover svg path,
  &:focus svg path {
    fill: var(--colorPrimary);
  }

  &:hover,
  &:focus,
  &:active {
    color: var(--colorPrimary);
  }
}

.historyControls {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.iconPublished {
  background: #E0F6F3;
  color: #00B197;
}

.iconNotPublished {
  background: #FBF3E7;
  color: #DB973A;
}

.titleStatusIconSvg {
  width: 14px;
}

.titleStatusIconText {
  margin-left: 4px;
  margin-right: 4px;
}

.versionsBadge {
  position: relative;
  cursor: pointer;

  margin-right: 8px;
}

.versionsList {
  width: 216px;
  user-select: none;
}

.dropdownItem {
  width: 100%;
  padding: 0 2px;
}

.dropdownContent {
  right: initial;
  left: 0;
  top: calc(100% + 8px);
}

.subtitleRow {
  margin-top: 6px;
  color: #777776;
  font-weight: normal;
  font-size: 13px;
}

.tabs {
  display: flex;
  margin-top: 20px;
  margin-bottom: 36px;

  border-bottom: 1px solid #E7E9EE;

  @media (--tablet) {
    margin-bottom: 24px;
  }

  justify-content: center;
}

.tab {
  cursor: pointer;
  padding: 16px 0;
  margin-right: 32px;
  margin-bottom: -2px;

  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  color: var(--colorTextSecondary);
  position: relative;
}

.activeTab {
  color: var(--colorPrimary);
  /*box-shadow: inset 0 -5px 0 0 var(--colorPrimary);*/

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -2px;
    width: calc(100% + 4px);
    height: 3px;
    background-color: var(--colorPrimary);
  }
}

.tabsContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fieldsButton {
  padding: 8px;
}

.selectMenu {
  width: 44px;
}

.headerControls {
  display: flex;
  align-items: center;
}

.headerControlsButtons {
  display: flex;
}

.cancelButton {
  margin-right: 8px;
}

.problemPlate {
  margin: 0 auto;
  margin-bottom: 16px;
  max-width: 600px;
}

.separaror {
  margin: 2px 16px;
  height: 20px;
  width: 1px;
  background-color: var(--colorBorderCasper);
}
