@import 'styles/core';

.tooltipDate {
  margin-bottom: 12px;

  font-size: 14px;
  color: var(--colorTextPrimary);
  line-height: 20px;
  font-weight: 500;
}

.line {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  font-size: 13px;
  color: var(--colorTextPrimary);
  line-height: 18px;

  &:last-child {
    margin-bottom: 0;
  }
}

.lineHeader {
  display: flex;
  align-items: flex-start;
}

.lineSubTitle {
  display: block;
  color: var(--colorTextSecondary);
}

.lineTitle {
  display: block;
  width: max-content;
}

.lineColor {
  display: block;
  width: 12px;
  height: 4px;
  margin-right: 8px;
  background: #869BAD;
  margin-top: 6px;
}

.lineValue {
  font-weight: 500;
  white-space: nowrap;
}

.spased {
  & .lineValue {
    padding: 0;
  }
}
