@import 'styles/core';

.tooltip {
  z-index: 1000;
  padding: 12px;
  color: var(--colorTextPrimary);
  font-weight: 400;
  font-size: 13px;
  min-width: 300px;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #E1E8F0;
  box-shadow: 0 8px 24px 0 rgba(0,0,0,0.02), 0 2px 4px 0 rgba(0,0,0,0.04);
  border-radius: 4px;
  pointer-events: none;
}

.triangle {
  width: 0;
  height: 0;
  position: absolute;
  bottom: -7px;

  &:before {
    content: '';
    position: absolute;
    top: -6px;
    left: 20%;
    right: 50%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-top: 7px solid #E1E8F0;
    border-bottom-width: 7px;
    box-sizing: border-box;
  }

  &:after {
    content: '';
    position: absolute;
    top: -7px;
    left: 20%;
    right: 50%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-top: 7px solid #ffffff;
    border-bottom-width: 7px;
    box-sizing: border-box;
  }
}
