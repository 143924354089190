@import 'styles/core';

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 12px 0 0;
  border-top: 1px solid var(--colorBorderDefault);
}

.cancelButton,
.okButton {
  padding: 6px 16px;
}

.cancelButton {
  margin-right: 8px;
}

.withMobileVersion {
  @media (--phoneLandscape) {
    & .actions {
      position: fixed;
      bottom: 0;
      left: 12px;
      right: 12px;
      padding-bottom: 12px;
      width: calc(100% - 24px);
      background: var(--colorWhite);
    }
  }
}


.tabsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-top: 16px;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--colorBorderDefault);
}

.tab {
  cursor: pointer;
  margin-right: 24px;

  text-decoration: none;
  font-size: 13px;
  font-weight: 500;
  color: var(--colorTextSecondary);
  position: relative;

  background: none;
  border: none;
  padding: 0;
}

.active {
  color: var(--colorPrimary);

  &:after {
    content: '';
    position: absolute;
    bottom: -13px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--colorPrimary);
  }
}

.dateInputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.field {
  width: calc(50% - 4px);
}

.tabContent {
  position: relative;

  & :global(.rdrDefinedRangesWrapper) {
    border: none;
    width: auto;
  }

  & :global(.rdrCalendarWrapper) {
    width: 100%;
  }

  & :global(.rdrArrowsWrapper) {
    justify-content: space-between;
  }

  & :global(.rdrMonth) {
    padding-left: 0; 
  }

  & :global(.rdrWeekDays) {
    margin-left: 0; 
  }

  & :global(.rdrPrevButtonVertical) {
    position: absolute;
    top: 0;
    right: 0;
  }

  & :global(.rdrNextButtonVertical) {
    position: absolute;
    bottom: 112px;
    right: 0;
  }
}
