@import 'styles/core';

.totalRow {
  background-color: var(--colorBackgroundHover);
  color: var(--colorTextSecondary);
  font-weight: 500;
}

.chartIconContainer {
  width: 12px;
  height: 12px;
}


td.totalCell {

}

.tdValue {
  white-space: nowrap;
}

th.chartIconWrapper,
td.chartIconWrapper {
  width: 36px;
  vertical-align: top;
}

.chartIconWrapper {
  cursor: pointer;

  & svg {
    fill: #9EA4A8;
  }
}

.activeTh {
  cursor: pointer;
  &:hover {
    background-color: #e8ebed;
  }
}

.point {
  width: 12px;
  height: 12px;
}

.tr {
  height: 20px;
}

.dataCell {
  text-align: right!important;
  white-space: nowrap;
}

.fieldName {
  text-align: left!important;
  white-space: nowrap;
}

.categoryName {
  white-space: nowrap;
  vertical-align: top;
}

.percentValue {
  color: var(--colorTextSecondary);
}

.plantingCycleLink {
  display: block;
  color: var(--colorTextPrimary);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

td.emptyCell {
  box-shadow: none;
}

td.emptyCellTotal {
  background-color: var(--colorThDefault) !important;
}

tr td.categoryNameTd {
  box-shadow: none;
}

tr td.categoryName.categoryNameTd {
  align-items: flex-start;
  overflow: visible;
  z-index: 11;

  & div, a {
    white-space: normal;
  }
}

.lastRow td:first-child,
.lastRow td:nth-child(2),
.lastRow td:nth-child(3) {
  box-shadow: inset 0 -1px 0 0 #e7e9ee;
}

.rowTotal td {
  background-color: var(--colorThDefault) !important;
}


.categoryName {
  & div, & a {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
